import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class CustomFieldService {
    res: any;
    apiEndPoint = NAVIGATE_ROUTES;
    constructor(private apiService: ApiService) {}

    // get currency and phone code with countries
    async getCurrencyWithCountries(): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.CUSTOM_FIELD_SETTING}/countries`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    // get all custom field by module
    async getAllCustomField(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.CUSTOM_FIELD_SETTING}?companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    // add new field based on module
    async addNewCustomField(data: any): Promise<Observable<any>> {
        this.res = [];
        await this.apiService
            .post(this.apiEndPoint.CUSTOM_FIELD_SETTING, data)
            .then((respdata) => {
                this.res.push(respdata);
            });
        return of(this.res);
    }

    // get custom field by id
    async getCustomFieldByID(
        fieldId: any,
        companyId: any
    ): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.CUSTOM_FIELD_SETTING}/field-details?id=${fieldId}&companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    // update status active/inactive of custom field
    async updateStatus(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.CUSTOM_FIELD_SETTING}/status`;
        await this.apiService.put(url, data).then((resData) => {
            this.res.push(resData);
        });
        return of(this.res);
    }

    // update field based on module
    async updateCustomField(data: any, fieldId: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.CUSTOM_FIELD_SETTING}?fieldId=${fieldId}`;
        await this.apiService.put(url, data).then((respdata) => {
            this.res.push(respdata);
        });
        return of(this.res);
    }

    // update field like task client and custom field toggle
    async manageFieldDetail(data: any) {
        this.res = [];
        const url = `${this.apiEndPoint.CUSTOM_FIELD_SETTING}/manage`;
        await this.apiService.post(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    // get updated fields data
    async getManageFieldDetail(companyId: any) {
        this.res = [];
        const url = `${this.apiEndPoint.CUSTOM_FIELD_SETTING}/manage-detail?companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }
}
