import { createAction, props } from '@ngrx/store';

export const updateUserStatus = createAction(
    '[userState] Update User State',
    props<{ data: any }>()
);

export const changeUserStatus = createAction(
    '[userState] Change User State',
    props<{ data: any }>()
);
