import { Injectable } from '@angular/core';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuickReplyService {
    queryURL: any;
    apiEndPoint = NAVIGATE_ROUTES;
    constructor(private _apiService: ApiService) {}

    async quickReplyList() {
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.QUICK_REPLY_LIST}`
        );
        return of(response?.data);
    }

    async addManageShoeHideList(data: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.QUICK_REPLY_LIST,
            data
        );
        return of(response);
    }

    async updateDeleteList(objFavorite: any) {
        const response: any = await this._apiService.put(
            this.apiEndPoint.QUICK_REPLY_LIST,
            objFavorite
        );
        return of(response);
    }
}
