<div
  class="dms-content-block mub-dms-content-block mub-dms-small-modal"
  appDropZone
  (onFileDropped)="uploadFiles($event)"
>
  <div class="user-chat-box-header-search">
    <div
      class="user-chat-main-header chat-room-main-block"
      [ngClass]="{ 'search-top': !selectedChatRoomDetails._id }"
    >
      <a
        class="user-chat-box"
        *ngIf="
          selectedChatRoomDetails.type !== 'CHACKLIST' &&
          selectedChatRoomDetails.type !== 'TASK' &&
          selectedChatRoomDetails.type
        "
      >
        <div class="user-sortname user-ba">
          <span
            class="user-short-name mx-auto"
            [ngStyle]="{ background: selectedChatRoomDetails?.color }"
            *ngIf="
              selectedChatRoomDetails?.memeber_profile == null ||
              selectedChatRoomDetails?.memeber_profile?.charAt(0) == '#'
            "
          >
            {{ selectedChatRoomDetails?.main_avatar }}
          </span>
          <img
            *ngIf="
              selectedChatRoomDetails?.memeber_profile &&
              selectedChatRoomDetails?.memeber_profile?.charAt(0) != '#'
            "
            src="{{ selectedChatRoomDetails?.memeber_profile }}"
            alt="profile image"
            draggable="false"
            class="img-avatar user-short-name mx-auto"
          />
        </div>
        <div class="user-name">
          <h2
            class="discussion-title"
            matTooltip="{{ selectedChatRoomDetails?.title }}"
          >
            <span>{{ selectedChatRoomDetails?.title }} </span>
          </h2>
          <p *ngIf="selectedChatRoomDetails?.discussion_type !== 'MEMBER'">
            {{ chatRoomAccesses?.length }}
            {{ "MEMBERS" | translate }}
          </p>
          <p *ngIf="selectedChatRoomDetails?.discussion_type === 'MEMBER'">
            {{
              (selectedChatRoomDetails?.otherMember?.user_id
                | userStateByUserid : onlineArr) === "online"
                ? "Online"
                : "Offline"
            }}
          </p>
        </div>
      </a>
      <div class="search-box-icone">
        <img
          src="assets/images/crossicon.svg"
          class="crossicon"
          alt="crossicon"
          (click)="openSearchbar = false; handelResetSearch()"
          *ngIf="openSearchbar"
          draggable="false"
        />
        <img
          src="assets/icons/searchicon.svg"
          class="searchicone"
          draggable="false"
          alt="searchicone"
          (click)="openSearchbar = true; focusElement()"
          *ngIf="
            !openSearchbar &&
            !(
              dateRange.controls['from_date'].value &&
              dateRange.controls['to_date'].value
            )
          "
          [ngClass]="{
            'check-list-class': selectedChatRoomDetails?.type == 'CHACKLIST',
            'quick-comment-class': selectedChatRoomDetails?.taskQuickRelpy,
            'task-comment-class': selectedChatRoomDetails?.taskComment
          }"
        />
        <span
          class="search-box search-box-cal-icon calendar-design-cls"
          *ngIf="!openSearchbar && selectedChatRoomDetails._id"
        >
          <img
            [matMenuTriggerFor]="menu"
            class="mr-2 only-image"
            draggable="false"
            alt="image"
            #menuContacts="matMenuTrigger"
            src="assets/icons/calendericon.svg"
          />
          <mat-menu #menu="matMenu" class="log-cal-mega-menu">
            <div class="search-cal-ctm-wrp" (click)="$event.stopPropagation()">
              <div class="search-cal-ctm">
                <mat-calendar
                  [maxDate]="todayDate"
                  [(selected)]="dateRanges"
                  (selectedChange)="rangeTaskDueDate($event)"
                ></mat-calendar>
                <div
                  class="search-cal-start-end-date-stm"
                  [ngStyle]="{ 'font-size': '12px' }"
                  *ngIf="startDate && endDate"
                >
                  <span class="mx-2">{{
                    dateRange?.controls["from_date"]?.value | date : "MMM d, y"
                  }}</span>
                  -
                  <span class="mx-2">{{
                    dateRange?.controls["to_date"]?.value | date : "MMM d, y"
                  }}</span>
                </div>
              </div>
              <button class="btn btn--bordered me-2" (click)="removeRange();closeMenu(menuContacts)">
                {{ "CANCEl_BUTTON" | translate }}
              </button>
              <button
                class="btn btn-primary float-right"
                (click)="onDateRangeChange();closeMenu(menuContacts)"
              >
                {{ "APPLY" | translate }}
              </button>
            </div>
          </mat-menu>
        </span>
        <a
          (click)="openDetails()"
          *ngIf="
            selectedChatRoomDetails.type !== 'CHACKLIST' &&
            selectedChatRoomDetails.type !== 'TASK' &&
            selectedChatRoomDetails.type
          "
        >
          <img
            src="assets/icons/menudots.svg"
            class="menudotscone mx-3"
            alt="menu dots icon"
            draggable="false"
            *ngIf="!openSearchbar && !isShowing"
        /></a>
      </div>
    </div>

    <div
      class="user-chat-search-header custom-header-search-area"
      *ngIf="openSearchbar"
    >
      <input
        #myInput
        type="text"
        class="form-control custom-search-feild custom-header-search-box"
        placeholder="Type here to Search"
        [(ngModel)]="search"
        (ngModelChange)="handelSearchMessage()"
        (keyup.enter)="searchDown()"
        (keyup.arrowdown)="searchUp()"
        (keyup.arrowup)="searchDown()"
        autofocus
      />

      <div class="in-one-line-set">
        <div
          class="calendarcross-chat pe-3"
          (click)="handelResetSearch(); focusElement()"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9 0.25C4.125 0.25 0.25 4.125 0.25 9C0.25 13.875 4.125 17.75 9 17.75C13.875 17.75 17.75 13.875 17.75 9C17.75 4.125 13.875 0.25 9 0.25ZM12.375 13.375L9 10L5.625 13.375L4.625 12.375L8 9L4.625 5.625L5.625 4.625L9 8L12.375 4.625L13.375 5.625L10 9L13.375 12.375L12.375 13.375ZM1.5 9C1.5 13.125 4.875 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9Z"
              fill="#9E9E9E"
            />
          </svg>
        </div>
        <div class="set-position me-3 ps-3">
          <p>{{ searchPreviousStack.length }}/{{ searchResponse["count"] }}</p>
        </div>
        <div class="main-arrow-set">
          <div class="drop-arrow d-flex align-items-center">
            <div class="firstarrow">
              <svg
                width="20"
                height="20"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-3"
                *ngIf="searchNextStack.length"
                (click)="searchDown()"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 0.25L13.25 6.5L12.375 7.375L7 2L1.625 7.375L0.75 6.5L7 0.25Z"
                  fill="#444"
                />
              </svg>
              <svg
                width="20"
                height="20"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-3"
                *ngIf="!searchNextStack.length"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 0.25L13.25 6.5L12.375 7.375L7 2L1.625 7.375L0.75 6.5L7 0.25Z"
                  fill="#444"
                />
              </svg>
            </div>
            <div class="secondarrow">
              <svg
                width="20"
                height="20"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                *ngIf="searchPreviousStack.length"
                (click)="searchUp()"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 7.75L0.75 1.5L1.625 0.625L7 6L12.375 0.625L13.25 1.5L7 7.75Z"
                  fill="#444"
                />
              </svg>
              <svg
                width="20"
                height="20"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                *ngIf="!searchPreviousStack.length"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 7.75L0.75 1.5L1.625 0.625L7 6L12.375 0.625L13.25 1.5L7 7.75Z"
                  fill="#444"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chat-box-space">
    <div
      #chatRoomScroll
      infiniteScroll
      class="msgContentBox"
      id="msgContentBox"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="500"
      [scrollWindow]="false"
      (scrolled)="onScrollUp()"
      (scroll)="handelScrollCharRoom()"
      #scrollframe
    >
      <div class="custom-inner-content">
        <div class="loadding-message" *ngIf="showLoaddingConversation">
          {{ "LOADING_NEW_CONVERSATION" | translate }}....
        </div>
        <div class="create-new-chat-message" *ngIf="!hasNextValue">
          <p *ngIf="selectedChatRoomDetails.type === 'DISCUSSION'">
            This discussion has been created by
            {{ selectedChatRoomDetails?.created_by?.first_name }}
            {{ selectedChatRoomDetails?.created_by?.last_name }}
          </p>
          <p>
            {{ selectedChatRoomDetails?.created_at | date : "EEEE, d MMMM, y" }}
          </p>
        </div>
        <div *ngFor="let group of discussionMessages | groupBy : 'date'">
          <div class="divider divider-five">
            <div *ngIf="group?.key !== 'New Message'">
              {{ group?.key | customDate }}
            </div>
            <div *ngIf="group?.key === 'New Message'">New Message</div>
          </div>
          <div
            class="msg-cover"
            [class]="
              currentActiveUserId === item?.sender?.user_id
                ? 'right-msg'
                : 'left-msg'
            "
            *ngFor="let item of group.value; let i = index"
            [id]="item._id"
            [style]="{
              'margin-bottom':
                group?.value[i + 1]?.timestamp === item.timestamp
                  ? '5px'
                  : '10px',
              'background-color': scrollId === item._id ? '#e8f0fc' : '#ffffff'
            }"
          >
            <div
              class="msg-user"
              *ngIf="currentActiveUserId !== item?.sender?.user_id"
            >
              <img
                [src]="item?.sender?.profile_image"
                alt="profile image"
                draggable="false"
                *ngIf="
                  item?.sender?.profile_image &&
                  item?.sender?.profile_image?.charAt(0) != '#' &&
                  (group?.value[i + 1]?.timestamp !== item.timestamp ||
                    group?.value[i + 1]?.sender?.user_id !==
                      item?.sender?.user_id)
                "
              />
              <span
                class="user-short-name mx-auto usertag"
                *ngIf="
                  item?.sender?.profile_image?.charAt(0) == '#' &&
                  (group?.value[i + 1]?.timestamp !== item.timestamp ||
                    group?.value[i + 1]?.sender?.user_id !==
                      item?.sender?.user_id)
                "
              >
                {{ item?.sender?.avatar }}
              </span>
              <p
                class="online-state"
                [ngClass]="{
                  'default-active-status':
                    (item?.sender?.user_id | userStateByUserid : onlineArr) ===
                    'online'
                }"
                *ngIf="
                  group?.value[i + 1]?.timestamp !== item.timestamp ||
                  group?.value[i + 1]?.sender?.user_id !== item?.sender?.user_id
                "
              ></p>
            </div>
            <div
              class="userMsgContent"
              (contextmenu)="selectedMessage = item"
              [cdkContextMenuTriggerFor]="context_menu"
            >
              <div
                class="userMsgBg"
                [class]="
                  item?.is_image && item?.message === ''
                    ? 'image-containner-corner'
                    : null
                "
              >
                <div
                  class="userMsgreply"
                  *ngIf="item?.parent?._id"
                  (click)="scroll(item?.parent?._id)"
                >
                  <h5>
                    {{ item?.parent?.sender?.first_name }}
                    {{ item?.parent?.sender?.last_name }}
                  </h5>

                  <div
                    class="images-containner"
                    *ngIf="
                      item?.parent?.message_type ===
                        MESSAGE_TYPES['DOCUMENT'] && selectedChatRoomDetails._id
                    "
                    [class]="
                      item?.parent?.attachement_details.length > 1
                        ? 'image-groups'
                        : null
                    "
                  >
                    <div
                      class="image-containner images-containner-outer image-camera"
                      *ngFor="let image of item?.parent?.attachement_details"
                      [class]="
                        item?.parent?.attachement_details.length > 4
                          ? 'child-group'
                          : null
                      "
                      [attr.data-after-content]="
                        item?.parent?.attachement_details?.length - 4
                      "
                    >
                      <img
                        [src]="image.attachement_url"
                        alt="attachment"
                        draggable="false"
                        (click)="openPreviewImage(image.attachment_id)"
                        *ngIf="image?.is_image"
                      />
                      <div
                        class="file-details"
                        *ngIf="!image?.is_image"
                        (click)="getSantizeUrl(image.attachement_thumbnail)"
                      >
                        <img
                          [src]="image.image"
                          alt="file detail"
                          draggable="false"
                        />{{ image?.attachement_name }}
                      </div>
                      <div *ngIf="image?.is_image" >
                        <svg class="image-upload-type"  *ngIf="image?.image_type==='camera'"  id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                          <defs>
                          <style>.cls-1{fill:none;}</style>
                          </defs>
                          <title>camera</title>
                          <path d="M29,26H3a1,1,0,0,1-1-1V8A1,1,0,0,1,3,7H9.46l1.71-2.55A1,1,0,0,1,12,4h8a1,1,0,0,1,.83.45L22.54,7H29a1,1,0,0,1,1,1V25A1,1,0,0,1,29,26ZM4,24H28V9H22a1,1,0,0,1-.83-.45L19.46,6H12.54L10.83,8.55A1,1,0,0,1,10,9H4Z"/>
                          <path d="M16,22a6,6,0,1,1,6-6A6,6,0,0,1,16,22Zm0-10a4,4,0,1,0,4,4A4,4,0,0,0,16,12Z"/>
                          <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
                        </svg>
  
  
                        <svg id="icon" class="image-upload-type"  *ngIf="image?.image_type==='gallery'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                          <defs>
                          <style>.cls-1{fill:none;}</style>
                          </defs><title>image</title>
                          <path d="M19,14a3,3,0,1,0-3-3A3,3,0,0,0,19,14Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,19,10Z"/>
                          <path d="M26,4H6A2,2,0,0,0,4,6V26a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V6A2,2,0,0,0,26,4Zm0,22H6V20l5-5,5.59,5.59a2,2,0,0,0,2.82,0L21,19l5,5Zm0-4.83-3.59-3.59a2,2,0,0,0-2.82,0L18,19.17l-5.59-5.59a2,2,0,0,0-2.82,0L6,17.17V6H26Z"/>
                          <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
                        </svg>
  
                        <svg id="icon" class="image-upload-type"   *ngIf="image?.image_type==='web'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                          <defs>
                          <style>.cls-1{fill:none;}</style>
                          </defs>
                          <title>desktop</title>
                          <path d="M28,4H4A2,2,0,0,0,2,6V22a2,2,0,0,0,2,2h8v4H8v2H24V28H20V24h8a2,2,0,0,0,2-2V6A2,2,0,0,0,28,4ZM18,28H14V24h4Zm10-6H4V6H28Z" transform="translate(0)"/>
                          <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div
                    class="images-containner"
                    *ngIf="
                      item?.parent?.message_type ===
                        MESSAGE_TYPES['DOCUMENT'] &&
                      !selectedChatRoomDetails._id
                    "
                    [class]="
                      item?.parent?.uploadFile.length > 1
                        ? 'image-groups'
                        : null
                    "
                  >
                    <div
                      class="image-containner images-containner-outer"
                      *ngFor="
                        let image of item?.parent?.uploadFileInBase64;
                        let i = index
                      "
                      [class]="
                        item?.parent?.uploadFileInBase64.length > 4
                          ? 'child-group'
                          : null
                      "
                      [attr.data-after-content]="
                        item?.parent?.uploadFileInBase64?.length - 4
                      "
                    >
                      <span
                        class="custom-wh"
                        [ngClass]="{
                          onlyForImg: item?.parent?.uploadFile[i]?.is_image
                        }"
                      >
                        <img
                          [src]="image"
                          alt="upload"
                          draggable="false"
                          *ngIf="item?.parent?.uploadFile[i]?.is_image"
                      /></span>

                      <div
                        class="file-details"
                        *ngIf="!item?.parent?.uploadFile[i]?.is_image"
                      >
                        <img
                          [src]="item?.parent?.uploadFile[i]?.image"
                          alt="upload"
                          draggable="false"
                          *ngIf="!item?.parent?.uploadFile[i]?.is_image"
                        />
                        <span *ngIf="!item?.parent?.uploadFile[i]?.is_image">{{
                          item?.parent?.uploadFile[i].name
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="
                      item?.parent?.message_type === MESSAGE_TYPES['CONTACT']
                    "
                  >
                    <div class="d-flex justify-content">
                      <div class="user-short-name mr-0">
                        {{
                          utilsService.forShortName(item?.parent?.contact_name)
                        }}
                      </div>
                      <strong>{{ item?.parent?.contact_name }}</strong
                      ><br />
                    </div>
                    <span>{{ item?.parent?.contact_number }}</span>
                  </div>

                  <div
                    class="images-containner"
                    *ngIf="
                      item?.parent?.message_type === MESSAGE_TYPES['AUDIO'] &&
                      selectedChatRoomDetails._id
                    "
                  >
                    <div
                      class="image-containner"
                      *ngFor="let audio of item?.parent?.attachement_details"
                    >
                      <audio
                        id="player"
                        controls
                        [src]="audio.attachement_url"
                        (play)="onPlay(i)"
                      ></audio>
                      <p class="mb-0"></p>
                    </div>
                  </div>

                  <div
                    class="images-containner"
                    *ngIf="
                      item?.parent?.message_type === MESSAGE_TYPES['AUDIO'] &&
                      !selectedChatRoomDetails._id
                    "
                  >
                    <div
                      class="image-containner"
                      *ngFor="let audio of item?.parent?.audioFiles"
                    >
                      <audio controls="controls" autobuffer="autobuffer">
                        <source [src]="audio?.result" />
                      </audio>
                    </div>
                  </div>

                  <div
                    (click)="
                      redirectMap(
                        item?.parent?.latitude,
                        item?.parent?.longitude
                      )
                    "
                    *ngIf="
                      item?.parent?.message_type === MESSAGE_TYPES['LOCATION']
                    "
                    class="position-relative"
                  >
                    <img
                      class="map-image"
                      src="../../../../assets/images/map_image.png"
                      alt="map"
                      draggable="false"
                    />
                    <span class="set-to-position"
                      ><img
                        class="location-pin"
                        src="../../../../assets/images/loctaion_pin.png"
                        alt="location"
                        draggable="false"
                    /></span>
                  </div>

                  <div
                    class="info-box-click mt-4"
                    *ngIf="item?.parent?.message_type == MESSAGE_TYPES['INFO']"
                  >
                    <div
                      class="d-flex align-items-baseline justify-content-between"
                    >
                      <div class="limited-pvt">
                        <h6>{{ item?.parent?.clientInfo?.company_name }}</h6>
                        <label
                          >{{ item?.parent?.clientInfo?.client_name }}
                          <span
                            *ngIf="item?.parent?.clientInfo?.gender"
                            class="mail-btn-add"
                            >{{ item?.parent?.clientInfo?.gender }}</span
                          ></label
                        >
                      </div>
                      <div
                        class="info-cls"
                        (click)="
                          openContactDetails(item?.parent?.clientInfo?.id)
                        "
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.5 10.5498C0.5 16.3488 5.20101 21.0498 11 21.0498C16.799 21.0498 21.5 16.3488 21.5 10.5498C21.5 4.75081 16.799 0.0498047 11 0.0498047C5.20101 0.0498047 0.5 4.75081 0.5 10.5498ZM2 10.5498C2 5.57924 6.02944 1.5498 11 1.5498C15.9706 1.5498 20 5.57924 20 10.5498C20 15.5204 15.9706 19.5498 11 19.5498C6.02944 19.5498 2 15.5204 2 10.5498ZM11.75 14.7998V8.0498H8.75V9.5498H10.25V14.7998H8V16.2998H14V14.7998H11.75ZM9.875 4.6748C9.875 4.05348 10.3787 3.5498 11 3.5498C11.6213 3.5498 12.125 4.05348 12.125 4.6748C12.125 5.29612 11.6213 5.7998 11 5.7998C10.3787 5.7998 9.875 5.29612 9.875 4.6748Z"
                            fill="#276EF1"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="intor-contacts mt-4">
                      <div class="row align-items-center">
                        <div class="col-lg-2 my-1">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.75 0.799805H1.25C0.55993 0.800494 0.000689037 1.35973 0 2.0498V15.7998C0.000689037 16.4899 0.55993 17.0491 1.25 17.0498H8.75C9.43993 17.0488 9.99897 16.4897 10 15.7998V2.0498C9.99931 1.35973 9.44007 0.800494 8.75 0.799805ZM8.75 2.0498V3.2998H1.25V2.0498H8.75ZM1.25 15.7998V4.5498H8.75V15.7998H1.25Z"
                              fill="#276EF1"
                            />
                          </svg>
                        </div>
                        <div class="col-lg-10 my-1">
                          <p class="mb-0">
                            {{ item?.parent?.clientInfo?.mobile_number }}
                          </p>
                        </div>
                      </div>

                      <div class="row align-items-center">
                        <div class="col-lg-2 my-1">
                          <svg
                            width="18"
                            height="13"
                            viewBox="0 0 18 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.5 0.0498047H1.5C0.809644 0.0498047 0.25 0.609449 0.25 1.2998V11.2998C0.25 11.9902 0.809644 12.5498 1.5 12.5498H16.5C17.1904 12.5498 17.75 11.9902 17.75 11.2998V1.2998C17.75 0.609449 17.1904 0.0498047 16.5 0.0498047ZM15.125 1.2998L9 5.5373L2.875 1.2998H15.125ZM1.5 11.2998V1.86855L8.64375 6.8123C8.858 6.96093 9.142 6.96093 9.35625 6.8123L16.5 1.86855V11.2998H1.5Z"
                              fill="#276EF1"
                            />
                          </svg>
                        </div>
                        <div class="col-lg-10 my-1">
                          <p class="mb-0">
                            {{ item?.parent?.clientInfo?.email }}
                          </p>
                        </div>
                      </div>

                      <div class="row align-items-center">
                        <div class="col-lg-2 my-1">
                          <svg
                            width="14"
                            height="20"
                            viewBox="0 0 14 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.125 7.24414C0.176434 3.49783 3.25365 0.501736 7 0.550389C10.7463 0.501736 13.8236 3.49783 13.875 7.24414C13.8734 8.69887 13.3899 10.1121 12.5 11.2629L7 19.3004L1.5 11.2629C0.610106 10.1121 0.12658 8.69887 0.125 7.24414ZM12.625 7.24414C12.5668 4.19111 10.0533 1.75857 7 1.80039C3.94671 1.75857 1.43317 4.19111 1.375 7.24414C1.37409 8.44549 1.78184 9.61143 2.53125 10.5504L7 17.0879L11.5063 10.5004C12.2316 9.56996 12.6253 8.42389 12.625 7.24414ZM7 5.5498C8.03553 5.5498 8.875 6.38927 8.875 7.4248C8.875 8.46034 8.03553 9.2998 7 9.2998C5.96447 9.2998 5.125 8.46034 5.125 7.4248C5.125 6.38927 5.96447 5.5498 7 5.5498ZM3.875 7.4248C3.875 5.69892 5.27411 4.2998 7 4.2998C8.72589 4.2998 10.125 5.69892 10.125 7.4248C10.125 9.15069 8.72589 10.5498 7 10.5498C5.27411 10.5498 3.875 9.15069 3.875 7.4248Z"
                              fill="#276EF1"
                            />
                          </svg>
                        </div>
                        <div class="col-lg-10 my-1">
                          <p class="mb-0">
                            {{ item?.parent?.clientInfo?.company_address }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p [innerHTML]="item?.parent?.message"></p>
                </div>

                <div
                  class="images-containner"
                  *ngIf="
                    item?.message_type === MESSAGE_TYPES['AUDIO'] &&
                    selectedChatRoomDetails._id
                  "
                >
                  <div
                    class="image-containner"
                    *ngFor="let audio of item?.attachement_details"
                  >
                    <audio
                      id="player"
                      controls
                      [src]="audio.attachement_url"
                      (play)="onPlay(i)"
                    ></audio>
                    <p class="mb-0"></p>
                  </div>
                </div>
                <div
                  class="images-containner"
                  *ngIf="
                    item?.message_type === MESSAGE_TYPES['AUDIO'] &&
                    !selectedChatRoomDetails._id
                  "
                >
                  <div
                    class="image-containner"
                    *ngFor="let audio of item?.audioFiles"
                  >
                    <audio controls="controls" autobuffer="autobuffer">
                      <source [src]="audio?.result" />
                    </audio>
                  </div>
                </div>
                <div
                  class="images-containner"
                  *ngIf="
                    item?.message_type === MESSAGE_TYPES['DOCUMENT'] &&
                    selectedChatRoomDetails._id
                  "
                  [class]="
                    item.attachement_details.length > 1 ? 'image-groups' : null
                  "
                >
                  <div
                    class="image-containner images-containner-outer image-camera"
                    *ngFor="let image of item?.attachement_details"
                    [class]="
                      item.attachement_details.length > 4 ? 'child-group' : null
                    "
                    [attr.data-after-content]="
                      item?.attachement_details?.length - 4
                    "
                  >
                    <span class="custom-wh onlyforNoThumbnails">
                      <img
                        [src]="getSantizeUrl(image.attachement_url)"
                        alt="attachment"
                        draggable="false"
                        (click)="openPreviewImage(image.attachment_id)"
                        *ngIf="image?.is_image"
                      />
                      
                      <div class="image-body" (click)="downloadFile(image)">
                      </div>
                    </span>
                    <div class="file-details" *ngIf="!image?.is_image">
                      <img
                        [src]="image.image"
                        alt="file detail"
                        *ngIf="!image?.is_image"
                        draggable="false"
                      />
                      <span
                        *ngIf="!image?.is_image"
                        (click)="downloadFile(image)"
                        class="discussion-title"
                        matTooltip="{{ image?.attachement_name }}"
                        >{{ image?.attachement_name }}</span
                      >
                    </div>
                    <div *ngIf="image?.is_image" >
                      <svg  class="image-upload-type" *ngIf="image?.image_type==='camera'"  id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <defs>
                        <style>.cls-1{fill:none;}</style>
                        </defs>
                        <title>camera</title>
                        <path d="M29,26H3a1,1,0,0,1-1-1V8A1,1,0,0,1,3,7H9.46l1.71-2.55A1,1,0,0,1,12,4h8a1,1,0,0,1,.83.45L22.54,7H29a1,1,0,0,1,1,1V25A1,1,0,0,1,29,26ZM4,24H28V9H22a1,1,0,0,1-.83-.45L19.46,6H12.54L10.83,8.55A1,1,0,0,1,10,9H4Z"/>
                        <path d="M16,22a6,6,0,1,1,6-6A6,6,0,0,1,16,22Zm0-10a4,4,0,1,0,4,4A4,4,0,0,0,16,12Z"/>
                        <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
                      </svg>


                      <svg id="icon" class="image-upload-type"  *ngIf="image?.image_type==='gallery'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <defs>
                        <style>.cls-1{fill:none;}</style>
                        </defs><title>image</title>
                        <path d="M19,14a3,3,0,1,0-3-3A3,3,0,0,0,19,14Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,19,10Z"/>
                        <path d="M26,4H6A2,2,0,0,0,4,6V26a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V6A2,2,0,0,0,26,4Zm0,22H6V20l5-5,5.59,5.59a2,2,0,0,0,2.82,0L21,19l5,5Zm0-4.83-3.59-3.59a2,2,0,0,0-2.82,0L18,19.17l-5.59-5.59a2,2,0,0,0-2.82,0L6,17.17V6H26Z"/>
                        <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
                      </svg>

                      <svg id="icon" class="image-upload-type"   *ngIf="image?.image_type==='web'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <defs>
                        <style>.cls-1{fill:none;}</style>
                        </defs>
                        <title>desktop</title>
                        <path d="M28,4H4A2,2,0,0,0,2,6V22a2,2,0,0,0,2,2h8v4H8v2H24V28H20V24h8a2,2,0,0,0,2-2V6A2,2,0,0,0,28,4ZM18,28H14V24h4Zm10-6H4V6H28Z" transform="translate(0)"/>
                        <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  class="images-containner"
                  *ngIf="
                    item?.message_type === MESSAGE_TYPES['DOCUMENT'] &&
                    !selectedChatRoomDetails._id
                  "
                  [class]="item.uploadFile.length > 1 ? 'image-groups' : null"
                >
                  <div
                    class="image-containner images-containner-outer"
                    *ngFor="
                      let image of item?.uploadFileInBase64;
                      let i = index
                    "
                    [class]="
                      item.uploadFileInBase64.length > 4 ? 'child-group' : null
                    "
                    [attr.data-after-content]="
                      item?.uploadFileInBase64?.length - 4
                    "
                  >
                    <span class="custom-wh">
                      <img
                        [src]="image"
                        *ngIf="item?.uploadFile[i]?.is_image"
                        alt="upload"
                        draggable="false"
                      />
                    </span>
                    <div
                      class="file-details"
                      *ngIf="!item?.uploadFile[i]?.is_image"
                    >
                      <img
                        [src]="item?.uploadFile[i]?.image"
                        alt="upload"
                        draggable="false"
                        *ngIf="!item?.uploadFile[i]?.is_image"
                      />
                      <span *ngIf="!item?.uploadFile[i]?.is_image">{{
                        item?.uploadFile[i].name
                      }}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="item?.message_type === MESSAGE_TYPES['CONTACT']">
                  <div class="d-flex justify-content">
                    <div class="user-short-name mr-0">
                      {{ utilsService.forShortName(item.contact_name) }}
                    </div>
                    <strong>{{ item.contact_name }}</strong
                    ><br />
                  </div>
                  <span>{{ item.contact_number }}</span>
                </div>
                <div
                  class="info-box-click"
                  *ngIf="item?.message_type == MESSAGE_TYPES['INFO']"
                >
                  <div
                    class="d-flex align-items-baseline justify-content-between"
                  >
                    <div class="limited-pvt">
                      <h6>{{ item?.clientInfo?.company_name }}</h6>
                      <label
                        >{{ item?.clientInfo?.client_name }}
                        <span
                          *ngIf="item?.clientInfo?.gender"
                          class="mail-btn-add"
                          >{{ item?.clientInfo?.gender }}</span
                        ></label
                      >
                    </div>
                    <div
                      class="info-cls"
                      (click)="openContactDetails(item?.clientInfo?.id)"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.5 10.5498C0.5 16.3488 5.20101 21.0498 11 21.0498C16.799 21.0498 21.5 16.3488 21.5 10.5498C21.5 4.75081 16.799 0.0498047 11 0.0498047C5.20101 0.0498047 0.5 4.75081 0.5 10.5498ZM2 10.5498C2 5.57924 6.02944 1.5498 11 1.5498C15.9706 1.5498 20 5.57924 20 10.5498C20 15.5204 15.9706 19.5498 11 19.5498C6.02944 19.5498 2 15.5204 2 10.5498ZM11.75 14.7998V8.0498H8.75V9.5498H10.25V14.7998H8V16.2998H14V14.7998H11.75ZM9.875 4.6748C9.875 4.05348 10.3787 3.5498 11 3.5498C11.6213 3.5498 12.125 4.05348 12.125 4.6748C12.125 5.29612 11.6213 5.7998 11 5.7998C10.3787 5.7998 9.875 5.29612 9.875 4.6748Z"
                          fill="#276EF1"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="intor-contacts mt-4">
                    <div class="row align-items-center">
                      <div class="col-lg-2 my-1">
                        <svg
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.75 0.799805H1.25C0.55993 0.800494 0.000689037 1.35973 0 2.0498V15.7998C0.000689037 16.4899 0.55993 17.0491 1.25 17.0498H8.75C9.43993 17.0488 9.99897 16.4897 10 15.7998V2.0498C9.99931 1.35973 9.44007 0.800494 8.75 0.799805ZM8.75 2.0498V3.2998H1.25V2.0498H8.75ZM1.25 15.7998V4.5498H8.75V15.7998H1.25Z"
                            fill="#276EF1"
                          />
                        </svg>
                      </div>
                      <div class="col-lg-10 my-1">
                        <p class="mb-0">
                          {{ item?.clientInfo?.mobile_number }}
                        </p>
                      </div>
                    </div>

                    <div class="row align-items-center">
                      <div class="col-lg-2">
                        <svg
                          width="18"
                          height="13"
                          viewBox="0 0 18 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.5 0.0498047H1.5C0.809644 0.0498047 0.25 0.609449 0.25 1.2998V11.2998C0.25 11.9902 0.809644 12.5498 1.5 12.5498H16.5C17.1904 12.5498 17.75 11.9902 17.75 11.2998V1.2998C17.75 0.609449 17.1904 0.0498047 16.5 0.0498047ZM15.125 1.2998L9 5.5373L2.875 1.2998H15.125ZM1.5 11.2998V1.86855L8.64375 6.8123C8.858 6.96093 9.142 6.96093 9.35625 6.8123L16.5 1.86855V11.2998H1.5Z"
                            fill="#276EF1"
                          />
                        </svg>
                      </div>
                      <div class="col-lg-10 my-1">
                        <p class="mb-0">{{ item?.clientInfo?.email }}</p>
                      </div>
                    </div>

                    <div class="row align-items-center">
                      <div class="col-lg-2 my-1">
                        <svg
                          width="14"
                          height="20"
                          viewBox="0 0 14 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.125 7.24414C0.176434 3.49783 3.25365 0.501736 7 0.550389C10.7463 0.501736 13.8236 3.49783 13.875 7.24414C13.8734 8.69887 13.3899 10.1121 12.5 11.2629L7 19.3004L1.5 11.2629C0.610106 10.1121 0.12658 8.69887 0.125 7.24414ZM12.625 7.24414C12.5668 4.19111 10.0533 1.75857 7 1.80039C3.94671 1.75857 1.43317 4.19111 1.375 7.24414C1.37409 8.44549 1.78184 9.61143 2.53125 10.5504L7 17.0879L11.5063 10.5004C12.2316 9.56996 12.6253 8.42389 12.625 7.24414ZM7 5.5498C8.03553 5.5498 8.875 6.38927 8.875 7.4248C8.875 8.46034 8.03553 9.2998 7 9.2998C5.96447 9.2998 5.125 8.46034 5.125 7.4248C5.125 6.38927 5.96447 5.5498 7 5.5498ZM3.875 7.4248C3.875 5.69892 5.27411 4.2998 7 4.2998C8.72589 4.2998 10.125 5.69892 10.125 7.4248C10.125 9.15069 8.72589 10.5498 7 10.5498C5.27411 10.5498 3.875 9.15069 3.875 7.4248Z"
                            fill="#276EF1"
                          />
                        </svg>
                      </div>
                      <div class="col-lg-10 my-1">
                        <p class="mb-0">
                          {{ item?.clientInfo?.company_address }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  (click)="redirectMap(item.latitude, item.longitude)"
                  *ngIf="item?.message_type === MESSAGE_TYPES['LOCATION']"
                  class="position-relative"
                >
                  <img
                    class="map-image"
                    src="../../../../assets/images/map_image.png"
                    alt="map-image"
                    draggable="false"
                  />
                  <span class="set-to-position">
                    <img
                      class="location-pin"
                      src="../../../../assets/images/loctaion_pin.png"
                      alt="location"
                      draggable="false"
                    />
                  </span>
                </div>
                <p 
                  [innerHTML]="
                    item?.message
                      | highlighter : search : item : highlightMessage
                  "
                ></p>
              </div>
              <ul
                class="userName"
                *ngIf="
                  group?.value[i + 1]?.timestamp !== item.timestamp ||
                  group?.value[i + 1]?.sender?.user_id !== item?.sender?.user_id
                "
              >
                <li *ngIf="currentActiveUserId !== item?.sender?.user_id">
                  {{ item?.sender?.first_name }} {{ item?.sender?.last_name }}
                </li>
                <li *ngIf="currentActiveUserId !== item?.sender?.user_id">•</li>
                <li>{{ item?.time }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div [hidden]="!uploadFileFakeData.length">
          <div
            class="msg-cover right-msg"
            *ngFor="let item of uploadFileFakeData"
          >
            <div class="userMsgContent">
              <div class="userMsgBg">
                <div class="custom-spinner-containner">
                  <mat-spinner diameter="40"></mat-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container"
      (click)="scrollBottomSmooth()"
      [hidden]="hasBootomTouch"
    >
      <div class="field">
        <div class="scroll"></div>
      </div>
    </div>
  </div>

  <mat-card-actions
    class="mx-0 chat-msg-send-action-panel mb-0 only-chat-portion-to-edit"
  >
    <div class="users-typing discussion-room-typing" *ngIf="usersTyping.length">
      <span>
        {{ usersTyping[0]?.first_name + " " + usersTyping[0]?.last_name }}
        <span *ngIf="usersTyping.length > 1"
          >+{{ usersTyping.length - 1 }} typing</span
        >
      </span>
      <div class="typing">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <app-comment-editor
      (commentData)="addCommentData($event)"
      (userTyping)="userTypingData($event)"
      [selectedMessage]="replyMessage"
      (isCancelReply)="replyMessage = null"
      [mentionUsers]="mentionUsers"
      *ngIf="is_change"
      [dropFiles]="dropFiles"
    ></app-comment-editor>
  </mat-card-actions>
</div>

<ng-template #context_menu>
  <div class="right-menu" cdkMenu>
    <button
      class="right-menu-item"
      (click)="isReplyMessage(selectedMessage)"
      cdkMenuItem
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="me-3"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.05 18.7501C17.8236 18.7533 17.6132 18.6337 17.5 18.4376C15.8066 15.5528 12.72 13.7721 9.37496 13.7501V17.5001C9.3737 17.7524 9.22085 17.9792 8.98746 18.0751C8.75471 18.173 8.48594 18.1212 8.30621 17.9439L0.806212 10.4439C0.687891 10.3265 0.621338 10.1668 0.621338 10.0001C0.621338 9.83346 0.687891 9.67371 0.806212 9.55635L8.30621 2.05635C8.48594 1.87901 8.75471 1.82723 8.98746 1.9251C9.22085 2.02098 9.3737 2.24779 9.37496 2.5001V6.31885C14.7236 7.00584 18.7343 11.5513 18.75 16.9439C18.7485 17.3618 18.7213 17.7792 18.6687 18.1939C18.6367 18.4605 18.4379 18.6769 18.175 18.7314L18.05 18.7501ZM9.06246 12.5001C12.2885 12.4538 15.3726 13.8245 17.5 16.2501C17.1115 11.5667 13.3796 7.85851 8.69371 7.5001C8.37067 7.47091 8.12365 7.19946 8.12496 6.8751V4.00635L2.13121 10.0001L8.12496 15.9939V13.1251C8.12496 12.7799 8.40478 12.5001 8.74996 12.5001H9.08746H9.06246Z"
          fill="#424242"
        />
      </svg>
      Reply
    </button>
    <button
      class="right-menu-item"
      *ngIf="
        selectedMessage?.message_type !== MESSAGE_TYPES['INFO'] &&
        selectedMessage?.message_type !== MESSAGE_TYPES['DOCUMENT'] &&
        selectedMessage?.message_type !== MESSAGE_TYPES['LOCATION'] &&
        selectedMessage?.message_type !== MESSAGE_TYPES['AUDIO']
      "
      (click)="copyMessage(selectedMessage)"
      cdkMenuItem
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="me-3"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.5 11.25H1.25V2.5C1.25 1.80964 1.80964 1.25 2.5 1.25H11.25V2.5H2.5V11.25ZM17.5 6.25V17.5H6.25V6.25H17.5ZM6.25 5H17.5C18.1904 5 18.75 5.55964 18.75 6.25V17.5C18.75 18.1904 18.1904 18.75 17.5 18.75H6.25C5.55964 18.75 5 18.1904 5 17.5V6.25C5 5.55964 5.55964 5 6.25 5Z"
          fill="#424242"
        />
      </svg>
      Copy
    </button>
    <button
      class="right-menu-item"
      *ngIf="
        deleteMessage(selectedMessage) &&
        currentActiveUserId === selectedMessage?.sender?.user_id
      "
      (click)="removeMessage(selectedMessage)"
      cdkMenuItem
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="me-3"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5 1.25H7.5V2.5H12.5V1.25ZM2.5 3.75V5H3.75V17.5C3.75 18.1904 4.30964 18.75 5 18.75H15C15.6904 18.75 16.25 18.1904 16.25 17.5V5H17.5V3.75H2.5ZM5 17.5V5H15V17.5H5ZM7.5 7.5H8.75V15H7.5V7.5ZM12.5 7.5H11.25V15H12.5V7.5Z"
          fill="#424242"
        />
      </svg>
      Remove
    </button>
  </div>
</ng-template>
