import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, of, Subject } from 'rxjs';
import { ApiService } from '../api-service/api.service';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class DiscussionService {
    queryURL: any;
    apiEndPoint = NAVIGATE_ROUTES;
    $discussionAccess = new Subject();
    offlineTaskCommentArr = new BehaviorSubject<any>([]);

    constructor(private _apiService: ApiService, private http: HttpClient) {}

    async getDiscussionList(data: any) {
        let response: any;
        data.limit = data.is_favourite ? 1000 : data.limit;
        this.queryURL = `page=${data.page}&limit=${data.limit}&type=${data.type}&is_favourite=${data.is_favourite}`;
        if (data.type === 'TASK') {
            this.queryURL += `&status=${data.status}`;
        }
        if (data.search) {
            this.queryURL += `search=${data.search}`;
            response = await this._apiService.get(
                `${this.apiEndPoint.DISCUSSION_SEARCH}?${this.queryURL}`
            );
        } else {
            response = await this._apiService.get(
                `${this.apiEndPoint.DISCUSSION_LIST}?${this.queryURL}`
            );
        }
        return of(response?.data);
    }

    async addDiscussion(data: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.DISCUSSION_ADD,
            data
        );
        return of(response);
    }

    async editDiscussion(id: any, data: any) {
        delete data._id;
        const response: any = await this._apiService.put(
            `${this.apiEndPoint.DISCUSSION_EDIT}/${id}`,
            data
        );
        return of(response);
    }

    async deleteDiscussion(id: any) {
        const response: any = await this._apiService.delete(
            `${this.apiEndPoint.DISCUSSION_DELETE}/${id}`
        );
        return of(response);
    }

    async manageFavourite(objFavorite: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.DISCUSSION_FAVOURITE,
            objFavorite
        );
        return of(response);
    }
    async discussionDetails(id: any) {
        return this.http.get(`${this.apiEndPoint.DISCUSSION_DETAILS}/${id}`).pipe(
            catchError((_error: any, _caught: any) => {
                return EMPTY;
            })
        );
    }

    async projectList() {
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.PROJECT_LIST}`
        );
        return of(response?.data);
    }
    async projectDetails(id: any) {
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.PROJECT_DETAIL}?project_id=${id}`
        );
        return of(response?.data);
    }

    async getCompanyAccesses() {
        const response: any = await this._apiService.get(
            this.apiEndPoint.GET_COMPANY_ACCESSES
        );
        return of(response.data);
    }

    async discussionImage(data: any) {
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.DISCUSSION_IMAGES}/${data._id}?type=${data.type}`
        );
        return of(response.data);
    }

    async discussionListSearch(data: any) {
        let queryURL = `page=${data.page}&limit=${data.limit}&type=${data.type}`;
        if (data.search) {
            queryURL += `&search=${data.search}`;
        }
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.DISCUSSIONLIST_SEARCH}?${queryURL}`
        );
        return of(response.data);
    }
}
