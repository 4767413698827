import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    public alertBoxFlag = false;
    public toasterIdList: any[] = [];
    configClass: any = { backdrop: 'static' };

    constructor(public toastService: ToastrService) { }

    clear() {
        this.toasterIdList.forEach((toastId) => {
            this.toastService.clear(toastId);
        });
        this.toasterIdList = [];
    }

    showWarning(message: string) {
        const warningMessage = this.parseErrorMessage(message);
        this.toastService.warning(warningMessage, 'Warning');
    }
    showError(message: string) {
        const warningMessage = this.parseErrorMessage(message);
        this.toastService.error(warningMessage);
    }

    showSuccess(message: string) {
        const successMessage = this.parseErrorMessage(message);
        this.toastService.success(successMessage);
    }

    parseErrorMessage(message: any) {
        let errorMessage = '';
        try {
            errorMessage = JSON.parse(message).message;
        } catch (e) {
            if (typeof message === 'object') {
                errorMessage = message.message;
            } else {
                errorMessage = message;
            }
        }
        return errorMessage;
    }
}
