import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
    selector: 'input[numbersOnly]',
})
export class NumberDirective {
    private regex = new RegExp(/^-?[0-9\/]+(\[0-9\/'']*){0,1}$/g);
    private specialKeys: Array<string> = [
        'Backspace',
        'Tab',
        'End',
        'Home',
        'ArrowLeft',
        'ArrowRight',
    ];

    constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
