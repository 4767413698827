<form [formGroup]="taskDetailForm" class="h-100 task-list-date-create-task-form">
  <div class="d-flex w-100">
    <div class="create-task-block w-100">
      <mat-card class="create-task--card p-0 mat-elevation-z0">
        <mat-card-header class="align-items-end"
          [ngClass]="{ 'multipleEmailTask': multipleEmailTask, 'simpleheader': !multipleEmailTask }">
          <div class="d-flex w-100">
            <div *ngIf="data?.editTaskId && taskCreatorInfo" class="d-flex align-items-center">
              <p class="sub-block-title sub-block-title-sm mb-0">
                {{ "CREATED_BY" | translate }}
              </p>
              <div class="d-flex align-items-center" style="margin-left: 10px">
                <span *ngFor="let owneruser of allMemberListData" class="d-flex align-items-center">
                  <span *ngIf="owneruser?.user_id == taskCreatorInfo?.user_id" class="d-flex align-items-center">
                    <span class="user--img me-1">
                      <img [src]="owneruser?.profile_image" *ngIf="
                          owneruser?.profile_image?.charAt(0) !== '#' &&
                          owneruser?.profile_image?.charAt(0) == 'h'
                        " class="img-avatar" alt="assignee profile" />
                      <div *ngIf="
                          owneruser?.profile_image?.charAt(0) == '#' ||
                          !owneruser?.profile_image ||
                          owneruser?.profile_image?.charAt(0) != 'h'
                        " class="user-short-name mr-0" [ngStyle]="{
                          'background-color': owneruser?.profile_image
                        }">
                        {{
                        utils.forShortName(
                        owneruser?.first_name + " " + owneruser?.last_name
                        )
                        }}
                      </div>
                      <div *ngIf="
                          owneruser?.profile_image &&
                          owneruser?.profile_image?.charAt(0) == 'u'
                        " class="user-short-name" [ngStyle]="{
                          'background-color': '#F2793D'
                        }">
                        {{
                        utils.forShortName(
                        owneruser?.first_name + " " + owneruser?.last_name
                        )
                        }}
                      </div>
                    </span>
                    <h4 class="cmn-txt mb-0">
                      {{
                      taskCreatorInfo?.first_name +
                      " " +
                      taskCreatorInfo?.last_name
                      }}
                    </h4>
                  </span>
                </span>
              </div>
            </div>

            <button class="btn btn--bordered ms-2" mat-button mat-flat-button type="button" *ngIf="
                data?.editTaskId &&
                taskData?.recurring_parrant_id &&
                !taskData?.is_service_recurring
              " (click)="recurringParentRedirect(taskData?.recurring_parrant_id)">
              {{ "PRIMARY_TASK" | translate }}
            </button>
            <div class="d-flex justify-content-end flex-grow-1">
              <ul class="list-unstyled d-flex align-items-end add-edit-text-top-action mb-0">
                <li [class.edit-task-disable]="!disableAllFunctionality" class="d-flex slider-list flex-wrap"
                  *ngIf="progressVisible">
                  <span class="cmn-txt font-12">{{
                    "PROGRESS" | translate
                    }}</span><span class="ms-auto cmn-txt font-12 font-weight-bold">{{ progressValue }}%</span>
                  <mat-slider class="progress--slider" min="0" max="100" step="5" [value]="progressValue"
                    (change)="addProgress($event)" thumbLabel="true">
                    <input matSliderThumb />
                  </mat-slider>
                </li>
                <li>
                  <img [src]="
                      taskListData.recurringTaskIcon(
                        taskData?.is_recurring_task,
                        taskData?.is_service_recurring,
                        taskData?.recurring_icon,
                        taskData?.recurring_parrant_id
                      )
                    " alt="" />
                </li>
                <li [ngClass]="{
                    'custom-button-cls-remind': isReminder && this.reminderOn
                  }">
                  <span [matMenuTriggerFor]="alarmmenu" class="hover-btn-link">
                    <img src="assets/images/clockicon.svg" alt="clock-icon" draggable="false" matTooltip="Reminder"
                      class="reminder" />
                  </span>
                  <div>
                    <mat-menu #alarmmenu="matMenu" class="custom-sub-menu-dropdown-menu calender-sub-menu p-0 {{
                        reminderOn ? 'reminder-backdrop' : 'reminder-off'
                      }}" xPosition="before">
                      <app-task-reminder-component [reminderDetail]="reminderDetail"
                        (selectedDate)="reminderData($event)" class="task-reminder" (closeMenu)="close($event)"
                        [task_id]="this.data?.editTaskId"></app-task-reminder-component>
                    </mat-menu>
                  </div>
                </li>
                <li [ngClass]="{
                    'edit-task-disable':
                    !userRolePermission?.task?.can_edit &&
                  data?.editTaskId &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId
                  }" *ngIf="!multipleEmailTask">
                  <a [class.edit-task-disable]="!disableAllFunctionality">
                    <img (click)="addMultipleSubTask()" src="assets/images/taskshareicon.svg" alt="share-icon"
                      draggable="false" matTooltip="Sub Task" onkeypress/>
                  </a>
                </li>
                <li [ngClass]="{
                    'edit-task-disable':
                      (!userRolePermission?.task?.can_edit &&
                  data?.editTaskId &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId) ||
                      !disableAllFunctionality
                  }" *ngIf="!multipleEmailTask">
                  <a>
                    <img src="assets/images/clipicon.svg" alt="upload-icon" draggable="false" matTooltip="Files"
                      (click)="uploadFile.click()" onkeypress />
                    <input #uploadFile type="file" multiple style="display: none"
                      (change)="uploadFiles($any($event).target.files)" />
                  </a>
                </li>
                <li *ngIf="!multipleEmailTask && data?.editTaskId" class="pointer-arrow">
                  <div>
                    <span mat-button [matMenuTriggerFor]="dotsMenu">
                      <img src="assets/images/verticaldots.svg" alt="dots-icon" draggable="false" />
                    </span>
                    <mat-menu #dotsMenu="matMenu" class="custom--status-menu" xPosition="before">
                      <button *ngIf="
                          data?.editTaskId &&
                          this.timerResponseObj?.task_id !=
                      this.data?.editTaskId && selectedStatus?.status_name != statusNameFormConst.UNDER_REVIEW ? (userRolePermission?.task?.can_remove || 
             userRolePermission?.superAdmin || 
             userRolePermission?.owner || 
             taskData?.owner?.user_id == decryptedUserId):''
                        " mat-menu-item (click)="deleteTask(data?.editTaskId, false)">
                        {{ "DELETE_TASK" | translate }}
                      </button>
                      <button mat-menu-item *ngIf="data?.editTaskId" (click)="addTaskDiscussion(data)">
                        {{ "DISCUSSION_TASK" | translate }}
                      </button>
                      <button *ngIf="
                          data?.editTaskId &&
                          taskData?.is_recurring_task &&
                            (taskData?.is_recurring_task == 'true' ||
                              taskData?.is_recurring_task == true) &&
                          !taskData?.recurring_parrant_id
                        " mat-menu-item (click)="stopRecurringTask(data?.editTaskId)">
                        {{ "STOP_RECURRING" | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </mat-card-header>
        <ng-scrollbar #scrollable class="custom-height-for-editor">
          <div class="row" *ngIf="isTimeSheetSetting && timerShow && data?.editTaskId">
            <div class="col-lg-12">
              <div class="estimated-hrs-design-cls">
                <ul class="d-flex align-items-center list-unstyled mb-0">
                  <li class="me-3 first-li-border-cls">
                    Est. Hrs : <strong>{{ estimatedHours || "00:00" }}</strong>
                  </li>
                  <li>
                    Actual Hours :
                    <span>
                      <strong>{{ ActualHours }}</strong>
                    </span>
                  </li>

                  <li class="ms-auto">
                    Today worked: <strong>{{ timerValue }}</strong>
                  </li>

                  <li>
                    <div class="d-flex play-btn-block" *ngIf="
                        ((this.timerResponseObj &&
                          this.timerResponseObj?.task_id ==
                            this.data?.editTaskId) ||
                          !this.isAnyTaskOngoingOfUser) &&
                        selectedStatus?.status_name !=
                          statusNameFormConst.UNDER_REVIEW
                      ">
                      <button class="btn play-btn" (click)="submitTimer('start')"
                        *ngIf="!timerEvent || timerEvent === 'end'" [class.edit-task-disable]="
                          selectedStatus?.status_name ===
                          statusNameFormConst.CLOSED
                        ">
                        <img src="assets/images/blueplayicon.svg" alt="start icon" draggable="false" />
                      </button>
                      <button class="btn play-btn" (click)="submitTimer('end')" *ngIf="timerEvent === 'start'"
                        data-toggle="pill">
                        <img src="assets/images/Fill.svg" alt="stop icon" draggable="false" />
                      </button>
                      <p class="mb-0">
                        <span class="minute-txt"></span>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <mat-card-content id="mat-card-content">
            <div class="row">
              <div class="col-12 position-custom">
                <ul class="list-unstyled d-flex align-items-center mb-0 custom-tak-listing-left">
                  <li class="me-2" *ngIf="!multipleEmailTask">
                    <mat-checkbox class="circle--checkbox" matPrefix [(ngModel)]="markAsCompleted" *ngIf="
                        (!markAsApprovalData?.isMarksAsApprovalFlow ||
                          (markAsApprovalData?.isMarksAsApprovalFlow &&
                            markAsApprovalData?.isCreatorSelected &&
                            taskData?.owner?.user_id == decryptedUserId) ||
                          taskData?.is_completed == 1) &&
                        selectedStatus?.status_name !=
                          statusNameFormConst.UNDER_REVIEW
                      " [checked]="markAsCompleted ? true : false" (change)="markAsCompleteTask($event.checked)"
                      [disabled]="!data?.editTaskId || taskData?.on_going"></mat-checkbox>
                    <mat-checkbox class="circle--checkbox" matPrefix [(ngModel)]="markAsApproval" *ngIf="
                        markAsApprovalData?.isMarksAsApprovalFlow &&
                        (!markAsApprovalData?.isCreatorSelected ||
                          (markAsApprovalData?.isCreatorSelected &&
                            taskData?.owner?.user_id !== decryptedUserId)) &&
                        selectedStatus?.status_name !=
                          statusNameFormConst.UNDER_REVIEW &&
                        selectedStatus?.status_name !=
                          statusNameFormConst.CLOSED
                      " [checked]="markAsApproval ? true : false" (change)="markAsApprovalFlow($event.checked)"
                      [disabled]="
                        !data?.editTaskId ||
                        !disableAllFunctionality ||
                        selectedStatus?.status_name ===
                          statusNameFormConst.UNDER_REVIEW
                      "></mat-checkbox>
                    <mat-checkbox class="circle--checkbox" matPrefix [(ngModel)]="markAsApproval" *ngIf="
                        selectedStatus?.status_name ===
                        statusNameFormConst.UNDER_REVIEW
                      " [checked]="markAsApproval ? true : false" (change)="taskApprovalReject($event.checked)"
                      [disabled]="!data?.editTaskId || !canMarkAsApprove"></mat-checkbox>
                  </li>
                  <li class="flex-grow-1 custom-tak-listing-left-first" *ngIf="!multipleEmailTask">
                    <div class="d-flex align-items-center" [ngClass]="{
                        'edit-task-disable': (
                          !userRolePermission?.task?.can_edit &&
                          data?.editTaskId &&
                          !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId
                        )
                      }">
                      <mat-form-field appearance="standard" class="custom-input-field task-name w-100">
                        <div hidden>
                          {{ taskDetailForm?.controls["taskName"]?.value }}
                        </div>
                        <input placeholder="Write your task" class="form-control mt-0" matInput name="taskName"
                          formControlName="taskName" type="text" trim="blur"
                          (keyup)="quickReplyCheck($event, 'taskName')" [readonly]="!disableAllFunctionality" />
                      </mat-form-field>
                      <div *ngIf="
                          isEnableDropdown?.isTaskNameEnable &&
                          quickReplyFilter?.length > 0
                        "
                        class="form-group-inner custom-placeholder-form-group custon-data-list custon-data-list-1 mt-0">
                        <ng-container *ngFor="let reply of quickReplyFilter">
                          <div (click)="
                              handleStaticResultSelected(reply, 'taskName')
                            " class="items" onkeypress>
                            <label class="mb-0">{{ reply.title }}</label>
                            <span>{{ reply.message }}</span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ul class="d-flex align-items-center list-unstyled">
                  <li class="me-auto d-flex align-items-center due-date-badge">
                    <div class="form-floating d-flex align-items-center" [matMenuTriggerFor]="duedate">
                      <a class="d-flex align-items-center anchor-link font-weight-medium" id="floatingInput">
                        <img src="assets/icons/calendericon.svg" class="me-2" alt="calendar-icon" draggable="false" />
                        <label for="floatingInput"><span *ngIf="
                              !dueDateDataObj?.endDate &&
                              !dueDateDataObj?.taskDueDate
                            ">{{ "DUE_DATE_LABEL" | translate
                            }}<span class="text-danger"> *</span></span></label>
                      </a>
                      <div class="due-date-txt">
                        <div *ngIf="
                            dueDateDataObj?.endDate &&
                            dueDateDataObj?.startDate &&
                            isTimeSheetSetting
                          ">
                          <span class="ml-3">{{
                            dueDateDataObj?.startDate | date : "MMM d, y"
                            }}</span>
                          <span *ngIf="dueDateDataObj?.startTime">({{ dueDateDataObj?.startTime }})</span>
                          -
                          <span class="ml-3">{{
                            dueDateDataObj?.endDate | date : "MMM d, y"
                            }}</span>
                          <span *ngIf="dueDateDataObj?.endTime">({{ dueDateDataObj?.endTime }})</span>
                        </div>
                        <div *ngIf="
                            dueDateDataObj?.taskDueDate && !isTimeSheetSetting
                          " class="ml-3">
                          {{ dueDateDataObj?.taskDueDate | date : "MMM d, y" }}
                        </div>
                      </div>
                    </div>
                    <mat-menu #duedate="matMenu" class="custom-sub-menu-dropdown-menu calender-sub-menu p-0"
                      xPosition="before">
                      <app-task-due-date-common class="task-list-date-task-due-date" (click)="$event.stopPropagation()"
                        [maxDateProject]="maxDateProject" [minDateProject]="minDateProject" [rmax]="maxDateProject"
                        [rmin]="minDateProject" [isRecurringParantId]="taskData?.recurring_parrant_id" [isRecurringOn]="
                          utils.getBooleanValueWithFalse(
                            taskData?.is_recurring_task
                          )
                        " [isTimeSheetSetting]="isTimeSheetSetting" [dueDateData]="dueDateDataObj"
                        [isPrimaryTask]="primaryTask" [isSubTask]="false" [task_id]="this.data?.editTaskId"
                        (selectedDate)="selectedDate($event)" [serviceRecurring]="taskData?.is_service_recurring"></app-task-due-date-common>
                    </mat-menu>
                  </li>
                  <li class="me-2">
                    <span mat-button [matMenuTriggerFor]="statusmenu" [class.edit-task-disable]="
                        selectedStatus?.status_name ===
                          statusNameFormConst.CLOSED || !disableAllFunctionality
                      ">
                      <span class="badge badge--primary caret custom-badge status-badge" [ngStyle]="{
                          color: selectedStatus?.status_color,
                          'background-color':
                            selectedStatus?.status_color + '15'
                        }">
                        <span class="s-icon mx-1" [ngStyle]="{
                            'background-color': selectedStatus?.status_color
                          }"></span>
                        <span class="text-capitalize">
                          {{ selectedStatus?.status_name }}</span>
                      </span>
                    </span>
                    <mat-menu #statusmenu="matMenu"
                      class="custom--status-menu custom-menu-list p-0 priority-menu custom-project-popup status-set"
                      xPosition="before">
                      <button mat-menu-item *ngFor="let status of statusList" (click)="setStatus(status)">
                        <span class="badge badge--primary" [ngStyle]="{
                            color: status?.status_color,
                            'background-color': status?.status_color + '15'
                          }">
                          <span class="s-icon mx-1" [ngStyle]="{
                              'background-color': status?.status_color
                            }"></span>
                          <span class="text-capitalize">{{
                            status?.status_name
                            }}</span></span>
                      </button>
                    </mat-menu>
                  </li>
                  <li>
                    <span mat-button [matMenuTriggerFor]="prioritymenu"
                      class="badge badge--primary caret custom-badge priority-badge priority-menu"
                      [class.edit-task-disable]="!disableAllFunctionality" [ngStyle]="{
                        color: selectedPriority?.priority_color,
                        'background-color':
                          selectedPriority?.priority_color + '15'
                      }"[ngClass]="{
                'edit-task-disable':
                  !userRolePermission?.task?.can_edit &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId
              }">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12.36 6L12.44 6.39L12.76 8H18V14H14.64L14.56 13.61L14.24 12H7V6H12.36ZM14 4H5V21H7V14H12.6L13 16H20V6H14.4L14 4Z"
                          [attr.fill]="selectedPriority?.priority_color"></path>
                      </svg>
                      <span class="text-capitalize">{{ selectedPriority?.priority_name }}
                      </span>
                    </span>
                    <mat-menu #prioritymenu="matMenu" class="custom--status-menu custom-menu-list p-0 priority-menu"
                      xPosition="before">
                      <button mat-menu-item *ngFor="let ele of priority" (click)="setPriority(ele)">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24"
                            fill="none">
                            <path
                              d="M12.36 6L12.44 6.39L12.76 8H18V14H14.64L14.56 13.61L14.24 12H7V6H12.36ZM14 4H5V21H7V14H12.6L13 16H20V6H14.4L14 4Z"
                              [attr.fill]="ele?.priority_color"></path>
                          </svg>
                          <span class="text-capitalize">{{
                            ele?.priority_name
                            }}</span></span>
                      </button>
                    </mat-menu>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row" [ngClass]="{
                'edit-task-disable':
                !userRolePermission?.task?.can_edit &&
                  data?.editTaskId &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId
              }" *ngIf="!multipleEmailTask">
              <div class="col-12 position-custom-relative" [class.edit-task-disable]="!disableAllFunctionality">
                <textarea formControlName="taskDesc" placeholder="Task description"
                  class="form-control custom-textarea-form-control" trim="blur" (input)="adjustTextareaHeight()"
                  [(ngModel)]="content" [(ngModel)]="inputValue" #textareaRef
                  (keyup)="quickReplyCheck($event, 'taskDesc')"></textarea>
                <div *ngIf="
                    isEnableDropdown?.isTaskDescEnable &&
                    quickReplyFilter?.length > 0
                  " class="form-group-inner custom-placeholder-form-group custon-data-list custon-data-list-3 mt-0">
                  <ng-container *ngFor="let reply of quickReplyFilter">
                    <div (click)="handleStaticResultSelected(reply, 'taskDesc')" class="items">
                      <label class="mb-0">{{ reply.title }}</label>
                      <span>{{ reply.message }}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="row" [ngClass]="{
                'edit-task-disable':
                !userRolePermission?.task?.can_edit &&
                  data?.editTaskId &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId
              }">
              <div class="col-12 mb-3 d-flex"></div>
              <div class="col-12 mb-1">
                <label class="custom-label">Projects</label>
                <div class="d-flex align-items-center" [class.edit-task-disable]="
                    !disableAllFunctionality ||
                    (data?.editTaskId && selectedProjectId) ||
                    router.url.includes('/project')
                  ">
                  <a [matMenuTriggerFor]="projectMenu"
                    class="d-flex align-items-center anchor-link font-weight-medium form-feild-select-custom">
                    <span *ngIf="!selectedProjectId.length">Project</span>
                    <app-mat-chip-preview [selectedMembers]="projectMatPreview"
                      listArrName="project"></app-mat-chip-preview>
                  </a>
                </div>
                <mat-menu #projectMenu="matMenu"
                  class="multi-select-chip-menu custom-menu-list custom-project-popup dynamicwidth-popup">
                  <ng-template matMenuContent>
                    <div [style.width]="dynamicWidth + 'px'">
                      <input *ngIf="projectList.length !== 0" (click)="$event.stopPropagation()" type="text"
                        autocomplete="off" placeholder="Write here"
                        class="form-control c-s-input custom-input border-0 w-100 m-w-100" (input)="
                          search(
                            $event,
                            projectList,
                            projectListData,
                            'project'
                          )
                        " />
                      <div class="scroll-fix">
                        <app-check-box-common [memberListData]="projectListData" [listName]="serviceListName"
                          [isShowSelectAll]="false" [isSingleSelect]="true" [isDisable]="false"
                          (onSelectCheckBox)="projectSelection($event, true)">
                        </app-check-box-common>
                        <div class="text-center" *ngIf="
                            projectList.length !== 0 &&
                            projectListData.length === 0
                          ">
                          {{ "NO_DATA_FOUND" | translate }}
                        </div>
                        <div class="text-center" *ngIf="projectList.length === 0">
                          {{ "NO_DATA_AVAILABLE" | translate }}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-menu>
              </div>
              <div class="col-12 mb-1">
                <label class="custom-label">Assignee Team</label>
                <div class="d-flex align-items-center" [class.edit-task-disable]="!disableAllFunctionality">
                  <a [matMenuTriggerFor]="assignee"
                    class="d-flex align-items-center anchor-link font-weight-medium form-feild-select-custom"
                    #elementRefDiv>
                    <span *ngIf="!selectedGroup.length && !selectedAssignees.length">{{ "ASSIGNEE_TEAM" | translate
                      }}<span class="text-danger"> *</span></span>
                    <app-mat-chip-preview [isInfoShow]="true" [isSubTask]="false" [selectedMembers]="selectedMatPreview"
                      listArrName="{{ listArrName }}"></app-mat-chip-preview>
                  </a>
                </div>

                <mat-menu #assignee="matMenu" class="multi-select-chip-menu custom-menu-list">
                  <app-group-assignee-common-checkbox (click)="$event.stopPropagation()" [isSubTask]="false"
                    [selectedAssigneesFromBE]="selectedAssigneesFromBE" [coMembersList]="coMembersList"
                    (onSelectCheckBox)="onSelectCheckBox($event)"></app-group-assignee-common-checkbox>
                </mat-menu>
              </div>
              <div class="col-md-6 mb-1" *ngIf="isShowService || isShowClientList">
                <label class="custom-label">Client<a *ngIf="
                      !data?.editTaskId &&
                      (userRolePermission?.permissions?.Clients ||
                        userRolePermission?.superAdmin) &&
                      isShowClientList
                    " (click)="openClientModel()" class="anchor-link al-plus">
                    <svg width="10" height="9" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.125 5.375V0.375H5.875V5.375H0.875V6.625H5.875V11.625H7.125V6.625H12.125V5.375H7.125Z"
                        fill="#276EF1" />
                    </svg> </a></label>
                <div class="d-flex align-items-center" [class.edit-task-disable]="
                    !disableAllFunctionality ||
                    data?.editTaskId ||
                    (selectedProjectId.length > 0 &&
                      selectedClient.length > 0 &&
                      disableClient) ||
                    !isShowClientList
                  " [ngClass]="
                    !isShowClientList ? ' background-color: #e8e8e8' : ''
                  ">
                  <a [matMenuTriggerFor]="client"
                    class="d-flex align-items-center anchor-link font-weight-medium form-feild-select-custom"
                    #elementRefDivClient>
                    <span *ngIf="!selectedClient||selectedClient?.length==0">{{
                      "CLIENTS" | translate
                      }}</span>
                    <div *ngIf="selectedClient">
                      <app-mat-chip-preview [isInfoShow]="true"
                        [selectedMembers]="taskData?[taskData?.client]:clientMatPreview"
                        [isStrong]="!data?.editTaskId ? false : true" listArrName="client"></app-mat-chip-preview>
                    </div>
                  </a>
                </div>
                <mat-menu #client="matMenu"
                  class="multi-select-chip-menu custom-menu-list custom-project-popup dynamicwidth-popup">
                  <app-shared-client [selection_type]="'single'"
                    (selectedClient)="receiveClientData($event)"></app-shared-client>
                </mat-menu>
              </div>
              <div class="col-md-6 mb-1" *ngIf="isShowService || isShowClientList">
                <label class="custom-label" for="floatingPassword">Service<a *ngIf="
                      !data?.editTaskId &&
                      (userRolePermission?.permissions?.Services ||
                        userRolePermission?.superAdmin) &&
                      isShowService
                    " (click)="openServiceModel()" class="anchor-link al-plus">
                    <svg width="10" height="9" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.125 5.375V0.375H5.875V5.375H0.875V6.625H5.875V11.625H7.125V6.625H12.125V5.375H7.125Z"
                        fill="#276EF1" />
                    </svg> </a></label>
                <div class="d-flex align-items-center" [class.edit-task-disable]="
                    !disableAllFunctionality ||
                    !isShowService ||
                    data?.editTaskId
                  ">
                  <a id="floatingPassword" [matMenuTriggerFor]="service"
                    class="d-flex align-items-center anchor-link font-weight-medium form-feild-select-custom"
                    (click)="getRegularService()">
                    <span *ngIf="!selectedService.length">{{
                      "SERVICES" | translate
                      }}</span>
                    <app-mat-chip-preview [selectedMembers]="serviceMatPreview"
                      listArrName="service"></app-mat-chip-preview>
                  </a>
                </div>
                <mat-menu #service="matMenu"
                  class="multi-select-chip-menu custom-menu-list custom-project-popup dynamicwidth-popup">
                  <ng-template matMenuContent>
                    <div [style.width]="dynamicClientWidth + 'px'">
                      <input *ngIf="originalServiceListData.length !== 0" (click)="$event.stopPropagation()" type="text"
                        autocomplete="off" placeholder="Write here"
                        class="form-control c-s-input custom-input border-0 w-100 m-w-100" (input)="
                          search(
                            $event,
                            originalServiceListData,
                            serviceListData,
                            'service'
                          )
                        " />
                      <div class="scroll-fix">
                        <app-check-box-common [memberListData]="serviceListData" [listName]="serviceListName"
                          [isShowSelectAll]="false" [isSingleSelect]="true" [isDisable]="false"
                          (onSelectCheckBox)="onSelectServiceCheckBox($event)">
                        </app-check-box-common>
                        <div class="text-center" *ngIf="
                            originalServiceListData.length !== 0 &&
                            serviceListData.length === 0
                          ">
                          {{ "NO_DATA_FOUND" | translate }}
                        </div>
                        <div class="text-center" *ngIf="originalServiceListData.length === 0">
                          {{ "NO_DATA_AVAILABLE" | translate }}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </mat-menu>
              </div>
              <div *ngIf="primaryTask" class="col-12 mb-1" (click)="openFollower()">
                <label class="custom-label">Follower</label>
                <div class="d-flex align-items-center" [class.edit-task-disable]="!disableAllFunctionality">
                  <a [matMenuTriggerFor]="followers"
                    class="d-flex align-items-center anchor-link font-weight-medium form-feild-select-custom">
                    <span *ngIf="!selectedFollower.length">{{
                      "FOLLOWERS" | translate
                      }}</span>
                    <app-mat-chip-preview [selectedMembers]="followerMatPreview"
                      listArrName="follower"></app-mat-chip-preview>
                    <span class="anchor-link" *ngIf="followerMatPreview.length > 3">{{ followerMatPreview.length - 3 }}
                      + more</span>
                  </a>
                </div>
                <mat-menu #followers="matMenu"
                  class="multi-select-chip-menu custom-menu-list custom-project-popup dynamicwidth-popup">
                  <ng-template matMenuContent>
                    <div [style.width]="dynamicWidth + 'px'">
                      <input *ngIf="originalFollowerListData.length !== 0" (click)="$event.stopPropagation()"
                        type="text" autocomplete="off" placeholder="Write here"
                        class="form-control c-s-input custom-input border-0 w-100 m-w-100" (input)="
                          search(
                            $event,
                            originalFollowerListData,
                            followerListData,
                            'follower'
                          )
                        " />
                      <div class="scroll-fix">
                        <app-check-box-common [memberListData]="followerListData" [listName]="followerListName"
                          [isShowSelectAll]="false" [isSingleSelect]="false" [isDisable]="false"
                          (onSelectCheckBox)="onSelectFollowerCheckBox($event)">
                        </app-check-box-common>
                      </div>
                      <div class="text-center" *ngIf="
                          originalFollowerListData.length !== 0 &&
                          followerListData.length === 0
                        ">
                        {{ "NO_DATA_FOUND" | translate }}
                      </div>
                      <div class="text-center" *ngIf="originalFollowerListData.length === 0">
                        {{ "NO_DATA_AVAILABLE" | translate }}
                      </div>
                    </div>
                  </ng-template>
                </mat-menu>
              </div>
              <div class="d-flex align-items-center" *ngIf="!data?.editTaskId && !multipleEmailTask">
                <a class="d-flex align-items-center anchor-link font-weight-medium">
                  <img src="assets/icons/perdonwithleftarrow.svg" alt="left-icon" draggable="false" class="me-2" />
                  <span>{{ "MULTIPLE_ASSIGNEE" | translate }}</span>
                  <mat-slide-toggle class="ms-3" (change)="onChangeSetAssignee($event)">
                  </mat-slide-toggle>
                </a>
              </div>
            </div>
            <div class="row mt-3" appDropZone (onFileDropped)="uploadFiles($event)" [ngClass]="{
                'edit-task-disable':
                  ((!userRolePermission?.task?.can_edit &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId) ||
                    !disableAllFunctionality) &&
                  data?.editTaskId
              }" *ngIf="!multipleEmailTask">
              <div class="col-12">
                <div class="col-12" [class.edit-task-disable]="!disableAllFunctionality">
                  <a class="anchor-link d-flex align-items-center sub-font" (click)="uploadFile.click()">{{ "UPLOAD_DOC"
                    | translate }}</a>
                  <input #uploadFile type="file" multiple style="display: none"
                    (change)="uploadFiles($any($event).target.files)" />
                </div>
                <ul class="list-unstyled attachment-doc-list">
                  <li *ngFor="let doc of updateFiles; let i = index"
                    class="d-flex align-items-center doc--list justify-content-between">
                    <app-document-icon [file]="doc" [attachmentData]="attachmentData"></app-document-icon>
                    <img src="../../../../assets/images/crossblack.svg" type="button" aria-label="Close"
                      class="crossblack-cls-svg ms-3" (click)="removeFiles(i, doc)" width="15px" height="15px"
                      alt="close-button" />
                  </li>
                  <li *ngFor="let doc of newFiles; let i = index" class="d-flex align-items-center doc--list">
                    <app-document-icon [file]="doc" [attachmentData]="attachmentData"></app-document-icon>
                    <img src="../../../../assets/images/crossblack.svg" type="button" aria-label="Close"
                      class="crossblack-cls-svg ms-3" (click)="removeFiles(i, doc)" width="15px" height="15px"
                      alt="close" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-2" *ngIf="primaryTask && !multipleEmailTask" [ngClass]="{
                'edit-task-disable':
                !userRolePermission?.task?.can_edit &&
                  data?.editTaskId &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId
              }">
              <div class="col-12 d-flex align-items-center">
                <mat-menu #dotsubMenu="matMenu"
                  class="custom--status-menu task-checklist-tab checklist-niest-import-subtask2" xPosition="before">
                  <app-task-checklist-template *ngIf="checklistData?.length" (click)="$event.stopPropagation()"
                    (selectedCheckList)="subTaskCheckList($event)"></app-task-checklist-template>
                  <span class="span-tab" *ngIf="checklistData?.length == 0">
                    No Data Found
                  </span>
                </mat-menu>
                <div class="add-checklist-hover me-auto">
                  <a class="anchor-link d-flex align-items-center sub-font" (click)="addMultipleSubTask()"
                    [class.edit-task-disable]="!disableAllFunctionality">{{ "ADD_SUB_TASK" | translate }}</a>

                  <span class="show-on-hover">|</span>
                  <a class="anchor-link show-on-hover" [matMenuTriggerFor]="dotsubMenu"
                    #menuTrigger="matMenuTrigger">Import Subtask</a>
                  <mat-menu #menu="matMenu"
                    class="custom-sub-menu-dropdown-menu checklist-menu p-0 task-checklist-tab checklist-niest-import-subtask">
                    <app-task-checklist-template *ngIf="checklistData?.length" (click)="$event.stopPropagation()"
                      (selectedCheckList)="importChecklist($event)"></app-task-checklist-template>
                    <span class="span-tab" *ngIf="checklistData?.length == 0">No Data Found
                    </span>
                  </mat-menu>
                </div>
                <mat-checkbox class="p-0 custom-checkbox" [(ngModel)]="markAsSubtaskMandatory"
                  [checked]="markAsSubtaskMandatory" (change)="changeSubtaskMandatory($event.checked)">
                  {{ "MARK_AS_MANDATORY" | translate }}
                </mat-checkbox>
              </div>
              <div [class.edit-task-disable]="!disableAllFunctionality">
                <app-create-sub-task [statusList]="statusList" [addSubTask]="addSubTask" [priorityList]="priority"
                  [isTimeSheetSetting]="isTimeSheetSetting" [quickReplyList]="quickReplyList" [data]="data"
                  [maxDateProject]="maxDateProject" [minDateProject]="minDateProject" [coMembersList]="coMembersList"
                  (subTaskData)="getSubTaskData($event)"></app-create-sub-task>
              </div>
              <div formArrayName="subTask" class="col-12 px-0">
                <div *ngFor="let email of subTasksList.controls; let i = index" [formGroupName]="i"
                  class="task-acion-bar my-1">
                  <span class="square--mini square--mini-low"></span>
                  <div class="me-auto flex-grow-1">
                    <input type="text" formControlName="subTaskName" trim="blur" class="form-control"
                      placeholder="Write your sub task" />
                  </div>
                  <div>
                    <span mat-button [matMenuTriggerFor]="statusmenuSubTask" class="badge violet caret" [ngStyle]="{
                        color: taskDetailForm.get('subTask').value[i]
                          ?.subTaskStatus?.status_color
                          ? taskDetailForm.get('subTask').value[i].subTaskStatus
                              ?.status_color
                          : statusList[0]?.status_color,
                        'background-color': taskDetailForm.get('subTask').value[
                          i
                        ]?.subTaskStatus?.status_color
                          ? taskDetailForm.get('subTask').value[i].subTaskStatus
                              ?.status_color + '15'
                          : statusList[0]?.status_color + '15'
                      }">
                      {{
                      taskDetailForm.get("subTask").value[i]?.subTaskStatus
                      ?.status_name
                      ? taskDetailForm.get("subTask").value[i].subTaskStatus
                      ?.status_name
                      : statusList[0]?.status_name
                      }}
                    </span>
                    <mat-menu #statusmenuSubTask="matMenu" class="custom--status-menu" xPosition="before">
                      <button mat-menu-item *ngFor="let status of statusList" (click)="setSubTaskStatus(status, i)">
                        <span class="badge badge--primary" [ngStyle]="{
                            color: status.status_color,
                            'background-color': status.status_color + '15'
                          }">
                          <span class="s-icon mx-1" [ngStyle]="{
                              'background-color': status.status_color
                            }"></span>
                          <span class="text-capitalize">{{
                            status.status_name
                            }}</span>
                        </span>
                      </button>
                    </mat-menu>
                  </div>
                  <div>
                    <span mat-button [matMenuTriggerFor]="prioritymenu" class="badge badge--primary" [ngStyle]="{
                        color: taskDetailForm.get('subTask').value[i]
                          ?.subTaskPriority?.priority_color
                          ? taskDetailForm.get('subTask').value[i]
                              ?.subTaskPriority?.priority_color
                          : priority[0]?.priority_color,
                        'background-color': taskDetailForm.get('subTask').value[
                          i
                        ]?.subTaskPriority?.priority_color
                          ? taskDetailForm.get('subTask').value[i]
                              ?.subTaskPriority?.priority_color + '15'
                          : priority[0]?.priority_color + '15'
                      }">
                      {{
                      taskDetailForm.get("subTask").value[i]?.subTaskPriority
                      ?.priority_name
                      ? taskDetailForm.get("subTask").value[i]
                      ?.subTaskPriority?.priority_name
                      : priority[0]?.priority_name
                      }}
                    </span>
                    <mat-menu #prioritymenu="matMenu" class="custom--status-menu" xPosition="before">
                      <button mat-menu-item *ngFor="let ele of priority" (click)="setSubTaskPriority(ele, i)">
                        <span>
                          <span class="s-icon mx-1" [ngStyle]="{
                              'background-color': ele?.priority_color
                            }"></span>
                          <span class="text-capitalize">{{
                            ele?.priority_name
                            }}</span></span>
                      </button>
                    </mat-menu>
                  </div>
                  <a [matMenuTriggerFor]="assignee">
                    <img src="assets/icons/perdonwithleftarrow.svg" class="me-2" alt="left-icon" draggable="false" />
                  </a>
                  <mat-menu #assignee="matMenu" class="multi-select-chip-menu custom-menu-list">
                    <app-group-assignee-common-checkbox (click)="$event.stopPropagation()" [isSubTask]="true"
                      [selectedAssigneesFromBE]="selectedAssigneesFromBE"
                      (onSelectCheckBox)="onSelectSubTaskCheckBox($event, i)">
                    </app-group-assignee-common-checkbox>
                  </mat-menu>
                  <div>
                    <a [matMenuTriggerFor]="subtaskduedate">
                      <img src="assets/icons/calendericon.svg" class="me-2" alt="calendar-icon" draggable="false" />
                    </a>
                    <mat-menu #subtaskduedate="matMenu" class="custom-sub-menu-dropdown-menu calender-sub-menu p-0"
                      xPosition="before">
                      <app-task-due-date-common [maxDateProject]="maxDateProject" [minDateProject]="minDateProject"
                        [isTimeSheetSetting]="isTimeSheetSetting" [dueDateData]="{
                          endDate: subTaskArr[i]?.due_date,
                          startDate: subTaskArr[i]?.start_date
                        }" [isSubTask]="true" [task_id]="this.data?.editTaskId"
                        (selectedDate)="selectedSubTaskDate($event, i)" [serviceRecurring]="taskData?.is_service_recurring">
                      </app-task-due-date-common>
                    </mat-menu>
                  </div>
                  <div>
                    <img alt="delete-icon" draggable="false" src="assets/images/deletedarkicon.svg"
                      (click)="deleteSubTask(i)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2" *ngIf="isShowCheckList">
              <div class="d-flex align-items-center" [class.edit-task-disable]="!disableAllFunctionality">
                <div class="d-flex w-100">
                  <div class="add-checklist-hover me-auto">
                    <a class="anchor-link d-flex align-items-center sub-font" (click)="addCheckListRow()">+ {{
                      "ADD_CHECKLIST" | translate }}</a>
                    <span class="show-on-hover">|</span>
                    <a class="anchor-link show-on-hover" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">{{
                      "IMPORT_CHECKLIST" | translate }}</a>
                    <mat-menu #menu="matMenu" class="custom-sub-menu-dropdown-menu checklist-menu p-0">
                      <span [ngClass]="{
                          checklist_show: checklistData?.length == 0
                        }" *ngIf="checklistData?.length == 0">No Data found</span>
                      <span [ngClass]="{
                          checklist_show: checklistData?.length == 0
                        }" *ngIf="checklistData?.length > 0">
                        <app-task-checklist-template (click)="$event.stopPropagation()"
                          (selectedCheckList)="importChecklist($event)"></app-task-checklist-template>
                      </span>
                    </mat-menu>
                  </div>
                  <mat-checkbox class="p-0 custom-checkbox" [(ngModel)]="markAsMandatory" [checked]="markAsMandatory"
                    (change)="changeMandatory($event.checked)">
                    {{ "MARK_AS_MANDATORY" | translate }}
                  </mat-checkbox>
                </div>
              </div>
              <div class="col-12 mt-1 position-custom" *ngIf="checkList.length > 0"
                [class.edit-task-disable]="!disableAllFunctionality">
                <ul class="list-unstyled checklist-list mb-0">
                  <li>
                    <div class="checklist-box" formArrayName="checkList">
                      <ul class="list-unstyled checklist-list">
                        <li class="d-flex align-items-center my-1 set-list list-block-checklist" *ngFor="
                            let item of checkList.controls;
                            let pointIndex = index
                          " [formGroupName]="pointIndex" [ngStyle]="{
                            border: !taskDetailForm.get('checkList').value[
                              pointIndex
                            ]?.label
                              ? '1px solid rgb(235, 2, 12)'
                              : ''
                          }">
                          <div class="me-auto check-list-main">
                            <mat-checkbox class="circle--checkbox" formControlName="status" checked="status" (change)="
                                updateCheckListStatusAndDelete(
                                  item,
                                  'status',
                                  pointIndex
                                )
                              "></mat-checkbox>
                            <div matTooltip="{{
                                taskDetailForm.get('checkList').value[
                                  pointIndex
                                ]?.label
                              }}" class="input-block">
                              <input matInput name="label" class="ms-2 w-auto input-block-field text-dwindle d-590"
                                formControlName="label" (keyup)="
                                  quickReplyCheck(
                                    $event,
                                    'checkList',
                                    pointIndex
                                  )
                                " type="text" trim="blur" [readonly]="
                                  !userRolePermission?.task?.can_edit &&
                  data?.editTaskId &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId
                                " />
                            </div>
                            <mat-error class="error-validation" *ngIf="
                                !taskDetailForm.get('checkList').value[
                                  pointIndex
                                ]?.label
                              ">
                              Checklist label is required
                            </mat-error>
                          </div>
                          <div *ngIf="
                              isEnableDropdown?.isCheckListEnable[pointIndex] &&
                              quickReplyFilter?.length > 0
                            "
                            class="form-group-inner custom-placeholder-form-group custon-data-list custon-data-list-3 mt-0">
                            <ng-container *ngFor="let reply of quickReplyFilter">
                              <div (click)="
                                  handleStaticResultSelected(
                                    reply,
                                    'checkList',
                                    pointIndex
                                  )
                                " class="items">
                                <label class="mb-0">
                                  {{ reply.title }}
                                </label>
                                <span>{{ reply.message }}</span>
                              </div>
                            </ng-container>
                          </div>
                          <div class="vertical-divider"></div>
                          <ul [ngClass]="{
                              'edit-task-disable':
                              !userRolePermission?.task?.can_edit &&
                  data?.editTaskId &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId
                            }" class="list-unstyled d-flex align-items-center calendar-date">
                            <li class="me-2">
                              <a class="d-flex align-items-center">
                                <img src="assets/icons/calendericon.svg" alt="calendar-icon" draggable="false"
                                  (click)="picker.open()" class="me-2" />
                                <mat-datepicker #picker></mat-datepicker>
                                <input matInput [matDatepicker]="picker" [readonly]="true" (click)="picker.open()"
                                  formControlName="due_date" [required]="false" />
                              </a>
                            </li>
                            <li class="me-2" *ngIf="
                                data?.editTaskId &&
                                taskDetailForm.get('checkList').value[
                                  pointIndex
                                ]._id
                              " (click)="
                                checkListComment(
                                  taskDetailForm.get('checkList').value[
                                    pointIndex
                                  ]._id,
                                  taskDetailForm.get('checkList').value[
                                    pointIndex
                                  ]?.label
                                )
                              ">
                              <a>
                                <img src="assets/images/chaticon.svg" alt="chat-icon" draggable="false" />
                              </a>
                            </li>
                            <li>
                              <a>
                                <img src="assets/images/deletedarkicon.svg" alt="delete-icon" draggable="false" (click)="
                                    updateCheckListStatusAndDelete(
                                      item,
                                      'delete',
                                      pointIndex
                                    )
                                  " />
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="is_task_active && is_active && fields?.length > 0" [ngClass]="{
                'edit-task-disable':
                !userRolePermission?.task?.can_edit &&
                  data?.editTaskId &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId
              }" class="mt-3">
              <mat-card class="p-0 h-100 w-100 mat-elevation-z0 custom-field-card">
                <mat-card-header class="align-items-end h-auto p-0 border-0">
                  <div class="d-flex w-100 mt-2">
                    <h4 class="extra--user-link mb-3">
                      {{ "CUSTOM_FIELD" | translate }}
                    </h4>
                  </div>
                </mat-card-header>
                <mat-card-content *ngIf="isActiveContent" class="p-0 h-auto">
                  <div *ngIf="fields.length > 0">
                    <app-custom-filed [fields]="fields" [form]="customForm"></app-custom-filed>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-card-content>
        </ng-scrollbar>
      </mat-card>
    </div>
  </div>
  <div class="row bottom-action-row mx-0">
    <div class="col p-3 d-flex justify-content-start math_ui_inside-footer-action-button" *ngIf="subTasks || parentId">
      <div class="footer-action-button-left">
        <button mat-icon-button color="primary" (click)="previousNextTask('isPrevious')"
          [disabled]="isDisable('isPrevious')">
          <span class="material-symbols-outlined"> arrow_back_ios </span>
          <span class="text-button"> Prev </span>
        </button>
      </div>
      <div class="mx-3 footer-action-button-right">
        <button mat-icon-button color="primary" (click)="previousNextTask('isNext')" [disabled]="isDisable('isNext')">
          <span class="text-button"> Next </span>
          <span class="material-symbols-outlined"> arrow_forward_ios </span>
        </button>
      </div>
    </div>
    <div class="col p-3 d-flex justify-content-end">
      <button class="btn btn--bordered me-2" mat-button mat-flat-button (click)="closeDialog()" type="button">
        {{ "CLOSE" | translate }}
      </button>
      <button type="button" *ngIf="!data.isEditTask" [disabled]="
          !taskDetailForm.valid || !customForm?.valid || submitBtnDisable
        " (click)="createMultipleTask()" class="btn btn-primary float-right">
        {{ "SUBMIT" | translate }}
      </button>
      <button type="button" *ngIf="data.isEditTask" [class.edit-task-disable]="!disableAllFunctionality" [disabled]="
          !taskDetailForm.valid ||
          !customForm?.valid ||
          submitBtnDisable ||
          (!userRolePermission?.task?.can_edit &&
                  data?.editTaskId &&
                  !userRolePermission?.superAdmin && taskData?.owner?.user_id != decryptedUserId)
        " (click)="updateTask()" class="btn btn-primary float-right">
        {{ "UPDATE_BUTTON" | translate }}
      </button>
    </div>
  </div>
</form>