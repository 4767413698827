import { Theme } from './symbols';

export const darkTheme: Theme = {
    name: 'dark',
    properties: {
        '--background': '#1F2125',
        '--on-background': '#fff',
        '--primary': 'darkorange',
        '--on-primary': '#fff',
        '--header': '#000',
        '--icons': '#fff',
        '--menu-items': '#fff',
    },
    booleans: true,
};
