import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterData',
})
export class FilterPipe implements PipeTransform {
    transform(value: any, textSearch: any, param?: string): any {
        if (!textSearch) return value;
        if (param) {
            return value.filter((ele: any) =>
                ele[param].toLowerCase().includes(textSearch)
            );
        }
        return value.filter((ele: any) =>
            (ele.first_name + ' ' + ele.last_name)
                .toLowerCase()
                .includes(textSearch.toLowerCase())
        );
    }
}
