import { Injectable } from '@angular/core';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { Observable, Subject, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EmailManagementService {
    apiEndPoint = NAVIGATE_ROUTES;

    private emailData: Subject<any> = new Subject<any>();
    emailData$: Observable<any> = this.emailData.asObservable();

    private changeCompany: Subject<any> = new Subject<any>();
    changeCompany$: Observable<any> = this.changeCompany.asObservable();

    constructor(private apiService: ApiService) {}

    setemailData(email: any) {
        this.emailData.next(email);
    }

    setCompanySidebar(company: any) {
        this.changeCompany.next(company);
    }

    async getEmailCompanyList(): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.EMAIL_COMPANY_LIST}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getEmailList(
        company_id: any,
        member_id: any
    ): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.GET_EMAIL_LIST}?company_id=${company_id}&member_id=${member_id}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getEmailData(
        emailId: any,
        page: any,
        limit: any
    ): Promise<Observable<any>> {
        let res: any[] = [];
        let url = `${this.apiEndPoint.GET_EMAIL_DETAIL}?emailId=${emailId}`;
        if (page) {
            url += `&page=${page}`;
        }
        if (limit) {
            url += `&limit=${limit}`;
        }
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async taskCreateFromEmail(data: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.ADD_TASK_EMAIL}`;
        await this.apiService.post(url, data).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getUserRolePermissionForEmail(userId: any, companyId: any) {
        let res: any[] = [];
        const url = `${this.apiEndPoint.COMPANY}/userCompanyAccesses?companyId=${companyId}&userId=${userId}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }
}
