import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils-service/utils.service';
import { TasksService } from '../../services/task-service/tasks.service';
import { ToastrService } from 'ngx-toastr';
import { ClientInfoPreviewComponent } from '../client-info-preview/client-info-preview.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-mat-chip-preview',
    templateUrl: './mat-chip-preview.component.html',
    styleUrls: ['./mat-chip-preview.component.scss'],
})
export class MatChipPreviewComponent implements OnInit {
    constructor(
    public utils: UtilsService,
    public taskService: TasksService,
    private toastrService: ToastrService,
    private matDialog: MatDialog
    ) {}

  @Input() isInfoShow = false;
  @Input() selectedMembers: any;
  @Input() listArrName = '';
  @Input() isSubTask = false;
  @Input() isStrong = false;
  groupMemberInfo: any = [];

  ngOnInit() {
    // Intialize the component
  }

  onToppingRemoved(removeId: any, listArrName: string) {
      this.taskService.removeIdFromMatPreview({
          removeId: removeId,
          listArrName: listArrName,
          isSubTask: this.isSubTask,
      });
  }

  async getTeamMemberDetails(groupId: any) {
      (await this.taskService.getGroupDetails(groupId)).subscribe(
          async (resData: any) => {
              if (resData) {
                  this.groupMemberInfo = [];
                  resData?.forEach((ele: any) => {
                      ele?.group_members.forEach((data: any) => {
                          const dataObj = {
                              profile_image: data?.user_company_relation?.profile_image,
                              name:
                  data?.user_company_relation?.first_name +
                  ' ' +
                  data?.user_company_relation?.last_name,
                          };
                          this.groupMemberInfo.push(dataObj);
                      });
                  });
              } else {
                  return this.toastrService.error('No data available');
              }
          }
      );
  }

  clientInfo(clientId: any) {
      this.matDialog.open(ClientInfoPreviewComponent, {
          width: '408px',
          panelClass: [
              'custom-modal',
              'custom-modal-md',
              'new-client-info-cls-add',
          ],
          data: { clientId: clientId },
      });
  }
}
