import { Store } from '@ngrx/store';
import { CheckListTemplateService } from './../../../shared/services/organization-settings/check-list-template.service';
import { UserDetailsService } from './../../../shared/services/user-details.service';
import { UtilsService } from './../../../shared/services/utils-service/utils.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-task-checklist-template',
    templateUrl: './task-checklist-template.component.html',
    styleUrls: ['./task-checklist-template.component.scss'],
})
export class TaskChecklistTemplateComponent implements OnInit {
    userId: any;
    companyId: any;
    checklistData: any = [];
    customTemplate: any;
    taskState: any;
  @Output('selectedCheckList') selectedCheckList = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<TaskChecklistTemplateComponent>,
    public translate: TranslateService,
    public utils: UtilsService,
    public userDetailService: UserDetailsService,
    public checklistService: CheckListTemplateService,
    private store: Store<{ taskState: TaskState }>
  ) {
      translate.setDefaultLang('en');
      this.getCheckTemplateList(this.companyId, this.userId);
  }

  async ngOnInit() {
      this.userId = await this.userDetailService.encryptUserId;
      this.companyId = await this.userDetailService.getCompanyId();
  }

  async getCheckTemplateList(_companyId: any, _userId: any) {
      this.store.select('taskState').subscribe((ele: any) => {
          if (!ele.taskState) return;
          this.taskState = ele?.taskState['checkList'];
      });
      this.customTemplate = this.taskState?.checkLists
          ? JSON.parse(JSON.stringify(this.taskState?.checkLists))
          : '';
  }

  checkSelected(_status: any, value: any) {
      this.customTemplate.forEach((element: any) => {
          if (element._id == value._id) {
              if (value.checked) {
                  element['checked'] = true;
              } else {
                  element['checked'] = false;
              }
          } else {
              element['checked'] = false;
          }
      });
      this.checklistData = this.customTemplate.filter(
          (item: any) => item.checked === true
      );
  }

  checkedChecklist() {
      this.selectedCheckList.emit(this.checklistData);
      this.customTemplate.forEach((element: any) => {
          element.checked = false;
      });
      this.checklistData = [];
  }

  closeModal() {
      this.dialogRef.close();
  }
}
