import { Component, Input, OnInit } from '@angular/core';
import { UserState } from '../../ngrx/reducer/user-state.reducer';
import { Store } from '@ngrx/store';
import { UtilsService } from '../../services/utils-service/utils.service';

@Component({
    selector: 'app-member-hover',
    templateUrl: './member-hover.component.html',
    styleUrls: ['./member-hover.component.scss'],
})
export class MemberHoverComponent implements OnInit {
  @Input() itemList: any;
  @Input() isOnlineByUserId = false;
  onlineArr: any = [];

  constructor(
    private store: Store<{ userState: UserState }>,
    public utils: UtilsService
  ) {}

  ngOnInit() {
      this.store.select('userState').subscribe((ele: any) => {
          this.onlineArr = ele.updateState;
      });
  }
}
