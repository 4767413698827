<div class="card attachment--card">
  <div class="card-header">
    <input
      type="text"
      class="form-control custom-search-feild"
      placeholder="Type here to Search"
      (keyup)="applyFilter($event)"
    />
    <mat-button-toggle-group
      name="fontStyle"
      aria-label="Font Style"
      class="custom-button-toggle ms-auto"
    >
      <mat-button-toggle value="bold" (click)="listView()">
        <img src="assets/images/listviewicon.svg" alt="" />
      </mat-button-toggle>
      <mat-button-toggle value="italic" (click)="gridView()">
        <img src="assets/images/gridviewicon.svg" alt="" />
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="card-body p-0" [ngClass]="{ classname: isGridView }">
    <div class="row" *ngIf="isListView">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table list-view-table">
            <caption></caption>
            <thead>
              <tr>
                <th></th>
                <th>
                  {{ "FILE_NAME" | translate }}
                </th>
                <th class="text-center">
                  {{ "OWNER" | translate }}
                </th>
                <th>
                  {{ "DATE" | translate }}
                </th>
                <th class="text-end">
                  {{ "SIZE" | translate }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-title">
                <td></td>
                <td colspan="2">
                  <label>{{ "TASK_ATTACHMENT" | translate }}</label>
                </td>
                <td colspan="2" class="text-end">
                  <strong
                    >{{ attatchmentDetail.length }}
                    {{ "FILES" | translate }}</strong
                  >
                </td>
                <td></td>
              </tr>
              <tr
                *ngFor="
                  let documents of attatchmentDetail
                    | filterData : searchText : 'name'
                "
                (click)="downloadDoc(documents.file_path, documents)"
              >
                <td></td>
                <td class="text-dark">
                  <img
                    [src]="documents['icon']"
                    alt=""
                    class="me-2"
                    height="20"
                    width="20"
                  />
                  {{ documents.name }}
                </td>
                <td class="text-center">
                  <span class="user--img ms-auto">
                    <img
                      *ngIf="
                        documents.user_details.profile_image?.charAt(0) != '#'
                      "
                      [src]="documents.user_details.profile_image"
                      alt=""
                      class="img-avatar"
                    />
                    <div
                      *ngIf="
                        documents.user_details.profile_image?.charAt(0) == '#'
                      "
                      class="user-short-name mr-0"
                      [ngStyle]="{
                        'background-color': documents.user_details.profile_image
                      }"
                    >
                      {{
                        utils.forShortName(
                          documents.user_details?.first_name +
                            " " +
                            documents.user_details?.last_name
                        )
                      }}
                    </div>
                  </span>
                </td>
                <td>
                  {{ documents.created_at | date : "MMM d, y" }}
                </td>
                <td class="text-end">
                  {{ (documents.size * 0.000001).toFixed(3) }} mb
                </td>
                <td></td>
              </tr>
              <tr class="table-title">
                <td></td>
                <td colspan="2">
                  <label>{{ "COMMENT_ATTACHMENT" | translate }}</label>
                </td>
                <td colspan="2" class="text-end">
                  <strong
                    >{{ commentDocument.length }}
                    {{ "FILES" | translate }}</strong
                  >
                </td>
                <td></td>
              </tr>
              <tr
                *ngFor="let documents of commentDocument"
                (click)="downloadDoc(documents.file_path, documents)"
              >
                <td></td>
                <td class="text-dark">
                  <img
                    [src]="documents['icon']"
                    alt=""
                    class="me-2"
                    height="20"
                    width="20"
                  />
                  {{ documents.name }}
                </td>
                <td class="text-center">
                  <span class="user--img ms-auto">
                    <img
                      *ngIf="
                        documents.user_details.profile_image?.charAt(0) != '#'
                      "
                      [src]="documents.user_details.profile_image"
                      alt=""
                      class="img-avatar"
                    />
                    <div
                      *ngIf="
                        documents.user_details.profile_image?.charAt(0) == '#'
                      "
                      class="user-short-name mr-0"
                      [ngStyle]="{
                        'background-color': documents.user_details.profile_image
                      }"
                    >
                      {{
                        utils.forShortName(
                          documents.user_details?.first_name +
                            " " +
                            documents.user_details?.last_name
                        )
                      }}
                    </div>
                  </span>
                </td>
                <td>{{ documents.created_at | date }}</td>
                <td class="text-end">
                  {{ (documents.size * 0.000001).toFixed(3) }} mb
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="grid-view-main-block p-4" *ngIf="isGridView">
      <div class="row">
        <div class="col-12">
          <h4 class="block-title block-title-12 mb-3">
            {{ "TASK_ATTACHMENT" | translate }}
          </h4>
        </div>
      </div>
      <div class="grid-view">
        <div
          class="card mat-card doc-card p-0"
          *ngFor="
            let documents of attatchmentDetail
              | filterData : searchText : 'name'
          "
          (click)="downloadDoc(documents.file_path, documents)"
        >
          <div
            class="card-body p-0"
            [ngClass]="{ 'card-body-inside': isGridView }"
          >
            <img
              [src]="documents.thumb_path"
              alt=""
              *ngIf="
                documents.thumb_path !== null &&
                documents.thumb_path !==
                  'https://pre-prod.taskopad.com/uploads/null'
              "
            />
            <img
              [src]="documents['icon']"
              alt=""
              *ngIf="
                documents.thumb_path == null ||
                documents.thumb_path ==
                  'https://pre-prod.taskopad.com/uploads/null'
              "
            />
          </div>
          <div class="card-footer d-flex align-items-center">
            <div class="d-flex align-items-start">
              <img
                [src]="documents['icon']"
                alt=""
                class="me-2"
                height="20"
                width="20"
              />
              <div>
                <h4 class="cmn-txt mb-0">{{ documents.name }}</h4>
                <p class="sub-block-title mb-0 sub-block-title-sm">
                  {{ documents.created_at | date : "MMM d, y" }}
                  {{ (documents.size * 0.000001).toFixed(3) }} mb
                </p>
              </div>
            </div>
            <span class="user--img ms-auto">
              <img
                *ngIf="documents.user_details.profile_image?.charAt(0) != '#'"
                [src]="documents.user_details.profile_image"
                alt=""
                class="img-avatar"
              />
              <div
                *ngIf="documents.user_details.profile_image?.charAt(0) == '#'"
                class="user-short-name mr-0"
                [ngStyle]="{
                  'background-color': documents.user_details.profile_image
                }"
              >
                {{
                  utils.forShortName(
                    documents.user_details?.first_name +
                      " " +
                      documents.user_details?.last_name
                  )
                }}
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h4 class="block-title block-title-12 mb-3">
            {{ "COMMENT_ATTACHMENT" | translate }}
          </h4>
        </div>
      </div>
      <div class="grid-view">
        <div
          class="card mat-card doc-card p-0"
          *ngFor="
            let documents of commentDocument | filterData : searchText : 'name'
          "
          (click)="downloadDoc(documents.file_path, documents)"
        >
          <div class="card-body p-0">
            <img
              [src]="documents.thumb_path"
              alt=""
              *ngIf="
                documents.thumb_path !== null &&
                documents.thumb_path !==
                  'https://pre-prod.taskopad.com/uploads/null'
              "
            />
            <img
              [src]="documents['icon']"
              alt=""
              *ngIf="
                documents.thumb_path == null ||
                documents.thumb_path ==
                  'https://pre-prod.taskopad.com/uploads/null'
              "
            />
          </div>
          <div class="card-footer d-flex align-items-center">
            <div class="d-flex align-items-start">
              <img
                [src]="documents['icon']"
                alt=""
                class="me-2"
                height="20"
                width="20"
              />
              <div>
                <h4 class="cmn-txt mb-0">{{ documents.name }}</h4>
                <p class="sub-block-title mb-0 sub-block-title-sm">
                  {{ documents.created_at | date : "MMM d, y" }}
                  {{ (documents.size * 0.000001).toFixed(3) }} mb
                </p>
              </div>
            </div>
            <span class="user--img ms-auto">
              <img
                *ngIf="documents.user_details.profile_image?.charAt(0) != '#'"
                [src]="documents.user_details.profile_image"
                alt=""
                class="img-avatar"
              />
              <div
                *ngIf="documents.user_details.profile_image?.charAt(0) == '#'"
                class="user-short-name mr-0"
                [ngStyle]="{
                  'background-color': documents.user_details.profile_image
                }"
              >
                {{
                  utils.forShortName(
                    documents.user_details?.first_name +
                      " " +
                      documents.user_details?.last_name
                  )
                }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
