import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-timer-note-modal',
    templateUrl: './timer-note-modal.component.html',
    styleUrls: ['./timer-note-modal.component.scss'],
})
export class TimerNoteModalComponent implements OnInit {
    notes: any;

    constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<TimerNoteModalComponent>,
    public translate: TranslateService
    ) {}

    ngOnInit(): void {
        // Initialize the component
    }

    onConfirmClick(): void {
        const data = { isConfirm: true, notes: this.notes };
        this.dialogRef.close(data);
    }
    onCancelClick(): void {
        const data = { isConfirm: false, notes: this.notes };
        this.dialogRef.close(data);
    }
}
