<div
  (click)="$event.stopPropagation()"
  class="math-block-one-calendar-remainder"
>
  <div
    class="d-flex align-items-center flex-row justify-content-between pt-0 calendar-title"
    [ngClass]="{ 'border-bottom': isOn }"
  >
    <div
      class="inner-first-div d-flex justify-content-between"
      [ngClass]="{ customWidth: isOn }"
    >
      <div class="math_ui-remind-on">
        {{ "REMIND_ON" | translate }}
      </div>

      <mat-slide-toggle
        class="mat-slide-toggle d-flex align-items-end ms-2"
        (change)="emitDataObj()"
        [(ngModel)]="isOn"
      >
      </mat-slide-toggle>
    </div>
    <div class="inner-second-div">
    </div>
  </div>
  <div *ngIf="isOn" class="reminder-block">
    <div class="d-flex">
      <div class="left-side-blocking" (click)="$event.stopPropagation()">
        <div class="position-relative" (click)="$event.stopPropagation()">
          <mat-calendar
            [(selected)]="selectedDueDate"
            (selectedChange)="emitDataObj()"
            [minDate]="minDate"
          ></mat-calendar>
        </div>
      </div>
      <div class="border-full"></div>
      <div class="right-side-blocking">
        <div class="mx-3">
          <div
            class="d-flex align-items-center custom-height pt-2 justify-content-between"
          >
            <div class="px-0 cmn-txt text-cmns">
              {{ selectedDueDate | date : "MMM d, y" }}
            </div>
            <div class="time">
              <select
                [(ngModel)]="selectedHour"
                name="selectedHour"
                (change)="timeSelection()"
              >
                <option *ngFor="let hour of hours" [value]="hour">
                  {{ hour }}
                </option>
              </select>
              <select
                [(ngModel)]="selectedMinute"
                name="selectedMinute"
                (change)="timeSelection()"
              >
                <option *ngFor="let minute of minutes" [value]="minute">
                  {{ minute }}
                </option>
              </select>
            </div>
          </div>
          <div class="position-relative">
            <mat-label hidden></mat-label>
            <ul class="list-unstyled a-r-list">
              <mat-checkbox
                class="example-margin c-checkbox-button"
                [checked]="isAllChecked(remindTo)"
                (change)="allSelect(remindTo, $event)"
                >All</mat-checkbox
              >
              <li *ngFor="let list of remindTo">
                <mat-checkbox
                  class="example-margin c-checkbox-button"
                  (change)="optionClick(list, $event)"
                  [(ngModel)]="list['checked']"
                  >{{ list.label }}</mat-checkbox
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="m-3 border-bottom mb-0">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <img alt="revert-icon" src="./assets/images/bluereverticon.svg" class="mr-2" />
              <mat-select
                name="frequency range"
                [value]="selectedFrequency"
                [(ngModel)]="selectedFrequency"
                placeholder="Select"
                class="typo-text"
              >
                <mat-option
                  *ngFor="let ele of frequency"
                  [value]="ele.value"
                  (onSelectionChange)="selectFrequency(ele, $event)"
                >
                  {{ ele.name }}
                </mat-option>
              </mat-select>
            </div>
            <div *ngIf="selectedFrequency && selectedFrequency !== 'once'" class="input-mat">
              <input
                matInput
                type="number" [min]="1"
                [placeholder]="selectedFrequency == 'hourly' ? 'Hours' : 'Days'"
                [(ngModel)]="repeatValue"
              />
            </div>
          </div>
        </div>
        <div class="mx-3 reminder-notify">
          <div class="position-relative">
            <mat-label hidden></mat-label>
            <span class="typo-text font-12">Reminder On</span>
            <ul class="list-unstyled a-r-list">
              <mat-checkbox
                class="example-margin c-checkbox-button"
                [checked]="isAllChecked(reminderOption)"
                (change)="allSelect(reminderOption, $event)"
                >All</mat-checkbox
              >
              <li *ngFor="let list of reminderOption">
                <mat-checkbox
                  class="example-margin c-checkbox-button"
                  (change)="optionClick(list, $event)"
                  [(ngModel)]="list['checked']"
                  >{{ list.name }}</mat-checkbox
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex border-top footer-calendar">
      <div class="text-left">
        <a class="anchor-link float-end" (click)="clearData()">Clear all</a>
      </div>
      <div class="text-right">
        <button
          class="anchor-link float-end math_ui-text"
          (click)="emitDataObj(true)"
          [ngClass]="{ isGrey: isValidForm() }"
          style="border: none"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</div>
