<form [formGroup]="timeLogForm">
  <div
    class="card attachment--card oepntime-log-cls"
    *ngIf="taskId && timerToggle"
  >
    <div class="card-header d-flex align-items-center bg-white">
      <a
        class="anchor-link d-flex align-items-center"
        *ngIf="
          (timerShow && timeLogDetail?.timeSheetData?.length > 0) ||
          (timeLogDetail?.timeSheetData?.length == 0 && isFilterApply)
        "
        (click)="openTimeLogDialog()"
        [ngClass]="{ 'edit-task-disable': isCloseTask }"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="me-2"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.625 5.375V0.375H5.375V5.375H0.375V6.625H5.375V11.625H6.625V6.625H11.625V5.375H6.625Z"
            fill="#276EF1"
          /></svg
        >{{ "LOG_TIME" | translate }}</a
      >
      <ul
        class="list-unstyled ms-auto mb-0 d-flex align-items-center"
        *ngIf="
          (taskId && timeLogDetail?.timeSheetData?.length > 0) ||
          (timeLogDetail?.timeSheetData?.length == 0 && isFilterApply)
        "
      >
        <li class="me-2">
          <a
            class="sub-block-title sub-block-title-sm d-flex align-items-center"
            mat-menu-item
            [matMenuTriggerFor]="matMenu"
          >
            <svg
              width="11"
              height="14"
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="me-2"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.01807 1C6.41807 1 7.51807 2.1 7.51807 3.5C7.51807 4.9 6.41807 6 5.01807 6C3.61807 6 2.51807 4.9 2.51807 3.5C2.51807 2.1 3.61807 1 5.01807 1ZM5.01807 0C3.11807 0 1.51807 1.6 1.51807 3.5C1.51807 5.4 3.11807 7 5.01807 7C6.91807 7 8.51807 5.4 8.51807 3.5C8.51807 1.6 6.91807 0 5.01807 0ZM10.0181 14H9.01807V11.5C9.01807 10.1 7.91807 9 6.51807 9H3.51807C2.11807 9 1.01807 10.1 1.01807 11.5V14H0.0180664V11.5C0.0180664 9.6 1.61807 8 3.51807 8H6.51807C8.41807 8 10.0181 9.6 10.0181 11.5V14Z"
                fill="#424242"
              /></svg
            >Users</a
          >
          <mat-menu
            #matMenu="matMenu"
            class="multi-select-chip-menu calendar-type-module"
          >
            <div
              class="bg-dark-grey d-flex align-items-center d-flex pe-0 ps-3"
              (click)="$event.stopPropagation()"
            >
              <input
                type="text"
                autocomplete="off"
                placeholder="{{ 'TYPE_HERE_TO_SEARCH' | translate }}"
                class="form-control c-s-input custom-input border-0 w-100 m-w-100"
                (keyup)="applyFilterSearch($event)"
              />
            </div>

            <div (click)="$event.stopPropagation()" class="allselected-cls">
              <mat-checkbox
                class="ms-2 custom-checkbox"
                (change)="checkedAllStatus($event)"
                [checked]="isAllChecked(loggedUsers)"
              >
                select all</mat-checkbox
              >
            </div>
            <ul class="list-unstyled mb-0" (click)="$event.stopPropagation()">
              <li
                *ngFor="
                  let user of loggedUsers | filterData : searchForUser : 'name'
                "
              >
                <mat-checkbox
                  class="me-2 custom-checkbox"
                  [(ngModel)]="user.isCheck"
                  [checked]="user?.isCheck"
                  (change)="statusChange(user, $event)"
                >
                  <span>
                    <img
                      *ngIf="
                        user?.profile_image &&
                        user?.profile_image?.charAt(0) != '#'
                      "
                      [src]="user?.profile_image"
                      alt=""
                      class="img-avatar"
                    />
                    <div
                      *ngIf="
                        user?.profile_image?.charAt(0) == '#' ||
                        !user?.profile_image
                      "
                      class="user-short-name me-2"
                      [ngStyle]="{ 'background-color': user?.profile_image }"
                    >
                      {{ utils.forShortName(user?.name) }}
                    </div>
                  </span>
                  <div>
                    <h4 class="cmn-txt mb-0 size-fix">
                      <span class="trim-info">{{ user?.name }}</span>
                    </h4>
                  </div>
                </mat-checkbox>
              </li>
            </ul>
          </mat-menu>
        </li>
        <li>
          <a
            class="sub-block-title sub-block-title-sm d-flex align-items-center"
            mat-menu-item
            [matMenuTriggerFor]="allLogsMenu"
          >
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="me-2"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.0180664 10H5.01807V11H0.0180664V10ZM0.0180664 7H5.01807V8H0.0180664V7ZM11.0181 5H1.01807C0.465782 5 0.0180664 4.55228 0.0180664 4V1C0.0180664 0.447715 0.465782 0 1.01807 0H11.0181C11.5704 0 12.0181 0.447715 12.0181 1V4C12.0181 4.55228 11.5704 5 11.0181 5ZM1.01807 1V4H11.0181V1H1.01807ZM11.0181 12H8.01807C7.46578 12 7.01807 11.5523 7.01807 11V8C7.01807 7.44772 7.46578 7 8.01807 7H11.0181C11.5704 7 12.0181 7.44772 12.0181 8V11C12.0181 11.5523 11.5704 12 11.0181 12ZM8.01807 8V11H11.0181V8H8.01807Z"
                fill="#9E9E9E"
              />
            </svg>
            All logs</a
          >
          <mat-menu
            #allLogsMenu="matMenu"
            class="multi-select-chip-menu calendar-type-module"
          >
            <div
              class="bg-dark-grey d-flex align-items-center d-flex pe-0 ps-3"
              (click)="$event.stopPropagation()"
            >
              <input
                type="text"
                autocomplete="off"
                placeholder="{{ 'TYPE_HERE_TO_SEARCH' | translate }}"
                class="form-control c-s-input custom-input border-0 w-100 m-w-100"
                (keyup)="applyFilterSearch($event)"
              />
            </div>
            <div (click)="$event.stopPropagation()" class="allselected-cls">
              <mat-checkbox
                class="ms-2 custom-checkbox"
                (change)="checkedAllTaskStatus($event)"
                [checked]="isAllCheckedTask(allTasks)"
              >
                select all</mat-checkbox
              >
            </div>
            <ul class="list-unstyled mb-0" (click)="$event.stopPropagation()">
              <li
                *ngFor="
                  let task of allTasks
                    | filterData : searchForUser : 'task_name'
                "
              >
                <mat-checkbox
                  class="me-2 custom-checkbox"
                  [(ngModel)]="task.isCheck"
                  [checked]="task?.isCheck"
                  (change)="taskStatusChange(task, $event)"
                >
                  <div>
                    <h4 class="cmn-txt mb-0">
                      {{ task?.task_name ? task?.task_name : "-" }}
                    </h4>
                  </div>
                </mat-checkbox>
              </li>
            </ul>
          </mat-menu>
        </li>
      </ul>
    </div>
    <div class="card-body p-0 only-for-card-time-log timelog-for-table">
      <div
        class="row"
        *ngIf="
          (taskId && timeLogDetail?.timeSheetData?.length > 0) ||
          (timeLogDetail?.timeSheetData?.length == 0 && isFilterApply)
        "
      >
        <div class="col-12">
          <div class="">
            <table class="table list-view-tabl timelog-view-table">
              <caption></caption>
              <thead>
                <tr>
                  <th class="timelog_task_name">
                    {{ "NAME" | translate }}
                  </th>
                  <th class="text-center timelog_time">
                    {{ "TYPE" | translate }}
                  </th>
                  <th class="text-center timelog_label">
                    {{ "DATE_LABEL" | translate }}
                  </th>
                  <th class="text-center timelog_s-Time">
                    {{ "S_TIME" | translate }}
                  </th>
                  <th class="text-center timelog_e-Time">
                    {{ "E_TIME" | translate }}
                  </th>
                  <th class="text-center timelog_total-hrs">
                    {{ "TOTAL_HRS" | translate }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody *ngIf="taskId && timeLogDetail?.timeSheetData">
                <ng-container *ngFor="let data of timeLogDetail?.timeSheetData">
                  <tr class="table-title">
                    <td colspan="5">
                      <div class="p-1 text-center" style="font-weight: 700">
                        {{ data.date | date : "MMM d, y" }}
                      </div>
                    </td>
                  </tr>
                  <div *ngFor="let user of data?.data">
                    <tr>
                      <td class="text-dark table-para timelog_task_name">
                        <div class="d-flex align-items-center gap-2">
                          <span>
                            <img
                              *ngIf="
                                user?.user_details?.profile_image &&
                                user?.user_details?.profile_image?.charAt(0) !=
                                  '#'
                              "
                              [src]="user.user_details?.profile_image"
                              alt=""
                              class="img-avatar"
                            />
                            <div
                              *ngIf="
                                user.user_details?.profile_image?.charAt(0) ==
                                  '#' || !user.user_details?.profile_image
                              "
                              class="user-short-name me-2"
                              [ngStyle]="{
                                'background-color':
                                  user.user_details.profile_image
                              }"
                            >
                              {{
                                utils.forShortName(
                                  user.user_details?.first_name +
                                    "
                              " +
                                    user.user_details?.last_name
                                )
                              }}
                            </div>
                          </span>
                          <div>
                            <h4 class="cmn-txt mb-0">
                              {{ user.user_details?.first_name }}
                              {{ user.user_details?.last_name }}
                            </h4>
                            <p class="sub-block-title sub-block-title-sm mb-0">
                              {{ user.time_log_created_time }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="text-center timelog_time">
                        <span
                          class="badge"
                          [ngClass]="getTypeClass('Manual')"
                          *ngIf="user.event != 'end'"
                          >{{ "MANUAL" | translate }}</span
                        >
                        <span
                          class="badge"
                          [ngClass]="getTypeClass('Auto')"
                          *ngIf="user.event == 'end'"
                          >{{ "AUTO" | translate }}</span
                        >
                      </td>
                      <td class="text-center">
                        {{ user.time_log_date | date : "MMM d, y" }}
                      </td>
                      <td class="text-center">
                        {{
                          user.event == "manual"
                            ? "-"
                            : (user.start_time | date : "shortTime") || "-"
                        }}
                      </td>
                      <td class="text-center">
                        {{
                          user.event == "manual"
                            ? "-"
                            : (user.end_time | date : "shortTime") || "-"
                        }}
                      </td>
                      <td class="text-center">
                        {{ user.hours | hoursSecondFormat }}
                      </td>
                      <td class="text-center delete-action-btn">
                        <span
                          *ngIf="
                            (user?.status != 'approved' &&
                              user?.status != 'submitted' &&
                              (user?.user_details?.user_id == memberID ||
                                user?.user_details?.id == memberID)) ||
                            (user_company_relations?.owner &&
                              user?.user_details?.id == memberID)
                          "
                          (click)="deleteConfirm(user?._id)"
                          [ngClass]="{ 'edit-task-disable': isCloseTask }"
                        >
                          <img
                            src="assets/icons/greythrash-four.svg"
                            alt="Delete icon"
                          />
                        </span>
                      </td>
                    </tr>
                    <tr *ngFor="let userNote of data?.data">
                      <div
                        class="custom-stt1 time-log-stt"
                        *ngIf="
                          userNote.comment != '' &&
                          userNote.comment != null &&
                          user._id == userNote._id
                        "
                      >
                        <p
                          class=""
                          matTooltip="{{ user.comment }}"
                          [matTooltipPosition]="'below'"
                        >
                          <strong>Notes:</strong> {{ user.comment }}
                        </p>
                      </div>
                    </tr>
                  </div>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="timeLogDetail?.timeSheetData?.length == 0 && isFilterApply">
        <div class="no-bin-found">
          <img src="assets/images/colorbinocular.png" alt="" />
          <p>{{ "TIME_LOG" | translate }}</p>
          <small>{{ "TIME_LOG_MESSAGE" | translate }}</small>
        </div>
      </div>
    </div>
  </div>

  <p
    class="total-hr new-postion-set-cls estimate-bottom-bar"
    *ngIf="taskId && timeLogDetail?.timeSheetData?.length > 0"
  >
    <span
      style="padding-right: 15px"
      class="math_ui_inside-actual-hours-number"
    >
      <span class="math_ui_inside-estimate-hours-label">Est. Hrs : </span>
      <span class="math_ui_inside-estimate-hours-label-number">
        {{ timeLogDetail?.time_sheet_estimated_hr || "00:00" }}</span
      >
    </span>
    <span
      style="float: left; padding-left: 12px"
      class="math_ui_inside-actual-hours-number"
    >
      <span class="math_ui_inside-actual-hours-label">Actual Hours : </span>
      <span class="math_ui_inside-actual-hours-number">
        {{ timeLogDetail?.totalHours || "00:00" }}</span
      >
    </span>
  </p>
  <div
    class="timesheet-report time-log-add"
    *ngIf="
      (timeLogDetail?.timeSheetData?.length == 0 || timeLogData?.length == 0) &&
      !isFilterApply
    "
  >
    <div class="bg-cls-add-time">
      <div class="no-bin-found position-set-empty">
        <img src="../../../../assets/images/Empty stat.png" alt="search" />
        <p>{{ "TIME_LOG" | translate }}</p>
        <small>{{ "TIME_LOG_MESSAGE" | translate }}</small>
        <div class="center-button mt-4">
          <button
            class="btn btn--bordered me-2"
            mat-flat-button
            (click)="openTimeLogDialog()"
            *ngIf="
              timeLogDetail?.timeSheetData?.length == 0 &&
              timerShow &&
              timerToggle &&
              taskId &&
              !isFilterApply &&
              status.status_name !== statusNameFormConst.UNDER_REVIEW 
            "
            [disabled]="isCloseTask"
          >
            {{ "ADD_TIME_LOG" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
