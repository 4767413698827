import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'userLastSeenPipe',
})
export class UserLastSeenPipePipe implements PipeTransform {
    transform(id: any, onlineArr: any, isOnlineByUserId: any): any {
        let lastSeenDate: any = '';
        let user_state_time: any = '';
        const todayDate = moment().format('DD-MM-YYYY HH:mm A');
        if (!isOnlineByUserId) {
            if (onlineArr.length && id) {
                onlineArr?.forEach((ele: any) => {
                    if (ele?.memberIds?.includes(parseInt(id)) && ele.user_state_time) {
                        user_state_time = ele.user_state_time;
                        return ele?.memberIds?.includes(parseInt(id)) && ele.user_state_time
                            ? (lastSeenDate = moment(ele.user_state_time).format(
                                'DD-MM-YYYY HH:mm A'
                            ))
                            : '';
                    }
                });
            }
            const date1 = moment(lastSeenDate, 'DD-MM-YYYY HH:mm A');
            const date2 = moment(todayDate, 'DD-MM-YYYY HH:mm A').startOf('day');

            const dayDifference = date2.diff(date1, 'days');

            if (dayDifference >= 1) {
                return moment(user_state_time).format('DD/MM/YYYY, HH:mm A');
            } else if (dayDifference < 1) {
                return moment(user_state_time).format('HH:mm A');
            } else {
                return '';
            }
        } else {
            if (onlineArr.length && id) {
                onlineArr?.forEach((ele: any) => {
                    if (ele?.user_id == id) {
                        user_state_time = ele.user_state_time;
                        return ele?.user_id === id && ele.user_state_time
                            ? (lastSeenDate = moment(ele.user_state_time).format(
                                'DD-MM-YYYY HH:mm A'
                            ))
                            : '';
                    }
                });
            }
            const date1 = moment(lastSeenDate, 'DD-MM-YYYY HH:mm A');
            const date2 = moment(todayDate, 'DD-MM-YYYY HH:mm A').startOf('day');

            const dayDifference = date2.diff(date1, 'days');
            if (dayDifference >= 1) {
                return moment(user_state_time).format('DD/MM/YYYY, HH:mm A');
            } else if (dayDifference < 1) {
                return moment(user_state_time).format('HH:mm A');
            } else {
                return '';
            }
        }
    }
}
