import { createReducer, on } from '@ngrx/store';
import * as taskStates from '../action/task-state.actions';

export interface taskState {
  taskState: any;
}

export const initialState: taskState = {
    taskState: {},
};

export const reducer = createReducer(
    initialState,
    on(taskStates.taskState, (state: taskState, action): any => {
        return {
            ...state,
            taskState: action.data,
        };
    })
);

export function taskReducer(state: any, action: any) {
    return reducer(state, action);
}
