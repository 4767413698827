import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { TasksService } from 'src/app/shared/services/task-service/tasks.service';
import { UtilsService } from './../../../shared/services/utils-service/utils.service';
import { TranslateService } from '@ngx-translate/core';
import {
    DateRange,
    DefaultMatCalendarRangeStrategy,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
    MatRangeDateSelectionModel,
} from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';
import { TaskEmitService } from 'src/app/shared/services/task-service/task-emit.service';
import * as moment from 'moment-timezone';
import { UserState } from 'src/app/shared/ngrx/reducer/user-state.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-log-management-tab',
    templateUrl: './log-management-tab.component.html',
    styleUrls: ['./log-management-tab.component.scss'],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
        DefaultMatCalendarRangeStrategy,
        MatRangeDateSelectionModel,
    ],
})
export class LogManagementTabComponent implements OnInit {
    readUnreadData: any = [];
    memberTotalCount: any;
  @Input() taskId: any;
  logActivityArr: any = [];
  selectedCompanyId: any;
  todayDate: any = new Date();
  dateRange: DateRange<Date>;
  testdata: any;
  startDate: any;
  endDate: any;
  @ViewChild('languageMenuTrigger') languageMenuTrigger: MatMenuTrigger;
  searchInput: any;
  alluserList: any = [];
  public emitTaskService: TaskEmitService;
  mentionListTemplate: any;
  onlineArr: any = [];
  subscription: Subscription;
  constructor(
    public injector: Injector,
    public translate: TranslateService,
    public utils: UtilsService,
    public taskService: TasksService,
    public userDetailService: UserDetailsService,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private store: Store<{ userState: UserState }>
  ) {
      translate.setDefaultLang('en');
      this.emitTaskService = injector.get<TaskEmitService>(TaskEmitService);
      this.subscription = this.taskService.getTotalCountMember.subscribe(
          (data: any) => {
              if (data) {
                  this.memberTotalCount = data;
              }
          }
      );
      this.userList();
  }
  async ngOnInit() {
      this.selectedCompanyId =
      await this.userDetailService.getSelectedCompanyId();
      await this.getTaskActivityLog('');
      this.store.select('userState').subscribe((ele: any) => {
          this.onlineArr = ele.updateState;
      });
  }
  async getTaskActivityLog(
      searchInp?: any,
      startDate?: number,
      endDate?: number
  ) {
      this.logActivityArr = [];
      (
          await this.taskService.getTaskActivityLog(
              this.taskId,
              this.selectedCompanyId,
              searchInp,
              startDate,
              endDate
          )
      ).subscribe(async (resData: any) => {
          this.logActivityArr = resData;
      });
  }

  async rangeTaskDueDate(selectedDate: Date) {
      const selection = this.selectionModel.selection,
          dateRange = this.selectionStrategy.selectionFinished(
              selectedDate,
              selection
          );

      this.selectionModel.updateSelection(dateRange, this);
      this.dateRange = new DateRange<Date>(dateRange.start, dateRange.end);
      if (this.selectionModel.isComplete()) {
          this.endDate = dateRange.end.setHours(23, 59, 59);
          this.startDate = dateRange.start.setHours(0, 0, 0, 0);
          await this.getTaskActivityLog(
              '',
              moment(this.startDate).valueOf(),
              moment(this.endDate).valueOf()
          );
      }
  }
  async onInputChange(event: any) {
      this.logActivityArr = [];
      this.searchInput = event.target.value.toLowerCase();
      this.searchInput = this.searchInput.replace('@', '');
      await this.getTaskActivityLog(this.searchInput);
  }

  userList() {
      this.subscription = this.emitTaskService.usersList$.subscribe(
          (data: any) => {
              data.forEach((element: any) => {
                  this.alluserList.push({
                      name: element.first_name + ' ' + element.last_name,
                      profile: element.profile_image,
                  });
              });
          }
      );
      this.subscription = this.emitTaskService.readByusersList$.subscribe(
          (data: any) => {
              data.forEach((element: any) => {
                  element['name'] = element.first_name + ' ' + element.last_name;
                  element['image'] = element.profile_image;
              });
              this.readUnreadData = data;
          }
      );
  }
  ngOnDestroy() {
      this.subscription?.unsubscribe();
  }
}
