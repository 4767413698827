import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    Router,
    UrlTree,
} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    canActivate(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree {
        const url: string = state.url;

        return this.checkLogin(url);
    }
    constructor(private router: Router) {}
    checkLogin(url: string): true | UrlTree {
        const val: string | null = localStorage.getItem('isUserLoggedIn');
        if (val != null && val == 'true') {
            if (url == '/') {
                setTimeout(() => {
                    this.router.parseUrl('/dashboard');
                }, 1500);
            } else return true;
        } else {
            return this.router.parseUrl('/');
        }
        return true;
    }
}
