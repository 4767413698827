import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userState',
})
export class UserStatePipe implements PipeTransform {
    transform(id: any, onlineArr: any): any {
        let activeState = '';
        if (onlineArr.length && id) {
            onlineArr?.forEach((ele: any) => {
                return ele?.memberIds?.includes(id) && ele.user_state
                    ? (activeState = ele.user_state)
                    : '';
            });
        }

        return activeState;
    }
}
