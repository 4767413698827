import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class SaveReportService {
    apiEndPoint = NAVIGATE_ROUTES;

    private editReportData: Subject<any> = new Subject<any>();
    editReportData$: Observable<any> = this.editReportData.asObservable();

    private reportFilterActivity: Subject<any> = new Subject<any>();
    reportFilterActivity$: Observable<any> =
        this.reportFilterActivity.asObservable();

    constructor(private apiService: ApiService) {}

    setEditReportData(data: any) {
        this.editReportData.next(data);
    }

    setActivityLabel(label: any) {
        this.reportFilterActivity.next(label);
    }

    async saveReport(data: any): Promise<Observable<any>> {
        let res: any = [];
        const url = `${this.apiEndPoint.SAVED_REPORT}`;
        await this.apiService.post(url, data).then((resData) => {
            res = resData;
        });
        return of(res);
    }

    async filterReport(): Promise<Observable<any>> {
        let res: any = [];
        const url = `${this.apiEndPoint.REPORT_CONFIG}`;
        await this.apiService.get(url).then((resData) => {
            res = resData;
        });
        return of(res);
    }

    async getSavedReport(data: any): Promise<Observable<any>> {
        let res: any = [];
        let url = `${this.apiEndPoint.SAVED_REPORT}?type=${data.type}`;
        if (data.project_id) {
            url += `&project_id=${data.project_id}`;
        }
        await this.apiService.get(url).then((resData) => {
            res = resData;
        });
        return of(res);
    }

    async deleteSavedReport(reportId: any): Promise<Observable<any>> {
        let res: any = [];
        const url = `${this.apiEndPoint.SAVED_REPORT}?report_id=${reportId}`;
        await this.apiService.delete(url).then((resData) => {
            res = resData;
        });
        return of(res);
    }
}
