import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Inject,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GetNewMemberService } from '../../services/organization/get-new-member.service';
import { TasksService } from '../../services/task-service/tasks.service';
import { UserDetailsService } from '../../services/user-details.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification/notification.service';
import { taskState } from '../../ngrx/reducer/task-state.reducer';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ProjectManagementService } from '../../services/project-service/project-management.service';
import { LocalStorageService } from '../../services/storage-service/local-storage.service';

@Component({
    selector: 'app-group-assignee-common-checkbox',
    templateUrl: './group-assignee-common-checkbox.component.html',
    styleUrls: ['./group-assignee-common-checkbox.component.scss'],
})
export class GroupAssigneeCommonCheckboxComponent implements OnInit, OnChanges {
    membersListData: any = [];
    searchText: '';
    groupListData: any = [];
    assigneesListName = 'Member';
    groupListName = 'Team';
    isDisableGroup = false;
    isDisableAssignee = false;
    selectedAssignees: any = [];
    selectedGroup: any = [];
    selectedCompanyId: any;
    originalMemberList: any = [];
    originalGroupList: any = [];
    universalGroupList: any = [];
    selectedMatPreview: any = [];
    listArrName = '';
    is_user_id = false;
    owner: any;
    isProjectId = false;
    universalMemberList: any = [];
    previewUnsubscribe: Subscription;
    assigneeUnsubscribe: Subscription;
    memberUnsubscribe: Subscription;
    taskState: any;
    @Output('onSelectCheckBox') onSelectCheckBox = new EventEmitter();
    @Output('getAssignee') getAssignee = new EventEmitter();
    @Input() selectedAssigneesFromBE: any;
    @Input() isSubTask = false;
    @Input() isDialog = false;
    @Input() use_user = false;
    @Input() hide_user: number;
    @Input() hide_userdata: any;
    @Input() project_id: number;
    @Input() coMembersList: any;
    @Input() assigneeSelected: any;
    subscription: Subscription;
    removeAssigneesFromFollower: any = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public getMemberList: GetNewMemberService,
        private taskService: TasksService,
        public userDetail: UserDetailsService,
        public translate: TranslateService,
        public dialogRef: MatDialogRef<GroupAssigneeCommonCheckboxComponent>,
        public notificationService: NotificationService,
        private store: Store<{ taskState: taskState }>,
        public router: Router,
        public projectManagementService: ProjectManagementService,
        public localStorage: LocalStorageService,
    ) {
        translate.setDefaultLang('en');
        this.store.select('taskState').subscribe((ele: any) => {
            this.taskState = ele?.taskState['reportConfig'];
        });
    }
    ngOnChanges() {
        if (
            this.selectedAssigneesFromBE?.mainTask ||
            this.selectedAssigneesFromBE?.change == true
        ) {
            this.updateSelectedData();
        } else if (!this.selectedAssigneesFromBE?.mainTask) {
            this.updateSelectedData();
        }
    }

    async ngOnInit() {
        if (this.data && this.data?.isDialog) {
            this.isDialog = this.data.isDialog;
            this.selectedAssigneesFromBE = this.data.selectedAssigneesFromBE;
            this.is_user_id = true;
            this.owner = this.data.owner;
        } else {
            this.is_user_id = this.use_user === true ? true : false;
        }
        await this.getCoMemberInCompany();
        this.selectedCompanyId = await this.userDetail.getSelectedCompanyId();
        await this.getGroupList();
        await this.getMemberListData();
        await this.removeAssignee();
        this.previewUnsubscribe = this.taskService.removeIdFromPreview.subscribe(
            async (data: any) => {
                if (
                    (data.listArrName === 'assignee' || data.listArrName === 'Team') &&
                    data &&
                    !data.isSubTask &&
                    !this.isSubTask
                ) {
                    this.removedIdFromMat(data);
                } else if (
                    (data.listArrName === 'assignee' || data.listArrName === 'Team') &&
                    data &&
                    data.isSubTask &&
                    this.isSubTask
                ) {
                    this.removedIdFromMat(data);
                }
            }
        );
        await this.getProjectMembers();
        if (this.selectedAssigneesFromBE) {
            await this.updateSelectedData();
        }
    }

    async getCoMemberInCompany() {
        if (this.taskState?.co_members) {
            const coMember: any = JSON.parse(
                JSON.stringify(this.taskState?.co_members)
            );
            const comembers: any = [];
            coMember?.forEach((element: any) => {
                let member_id;
                if (this.is_user_id) {
                    member_id = element.id;
                    element.id = element.user_id;
                }
                const dataobj: any = {
                    name: element.first_name + ' ' + element.last_name,
                    id: element.id,
                    profile_image: element.profile_image || '#f2793d',
                    isCheck: false,
                    user_id: element.user_id,
                    member_id: member_id,
                    first_name: element.first_name,
                    last_name: element.last_name,
                };
                if (this.hide_user != dataobj.id) {
                    comembers.push(dataobj);
                }
            });
            this.coMembersList = _.keyBy(
                comembers,
                this.is_user_id ? 'member_id' : 'id'
            );
        }
    }

    async removeAssignee() {
        if (!this.isSubTask) {
            this.subscription = this.taskService.removeAssignee.subscribe(
                (data: any) => {
                    this.removeAssigneesFromFollower = data;
                    this.membersListData = this.originalMemberList.filter((ele: any) => {
                        if (!data.includes(ele.id)) {
                            return ele;
                        }
                    });
                    setTimeout(() => {
                        this.taskService?.searchUpdate('');
                    }, 100);
                }
            );
        }
    }

    async removedIdFromMat(data: any) {
        if (this.selectedAssignees.length) {
            this.membersListData = await this.originalMemberList.filter(
                (ele: any) => {
                    if (data.removeId === ele.id) {
                        ele.isCheck = false;
                    }
                    return ele;
                }
            );
            this.selectedAssignees = await this.membersListData
                .filter((res: any) => {
                    if (res.isCheck) {
                        return res.id;
                    }
                })
                .map((ele: any) => ele.id);
            this.removeAssignee();
            this.onSelectAssigneeCheckBox(this.selectedAssignees);
        } else if (this.selectedGroup.length) {
            this.groupListData = this.originalGroupList.filter((ele: any) => {
                if (data.removeId === ele.id) {
                    ele.isCheck = false;
                }
                return ele;
            });
            this.selectedGroup = await this.groupListData
                .filter((res: any) => {
                    if (res.isCheck) {
                        return res.id;
                    }
                })
                .map((ele: any) => ele.id);
            this.onSelectGroupCheckBox(this.selectedGroup);
        }
        setTimeout(() => {
            this.taskService.searchUpdate('');
        }, 100);
    }

    async updateSelectedData() {
        if (
            this.selectedAssigneesFromBE?.assignees?.length > 0 ||
            (this.selectedAssigneesFromBE?.assignees?.length == 1 &&
                this.hide_user != this.selectedAssigneesFromBE?.assignees[0])
        ) {
            this.groupListData = this.originalGroupList.filter((ele: any) => {
                ele.isCheck = false;
                return ele;
            });
            this.membersListData = this.originalMemberList.filter((ele: any) => {
                ele.isCheck = false;
                return ele;
            });
            await this.isChecked(
                this.selectedAssigneesFromBE.assignees,
                this.membersListData
            );
            if (this.selectedAssigneesFromBE.mainTask) {
                await this.onSelectAssigneeCheckBox(this.selectedAssigneesFromBE?.assignees);
            }
            this.isDisableAssignee = false;
            this.isDisableGroup = true;
        } else if (this.selectedAssigneesFromBE?.groups?.length > 0) {
            this.groupListData = this.originalGroupList.filter((ele: any) => {
                ele.isCheck = false;
                return ele;
            });
            this.membersListData = this.originalMemberList.filter((ele: any) => {
                ele.isCheck = false;
                return ele;
            });
            await this.isChecked(
                this.selectedAssigneesFromBE?.groups,
                this.groupListData
            );
            if (this.selectedAssigneesFromBE?.mainTask) {
                await this.onSelectGroupCheckBox(this.selectedAssigneesFromBE?.groups);
            }
            this.isDisableGroup = false;
            this.isDisableAssignee = true;
        } else if (
            this.selectedAssigneesFromBE?.groups.length === 0 &&
            this.selectedAssigneesFromBE?.assignees.length === 0
        ) {
            this.membersListData = this.originalMemberList.filter((ele: any) => {
                if (ele.isCheck) {
                    ele.isCheck = false;
                }
                return ele;
            });
            this.groupListData = this.originalGroupList.filter((ele: any) => {
                if (ele.isCheck) {
                    ele.isCheck = false;
                }
                return ele;
            });
            this.isDisableGroup = false;
            this.isDisableAssignee = false;
            this.selectedAssignees = [];
            this.selectedGroup = [];
            this.setMatPreview();
        }
        await this.removeAssignee();
        setTimeout(() => {
            this.taskService.searchUpdate('');
        }, 100);
    }

    async isChecked(selectedDataArr: any, membersListData: any) {
        selectedDataArr.forEach(async (data: any) => {
            await membersListData.forEach((ele: any) => {
                if (ele.id === data) {
                    ele.isCheck = true;
                }
            });
        });
    }

    async getGroupList() {
        this.groupListData = [];
        const groupData: any = this.taskState?.group_details;
        await groupData?.forEach((ele: any) => {
            if (ele.is_deleted == false) {

                const data = {
                    name: ele?.group_name,
                    id: ele?.id,
                    profile_image: ele?.color_id,
                    isCheck: false,
                    members_details: ele?.members_details,
                };
                this.groupListData.push(data);
            }
        });
        this.originalGroupList = Object.assign([], this.groupListData);
        this.universalGroupList = Object.assign([], this.groupListData);
    }

    async getMemberListData() {
        const member = JSON.parse(JSON.stringify(this.taskState?.company_members));
        await member?.forEach((ele: any) => {
            let member_id;
            if (this.is_user_id) {
                member_id = ele.id;
                ele.id = ele.user_id;
            }
            const data = {
                name: ele.first_name + ' ' + ele.last_name,
                id: ele.id,
                profile_image: ele.profile_image,
                isCheck: false,
                member_id: member_id,
            };
            if (this.hide_user != data.id) {
                this.membersListData.push(data);
            }
        });
        if (this.owner) {
            this.membersListData = this.membersListData.filter(
                (ele: any) => ele.id !== this.owner.user_id
            );
        }
        this.originalMemberList = Object.assign([], this.membersListData);
        this.universalMemberList = Object.assign([], this.membersListData);
        this.taskService.membersCount = this.membersListData?.length;
        const project_selected: any = await this.localStorage.getDataFromIndexedDB('selectedProject');
    }

    async onSelectAssigneeCheckBox(selectedAssignees: any) {
        this.selectedAssignees = selectedAssignees;
        const oldSelected = this.originalMemberList
            .filter(
                (data: any) =>
                    data.isCheck && !this.removeAssigneesFromFollower.includes(data.id)
            )
            .map((ele: any) => ele.id);
        this.selectedGroup = [];
        this.listArrName = 'assignee';
        this.selectedAssignees.push(...new Set(oldSelected));
        this.selectedAssignees = this.removeDuplicates(this.selectedAssignees);
        if (this.selectedAssignees.length > 0) {
            if (
                this.selectedAssignees.length == 1 &&
                this.hide_user == this.selectedAssignees[0]
            ) {
                this.isDisableGroup = false;
            } else {
                this.isDisableGroup = true;
            }
        } else {
            this.isDisableGroup = false;
        }

        this.setMatPreview();
        this.onSelectCheckBox.emit({
            assignees: this.selectedAssignees,
            groups: [],
            selectedMatPreview: this.selectedMatPreview,
        });
        this.getAssignee.emit(this.selectedAssignees);
    }

    async onSelectGroupCheckBox(selectedGroup: any) {
        this.selectedGroup = selectedGroup;
        const oldSelected = this.originalGroupList
            .filter((data: any) => data.isCheck)
            .map((ele: any) => ele.id);
        this.selectedAssignees = [];
        this.listArrName = 'Team';
        this.selectedGroup.push(...oldSelected);
        this.selectedGroup = this.removeDuplicates(this.selectedGroup);
        if (this.selectedGroup.length > 0) {
            this.isDisableAssignee = true;
        } else {
            this.isDisableAssignee = false;
        }
        this.setMatPreview();
        this.onSelectCheckBox.emit({
            assignees: [],
            groups: this.selectedGroup,
            selectedMatPreview: this.selectedMatPreview,
        });
    }

    async getUpdateList(searchText: string) {
        searchText = searchText.toLowerCase();
        await this.updateMemberList(searchText);
        await this.updateGroupList(searchText);
        setTimeout(() => {
            this.taskService.searchUpdate(searchText);
        }, 100);
    }

    async updateMemberList(searchText: string) {
        this.membersListData = await this.originalMemberList.filter((ele: any) => {
            const name = ele.name.toLowerCase();
            return (
                name.includes(searchText) &&
                !this.removeAssigneesFromFollower.includes(ele.id)
            );
        });
        return this.membersListData;
    }

    async updateGroupList(searchText: string) {
        this.groupListData = await this.originalGroupList.filter((ele: any) => {
            const name = ele.name.toLowerCase();
            return name.includes(searchText);
        });
        return this.groupListData;
    }

    setMatPreview() {
        if (this.selectedAssignees.length > 0) {
            this.selectedMatPreview = this.originalMemberList.filter((ele: any) => {
                if (this.selectedAssignees.includes(ele.id)) {
                    return ele;
                }
            });
            return;
        } else if (this.selectedGroup.length > 0) {
            this.selectedMatPreview = this.originalGroupList.filter((ele: any) => {
                if (this.selectedGroup.includes(ele.id)) {
                    return ele;
                }
            });
            return;
        } else {
            this.selectedMatPreview = [];
        }
    }

    saveAssign() {
        const data = {
            assignees: this.selectedAssignees,
            groups: this.selectedGroup,
        };
        this.dialogRef.close(data);
        this.notificationService.setNotification(true);
    }
    cancel() {
        this.dialogRef.close();
    }

    removeDuplicates(arr: any) {
        return arr.filter((item: any, index: any) => arr.indexOf(item) === index);
    }

    async getProjectMembers() {
        this.memberUnsubscribe = this.taskService.projectMembersIds.subscribe(
            (data: any) => {
                data?.projectMembers?.length > 0
                    ? (this.isProjectId = true)
                    : (this.isProjectId = false);
                if (data?.projectMembers?.length > 0) {
                    this.universalMemberList.forEach((member: any) => {
                        member.isCheck = false;
                    });
                    this.universalGroupList.forEach((group: any) => {
                        group.isCheck = false;
                    });
                    this.selectedMatPreview = [];
                    this.selectedAssignees = [];
                    this.selectedGroup = [];
                    this.taskService.searchUpdate('');
                    if (this.selectedAssigneesFromBE?.mainTask) {
                        this.onSelectCheckBox.emit({
                            assignees: this.selectedAssignees,
                            groups: this.selectedGroup,
                            selectedMatPreview: this.selectedMatPreview,
                        });
                    }
                    this.isDisableAssignee = false;
                    this.isDisableGroup = false;
                    this.membersListData = this.is_user_id
                        ? this.universalMemberList.filter((ele: any) =>
                            data?.projectMembers?.includes(ele.member_id)
                        )
                        : this.universalMemberList.filter((ele: any) =>
                            data?.projectMembers?.includes(ele.id)
                        );
                    this.membersListData = [...this.membersListData];
                    const coMemberArr: any = Object.assign({}, this.coMembersList);
                    data?.projectMembers?.forEach((id: any) => {
                        if (coMemberArr[id]) {
                            this.membersListData.push(coMemberArr[id]);
                        }
                    });
                    this.originalMemberList = this.membersListData;
                    this.groupListData = this.universalGroupList.filter((ele: any) => data?.projectGroup?.includes(ele.id));
                    this.originalGroupList = this.groupListData;
                } else {
                    this.universalGroupList.forEach((member: any) => {
                        member.isCheck = false;
                    });
                    this.groupListData = Object.assign([], this.universalGroupList);
                    this.originalGroupList = Object.assign([], this.universalGroupList);
                    this.universalMemberList.forEach((member: any) => {
                        member.isCheck = false;
                    });
                    this.selectedMatPreview = [];
                    this.selectedAssignees = [];
                    this.selectedGroup = [];
                    this.taskService.searchUpdate('');
                    this.membersListData = Object.assign([], this.universalMemberList);
                    this.originalMemberList = Object.assign([], this.universalMemberList);
                    this.isDisableAssignee = false;
                    this.isDisableGroup = false;
                    setTimeout(() => {
                        this.taskService.searchUpdate('');
                    }, 100);
                }
            }
        );
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.previewUnsubscribe?.unsubscribe();
        this.memberUnsubscribe?.unsubscribe();
        this.subscription?.unsubscribe();
    }
    checkAssignee(event: any) {
        this.onSelectAssigneeCheckBox(event);
        event.forEach((targetId: any) => {
            const foundObject = this.membersListData.find(
                (obj: any) => obj.id === targetId
            );
            if (foundObject) {
                foundObject.isCheck = true;
            }
        });
    }
    unAssign(event: any) {
        this.removedIdFromMat({
            isSubTask: this.isSubTask,
            listArrName: 'assignee',
            removeId: event,
        });
    }
}
