import { ApiService } from 'src/app/shared/services/api-service/api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils-service/utils.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserDetailsService } from '../../services/user-details.service';

@Component({
    selector: 'app-guide-model',
    templateUrl: './guide-model.component.html',
    styleUrls: ['./guide-model.component.scss'],
})
export class GuideModelComponent implements OnInit {
    currentCompany: any;
    haveActivePlan = false;
    user: any;

    constructor(
    private router: Router,
    public utils: UtilsService,
    public httpRequest: ApiService,
    private dialogRef: MatDialogRef<GuideModelComponent>,
    public translate: TranslateService,
    public userDetail: UserDetailsService
    ) {
        this.dialogRef.disableClose = true;
    }

    async ngOnInit() {
        this.user = await this.userDetail.getUserName();
    }

    public discardClicked(_data: any) {
        this.router.navigate(['/organization']);
        this.dialogRef.close();
    }
}
