import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Optional,
    Inject,
    Injector,
    Output,
    EventEmitter,
    AfterViewInit,
    ViewChildren,
    QueryList,
} from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormControl,
    FormArray,
    AbstractControl,
} from '@angular/forms';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import { CountryISO } from 'ngx-intl-tel-input';
import { DateAdapter } from '@angular/material/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ClientServiceService } from 'src/app/shared/services/organization/client-service.service';
import { AlertService } from 'src/app/shared/services/alert-service/alert.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ServiceManagementService } from 'src/app/shared/services/organization/service-management.service';
import { ManagementServiceService } from 'src/app/shared/services/management-service/management.service';
import { COLOR_CODE, MODULE_NAME } from 'src/app/shared/global/constants';
import { RegexEnum } from 'src/app/shared/global/regex-enum';
import { MAT_SELECT_CONFIG, MatSelect } from '@angular/material/select';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CustomFieldService } from 'src/app/shared/services/organization-settings/custom-field.service';
import { CustomFieldTaskClientService } from 'src/app/shared/services/custom-field-client-task/custom-field-task-client.service';
import { DmsService } from 'src/app/shared/services/dms-service/dms.service';
import { ApiService } from 'src/app/shared/services/api-service/api.service';
import * as _ from 'lodash';
import { DocumentPreviewComponent } from 'src/app/shared/components/document-preview/document-preview.component';
export interface Fruit {
  name: string;
}
@Component({
    selector: 'app-add-client',
    templateUrl: './add-client.component.html',
    styleUrls: ['./add-client.component.scss'],
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'custom-overlay-panel' },
        },
    ],
})
export class AddClientComponent implements OnInit, AfterViewInit {
    _viewPreviewComponent: MatDialogRef<DocumentPreviewComponent>;
  @ViewChild('matSelect') matSelect: MatSelect;
  index: number;
  step = 0;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  fruits: Fruit[] = [];
  clientDetailForm!: FormGroup;
  customDetailForm!: FormGroup;
  memberArr: [] = [];
  selectedToppings: any = [];
  serviceArr: any;
  dataImage: any;
  epochTime: any;
  allSelected: any = [];
  toHighlight = '';
  clientImage: any;
  isShowAddMultile = true;

  attachments: any = [];
  attachment_details: any = [];

  @ViewChild('searchMember') searchTextMemberBox: ElementRef;
  selectFormControl = new FormControl();
  searchTextBoxControl = new FormControl();
  selectMemberFormControl = new FormControl();
  searchTextMemberBoxControl = new FormControl();
  selectedValues: any = [];
  selectedMember: any = [];
  data: string[] = ['Developer', 'Constructor', 'Farmer', 'Caller'];
  member: any = [];
  memberCopy: any = [];
  total_servicesMember: any = [];
  emails: any;
  encryptCompanyId: any;
  filteredOptions: Observable<any[]>;
  filteredMember: Observable<any[]>;
  @ViewChild('clientImg') public clientImg!: ElementRef;
  fileAttr: any;
  filteredData: Observable<any[]>;
  service_to = new FormControl();
  companyId: any;
  userId: any;
  public headers: any[] = [];
  serviceList: any[] = [];
  multidropdownSettings: IDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      clearSearchFilter: true,
      itemsShowLimit: 5,
  };
  multidropdownSettingsService: IDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      clearSearchFilter: true,
      itemsShowLimit: 5,
  };
  selectedMembers: any = [];
  originalList: any;
  randomColor: any = [];
  filteredProviders: any = [];
  selectedService: any = [];
  unSubscribe: any;
  public customForm: FormGroup;
  @Output() onSubmit = new EventEmitter();
  is_client_active = false;

  public fields: any = [];
  moduleId: string;
  moduleName: string;
  isCustomize = 0;
  selectedCompanyId: any;
  public getServiceListData: ServiceManagementService;
  public clientCustomList: CustomFieldTaskClientService;
  tArray: any = [];
  is_active = false;
  countries: any = [];
  preferredCountriesForCustomField: any = CountryISO;
  selectedCountry: any = [];
  showCollaboration = false;
  fieldsCtrl: any = {};
  finalCode: any;
  attachmentData: any;
  searchText: any;
  isAllCheck = false;
  @ViewChild('myFileInput') myFileInput: any;
  private clientService: ClientServiceService
  private userDetailService: UserDetailsService
  public managementService: ManagementServiceService
  private customFieldService: CustomFieldService
  private apiService: ApiService
  private dmsService: DmsService
  isVisible: boolean = false;
  @ViewChildren('multiUserSearch') multiUserSearchInputs: QueryList<ElementRef>;

  constructor(
    public dialogRef: MatDialogRef<AddClientComponent>,
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    public utils: UtilsService,
    private injector: Injector,
    private translate: TranslateService,
    public alertService: AlertService,
    private toastrService: ToastrService,
    private matDialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public isEdit: any
  ) {
      dialogRef.disableClose = true;
      translate.setDefaultLang('en');
      this.dateAdapter.setLocale('en-GB');
      this.getServiceListData = injector.get<ServiceManagementService>(ServiceManagementService);
      this.clientCustomList = injector.get<CustomFieldTaskClientService>(CustomFieldTaskClientService);
      this.clientService = injector.get<ClientServiceService >(ClientServiceService);
      this.userDetailService = injector.get<UserDetailsService >(UserDetailsService);
      this.managementService = injector.get<ManagementServiceService >(ManagementServiceService);
      this.customFieldService = injector.get<CustomFieldService >(CustomFieldService);
      this.apiService = injector.get<ApiService >(ApiService);
      this.dmsService = injector.get<DmsService >(DmsService);
  }

  ngAfterViewInit(): void {
      const scrollContainer = document.querySelector(
          '.custom-attachment-list .mat-chip-list-wrapper'
      );

      scrollContainer.addEventListener('wheel', (evt: any) => {
          evt.preventDefault();
          scrollContainer.scrollLeft += evt.deltaY;
      });
  }

  removeInput(index: any) {
      this.headers.push(index);
      this.service_to = new FormControl();
      this.selectMemberFormControl = new FormControl();
  }

  async ngOnInit() {
      this.clientFormInit();
      COLOR_CODE.forEach((element) => {
          this.randomColor.push(element.value);
      });
      this.headers = [{ name: 'Accept-Encoding', value: 'gzip' }];
      this.userId = await this.userDetailService.encryptUserId;
      this.companyId = await this.userDetailService.getCompanyId();
      this.encryptCompanyId = this.companyId;
      this.companyId = parseInt(this.companyId);
      this.selectedCompanyId =
      await this.userDetailService.getSelectedCompanyId();
      await this.getManageFieldDetail(this.encryptCompanyId);
      await this.getModuleData();
      if (this.is_client_active && this.is_active) {
          await this.getCustomField();
          this.customForm = new FormGroup({
              fields: new FormControl(JSON.stringify(this.fields)),
          });
          this.unSubscribe = this.customForm.valueChanges.subscribe(
              (update: any) => {
                  this.fields = JSON.parse(update.fields);
              }
          );
      }
      await this.getMemberList();
      await this.getServiceList();
      if (this.isEdit?.editClient) {
          await this.getClientDetails();
      }
      this.filteredData = this.service_to.valueChanges.pipe(
          startWith(''),
          map((element) =>
              element ? this.filterCountry(element) : this.serviceList.slice()
          )
      );
  }

  async getServiceList() {
      (
          await this.getServiceListData.getAllServiceList(
              this.encryptCompanyId
          )
      ).subscribe((res: any) => {
          this.serviceList = [];
          this.originalList = [];
          this.emails.clear();
          res?.forEach((service: any) => {
              if (service?.is_active && service?.is_recurring) {
                  const data = {
                      name: service.service_name,
                      id: service.id,
                  };
                  this.addMultipleService();
                  this.serviceList.push(data);
                  this.originalList.push(data)
              }
          });
      });
  }

  async getMemberList() {
      (
          await this.clientService.getMemberListOfService(this.encryptCompanyId)
      ).subscribe((res: any) => {
          this.member = [];
          this.member = res.map((ele: any) => {
              return {
                  name: ele.first_name + ' ' + ele.last_name,
                  id: ele.id,
                  profile_image: ele.profile_image,
              };
          });
      });
  }

  clientFormInit() {
      this.clientDetailForm = this.formBuilder.group({
          client_name: ['', [Validators.required]],
          // [Validators.required, Validators.pattern("[a-zA-Z ]*")],
          clientEmail: [
              '',
              [Validators.email, Validators.pattern(RegexEnum.email)],
          ],
          clientMobile: [''],
          clientImg: [''],
          mobile_isd_code: [''],
          gender: [''],
          companyName: ['', [Validators.required]],
          companyAddress: [''],
          subTask: this.formBuilder.array([]),
      });
      this.emails = this.clientDetailForm.controls['subTask'] as FormArray;
  }

  addMultipleService() {
      this.toHighlight = '';
      this.emails.push(
          this.formBuilder.group({
              member: [{ value: [], disabled: true }],
              service: [''],
          })
      );
      this.member.forEach((element: any) => {
          element['selected'] = false;
      });
      this.total_servicesMember.push(this.member);
      this.filteredProviders = Object.assign([], this.total_servicesMember);
      this.isShowAddMultile = this.serviceList?.length !== this.emails?.value?.length;
  }

  async deleteRow(index: number) {
      if (index !== -1) {
          this.emails.removeAt(index);
      }
      this.clientDetailForm.markAsDirty();
      this.isShowAddMultile = this.serviceList?.length !== this.emails?.value?.length;
      this.selectedMembers[index] = [];
      this.allSelected[index] = false;
      this.toHighlight = '';
  }

  checkMobile(_e: any) {
      if (this.clientDetailForm.get('clientMobile').value != '') {
          this.clientDetailForm
              .get('clientMobile')
              .setErrors({ validatePhoneNumber: true });
      } else {
          this.clientDetailForm
              .get('clientMobile')
              .setErrors({ validatePhoneNumber: null });
          this.clientDetailForm.get('clientMobile').setErrors(null);
      }
  }

  getPhonesFormControls(): AbstractControl[] {
      return (this.clientDetailForm.get('subTask') as FormArray).controls;
  }

  get subTask() {
      return this.clientDetailForm.get('subTask') as FormArray;
  }

  closeDialog() {
      this.dialogRef.close();
  }

  async setFormData(resData: any) {
      this.emails = [];
      this.clientDetailForm.controls['client_name'].setValue(resData.client_name);
      this.clientDetailForm.controls['clientEmail'].setValue(resData.email);
      if (resData.mobile_number == '0' || resData.mobile_number == 0) {
          this.clientDetailForm.controls['mobile_isd_code'].setValue(
              resData.isd_code
          );
      }
      if (
          (resData.mobile_number != null && resData.mobile_number !== '0') ||
          (resData.mobile_number != null && resData.mobile_number !== 0)
      ) {
          this.clientDetailForm.controls['clientMobile'].patchValue(resData.mobile_number ? ('+' + resData?.isd_code + resData.mobile_number) : '');
      }
      this.clientDetailForm.controls['gender'].setValue(resData.gender);
      this.clientDetailForm.controls['companyName'].setValue(
          resData.company_name
      );
      this.clientDetailForm.controls['companyAddress'].setValue(
          resData.company_address
      );
      this.emails = this.clientDetailForm.controls['subTask'] as FormArray;
      this.dataImage = resData.profile_image;
      this.filteredProviders = Object.assign([], this.total_servicesMember);
      if (resData.client_service_relations) {
          this.isAllCheck =
        resData.client_service_relations.length === this.serviceList.length;
          await resData.client_service_relations.forEach(
              async (x: any, index: any) => {
                  const selectedMembers: any = [];
                  const selectedService: any = [];
                  this.member.forEach(async (y: any) => {
                      x.member_details.forEach((z: any) => {
                          if (z.id == y.id) {
                              selectedMembers.push(y.id);
                          }
                      });
                  });
                  let setIndex:any = this.serviceList.findIndex((ttt:any)  => ttt.id == x.service_details.id)
                  await this.originalList.forEach(async (y: any) => {
                          selectedService.push(y);
                          this.setReporting({ checked: true }, x.service_details.id, setIndex);
                  });
                  this.changeSelection(
                      x.member_details.map((d: any) => d.id),
                      setIndex,
                      null
                  );
                  this.memberCopy = this.member;
              }
          );
      }

      for (let j = 0; j <= resData.client_service_relations?.length; j++) {
          const newMember = JSON.parse(JSON.stringify(this.member));
          for (const element of newMember) {
              if (
                  this.isArrayInclude(
                      resData?.client_service_relations[j]?.member_details,
                      element.id
                  )
              ) {
                  element['selected'] = true;
              } else {
                  element['selected'] = false;
              }
          }
          this.total_servicesMember.push(newMember);
          this.filteredProviders = Object.assign([], this.total_servicesMember);
          this.checkForSelectAll(j);
      }

      this.serviceList.length === this.emails.value.length
          ? (this.isShowAddMultile = false)
          : (this.isShowAddMultile = true);
          
  }

  isArrayInclude(arr: any, id: any) {
      return arr?.map((data: any) => data.id).includes(id);
  }

  async submit() {
      this.apiService.showLoading();
      const formData = new FormData();
      if (this.is_client_active && this.is_active) {
          if (!this.customForm.valid) {
              this.toastrService.error(
                  this.translate.instant('ALL_CUSTOM_FIELD_REQUIRED')
              );
              this.apiService.hideLoading();
              return;
          }
          await this.manageCustomFormData(this.customForm.value);
          formData.append('custom_fields_values', JSON.stringify(this.tArray));
      }
      const data = this.clientDetailForm.value;
      this.epochTime = new Date().getTime();
      let serviceID: any = [];
      const selectedmembers: any = [];
      const selectedservice: any = [];
      const serviceDetails: any = [];
      await data?.subTask?.forEach((ele: any) => {
          const memberID: any = [];
          ele?.member?.forEach((x: any) => {
              memberID.push(x);
          });
          selectedmembers.push(memberID);
      });

      serviceID = [];
      await data.subTask.forEach((ele: any) => {
          serviceID = ele.service.id;
          selectedservice.push([serviceID]);
      });

      for (let index = 0; index < selectedmembers.length; index++) {
          if (selectedservice[index][0]) {
              serviceDetails.push({
                  selectedservice: selectedservice[index],
                  selectedmembers: selectedmembers[index],
              });
          }
      }
      data.companyName = data.companyName.trim();
      data.companyAddress = data.companyAddress.trim();
      data.client_name = data.client_name.trim();
      formData.append('client_name', data.client_name.replace(/\s\s+/g, ' '));
      formData.append('email', data.clientEmail ? data.clientEmail : '');
      formData.append(
          'mobile_number',
          data.clientMobile?.slice(3) ? data.clientMobile?.slice(3) : ''
      );
      formData.append('isd_code', data.mobile_isd_code);
      formData.append('gender', data.gender);
      formData.append('company_name', data.companyName.replace(/\s\s+/g, ' '));
      formData.append(
          'company_id',
          this.isEdit?.isOpenFromTaskModule
              ? this.isEdit.companyId
              : this.encryptCompanyId
      );
      formData.append(
          'company_address',
          data.companyAddress.replace(/\s\s+/g, ' ')
              ? data.companyAddress?.replace(/\s\s+/g, ' ')
              : ''
      );

      if (this.attachments.length > 0) {
          this.attachments.forEach((file: any) => {
              formData.append('attachments', file.file);
          });
      }

      if (serviceDetails) {
          formData.append('service_details', JSON.stringify(serviceDetails));
      }
      formData.append('user_id', this.userId);
      formData.append('created_at', this.epochTime);
      if (this.clientImage) {
          formData.append('image', this.clientImage);
      } else {
          const i = Math.floor(Math.random() * this.randomColor.length);   // NOSONAR
          const random_color = this.randomColor[i];
          formData.append('image', random_color);
      }
      (await this.clientService.addNewClient(formData)).subscribe((res: any) => {
          if (res[0]?.msg) {
              this.alertService.showSuccess('Client added successfully');
          }
          this.dialogRef.close({ event: 'close', data: { ...data } });
          this.apiService.hideLoading();
      });
  }

  async editClientDetails(val: any) {
      this.apiService.showLoading();
      this.epochTime = new Date().getTime();
      let serviceID: any = [];
      let memberID: any = [];
      const selectedmembers: any = [];
      const selectedservice: any = [];
      const serviceDetails: any = [];
      const formData = new FormData();
      if (this.is_client_active && this.is_active) {
          if (!this.customForm.valid) {
              this.toastrService.error(
                  this.translate.instant('ALL_CUSTOM_FIELD_REQUIRED')
              );
              this.apiService.hideLoading();
              return;
          }
          await this.manageCustomFormData(this.customForm.value);
          formData.append('custom_fields_values', JSON.stringify(this.tArray));
      }
      await val?.subTask?.forEach((ele: any) => {
          memberID = [];
          ele?.member?.forEach((x: any) => {
              memberID.push(x);
          });
          selectedmembers.push(memberID);
      });

      serviceID = [];
      await val.subTask.forEach((ele: any) => {
          serviceID = ele.service.id;
          selectedservice.push([serviceID]);
      });

      for (let index = 0; index < selectedmembers.length; index++) {
          if (selectedservice[index][0]) {
              serviceDetails.push({
                  selectedservice: selectedservice[index],
                  selectedmembers: selectedmembers[index],
              });
          }
      }
      val.companyName = val.companyName.trim();
      val.companyAddress = val.companyAddress?.trim();
      val.client_name = val.client_name.trim();
      formData.append('client_name', val.client_name.replace(/\s\s+/g, ' '));
      formData.append('email', val.clientEmail ? val.clientEmail : '');
      formData.append(
          'mobile_number',
          val?.clientMobile?.slice(3) ? val?.clientMobile?.slice(3) : ''
      );
      formData.append('isd_code', val.mobile_isd_code);
      formData.append('gender', val.gender ? val.gender : '');
      formData.append('company_name', val.companyName.replace(/\s\s+/g, ' '));
      formData.append('company_id', this.encryptCompanyId);

      if (this.attachments.length > 0) {
          this.attachments.forEach((file: any) => {
              formData.append('attachments', file.file);
          });
      }

      formData.append(
          'company_address',
          val.companyAddress?.replace(/\s\s+/g, ' ')
              ? val.companyAddress?.replace(/\s\s+/g, ' ')
              : ''
      );
      if (serviceDetails) {
          formData.append('service_details', JSON.stringify(serviceDetails));
      }
      formData.append('user_id', this.userId);
      formData.append('updated_at', this.epochTime);
      if (this.clientImage) {
          formData.append('image', this.clientImage);
      }

      (await this.clientService.editClientDetails(formData)).subscribe(
          (res: any) => {
              if (res.msg) {
                  this.toastrService.success(res.msg);
              }
          }
      );
      this.dialogRef.close();
      this.apiService.hideLoading();
  }

  changeCountryCode(e: any) {
      this.clientDetailForm.get('mobile_isd_code').setValue(e.dialCode);
  }

  async uploadFileEvt(imgFile: any) {
      const image = await this.managementService.updateProfilePic(imgFile);
      if (imgFile?.target?.files[0]) {
          this.fileAttr = '';
          Array.from(imgFile.target.files).forEach((file: any) => {
              this.fileAttr += file.name + ' - ';
          });

          // HTML5 FileReader API
          if (image.file) {
              const reader = new FileReader();
              reader.onload = (e: any) => {
                  const _image = new Image();
                  _image.src = e.target.result;
                  _image.onload = (_rs: any) => {
                      const imgBase64Path = e.target.result;
                      this.dataImage = imgBase64Path;
                      this.clientImage = image?.file;
                  };
              };
              reader.readAsDataURL(imgFile.target.files[0]);
          }
      } else {
          this.dataImage = '';
          this.fileAttr = 'Choose File';
      }
  }

  filterCountry(name: string) {
      const arr = this.serviceList.filter(
          (country) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0
      );
      return arr.length ? arr : [{ name: 'No Member found' }];
  }

  openService(_index: any) {
      const selectedService: any[] = [];
      this.selectedToppings = [];
      if (this.clientDetailForm.controls['subTask'].value) {
          const arr = this.clientDetailForm.controls['subTask'].value;
          arr.forEach((ele: any) => {
              if (ele?.service?.id) {
                  selectedService.push(ele?.service?.id);
              }
          });
          this.serviceList = this.serviceList.filter((ele: any) => {
              if (!selectedService.includes(ele.id)) {
                  return ele;
              }
          });
      }
  }

  setStep(index: number) {
      this.step = index;
  }

  changeSelection(event: any, j: number, _data: any) {
      const searchId = this.filteredProviders[j].map((item: any) => {
          return item.id;
      });
      const newMember = JSON.parse(JSON.stringify(this.total_servicesMember));
      for (let i = 0; i < newMember.length; i++) {
          if (i === j) {
              for (const member of newMember[i]) {
                if (event.includes(member.id)) {
                    member['selected'] = true;
                } else {
                    member['selected'] = !searchId.includes(member.id);
                }
              }
          }
      }
      this.total_servicesMember = newMember;
      const selectedData = this.total_servicesMember[j].filter(
          (member: any) => member.selected === true
      );
      this.selectedMembers[j] = Object.assign([], selectedData);
      this.filteredProviders = Object.assign([], this.total_servicesMember);
      const myForm = (<FormArray>this.clientDetailForm.get('subTask')).at(j);
      myForm.patchValue({
          member: this.selectedMembers[j]?.map((member: any) => member.id),
      });
      this.clientDetailForm.markAsDirty();
      this.checkForSelectAll(j);
  }

  async selectClientMember(event:any,form_index:number,data:any){
    const newMember = JSON.parse(JSON.stringify(this.total_servicesMember));
    newMember?.forEach((ele:any,index:number)=>{
        if(form_index === index){
            ele?.forEach((member:any)=>{
                if(member.id===data.id){
                    if(event.checked){
                        member.selected = true;
                    }else{
                        member.selected = false;
                    }
                }
            })
        }
    });
    this.total_servicesMember = newMember;
    
    const selectedData = this.total_servicesMember[form_index]?.filter(
        (member: any) => member.selected === true
    );
    this.selectedMembers[form_index] = Object.assign([], selectedData);

    this.filteredProviders = Object.assign([], this.total_servicesMember);
    const myForm = (<FormArray>this.clientDetailForm.get('subTask')).at(form_index);
    myForm.patchValue({
        member: this.selectedMembers[form_index]?.map((member: any) => member.id),
    });

    this.clientDetailForm.markAsDirty();
    this.checkForSelectAll(form_index)
    
    await this.onInputChange({target:{value:this.toHighlight}}, form_index);
  }
  
  getServiceInfo(index:number){
    const myForm = (<FormArray>this.clientDetailForm.get('subTask')).at(index);
    return myForm.get('service').value ? true : false;
  } 


  checkForSelectAll(index: number) {
    setTimeout(() => {
        this.allSelected[index] = this.filteredProviders[index]?.every((t: any) => t.selected);
    }, 100);
  }

  resetInput(index: number): void {
    const inputElement = this.multiUserSearchInputs.toArray()[index].nativeElement;
    inputElement.value = '';
  }

  async selectAll(i: number) {
    const selectedMembers:any[] = this.filteredProviders[i]?.map((user:any)=> user.id);
        const newMember = JSON.parse(JSON.stringify(this.total_servicesMember));
        newMember?.forEach((ele:any,index:number)=>{
            if(i === index){
                ele?.forEach((member:any)=>{
                    if(selectedMembers.includes(member.id)){
                        member.selected = this.allSelected[i];
                    }
                });
            }
        });

        this.total_servicesMember = newMember;
        this.filteredProviders = Object.assign([], this.total_servicesMember);
        const selectedData = this.total_servicesMember[i].filter(
            (member: any) => member.selected === true
        );
        this.selectedMembers[i] = Object.assign([], selectedData);
        const myForm = (<FormArray>this.clientDetailForm.get('subTask')).at(i);
        myForm.patchValue({
            member: this.selectedMembers[i]?.map((member: any) => member.id),
        });
        await this.onInputChange({target:{value:this.toHighlight}}, i);
        await this.checkForSelectAll(i)
  }

  onInputChange(event: any, i: number) {
      const searchInput = event.target.value.toLowerCase();
      this.toHighlight = searchInput;
      this.filteredProviders[i] = this.total_servicesMember[i].filter(
          (element: any) => element.name.toLowerCase().trim().includes(searchInput)
      );
      if (event.data == '') {
          this.allSelected[i] = [];
      }
      const selectedData = this.total_servicesMember[i].filter(
          (member: any) => member.selected === true
      );
      this.selectedMembers[i] = Object.assign([], selectedData);
      this.clientDetailForm.value.subTask[i].member = this.selectedMembers[
          i
      ]?.map((member: any) => member.id);
      this.checkForSelectAll(i)
      return this.clientDetailForm.value.subTask[i].member;
  }

  onToppingRemoved(topping: any, i: number) {
      const toppings = this.clientDetailForm.value.subTask[i].member as string[];
      this.removeFirst(toppings, topping, i);
      this.total_servicesMember[i].forEach((element: any) => {
          if (element.id == topping) {
              element['selected'] = false;
          }
      });
      const myForm = (<FormArray>this.clientDetailForm.get('subTask')).at(i);
      myForm.patchValue({ member: toppings });
      this.clientDetailForm.markAsDirty();
      this.checkForSelectAll(i);
  }

  private removeFirst<T>(array: T[], toRemove: T, index1: T): void {
      const index = array.indexOf(toRemove);
      if (index !== -1) {
          array.splice(index, 1);
          this.selectedMembers[index1].splice(index, 1);
      }
  }

  get employees(): FormArray {
      return this.clientDetailForm.get('subTask') as FormArray;
  }

  highlight(val: any) {
      const searchInput = val?.toLowerCase();
      this.toHighlight = searchInput;
      return this.serviceList.filter(({ name }) => {
          const prov = name.toLowerCase().trim();
          return prov.includes(searchInput);
      });
  }

  setReporting(event: any, val: any, i: number) {
      this.toHighlight = '';
      const myForm = (<FormArray>this.clientDetailForm.get('subTask')).at(i);
      const name = this.serviceList.find((member: any) => member.id == val).name;
      const id = this.serviceList.find((member: any) => member.id == val).id;

      if (event?.checked) {
          myForm.get('member').enable();
          myForm.get('member').setValidators(Validators.required);
          myForm.get('service').setValue({ name: name, id: id });
      } else {
          myForm.get('member').disable();
          myForm.get('member').setValidators(null);
          myForm.get('service').setValue('');
      }
      this.isAllCheck = this.clientDetailForm.value.subTask.every(
          (service: any) => service.service != ''
      );
      myForm.get('member').setValue([]);
      myForm.get('member').markAsDirty();
      myForm.get('service').markAsDirty();
      myForm.get('member').updateValueAndValidity();
  }

  displayFn(user?: any): string | undefined {
      return user ? user.name : undefined;
  }

  getFields() {
      this.fields.sort(
          (a: { order: number }, b: { order: number }) => a.order - b.order
      );
      return this.fields;
  }

  ngDistroy() {
      this.unSubscribe();
  }

  async getManageFieldDetail(companyId: any) {
      (await this.customFieldService.getManageFieldDetail(companyId)).subscribe(
          async (resData: any) => {
              this.is_client_active = resData?.result?.is_client_active;
              this.is_active = resData.result?.is_active;
              if (!this.is_client_active) {
                  this.showCollaboration = true;
              }
          }
      );
  }

  async getModuleData() {
      (await this.clientCustomList.getModuleDetail()).subscribe(
          async (res: any) => {
              if (res) {
                  this.moduleId = res.result.find(
                      (moduleData: any) => moduleData.module_name === MODULE_NAME.CLIENT
                  ).id;
                  this.moduleName = res.result.find(
                      (moduleData: any) => moduleData.module_name === MODULE_NAME.CLIENT
                  ).module_name;
              }
          }
      );
  }

  async getCustomField() {
      (
          await this.clientCustomList.getCustomFieldList(
              this.moduleId,
              this.encryptCompanyId,
              this.isCustomize,
              this.moduleName
          )
      ).subscribe(async (resData: any) => {
          await this.getCountries();
          resData.result.forEach(async (element: any) => {
              element.field['name'] = element.field.label.replace(/\s/g, '');
              element.field['option'] = element.optionDetails;
              this.fields.push(element.field);
              await this.customFieldMain();
          });
          this.showCollaboration = true;
      });
  }

  async customFieldMain() {
      await this.customFieldInitialize();
      await this.customFieldInitializeFirst();
      await this.customFieldInitializeSecond();
      await this.customFieldInitializeThird();
  }

  async customFieldInitialize() {
      for (const f of this.fields) {
          if (f.field_type === 'numeric' && f.sub_type === 'decimal') {
              this.fieldsCtrl[f.name] = new FormControl(
                  '',
                  f.is_mandatory
                      ? [Validators.required, Validators.pattern(RegexEnum.decimal)]
                      : [Validators.pattern(RegexEnum.decimal)]
              );
          } else if (f.field_type === 'numeric' && f.sub_type === 'percentage') {
              this.fieldsCtrl[f.name] = new FormControl(
                  '',
                  f.is_mandatory
                      ? [Validators.required, Validators.pattern(RegexEnum.percentage)]
                      : [Validators.pattern(RegexEnum.percentage)]
              );
          }
      }
  }

  async customFieldInitializeFirst() {
      for (const f of this.fields) {
          if (f.field_type === 'phone') {
              await this.getFinalCode(f?.country_id);
              f['country_short_code'] = this.selectedCountry[0];
              this.fieldsCtrl[f.name] = new FormControl(
                  '',
                  f.is_mandatory ? Validators.required : Validators.nullValidator
              );
          }
      }
  }

  async getFinalCode(country_id: any) {
      const code = this.countries.find((codeName: any) => {
          if (codeName.id === country_id) {
              return codeName.iso3;
          }
      });
      Object.entries(this.preferredCountries).forEach(([key, val]) => {
          if (key === code?.name?.replace(/\s/g, '')) {
              this.finalCode = val;
          }
      });
      this.selectedCountry.push(this.finalCode);
  }

  async customFieldInitializeSecond() {
      for (const f of this.fields) {
          if (f.field_type === 'currency') {
              this.fieldsCtrl[f.country_id] = new FormControl(f.country_id);
              this.fieldsCtrl[f.name] = new FormControl(
                  '',
                  f.is_mandatory
                      ? [Validators.required, Validators.pattern(RegexEnum.currency)]
                      : [Validators.pattern(RegexEnum.currency)]
              );
          } else if (
              f.field_type === 'text' ||
        f.field_type === 'textarea' ||
        f.field_type === 'date' ||
        f.field_type === 'url' ||
        f.field_type === 'checklist' ||
        f.field_type === 'radio'
          ) {
              this.fieldsCtrl[f.name] = new FormControl(
                  '',
                  f.is_mandatory ? Validators.required : Validators.nullValidator
              );
          }
      }
  }

  async customFieldInitializeThird() {
      for (const f of this.fields) {
          if (f.field_type === 'email') {
              this.fieldsCtrl[f.name] = new FormControl(
                  '',
                  f.is_mandatory
                      ? [Validators.required, Validators.pattern(RegexEnum.email)]
                      : [Validators.pattern(RegexEnum.email)]
              );
          } else if (
              f.field_type === 'numeric' &&
        (f.sub_type === 'number' || f.sub_type === 'autonumber')
          ) {
              this.fieldsCtrl[f.name] = new FormControl(
                  '',
                  f.is_mandatory ? Validators.required : Validators.nullValidator
              );
          }
      }
      this.customForm = new FormGroup(this.fieldsCtrl);
      this.customForm.get(this.fields);
  }

  async manageCustomFormData(event: any) {
      for (const [key, value] of Object.entries(event)) {
          let tObject: any = {};
          for (const element of this.fields) {
              if (element.name === key) {
                  switch (element.field_type) {
                  case 'radio':
                      tObject = await this.getValueForRadio(key, value, element);
                      break;
                  case 'checklist':
                      tObject = await this.getValueForChecklist(key, value, element);
                      break;
                  case 'phone':
                  case 'currency':
                      tObject = await this.getValuePhoneCurrency(key, value, element);
                      break;
                  case 'date':
                      tObject = await this.getValueDate(key, value, element);
                      break;
                  case 'numeric':
                      tObject = await this.getValueNumeric(key, value, element);
                      break;
                  default:
                      tObject = await this.getValueRemainType(key, value, element);
                      break;
                  }
              }
          }
          if (
              tObject &&
        Object.keys(tObject).length !== 0 &&
        tObject.value !== ''
          ) {
              this.tArray.push(tObject);
          }
      }
  }

  async getValueForRadio(_key: any, value: any, element: any) {
      let Data = {};
      for (const elementRadio of element.option) {
          if (elementRadio.id === value) {
              Data = {
                  custom_field_id: element.id,
                  value: elementRadio.option,
                  custom_field_option_id: value,
              };
          }
      }
      return Data;
  }

  async getValueForChecklist(key: any, value: any, element: any) {
      if (!element.is_single_select) {
          await this.getValueForChecklistSingle(key, value, element);
      } else if (element.is_single_select) {
          let Data = {};
          for (const elementSingle of element.option) {
              if (elementSingle.id === value) {
                  Data = {
                      custom_field_id: element.id,
                      value: elementSingle.option,
                      custom_field_option_id: elementSingle.id,
                  };
              }
          }
          return Data;
      }
  }

  async getValueForChecklistSingle(_key: any, value: any, element: any) {
      let Data = {};
      for (const elementMulti of element.option) {
          for (const elementValue of value) {
              if (elementMulti.id === elementValue) {
                  Data = {
                      custom_field_id: element.id,
                      value: elementMulti.option,
                      custom_field_option_id: elementValue,
                  };
                  this.tArray.push(Data);
              }
          }
      }
  }

  async getValuePhoneCurrency(_key: any, value: any, element: any) {
      return {
          custom_field_id: element.id,
          value: value,
          custom_field_country_id:
        this.customForm.controls[element.country_id]?.value,
      };
  }

  async getValueDate(_key: any, value: any, element: any) {
      return {
          custom_field_id: element?.id,
          value: value != '' ? value?.getTime() : value,
      };
  }

  async getValueNumeric(_key: any, value: any, element: any) {
      let Data = {};
      if (element.field_type === 'numeric' && element.sub_type === 'autonumber') {
          Data = {
              custom_field_id: element.id,
          };
          return Data;
      } else if (
          element.field_type === 'numeric' &&
      element.sub_type !== 'autonumber'
      ) {
          Data = {
              custom_field_id: element.id,
              value: value,
          };
          return Data;
      }
  }

  async getValueRemainType(_key: any, value: any, element: any) {
      let Data = {};
      Data = {
          custom_field_id: element.id,
          value: value,
      };
      return Data;
  }
  async getCountries() {
      (await this.customFieldService.getCurrencyWithCountries()).subscribe(
          (res: any) => {
              this.countries = res.result;
          }
      );
  }

  async setCustomFieldData(fieldData: any) {
      for (const data of fieldData) {
          const fieldID = this.fields.find(
              (field: any) => field.id === data[0].field_id
          );
          switch (fieldID?.field_type) {
          case 'date':
              this.customForm
                  .get(fieldID.name)
                  .patchValue(new Date(parseInt(data[0]?.value)));
              break;
          case 'phone':
              if (data[0]?.value?.length === 0) {
                  this.getFinalCode(fieldID?.country_id);
                  fieldID['country_short_code'] = this.selectedCountry[0];
                  const foundIndex = this.fields.findIndex(
                      (x: any) => x.id === data[0]?.field_id
                  );
                  this.fields[foundIndex] = fieldID;
              }
              this.customForm.get(fieldID.name).patchValue(data[0]?.value);
              break;
          case 'radio':
              this.customForm.get(fieldID.name).patchValue(data[0]?.option_id);
              break;
          case 'checklist':
              if (!fieldID?.is_single_select) {
                  let value = data;
                  value = value?.map((v: any) => {
                      return v.option_id;
                  });
                  this.customForm.get(fieldID.name).patchValue(value);
              } else {
                  this.customForm.get(fieldID.name).patchValue(data[0]?.option_id);
              }
              break;
          case 'currency':
              this.customForm.get(fieldID.name).patchValue(data[0]?.value);
              this.customForm.controls[fieldID.country_id].setValue(
                  data[0].country_id ? data[0].country_id : fieldID.country_id
              );
              break;
          default:
              this.customForm.get(fieldID?.name).patchValue(data[0]?.value);
              break;
          }
      }
  }

  async getClientDetails() {
      (await this.clientService.getClientDetails()).subscribe(
          async (resData: any) => {
              await this.setFormData(resData);
              if (resData?.attachment_details) {
                  for (const item of resData?.attachment_details) {
                      this.attachment_details.push({
                          item,
                          image: await this.utils.getDocumentIcon(
                              { ...item, type: item.type },
                              false
                          ),
                      });
                  }
              }
              if (this.is_client_active && this.is_active) {
                  const customData = resData?.client_custom_field_values;
                  const selectedVehicles = _.groupBy(customData, function (item) {
                      return item.field_id;
                  });
                  const mapData = _.map(selectedVehicles, function (data) {
                      return data;
                  });
                  await this.setCustomFieldData(mapData);
                  this.serviceList.length === this.emails.value.length
                      ? (this.isShowAddMultile = false)
                      : (this.isShowAddMultile = true);
              }
          }
      );
  }

  async handelSelectAttachment(files: any) {
      for (const file of [...files['target']['files']]) {
          if (file.size / (1024 * 1024) <= 5) {
              this.attachments.push({
                  file,
                  image: await this.utils.getDocumentIcon(file, false),
              });
          } else {
              this.toastrService.error('File size should be less then 5MB', 'Error');
          }
      }
      this.myFileInput.nativeElement.value = '';
  }

  handelRemoveUploadAttachment(index: any) {
      this.attachments?.splice(index, 1);
  }

  async handleDeleteAttachment(id: any) {
      (await this.dmsService.deleteFile(id)).subscribe((response: any) => {
          if (response.status) {
              this.attachment_details = this.attachment_details.filter(
                  (item: any) => item?.item?._id !== id
              );
          }
      });
  }

  async handleSelectFile(item: any) {
      this._viewPreviewComponent = this.matDialog.open(DocumentPreviewComponent, {
          disableClose: true,
          panelClass: ['custom-modal', 'custom-modal-md', 'custom-preview-file'],
          data: {
              url: item?.file_path,
              name: item?.name,
              thumbnaill: item?.thumb_path,
              docType: item?.type,
          },
      });
  }


  applyFilter(e: any) {
      this.searchText = (e.target as HTMLInputElement).value.toLowerCase();
      this.isVisible = this.originalList.filter((ele:any)=> ele?.name.toLowerCase().includes(this.searchText)).length === 0 ? true : false;
    }

  selectAllClient(e: any) {
      const myForm = <FormArray>this.clientDetailForm.get('subTask');
      myForm.controls.forEach((_control) => {
          this.serviceList.forEach(async (y: any, index: any) => {
              this.setReporting({ checked: e.checked }, y.id, index);
          });
      });
  }
}
