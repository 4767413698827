import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiscussionSocketService } from 'src/app/shared/services/discussion/discussion-socket.service';
import { TasksService } from 'src/app/shared/services/task-service/tasks.service';

@Component({
    selector: 'app-checklist-comment',
    templateUrl: './checklist-comment.component.html',
    styleUrls: ['./checklist-comment.component.scss'],
})
export class ChecklistCommentComponent implements OnInit {
    selectedRoom: any = {};
    searchText = '';

    constructor(
    public taskService: TasksService,
    private discussionSocketService: DiscussionSocketService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChecklistCommentComponent>
    ) {}

    ngOnInit() {
        // Initialize the component
    }



    close() {
        this.dialogRef.close(true);
    }

}
