import { Theme } from './symbols';

export const lightTheme: Theme = {
    name: 'light',
    properties: {
        '--background': '#f6f7f9',
        '--on-background': '#000',
        '--primary': '#1976d2',
        '--on-primary': '#000',
        '--header': '#fff',
        '--icons': '#9E9E9E',
        '--menu-items': '#616161',
    },
    booleans: false,
};
