import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { Observable, Subject, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotesService {
    private searchData: Subject<any> = new Subject<any>();
    searchData$: Observable<any> = this.searchData.asObservable();

    private noteData: Subject<any> = new Subject<any>();
    noteData$: Observable<any> = this.noteData.asObservable();

    apiEndPoint = NAVIGATE_ROUTES;
    constructor(private apiService: ApiService) {}

    async addNote(data: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.ADD_NOTE}`;
        await this.apiService.post(url, data).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getNotes(page: any, search: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${
            this.apiEndPoint.ADD_NOTE
        }?page=${page}&searchString=${encodeURIComponent(search)}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getNoteDetailById(noteId: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.ADD_NOTE}${noteId}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async editNote(data: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.ADD_NOTE}`;
        await this.apiService.put(url, data).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async deleteNote(noteId: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.ADD_NOTE}${noteId}`;
        await this.apiService.delete(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async pinNote(noteId: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.PIN_NOTE}${noteId}`;
        await this.apiService.put(url, null).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getShareNotes(page: any, search: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.SHARE_NOTE}?searchString=${search}&page=${page}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    setSearchData(searchData: any) {
        this.searchData.next(searchData);
    }

    noteDetail(noteData: any) {
        this.noteData.next(noteData);
    }

    async shareNotes(data: any, id: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.SHARE_NOTES}${(id)}`;
        await this.apiService.put(url, data).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }
}
