import {
    Component,
    ElementRef,
    Inject,
    Injector,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TasksService } from '../../services/task-service/tasks.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { TimesheetSettingService } from '../../services/organization-settings/timesheet-setting.service';
import { UserDetailsService } from '../../services/user-details.service';
import { TimeSheetService } from '../../services/time-sheet-service/time-sheet.service';
import * as CryptoJS from 'crypto-js';
import { TIME_LOG_TYPE } from '../../global/constants';
import { ApiService } from '../../services/api-service/api.service';
import { UtilsService } from '../../services/utils-service/utils.service';
import { Subscription } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'app-add-time-log',
    templateUrl: './add-time-log.component.html',
    styleUrls: ['./add-time-log.component.scss'],
})
export class AddTimeLogComponent implements OnInit {
    projectListData: any = [];
    projectList: any[] = [];
    selectedProjectId: any = [];
    projectMatPreview: any = [];
    totalHours: any;
    timeLogForm!: FormGroup;
    timeLogData: any;
    timerShow = false;
    timerToggle = false;
    selectedDate: any;
    freezeDays: any;
    selectedTimeLogType: string;
    minDate: any;
    maxDate: any;
    maxMinutes = 59;
    minEndTime: any = moment().endOf('day').toDate();
    decryptedCompanyId: any;
    decryptedUserId: any;
    company_id: any;
    userConfigType: any;
    memberID: any;
    public timeSheetSettingService: TimesheetSettingService;
    public userDetail: UserDetailsService;
    public timeSheetService: TimeSheetService;
    dialogData: any = {};
    startEpochTime: any;
    endEpochTime: any;
    currentTab: any;
    timerObj: any;
    loggedUserConfig: any;
    allTasks: any;
    toHighlight: '';
    selectedTaskId: any;
    timeLogDetail: any = [];
    subscription: Subscription;
    dynamicWidth: any;
    @ViewChild('elementRefDiv', { static: false }) elementRef: ElementRef;
    @ViewChild('textareaRef', { static: false })
    textareaRef: ElementRef<HTMLTextAreaElement>;
    content = '';
    @ViewChild('startButton') startButton: ElementRef;
    userRolePermission:any;
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<AddTimeLogComponent>,
        public translate: TranslateService,
        private injector: Injector,
        public utils: UtilsService,
        private taskService: TasksService,
        private formBuilder: FormBuilder,
        private apiService: ApiService
    ) {
        translate.setDefaultLang('en');
        this.timeSheetSettingService = injector.get<TimesheetSettingService>(
            TimesheetSettingService
        );
        this.userDetail = injector.get<UserDetailsService>(UserDetailsService);
        this.timeSheetService = injector.get<TimeSheetService>(TimeSheetService);
    }

    async ngOnInit() {
        if (this.data) {
            this.dialogData = Object.assign({}, this.data);
        }
        this.formData();
        if (this.dialogData?.type == 'timeLog') {
            this.logTypeChange('task');
        }

        this.decryptedUserId = await this.userDetail.userId;
        this.company_id = await this.userDetail.getSelectedCompanyId();
        this.decryptedCompanyId = CryptoJS.AES.decrypt(
            this.company_id?.trim(),
            ''
        ).toString(CryptoJS.enc.Utf8);
        this.userRolePermission = await this.userDetail.getUserRolePermission(
            this.decryptedUserId,
            this.decryptedCompanyId
        );
        this.memberID = this.userRolePermission.memberId;
        await this.getTimeSheetDetail(this.decryptedCompanyId);
        this.maxDate = moment().toDate();
        await this.getProjectList();
        await this.getAllTasksWithProjectTask();
        this.removeFromMat();
        if (this.data?.isEditTask) {
            this.getTimeLogData();
        }
        this.adjustTextareaHeight();
    }

    async getProjectList() {
        (await this.taskService.getProjectList((this.userRolePermission?.owner || this.userRolePermission?.superAdmin) ? '1' : '0')).subscribe(async (res: any) => {
            if (res) {
                await res.forEach((project: any) => {
                    const data = {
                        name: project.project_name,
                        id: project._id,
                        isCheck: false,
                        projectMembers: project.assignees,
                        start_date: project.start_date,
                        end_date: project.end_date,
                        client: project.client,
                        owner: project.owner,
                    };
                    this.projectListData.push(data);
                });
                this.projectList = this.projectListData;
            }
        });
    }

    async projectSelection(selectedId: any) {
        this.selectedProjectId = selectedId;
        this.setMatPreview();
        await this.projectListData.forEach((project: any) => {
            if (selectedId[0] === project.id) {
                project.isCheck = true;
            } else {
                project.isCheck = false;
            }
        });
        this.getAllTasksWithProjectTask();
    }

    search(event: any, originalArr: any, listArr: any, _listArrName: any) {
        const searchInput = event.target.value.toLowerCase();
        listArr = originalArr.filter((ele: any) => {
            const name = ele.name.toLowerCase();
            return name.includes(searchInput);
        });

        this.projectListData = [...listArr];
        for (const element of this.projectListData) {
            this.selectedProjectId && this.selectedProjectId[0] === element.id
                ? (element.isCheck = true)
                : (element.isCheck = false);
        }
        return this.projectListData;
    }

    setMatPreview() {
        this.projectMatPreview = this.projectList.filter((ele: any) => {
            if (this.selectedProjectId.includes(ele.id)) {
                return ele;
            }
        });
    }

    formData() {
        this.timeLogForm = this.formBuilder.group({
            hoursControl: [null],
            minutesControl: [null],
            startFormControl: [null],
            endFormControl: [null],
            noteFormcontrol: [null],
            timeLogDate: [null, [Validators.required]],
            logType: ['task'],
            configType: [null, [Validators.required]],
            taskControl: [null],
        });
        if ((this.data.type = 'task')) {
            this.timeLogForm.controls['logType'].setErrors(null);
        } else {
            this.timeLogForm.controls['logType'].setErrors({ required: true });
        }
    }

    onClosed() {
        this.dialogRef.close();
    }

    async getTimeSheetDetail(company_id: any) {
        (
            await this.timeSheetSettingService.getTimesheetSetting(company_id)
        ).subscribe((res: any) => {
            this.timeLogData = res?.result;
            this.selectedTimeLogType = this.timeLogData?.process_type;
            this.freezeDays = this.timeLogData?.freeze_days;
            this.minDate = moment()
                .subtract(this.timeLogData?.freeze_days, 'days')
                .toDate();
            this.loggedUserConfig = res?.result?.user_configrations.find(
                (item: any) => item.user_id === this.memberID
            );
            if (
                this.loggedUserConfig?.user_config == 'start_end' ||
                this.loggedUserConfig?.user_config == 'both'
            ) {
                this.currentTab = 'timeFrame';
                this.timeLogForm.controls['configType'].patchValue('timeFrame');
                this.tabChanged('timeFrame');
            } else {
                this.currentTab = 'noOfHours';
                this.timeLogForm.controls['configType'].patchValue('noOfHours');
                this.tabChanged('noOfHours');
            }
        });
    }

    selectDate(date: any) {
        this.selectedDate = date?.value;
    }

    onClear($event: Event) {
        this.timeLogForm.get('startFormControl').setValue(null);
    }

    onEndClear($event: Event) {
        this.timeLogForm.get('endFormControl').setValue(null);
    }

    setTime() {
        this.minEndTime = moment(
            this.timeLogForm.get('startFormControl').value,
            'HH:mm'
        ).format('HH:mm');
        this.setEndTime();
        this.updateTotalTime();
    }

    setEndTime() {
        const [startHours, startMinutes] = (
            this.timeLogForm.get('startFormControl').value?.split(':') || ['0', '0']
        ).map(Number);
        const [endHours, endMinutes] = (
            this.timeLogForm.get('endFormControl').value?.split(':') || ['0', '0']
        ).map(Number);
        const startTimeInMinutes = startHours * 60 + startMinutes;
        const endTimeInMinutes = endHours * 60 + endMinutes;
        const total: number = (endTimeInMinutes - startTimeInMinutes) / 60;
        this.totalHours = isNaN(total)
            ? '00:00'
            : moment.utc().startOf('day').add(total, 'hours').format('HH:mm:ss');
        this.updateTotalTime();
    }

    onHourChange() {
        const hr = this.timeLogForm.get('hoursControl').value;
        if (hr == 24) {
            this.timeLogForm.get('minutesControl').setValue(0);
            this.maxMinutes = 0;
        } else if (this.timeLogForm.get('hoursControl').invalid) {
            this.timeLogForm.get('hoursControl').setValue(0);
        }
        this.updateTotalTime();
    }

    onMinuteChange() {
        if (
            this.timeLogForm.get('minutesControl').invalid &&
            this.timeLogForm.get('minutesControl').value == ''
        ) {
            this.timeLogForm.get('minutesControl').setValue(0);
        }
        this.updateTotalTime();
    }

    updateTotalTime() {
        if (this.currentTab === TIME_LOG_TYPE.NO_OF_HOURS) {
            const hour = +this.timeLogForm.value.hoursControl;
            const minute = +this.timeLogForm.value.minutesControl;
            const seconds = hour * 60 * 60 + minute * 60;
            this.totalHours = new Date(seconds * 1000).toISOString().slice(11, 19);
        } else {
            this.totalHours = this.totalHours;
        }
    }

    tabChanged(tabChangeEvent: any): void {
        this.currentTab = tabChangeEvent;
        if (this.currentTab === TIME_LOG_TYPE.NO_OF_HOURS) {
            this.timeLogForm.get('startFormControl').setErrors(null);
            this.timeLogForm.get('endFormControl').setErrors(null);
            this.timeLogForm.controls['hoursControl'].setValidators([
                Validators.max(23),
                Validators.min(0),
                Validators.required,
            ]);
            this.timeLogForm.controls['minutesControl'].setValidators([
                Validators.max(59),
                Validators.min(0),
            ]);
            this.timeLogForm.get('hoursControl').setErrors({ required: true });
            this.updateTotalTime();
        } else {
            this.timeLogForm.get('startFormControl').setValue('');
            this.timeLogForm.get('endFormControl').setValue('');
            this.timeLogForm.controls['startFormControl'].setValidators([
                Validators.required,
            ]);
            this.timeLogForm.controls['endFormControl'].setValidators([
                Validators.required,
            ]);
            this.timeLogForm.get('startFormControl').setErrors({ required: true });
            this.timeLogForm.get('endFormControl').setErrors({ required: true });
            this.timeLogForm.get('hoursControl').setErrors(null);
            this.timeLogForm.get('minutesControl').setErrors(null);
            this.timeLogForm.get('minutesControl').patchValue(0);
            this.timeLogForm.updateValueAndValidity();
            this.setEndTime();
        }
    }

    async submit() {
        if (this.currentTab === TIME_LOG_TYPE.NO_OF_HOURS) {
            const startTime =
                moment(this.selectedDate).format('MM/DD/YYYY') +
                ' ' +
                (this.timeLogForm.get('startFormControl').value
                    ? this.timeLogForm.get('startFormControl').value + ':00'
                    : '00:00:00');
            this.startEpochTime = new Date(startTime).getTime();
            const endTime =
                moment(this.selectedDate).format('MM/DD/YYYY') +
                ' ' +
                (this.timeLogForm.get('endFormControl').value
                    ? this.timeLogForm.get('endFormControl').value + ':00'
                    : '00:00:00');
            this.endEpochTime = new Date(endTime).getTime();
            this.timerObj = {
                task_id:
                    this.dialogData?.type == 'task'
                        ? this.dialogData?.taskId
                        : this.selectedTaskId.length > 0
                            ? this.selectedTaskId
                            : null,
                comment: this.timeLogForm.value.noteFormcontrol
                    ? this.timeLogForm.value.noteFormcontrol
                    : '',
                project_id:
                    this.dialogData?.type == 'task'
                        ? null
                        : this.selectedProjectId.length > 0
                            ? this.selectedProjectId[0]
                            : null,
                event: 'manual',
                hours: this.totalHours,
                type:
                    this.dialogData?.type == 'task'
                        ? 'task'
                        : this.timeLogForm.value.logType,
                start_time: this.startEpochTime,
                end_time: this.endEpochTime,
            };
        } else {
            const startTime =
                moment(this.selectedDate).format('MM/DD/YYYY') +
                ' ' +
                (this.timeLogForm.get('startFormControl').value
                    ? this.timeLogForm.get('startFormControl').value + ':00'
                    : '00:00:00');
            this.startEpochTime = new Date(startTime).getTime();
            const endTime =
                moment(this.selectedDate).format('MM/DD/YYYY') +
                ' ' +
                (this.timeLogForm.get('endFormControl').value
                    ? this.timeLogForm.get('endFormControl').value + ':00'
                    : '00:00:00');
            this.endEpochTime = new Date(endTime).getTime();
            this.timerObj = {
                task_id:
                    this.dialogData?.type == 'task'
                        ? this.dialogData?.taskId
                        : this.selectedTaskId.length > 0
                            ? this.selectedTaskId
                            : null,
                comment: this.timeLogForm.value.noteFormcontrol
                    ? this.timeLogForm.value.noteFormcontrol
                    : '',
                project_id:
                    this.dialogData?.type == 'task'
                        ? null
                        : this.selectedProjectId.length > 0
                            ? this.selectedProjectId[0]
                            : null,
                event: 'start_end',
                start_time: this.startEpochTime,
                end_time: this.endEpochTime,
                hours: this.totalHours,
                type:
                    this.dialogData?.type == 'task'
                        ? 'task'
                        : this.timeLogForm.value.logType,
            };
        }
        this.apiService.showLoading();
        (await this.timeSheetService.addTimeLogEntry(this.timerObj)).subscribe(
            (res: any) => {
                if (res?.msg) {
                    this.apiService.showSuccess(res.msg);
                    this.timeLogForm.reset();
                    this.selectedDate = '';
                    this.dialogRef.close(true);
                    this.selectedProjectId = [];
                    this.selectedTaskId = [];
                }
                this.apiService.hideLoading();
            }
        );
    }

    logTypeChange(selectedType: any) {
        if (selectedType === 'task' && this.dialogData?.type == 'timeLog') {
            this.timeLogForm.get('taskControl').setValue('');
            this.timeLogForm.controls['taskControl'].setValidators([
                Validators.required,
            ]);
            this.timeLogForm.get('taskControl').setErrors({ required: true });
            this.timeLogForm.updateValueAndValidity();
            this.selectedProjectId = [];
            this.selectedTaskId = [];
            this.projectMatPreview = [];
            this.checkProjectIsChecked();
            this.getAllTasksWithProjectTask();
        } else {
            this.selectedProjectId = [];
            this.selectedTaskId = [];
            this.projectMatPreview = [];
            this.timeLogForm.get('taskControl').setErrors(null);
            this.checkProjectIsChecked();
        }
    }

    async getAllTasksWithProjectTask() {
        if (this.selectedProjectId.length > 0) {
            (
                await this.timeSheetService.getAllTasksForLog(this.selectedProjectId[0])
            ).subscribe((res: any) => {
                this.allTasks = res.result;
                this.allTasks.forEach((element: any) => {
                    element.isCheck = false;
                });
            });
        } else {
            (await this.timeSheetService.getAllTasksForLog()).subscribe(
                (res: any) => {
                    this.allTasks = res.result;
                    this.allTasks.forEach((element: any) => {
                        element.isCheck = false;
                    });
                }
            );
        }
    }
    
    async highlight(val: any) {
        this.toHighlight = val;
        if (this.toHighlight == null || this.toHighlight == '') {
            this.timeLogForm.controls['taskControl'].setValue(null);
            this.selectedTaskId = [];
        }
        if (val !== null) {
            let scrollPosition = 0;
            if (this.autocompleteTrigger && this.autocompleteTrigger.panelOpen) {
                const panel = this.autocompleteTrigger.autocomplete.panel?.nativeElement;
                scrollPosition = panel ? panel.scrollTop : 0;
            }

            this.allTasks = await this.allTasks.sort((a: any, b: any) => {
                const lowerVal = val?.toLowerCase();
                const aTask = a.task_name.toLowerCase();
                const bTask = b.task_name.toLowerCase();

                if (aTask === lowerVal && bTask !== lowerVal) return -1;
                if (bTask === lowerVal && aTask !== lowerVal) return 1;

                if (aTask.startsWith(lowerVal) && bTask.startsWith(lowerVal)) return 0;

                if (aTask.startsWith(lowerVal)) return -1;
                if (bTask.startsWith(lowerVal)) return 1;

                if (aTask.includes(lowerVal) && !bTask.includes(lowerVal)) return -1;
                if (!aTask.includes(lowerVal) && bTask.includes(lowerVal)) return 1;

                return 0;
            });
            
            const arr = await this.allTasks.filter(
                (member: any) =>
                    member.task_name.toLowerCase().indexOf(val?.toLowerCase()) === 0
            );

            setTimeout(() => {
                if (this.autocompleteTrigger && this.autocompleteTrigger.panelOpen) {
                    const panel = this.autocompleteTrigger.autocomplete.panel?.nativeElement;
                    if (panel) {
                        panel.scrollTop = 0;
                    }
                }
            }, 100);
            if (arr.length) {
                if (this.selectedTaskId.length > 0) {
                    this.timeLogForm.controls['taskControl'].setErrors({
                        invalidReporting: null,
                    });
                    this.timeLogForm.controls['taskControl'].setErrors(null);
                } else {
                    this.timeLogForm.controls['taskControl'].setErrors({
                        invalidReporting: true,
                    });
                    this.selectedTaskId = [];
                }
                return arr;
            } else {
                this.timeLogForm.controls['taskControl'].setErrors({
                    invalidReporting: true,
                });
                this.selectedTaskId = [];
                return [{ name: 'No Task found' }];
            }
        }
    }

    selectedTask(id: any) {
        this.selectedTaskId = id;
    }

    async checkProjectIsChecked() {
        await this.projectListData.forEach((project: any) => {
            project.isCheck = false;
        });
        await this.projectList.forEach((project: any) => {
            project.isCheck = false;
        });
    }

    removeFromMat() {
        this.subscription = this.taskService.removeIdFromPreview.subscribe(
            async (data: any) => {
                if (data.listArrName === 'timeLogProject' && data) {
                    await this.projectListData.forEach((project: any) => {
                        if (data.removeId === project.id) {
                            project.isCheck = false;
                        }
                    });
                    this.selectedProjectId = [];
                    this.projectMatPreview = [];
                    this.getAllTasksWithProjectTask();
                }
            }
        );
    }

    async getTimeLogData() {
        const data: any = {
            taskId: this.data.taskId,
            selectUserId: null,
            selectedTaskId: null,
            isPrimary: true,
            offset: this.utils.getOffsetTime(),
        };
        (await this.timeSheetService.getTimeLog(data)).subscribe((res: any) => {
            if (res) {
                this.timeLogDetail = res?.result?.timeSheetData[0]?.data[0];
                this.timeLogForm.controls['logType'].patchValue(
                    this.timeLogDetail?.type
                );
                this.totalHours = res?.result?.totalHours;
                if (this.timeLogDetail?.event == 'start_end') {
                    this.currentTab = 'timeFrame';
                    this.timeLogForm.controls['configType'].patchValue(this.currentTab);
                    const startTime = moment(this.timeLogDetail?.start_time).format(
                        'HH:mm'
                    )
                        ? moment(this.timeLogDetail?.start_time).format('HH:mm')
                        : '00:00';
                    const endTime = moment(this.timeLogDetail?.end_time).format('HH:mm')
                        ? moment(this.timeLogDetail?.end_time).format('HH:mm')
                        : '00:00';
                    this.timeLogForm.controls['startFormControl'].patchValue(startTime);
                    this.timeLogForm.controls['endFormControl'].patchValue(endTime);
                } else {
                    this.currentTab = 'noOfHours';
                    this.timeLogForm.controls['configType'].patchValue(this.currentTab);
                    const time = this.totalHours.split(':');
                    const startTime = moment(this.timeLogDetail?.start_time).format('HH')
                        ? moment(this.timeLogDetail?.start_time).format('HH')
                        : 0;
                    const endTime = moment(this.timeLogDetail?.end_time).format('HH:mm')
                        ? moment(this.timeLogDetail?.end_time).format('HH:mm')
                        : 0;
                    this.timeLogForm.controls['hoursControl'].patchValue(time[0]);
                    this.timeLogForm.controls['minutesControl'].patchValue(time[1]);
                }
                this.maxDate = res?.result?.timeSheetData[0]?.date;
                this.minDate = res?.result?.timeSheetData[0]?.date;
                this.timeLogForm.controls['timeLogDate'].patchValue(
                    res?.result?.timeSheetData[0]?.data[0]?.time_log_date
                );
                this.timeLogForm.controls['noteFormcontrol'].patchValue(
                    this.timeLogDetail?.comment
                );
            }
        });
    }
    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
    ngAfterViewInit() {
        this.dynamicWidth = this.elementRef.nativeElement.offsetWidth - 30;
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes['content']) {
            this.adjustTextareaHeight();
        }
    }
    adjustTextareaHeight(): void {
        const textarea = this.textareaRef.nativeElement;

        // Reset the textarea's height to its default first
        textarea.style.height = 'auto';

        // Set the textarea's height to its scroll height (content height)
        textarea.style.height = textarea.scrollHeight + 'px';
    }
    handleButtonClick() {
        this.startButton.nativeElement.click();
    }
}
