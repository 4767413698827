import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { UserDetailsService } from '../user-details.service';
import * as CryptoJS from 'crypto-js';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class StatusServiceService {
    apiEndPoint = NAVIGATE_ROUTES;
    res: any = [];
    company_id: any;

    constructor(
    private apiService: ApiService,
    public userDetail: UserDetailsService,
    public userService: UserDetailsService
    ) {}

    async getcompanyID() {
        this.company_id = await this.userDetail.getCompanyId();
        return this.company_id;
    }
    convertObjToParams(object: any) {
        let params = new HttpParams();
        Object.keys(object).forEach((key) => {
            if (object[key] != '') {
                params = params.set(key, object[key]);
            }
        });
        return params;
    }

    async addStatus(data: any): Promise<Observable<any>> {
        this.res = [];
        await this.apiService
            .post(this.apiEndPoint.STATUS, data)
            .then((resData) => {
                this.res.push(resData);
            });
        return of(this.res);
    }

    async getStatus(company_id: any): Promise<Observable<any>> {
        const obj: any = {
            companyId: company_id,
        };
        const params = this.convertObjToParams(obj);
        const url = `${this.apiEndPoint.STATUS}?${params}`;
        await this.apiService
            .get(url)
            .then((resData) => {
                this.res = [];
                this.res.push(resData);
            })
        return of(this.res);
    }

    async editStatus(data: any): Promise<Observable<any>> {
        const status_id = CryptoJS.AES.encrypt(
            data.id?.toString().trim(),
            ''
        ).toString();
        const obj: any = {
            statusId: status_id,
        };
        const params = this.convertObjToParams(obj);
        const url = `${this.apiEndPoint.STATUS}?${params}`;
        this.res = [];
        await this.apiService.put(url, data).then((data1) => {
            this.res.push(data1);
        });
        return of(this.res);
    }

    async deleteStatus(data: any): Promise<Observable<any>> {
        this.res = [];
        const status_id = CryptoJS.AES.encrypt(
            data.id?.toString().trim(),
            ''
        ).toString();

        const obj: any = {
            statusId: status_id,
        };
        const params = this.convertObjToParams(obj);
        const url = `${this.apiEndPoint.STATUS}?${params}`;
        this.apiService.delete(url).then((data) => {
            this.res = data;
        });
        return of(this.res);
    }
}
