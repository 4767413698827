<div class="col-md-12 mb-1">
  <app-mat-chip-preview
    [isInfoShow]="true"
    [selectedMembers]="clientMatPreview"
    listArrName="client"
  ></app-mat-chip-preview>
  <input
    *ngIf="originalClientListData?.length !== 0"
    (click)="$event.stopPropagation()"
    type="text"
    autocomplete="off"
    placeholder="Write here"
    class="form-control c-s-input custom-input border-0 w-100 m-w-100"
    (input)="search($event, originalClientListData, clientListData)"
  />
  <app-check-box-common
    [memberListData]="clientListData"
    [listName]="clientListName"
    [isShowSelectAll]="false"
    [isSingleSelect]="false"
    [isDisable]="false"
    (onSelectCheckBox)="onSelectClientCheckBox($event)"
  >
  </app-check-box-common>
  <div
    class="text-center"
    *ngIf="originalClientListData.length !== 0 && clientListData.length === 0"
  >
    {{ "NO_DATA_FOUND" | translate }}
  </div>
  <div class="text-center" *ngIf="originalClientListData.length === 0">
    {{ "NO_DATA_AVAILABLE" | translate }}
  </div>
  <!-- <app-shared-client [selection_type]="'multiple'"(selectedClient)="receiveClientData($event)" [resetCount]="resetData"></app-shared-client> -->
</div>
