<div (click)="$event.stopPropagation()">
    <input type="text" [(ngModel)]="inputData" (keydown.enter)="onEnter()" class="search-area" placeholder="Write here"/>
    <div class="mui-time-log-report-block content pt-2" #scrollContainer style="overflow: auto"
        (scroll)="handleScroll($event)">
        <ul class="p-2">
            <li *ngFor="let client of clientList" class="d-flex align-items-center">
                <div class="d-flex align-items-center w-100">
                    <mat-checkbox (change)="selectedClientData(client,$event)" [checked]="clientArr?.includes(client.id)" class="lazy-loaded-checkbox">
                    <div>
                        <img alt="profile" *ngIf="client.profile_image && client.profile_image?.charAt(0) != '#'"
                            src="{{ client.profile_image }}" class="img-avatar last-img-cls-margin-decrease" />
                        <div *ngIf="client.profile_image && client.profile_image?.charAt(0) == '#'"
                            class="me-2 user-short-name" [ngStyle]="{ 'background-color': client.profile_image }">
                            {{ utils.forShortName(client.client_name) }}
                        </div>
                    </div>
                    <div class="trim-info" for="checklist">{{client.client_name}} - {{client.company_name}}</div>
                </mat-checkbox>
                </div>
            </li>
        </ul>
    </div>
    <div class="d-flex align-items-center justify-content-end pt-2">

        <button (click)="onEnter()" class="search-button">Search</button>
    </div>
</div>