<div class="modal-header text-center justify-content-between">
  <h4 class="modal-title">{{ "Image View" | translate }}</h4>
  <div class="right-action-btn">
    <button class="right-menu-item" (click)="downloadImage(imagePath)">
      <img
        src="../../../../assets/images/downloadiconnew.svg"
        alt="icon"
        draggable="false"
        class="img-fluid download-icon"
      />
    </button>
    <img alt="cross-icon"
      src="../../../../assets/images/crossblack.svg"
      type="button"
      class="close p-0"
      draggable="false"
      aria-label="Close"
      (click)="closeImageModal()"
      class="crossblack-cls-svg close-icon"
    />
  </div>
</div>
<div class="modal-body text-center image-preview-content">
  <ul
    class="list-unstyled d-flex align-items-center h-100 justify-content-between mb-0"
  >
    <li [ngClass]="imageIndex > 0 ? '' : 'opacity-0'">
      <a (click)="loadImage('prev')" class="sliding-btn" *ngIf="isPrevEnable">
        <img
          src="../../../../assets/images/previous-blue.svg"
          alt=""
          class="arrow-pointer"
          width="50px"
          height="50px"
        />
      </a>
    </li>
    <li
      class="flex-grow-1 d-flex align-items-center justify-content-center h-100 mx-2"
    >
      <img alt="img-open"
        [src]="imagePath"
        class="img-fluid"
        width="100px"
        height="100px"
        class="preview-img-open"
      />
    </li>
    <li [ngClass]="imageIndex < data.imageArray.length - 1 ? '' : 'opacity-0'">
      <a (click)="loadImage('next')" class="sliding-btn" *ngIf="isNextEnable">
        <img
          src="../../../../assets/images/next-blue.svg"
          alt=""
          class="arrow-pointer"
          width="50px"
          height="50px"
        />
      </a>
    </li>
  </ul>
</div>
