import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
    FormArray,
} from '@angular/forms';
import { Component, ElementRef, Inject, Injector, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ServiceManagementService } from 'src/app/shared/services/organization/service-management.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { GetNewMemberService } from 'src/app/shared/services/organization/get-new-member.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateAdapter } from '@angular/material/core';
import { ClientServiceService } from 'src/app/shared/services/organization/client-service.service';
import { FREQUENCY_DATA } from 'src/app/shared/global/constants';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import { ApiService } from 'src/app/shared/services/api-service/api.service';
@Component({
    selector: 'app-add-service',
    templateUrl: './add-service.component.html',
    styleUrls: ['./add-service.component.scss'],
})
export class AddServiceComponent implements OnInit {
    checked = false;
    isAllCheck = false;
    searchText: any;
    addServiceForm!: FormGroup;
    selected = -1;
    epochNow: any;
    serviceCreatorName: any[] = [];
    frequencyOptions: any[] = [
        { option: 'Weekly' },
        { option: 'Monthly' },
        { option: 'Quarterly' },
        { option: 'halfyearly' },
        { option: 'Yearly' },
        { option: 'Custom' },
    ];
    frequencyDetails: any[] = [];
    weekDays: any = {
        '1Sun': false,
        '2Mon': false,
        '3Tue': false,
        '4Wed': false,
        '5Thu': false,
        '6Fri': false,
        '7Sat': false,
    };
    weekDaysLable: any = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    weekValue: any = [];
    monthly: any = null;
    yearly: any = null;
    weekDaysDetails: any[] = [];
    today = new Date();
    minDate: any;
    maxDate: any;
    minDateRecur: any;
    maxDateRecur: any;
    frequencyvalue: any;
    selectedweekday: any = [];
    companyId: any;
    toHighlight = '';
    toHighlightclient = '';
    creatorname: FormControl;
    private serviceManagementService: ServiceManagementService;
    public userDetail: UserDetailsService;
    public getNewMember: GetNewMemberService;
    addServiceButton = true;
    numbers = Array(28)
        .fill(1)
        .map((_x, index) => index + 1);
    count = 0;
    step = 0;
    filteredProviders: any = [];
    emails: any = [];
    member: any = [];
    encryptCompanyId: any;
    total_servicesMember: any = [];
    serviceList: any[] = [];
    isShowAddMultile = true;
    allSelected: any = [];
    selectedMembers: any = [];
    originalList: any = [];
    selectedToppings: any = [];
    clientDetailsArr: any = [];
    memberCopy: any = [];
    number: number;
    public clientService: ClientServiceService;
    clientCount: number;
    page: number = 1;
    @ViewChild('searchInputval') searchInputval: ElementRef;
    inputData: string;
    enterPressed: boolean = false;
    public apiService:any;
    searchSelection:any[]=[];
    @ViewChildren('multiUserSearch') multiUserSearchInputs: QueryList<ElementRef>;
    
    constructor(
        private ToastService: ToastrService,
        private injector: Injector,
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AddServiceComponent>,
        private translate: TranslateService,
        private dateAdapter: DateAdapter<Date>,
        public utils: UtilsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dialogRef.disableClose = true;
        translate.setDefaultLang('en');
        this.dateAdapter.setLocale('en-GB');
        this.clientService = injector.get<ClientServiceService>(ClientServiceService);
        this.userDetail = injector.get<UserDetailsService>(UserDetailsService);
        this.serviceManagementService = injector.get<ServiceManagementService>(
            ServiceManagementService
        );
        this.getNewMember = injector.get<GetNewMemberService>(GetNewMemberService);
        this.addServiceForm = this.formBuilder.group({
            serviceNameFormControl: ['', [Validators.required]],
            creatorNameFormControl: ['', [Validators.required]],
            toggleControl: [false, []],
            subTask: this.formBuilder.array([]),
        });
        
        this.apiService = injector.get<ApiService>(ApiService);
    }

    async ngOnInit() {
        this.creatorname = new FormControl('', Validators.required);
        this.today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        this.minDate = this.today;
        this.maxDate = this.today;
        this.minDateRecur = this.today;
        this.maxDateRecur = this.today;
        this.epochNow = new Date().getTime();
        this.companyId = await this.userDetail.getCompanyId();
        this.encryptCompanyId = await this.userDetail.getCompanyId();
        this.companyId = this.companyId
            ? CryptoJS.AES.decrypt(this.companyId?.trim(), '').toString(
                CryptoJS.enc.Utf8
            )
            : '';
        this.companyId = parseInt(this.companyId);
        await this.getMemberList();
        await this.getClientDetails();
        await this.getMemberListData();
        if (this.data?.id != null) {
            await this.patchServiceFromValue();
            this.addServiceForm.markAsDirty();
        }
    }

    async getMemberListData() {
        (
            await this.getNewMember.getStaffMember2(
                this.data?.isOpenFromTaskModule
                    ? this.data?.encryptedCompanyId
                    : this.encryptCompanyId
            )
        ).subscribe((resData: any) => {
            const dataObj = resData;
            dataObj?.forEach((element: any) => {
                element['name'] = element.first_name + ' ' + element.last_name;
                this.serviceCreatorName.push(element);
            });
        });
    }

    setmaxDate() {
        this.maxDate = this.addServiceForm.controls['dueDateFormControl'].value;
    }

    async patchServiceFromValue() {
        this.addServiceButton = false;
        this.addServiceForm.controls['serviceNameFormControl'].patchValue(
            this.data.service_name
        );
        this.addServiceForm.controls['creatorNameFormControl'].patchValue(
            this.data.creator_id
        );
        this.addServiceForm.controls['toggleControl'].patchValue(
            this.data.is_recurring
        );
        const result = this.serviceCreatorName.filter(
            (data) => data.id == this.data.creator_id
        );
        this.creatorname.patchValue(result[0]?.name);
        if (this.data.is_recurring) {
            this.checked = false;
            this.checkToggle();
            const frequencyData = this.frequencyOptions.filter(
                (item) =>
                    item.option.replace(/ +/g, '').toLowerCase() ==
                    this.data.service_recurring_details[0]?.recurring_frequency
            );
            this.frequencyvalue =
                this.data.service_recurring_details[0]?.recurring_value;
            if (frequencyData[0]?.option === FREQUENCY_DATA.WEEKLY) {
                this.frequencyvalue = this.data.service_recurring_details;
                const arr = Object.keys(this.weekDays);
                this.frequencyvalue.forEach((data: any) => {
                    const indexRecurr = +data.recurring_value;
                    const dataArr = arr[indexRecurr];
                    this.weekDays[dataArr] = true;
                });
            } else if (frequencyData[0]?.option === FREQUENCY_DATA.CUSTOM) {
                this.addServiceForm.addControl(
                    'onDayFormControl',
                    new FormControl(this.monthly, [
                        Validators.required,
                        Validators.min(1),
                        Validators.max(10000),
                    ])
                );
                this.monthly = this.frequencyvalue;
                this.number = this.monthly;
                this.addServiceForm
                    .get('onDayFormControl')
                    .setValue(parseInt(this.monthly));
            } else if (
                frequencyData[0]?.option === FREQUENCY_DATA.MONTHLY ||
                frequencyData[0]?.option === FREQUENCY_DATA.QUARTERLY ||
                frequencyData[0]?.option === FREQUENCY_DATA.HALF_YEARLY ||
                frequencyData[0]?.option === FREQUENCY_DATA.YEARLY
            ) {
                this.yearly = new Date(parseInt(this.frequencyvalue));
                this.minDateRecur = this.yearly;
                this.maxDateRecur = this.yearly;
            }
            this.checkFrequency(frequencyData[0]);
            this.selected = this.frequencyOptions.findIndex(
                (item) =>
                    item.option.replace(/ +/g, '').toLowerCase() ==
                    this.data.service_recurring_details[0]?.recurring_frequency
            );
            this.addServiceForm.controls['frequencyFormControl'].setValue(
                frequencyData
            );
            const date = moment(
                parseInt(this.data.service_recurring_details[0]?.due_date)
            ).toString();
            const finalDate = new Date(date);
            this.addServiceForm.controls['dueDateFormControl'].patchValue(finalDate);
            this.minDate = '';
            this.maxDate = '';
        }
        this.emails = this.addServiceForm.controls['subTask'] as FormArray;
        this.filteredProviders = Object.assign([], this.total_servicesMember);

        this.isShowAddMultile = this.serviceList?.length !== this.emails?.value?.length;
    }
    isArrayInclude(arr: any, id: any) {
        return arr?.map((data: any) => data.id).includes(id);
    }
    checkFrequency(val: any) {
        if (!this.data?.id) {
            this.minDate = this.today;
        }

        if (this.frequencyDetails.includes(val)) {
            this.frequencyDetails = [];
            this.addServiceForm.controls['frequencyFormControl'].patchValue(
                this.frequencyDetails
            );
            return;
        }
        this.frequencyDetails = [val];
        this.addServiceForm.controls['frequencyFormControl'].patchValue(
            this.frequencyDetails
        );
        switch (this.frequencyDetails[0]?.option) {
            case 'Weekly':
                this.addServiceForm.addControl(
                    'weekDayFormControl',
                    new FormControl(this.weekDays, [Validators.required])
                );
                this.ischeckWeekDay();
                this.addServiceForm.removeControl('onDayFormControl');
                this.addServiceForm.removeControl('recurringDateFormControl');
                this.addServiceForm.removeControl('startDateFormControl');
                break;
            case 'Custom':
                this.addServiceForm.addControl(
                    'onDayFormControl',
                    new FormControl(this.monthly, [
                        Validators.required,
                        Validators.min(1),
                        Validators.max(10000),
                    ])
                );
                this.addServiceForm.removeControl('weekDayFormControl');
                this.addServiceForm.removeControl('recurringDateFormControl');
                this.addServiceForm.removeControl('startDateFormControl');
                break;
            case 'Monthly':
            case 'Quarterly':
            case 'Yearly':
            case 'halfyearly':
                this.addServiceForm.addControl(
                    'recurringDateFormControl',
                    new FormControl(this.yearly, [Validators.required])
                );
                this.addServiceForm.removeControl('weekDayFormControl');
                this.addServiceForm.removeControl('onDayFormControl');
                this.addServiceForm.removeControl('startDateFormControl');
                break;
            default:
                break;
        }
        this.monthly = null;
        this.yearly = null;
    }

    ischeckWeekDay() {
        const daysSelect: any = Object.entries(
            this.addServiceForm.controls['weekDayFormControl'].value
        );
        const dataArr = [];
        daysSelect.forEach((x: any, _i: number) => {
            if (x.includes(false)) {
                dataArr.push(x[1]);
            }
        });
        if (dataArr.length === 7) {
            this.count = 0;
        } else {
            this.count = 1;
        }
    }

    checkWeekDay2(data: any, event: MatCheckboxChange) {
        for (const property in this.weekDays) {
            this.weekDays[property] = false;
        }
        this.weekDays[data.key] = event.checked;
        const selected = Object.keys(this.weekDays).filter(
            (per: any) => this.weekDays[per]
        );
        if (selected.length == 0) {
            this.addServiceForm.controls['weekDayFormControl'].setValue(null);
        }
        this.addServiceForm.controls['weekDayFormControl'].markAsDirty();
        this.addServiceForm.controls['weekDayFormControl']?.setValue(this.weekDays);
        this.ischeckWeekDay();
    }

    checkToggle() {
        if (this.checked) {
            this.addServiceForm.removeControl('dueDateFormControl');
            this.addServiceForm.removeControl('frequencyFormControl');
            this.addServiceForm.removeControl('onDayFormControl');
            this.addServiceForm.removeControl('recurringDateFormControl');
            this.addServiceForm.removeControl('weekDayFormControl');
            this.frequencyDetails = [];
            this.selected = -1;
            const myForm = <FormArray>this.addServiceForm.get('subTask');
            myForm.reset();
        } else {
            this.addServiceForm.addControl(
                'dueDateFormControl',
                new FormControl('', [Validators.required])
            );
            this.addServiceForm.addControl(
                'frequencyFormControl',
                new FormControl('', [Validators.required])
            );
        }
        this.checked = !this.checked;
    }

    cancelClick() {
        this.dialogRef.close();
    }

    async addService() {
        const dataToSend: any = {};
        if (this.checked) {
            const dueDayDateFormat = new Date(
                this.addServiceForm.value.dueDateFormControl
            ).getTime();
            this.addServiceForm.value.dueDateFormControl = dueDayDateFormat;
            const currentDate: any = await this.utils.setCurrentDateTime(this.addServiceForm.value['dueDateFormControl']);
            dataToSend['due_date'] = currentDate?.getTime();

            const frequency =
                this.addServiceForm.controls['frequencyFormControl'].value[0].option;
            dataToSend['recurring_frequency'] = frequency.toLowerCase();
        }
        dataToSend['service_name'] =
            this.addServiceForm.value['serviceNameFormControl'];
        dataToSend['is_recurring'] = this.addServiceForm.value['toggleControl'];
        dataToSend['company_id'] = this.data?.isOpenFromTaskModule
            ? this.data?.companyId
            : this.companyId;
        dataToSend['creator_id'] =
            this.addServiceForm.value['creatorNameFormControl'];
        dataToSend['user_id'] = this.userDetail.userId;
        if (dataToSend['is_recurring']) {
            switch (this.frequencyDetails[0].option) {
                case 'Weekly':
                    this.weekValue = [];
                    const daysSelect: any = Object.entries(
                        this.addServiceForm.controls['weekDayFormControl'].value
                    );

                    daysSelect.forEach((x: any, i: number) => {
                        if (x.includes(true)) {
                            this.weekValue.push(i);
                        }
                    });
                    dataToSend['recurring_value'] = this.weekValue;
                    break;
                case 'Quarterly':
                case 'Yearly':
                case 'halfyearly':
                case 'Monthly':
                    const currentDate: any = await this.utils.setCurrentDateTime(this.addServiceForm.value.recurringDateFormControl);
                    dataToSend['recurring_value'] = [
                        currentDate?.getTime()
                    ];
                    break;
                case 'Custom':
                    dataToSend['recurring_value'] = [
                        this.addServiceForm.value['onDayFormControl'],
                    ];
                    break;
                default:
                    break;
            }
        }
        let serviceID: any = [];
        const selectedmembers: any = [];
        const selectedclients: any = [];
        const client_details: any = [];
        await this.addServiceForm.value?.subTask?.forEach((ele: any) => {
            const memberID: any = [];
            ele?.member?.forEach((x: any) => {
                memberID.push(x);
            });
            selectedmembers.push(memberID);
        });

        serviceID = [];
        await this.addServiceForm.value.subTask.forEach((ele: any) => {
            serviceID = ele.client.id;
            selectedclients.push([serviceID]);
        });

        for (let index = 0; index < selectedmembers.length; index++) {
            if (selectedmembers[index][0]) {
                await client_details.push({
                    selectedClient: selectedclients[index],
                    selectedmembers: selectedmembers[index],
                });
            }
        }
        if (client_details.length > 0) {
            dataToSend['client_details'] = client_details;
        }
        this.apiService.showLoading();
        let apiEndPoint;
        if (this.data?.id) {
            if (dataToSend && dataToSend.due_date !== undefined) {
                delete dataToSend.due_date;
            }
            if (dataToSend && 'recurring_frequency' in dataToSend) {
                delete dataToSend.recurring_frequency;
            }
            if (dataToSend && dataToSend.hasOwnProperty('recurring_value')) {
                delete dataToSend.recurring_value;
            }
            dataToSend['updated_at'] = this.epochNow;
            dataToSend['updated_by'] = this.userDetail.userId;
            apiEndPoint = this.serviceManagementService.updateService(
                dataToSend,
                this.data.id
            );
        } else {
            dataToSend['created_at'] = this.epochNow;
            apiEndPoint = this.serviceManagementService.addNewService(dataToSend);
        }
        this.apiService.hideLoading();
        (await apiEndPoint).subscribe((res) => {
            if (res[0] != 400) {
                this.ToastService.success(res[0].msg);
            }
        });
        const data = { is_recurring: dataToSend['is_recurring'] };
        this.serviceManagementService.setSearchParamData(data);
        this.dialogRef.close({
            event: 'close',
            time: this.epochNow,
            data: { ...dataToSend },
        });
    }
    highlight(val: any) {
        this.toHighlight = val;
        return this.serviceCreatorName.filter(
            (serviceCreatorName) =>
                serviceCreatorName.name.toLowerCase().indexOf(val.toLowerCase()) === 0
        );
    }

    changeDateYYMMDD(date: any) {
        const newDate = new Date(date * 1000);
        return `${newDate.getFullYear()}-${newDate.getMonth() + 1
            }-${newDate.getDate()}`;
    }
    Set_Cre_Name(val: any) {
        this.addServiceForm.get('creatorNameFormControl').setValue(val);
        const name = this.serviceCreatorName.find(
            (member: any) => member.id == val
        ).name;
        this.addServiceForm.controls['creatorNameFormControl'].markAsDirty();

        this.creatorname?.setValue(name);
    }

    setStep(index: number) {
        this.step = index;
    }

    changeSelection(event: any, j: number, _data: any) {
        const searchId = this.filteredProviders[j]?.map((item: any) => {
            return item.id;
        });
        const newMember = JSON.parse(JSON.stringify(this.total_servicesMember));
        for (const member of newMember) {
            if (newMember.indexOf(member) === j) {
                for (const item of member) {
                    const isSelected = event.includes(item.id);
                    const isSearchIdIncluded = searchId.includes(item.id);
                    if (isSelected) {
                        item['selected'] = true;
                    } else {
                        item['selected'] = !isSearchIdIncluded;
                    }                    
                }
            }
        }
        this.total_servicesMember = newMember;
        const selectedData = this.total_servicesMember[j]?.filter(
            (member: any) => member.selected === true
        );
        this.selectedMembers[j] = Object.assign([], selectedData);

        this.filteredProviders = Object.assign([], this.total_servicesMember);
        const myForm = (<FormArray>this.addServiceForm.get('subTask')).at(j);
        myForm.patchValue({
            member: this.selectedMembers[j]?.map((member: any) => member.id),
        });
        this.addServiceForm.markAsDirty();
        this.checkForSelectAll(j);
    }

    async selectServiceMember(event:any,form_index:number,data:any){
        const newMember = JSON.parse(JSON.stringify(this.total_servicesMember));
        newMember?.forEach((ele:any,index:number)=>{
            if(form_index === index){
                ele?.forEach((member:any)=>{
                    if(member.id===data.id){
                        if(event.checked){
                            member.selected = true;
                        }else{
                            member.selected = false;
                        }
                    }
                })
            }
        });
        this.total_servicesMember = newMember;
        
        const selectedData = this.total_servicesMember[form_index]?.filter(
            (member: any) => member.selected === true
        );
        this.selectedMembers[form_index] = Object.assign([], selectedData);

        this.filteredProviders = Object.assign([], this.total_servicesMember);
        const myForm = (<FormArray>this.addServiceForm.get('subTask')).at(form_index);
        myForm.patchValue({
            member: this.selectedMembers[form_index]?.map((member: any) => member.id),
        });

        this.addServiceForm.markAsDirty();
        this.checkForSelectAll(form_index)
        
        await this.onInputChange({target:{value:this.toHighlightclient}}, form_index);
        
    }

    getClientInfo(index:number){
        const myForm = (<FormArray>this.addServiceForm.get('subTask')).at(index);
        return myForm.get('client').value ? true : false;
    }

    checkForSelectAll(index: number) {
        setTimeout(() => {
            this.allSelected[index] = this.filteredProviders[index]?.every((t: any) => t.selected);
        }, 100);
    }

    addMultipleService() {
        this.toHighlightclient = '';
        this.emails.push(
            this.formBuilder.group({
                member: [{ value: [], disabled: true }],
                client: [''],
            })
        );

        const resArr = Object.assign([], this.member);
        resArr.forEach((element: any) => {
            element['selected'] = false;
        });
        this.total_servicesMember.push(resArr);
        this.filteredProviders = Object.assign([], this.total_servicesMember);
        this.isShowAddMultile = this.serviceList?.length !== this.emails?.value?.length;
    }

    async getMemberList() {
        (
            await this.clientService.getMemberListOfService(
                this.data?.isOpenFromTaskModule
                    ? this.data?.encryptedCompanyId
                    : this.encryptCompanyId
            )
        ).subscribe((res: any) => {
            this.member = [];
            this.member = res.map((ele: any) => {
                return {
                    name: ele.first_name + ' ' + ele.last_name,
                    id: ele.id,
                    profile_image: ele.profile_image,
                };
            });
        });
        this.emails = this.addServiceForm.controls['subTask'] as FormArray;
    }

    get subTask() {
        return this.addServiceForm.get('subTask') as FormArray;
    }



    displayFn(user?: any): string | undefined {
        return user ? user.name : undefined;
    }

    setReporting(event: any, val: any, i?: number) {
        this.toHighlightclient = '';
        const myForm = (<FormArray>this.addServiceForm.get('subTask')).at(i);
        const name = this.serviceList.find((member: any) => member.id == val).name;
        const id = this.serviceList.find((member: any) => member.id == val).id;
        if (event?.checked) {
            myForm.get('member').enable();
            myForm.get('member').setValidators(Validators.required);
            myForm.get('client').setValue({ name: name, id: id });
            this.searchSelection.push(val)
        } else {
            myForm.get('member').disable();
            myForm.get('member').setValidators(null);
            myForm.get('member').setValue([]);
            myForm.get('client').setValue('');
            const newMember = JSON.parse(JSON.stringify(this.total_servicesMember));
            newMember?.forEach((ele:any,index:number)=>{
                if(i === index){
                    ele?.forEach((member:any)=>{
                        member.selected = false;
                    });
                }
            });
            this.total_servicesMember = newMember;
            this.filteredProviders = Object.assign([], this.total_servicesMember);
            const selectedData:any[] = this.total_servicesMember[i]?.filter(
                (member: any) => member.selected === true
            );
            this.selectedMembers[i] = Object.assign([], selectedData);
            this.allSelected[i] = this.total_servicesMember[i]?.every((t: any) => t.selected);
        }
        this.isAllCheck = this.addServiceForm.value.subTask.every(
            (client: any) => client.client != ''
        );
        myForm.get('client').markAsDirty();
        myForm.get('member').markAsDirty();
        myForm.get('member').updateValueAndValidity();
    }

    onToppingRemoved(topping: any, i: number) {
        const toppings = this.addServiceForm.value.subTask[i].member as string[];
        this.removeFirst(toppings, topping, i);
        this.total_servicesMember[i].forEach((element: any) => {
            if (element.id == topping) {
                element['selected'] = false;
            }
        });
        const myForm = (<FormArray>this.addServiceForm.get('subTask')).at(i);
        myForm.patchValue({ member: toppings });
        this.addServiceForm.markAsDirty();
        this.checkForSelectAll(i);
    }

    private removeFirst<T>(array: T[], toRemove: T, index1: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
            this.selectedMembers[index1].splice(index, 1);
        }
    }

    resetInput(index: number): void {
        const inputElement = this.multiUserSearchInputs.toArray()[index].nativeElement;
        inputElement.value = '';
      }

    async selectAll(i: number) {
        const selectedMembers:any[] = this.filteredProviders[i]?.map((user:any)=> user.id);
        const newMember = JSON.parse(JSON.stringify(this.total_servicesMember));
        newMember?.forEach((ele:any,index:number)=>{
            if(i === index){
                ele?.forEach((member:any)=>{
                    if(selectedMembers.includes(member.id)){
                        member.selected = this.allSelected[i];
                    }
                });
            }
        });

        this.total_servicesMember = newMember;
        this.filteredProviders = Object.assign([], this.total_servicesMember);
        const selectedData = this.total_servicesMember[i].filter(
            (member: any) => member.selected === true
        );
        this.selectedMembers[i] = Object.assign([], selectedData);
        const myForm = (<FormArray>this.addServiceForm.get('subTask')).at(i);
        myForm.patchValue({
            member: this.selectedMembers[i]?.map((member: any) => member.id),
        });
        await this.onInputChange({target:{value:this.toHighlightclient}}, i);
        await this.checkForSelectAll(i)
    }

    onInputChange(event: any, i: number) {
        const searchInput = event.target.value.toLowerCase();
        this.toHighlightclient = searchInput;
        this.filteredProviders[i] = this.total_servicesMember[i].filter(
            (element: any) => element.name.toLowerCase().trim().includes(searchInput)
        );
        if (event.data == '') {
            this.allSelected[i] = [];
        }
        const selectedData = this.total_servicesMember[i].filter(
            (member: any) => member.selected === true
        );
        this.selectedMembers[i] = Object.assign([], selectedData);
        this.addServiceForm.value.subTask[i].member = this.selectedMembers[i]?.map(
            (member: any) => member.id
        );
        this.checkForSelectAll(i);
        return this.addServiceForm.value.subTask[i].member;
    }

    async deleteRow(index: number) {
        if (index !== -1) {
            this.emails.removeAt(index);
        }
        this.addServiceForm.markAsDirty();

        this.isShowAddMultile = this.serviceList?.length !== this.emails?.value?.length;

        this.selectedMembers[index] = [];
        this.allSelected[index] = false;
        this.toHighlightclient = '';
    }

    async getClientDetails(search?: string) {
        this.clientDetailsArr = [];
        (
            await this.clientService.getClientListWithoutCustomField(
                this.encryptCompanyId, this.page, 50, search
            )
        ).subscribe(async (res: any) => {
            if (res?.data?.length) {
                res.data.forEach((element: any) => {
                    const dataObj = {
                        name: element.client_name,
                        company_name: element.company_name,
                        email: element.email,
                        profile: element.profile_image,
                        id: element.id,
                    };
                    this.addMultipleService();
                    this.serviceList.push(dataObj);
                });
                let uniqueObjects = new Set();
                let uniqueDataArray = this.serviceList.filter(obj => {
                    let stringifiedObj = JSON.stringify(obj);
                    if (!uniqueObjects.has(stringifiedObj)) {
                        uniqueObjects.add(stringifiedObj);
                        return true;
                    }
                    return false;
                });
                this.clientDetailsArr = uniqueDataArray;
                this.originalList = uniqueDataArray;
                this.clientCount = res.count
                if (this.data?.client) {
                    this.isAllCheck = this.data.client.length === this.serviceList.length;
                    await this.data.client.forEach(async (x: any) => {
                        const selectedMembers: any = [];
                        const selectedClient: any = [];
                        await this.serviceList.forEach(async (y: any, i: number) => {
                            if (x.client_details.id == y.id) {
                                selectedClient.push(y);
                                this.setReporting({ checked: true }, x.client_details.id, i);
                            }
                        });
                        this.member.forEach(async (y: any) => {
                            await x.members_details.forEach((z: any) => {
                                if (z.id == y.id) {
                                    const indexOfService: any = this.addServiceForm.get('subTask')?.value?.findIndex((item: any) => item?.client && item?.client?.id === x?.client_details?.id);
                                    selectedMembers.push(y.id);
                                    this.changeSelection(selectedMembers, indexOfService, null);
                                }
                            });
                        });
                        this.memberCopy = this.member;
                    });
                }

                for (let j = 0; j <= this.data?.client?.length; j++) {
                    const newMember = JSON.parse(JSON.stringify(this.member));
                    for (const element of newMember) {
                        if (
                            this.isArrayInclude(this.data?.client[j]?.members_details, element.id)
                        ) {
                            element['selected'] = true;
                        } else {
                            element['selected'] = false;
                        }
                    }
                    this.total_servicesMember.push(newMember);
                    this.filteredProviders = Object.assign([], this.total_servicesMember);
                    this.checkForSelectAll(j);
                }
            }
        });
    }

    clienthighlight(val: any) {
        this.toHighlightclient = val;
        return this.originalList.filter((item: any) =>
            item?.name?.toLowerCase().includes(val?.toLowerCase())
        );
    }

    applyFilter(e: any) {
        this.searchText = (e.target as HTMLInputElement).value.toLowerCase();
    }

    selectAllClient(e: any) {
        const myForm = <FormArray>this.addServiceForm.get('subTask');
        myForm.controls.forEach((_control) => {
            this.serviceList.forEach(async (y: any, index: any) => {
                this.setReporting({ checked: e.checked }, y.id, index);
            });
        });
    }

    onInputChangeName() {
        this.addServiceForm.get('serviceNameFormControl').markAsTouched();
        this.addServiceForm.updateValueAndValidity();
    }

    checkDueDate(number: any) {
        if (number && !this.data?.id) {
            this.minDate = this.today;
            this.minDate = this.addDays(this.minDate, number);
        } else {
            this.minDate = this.today;
        }
    }

    addDays(date: any, days: any) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    async handleScroll(event: Event): Promise<void> {
        const element = event.target as HTMLElement;
        const scrollPosition = element.scrollTop;
        const viewHeight = element.clientHeight;
        const contentHeight = element.scrollHeight;
        const isAtBottom = scrollPosition + viewHeight >= contentHeight - 150;
        if (isAtBottom) {
            this.apiService.showLoading();
            if (this.clientCount > (this.page * 50)) {
                this.page++;
                await this.getClientDetails(this.inputData);
            }
            this.apiService.hideLoading();
        }
    }
    onEnter(value: any) {
        if (/\S/.test(value)) {
            this.page = 1;
            this.serviceList.length = 0;
            let searchText;
            searchText = value?.replace(/\s+(?=\S)|(?<=\S)\s+/g, "").toLowerCase(); //NOSONAR
            this.getClientDetails(searchText)
        }
        else {
            let searchText;
            searchText = value?.replace(/\s+(?=\S)|(?<=\S)\s+/g, ""); //NOSONAR
            if (searchText.length == 0) {
                this.page = 1;
                this.serviceList.length = 0;
                this.getClientDetails()
            }
        }
    }
    searchClient(event: any, search: string) {
        this.enterPressed = true;
        event.preventDefault();
        this.onEnter(search.toLowerCase());
        this.inputData = search.toLowerCase();
    }
    onButtonClickSearchClient(value: string) {
        this.inputData = value.toLowerCase();
        if (!this.enterPressed) {
            this.onEnter(value);
        }
        this.enterPressed = false;
    }
}
