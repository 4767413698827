<form
  [formGroup]="timeLogForm"
  class="add-time-log-model rt-add-time-log-model"
>
  <mat-dialog-content>
    <div class="mb-4">
      <div class="col-12">
        <h2 class="page-title mb-2">{{ "ADD_TIME_LOG" | translate }}</h2>
      </div>
      <div class="add-time-log-body">
        <label class="inner-label me-2" *ngIf="dialogData.type == 'timeLog'">
          {{ "SELECT_TYPE_BY" | translate }}</label
        >
        <div
          class="col-md-6 d-flex align-items-center mt-2 mb-3"
          *ngIf="dialogData.type == 'timeLog'"
          [ngClass]="{ 'edit-task-disable': dialogData?.isEditTask }"
        >
          <mat-radio-group
            aria-label="Select an option"
            formControlName="logType"
            (change)="logTypeChange($event.value)"
          >
            <mat-radio-button
              class="c-radio-button me-2"
              [disableRipple]="true"
              value="task"
            >
              {{ "TASK" | translate }}
            </mat-radio-button>
            <mat-radio-button
              class="c-radio-button me-2"
              [disableRipple]="true"
              value="idle"
            >
              {{ "IDLE_TIME" | translate }}
            </mat-radio-button>
            <mat-radio-button
              class="c-radio-button"
              [disableRipple]="true"
              value="others"
            >
              {{ "OTHERS" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="col-12 mb-1"
          *ngIf="
            dialogData.type == 'timeLog' &&
            timeLogForm.controls['logType'].value == 'task'
          "
        >
          <div class="d-flex align-items-center">
            <a
              [matMenuTriggerFor]="projectMenu"
              class="d-flex align-items-center anchor-link font-weight-medium form-feild-select-custom"
              #elementRefDiv
            >
              <div class="project-icons">
                <img alt="project-name" src="/assets/images/projectname.svg" />
              </div>
              <span
                *ngIf="!selectedProjectId.length"
                style="margin-left: 15px"
                >{{ "SELECT_PROJECT" | translate }}</span
              >
              <app-mat-chip-preview
                [selectedMembers]="projectMatPreview"
                listArrName="timeLogProject"
              ></app-mat-chip-preview>
            </a>
          </div>
          <mat-menu
            #projectMenu="matMenu"
            class="multi-select-chip-menu custom-menu-list custom-project-popup dynamicwidth-popup"
            (closed)="search('', projectList, projectListData, 'project')"
          >
            <ng-template matMenuContent>
              <div [style.width]="dynamicWidth + 'px'">
                <input
                  *ngIf="projectList.length !== 0"
                  (click)="$event.stopPropagation()"
                  type="text"
                  autocomplete="off"
                  placeholder="Write here"
                  class="form-control c-s-input custom-input border-0 w-100 m-w-100"
                  (input)="
                    search($event, projectList, projectListData, 'project')
                  "
                />
                <div class="scroll-fix">
                  <app-check-box-common
                    *ngIf="projectListData"
                    [memberListData]="projectListData"
                    [listName]=""
                    [isShowSelectAll]="false"
                    [isSingleSelect]="true"
                    [isDisable]="false"
                    (onSelectCheckBox)="projectSelection($event)"
                  >
                  </app-check-box-common>
                </div>
                <div
                  class="text-center"
                  *ngIf="
                    projectList.length !== 0 && projectListData.length === 0
                  "
                >
                  {{ "NO_DATA_FOUND" | translate }}
                </div>
                <div class="text-center" *ngIf="projectList.length === 0">
                  {{ "NO_DATA_AVAILABLE" | translate }}
                </div>
              </div>
            </ng-template>
          </mat-menu>
        </div>
        <div
          class="col-12 mb-1 mt-3"
          *ngIf="
            dialogData.type == 'timeLog' &&
            timeLogForm.controls['logType'].value == 'task'
          "
        >
          <div class="d-flex align-items-center">
            <mat-form-field
              class="example-chip-list custom-input-field w-100"
              appearance="standard"
            >
              <div class="task-icons">
                <img alt="task-icon" src="/assets/images/task-icon.svg" />
              </div>
              <input
                matInput
                placeholder="Task name"
                aria-label="Country"
                (ngModelChange)="highlight($event)"
                formControlName="taskControl"
                [matAutocomplete]="auto"
                class="task-field"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let ele of allTasks"
                  [value]="ele.task_name"
                >
                  <div
                    class="d-flex justify-content"
                    (click)="selectedTask(ele._id)"
                  >
                    <span
                      [innerHTML]="ele?.task_name | highlight : toHighlight"
                    ></span>
                  </div>
                </mat-option>
                <mat-option disabled *ngIf="allTasks?.length === 0">
                  {{ "NO_DATA_AVAILABLE" | translate }}
                </mat-option>
              </mat-autocomplete>
              <mat-error
                *ngIf="timeLogForm.controls['taskControl'].hasError('required')"
              >
                {{ "TASK_NAME_REQUIRED" | translate }}</mat-error
              >
              <mat-error
                *ngIf="
                  timeLogForm.controls['taskControl'].hasError(
                    'invalidReporting'
                  )
                "
              >
                {{ "Please select valid Task name" }}</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 mb-1">
          <mat-form-field
            appearance="standard"
            class="custom-input-field w-100"
          >
            <mat-datepicker-toggle
              matPrefix
              [for]="startpicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startpicker></mat-datepicker>
            <input
              matInput
              [matDatepicker]="startpicker"
              placeholder="Select Date"
              [ngClass]="{ 'edit-task-disable': dialogData?.isEditTask }"
              formControlName="timeLogDate"
              [readonly]="true"
              (click)="startpicker.open()"
              (dateChange)="selectDate($event)"
              [required]="false"
              [min]="minDate"
              [max]="maxDate"
            />
            <mat-error
              *ngIf="timeLogForm.controls['timeLogDate'].hasError('required')"
            >
              {{ "SELECT_DATE" | translate }}</mat-error
            >
          </mat-form-field>
        </div>
        <div class="row">
          <div class="time-title">
            <h4>Time</h4>
          </div>
          <div
            class="col-6"
            [ngClass]="{ 'edit-task-disable': dialogData?.isEditTask }"
            *ngIf="
              loggedUserConfig?.user_config == 'both' ||
              loggedUserConfig?.user_config == 'start_end'
            "
          >
            <mat-radio-group
              aria-label="Select an option"
              formControlName="configType"
              (change)="tabChanged($event.value)"
            >
              <mat-radio-button
                class="c-radio-button me-2"
                [disableRipple]="true"
                value="timeFrame"
                *ngIf="
                  loggedUserConfig?.user_config == 'both' ||
                  loggedUserConfig?.user_config == 'start_end'
                "
              >
                time frame
              </mat-radio-button>
              <mat-radio-button
                class="c-radio-button"
                [disableRipple]="true"
                value="noOfHours"
                *ngIf="
                  loggedUserConfig?.user_config == 'both' ||
                  loggedUserConfig?.user_config == 'actual_hours'
                "
              >
                No. of Hrs
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-6">
            <div
              class="start-end-time"
              *ngIf="timeLogForm.controls['configType'].value == 'timeFrame'"
            >
              <div
                class="row p-0 w-100"
                [ngClass]="{ 'edit-task-disable': dialogData?.isEditTask }"
              >
                <div class="col-6 p-0" (click)="handleButtonClick()">
                  <mat-form-field
                    appearance="outline"
                    class="mat-time-pciker w-100"
                  >
                    <mat-label>START TIME</mat-label>
                    <input
                      type="time"
                      matInput
                      name="week"
                      formControlName="startFormControl"
                      (ngModelChange)="setTime()"
                      #startButton
                    />
                  </mat-form-field>
                </div>
                <div class="col-6 p-0">
                  <mat-form-field
                    appearance="outline"
                    class="mat-time-pciker w-100"
                  >
                    <mat-label>{{ "END_TIME" | translate }}</mat-label>
                    <input
                      type="time"
                      matInput
                      name="week"
                      formControlName="endFormControl"
                      (ngModelChange)="setEndTime()"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div
              class=""
              *ngIf="timeLogForm.controls['configType'].value == 'noOfHours'"
              [ngClass]="{ 'edit-task-disable': dialogData?.isEditTask }"
            >
              <div class="select-custom-time">
                <mat-form-field
                  appearance="fill"
                  class="select-custom-time-field"
                >
                  <img alt="time-icon" src="/assets/images/time-icons.svg" />
                  <input
                    type="number"
                    matInput
                    formControlName="hoursControl"
                    [min]="0"
                    [max]="24"
                    placeholder="HH"
                    (ngModelChange)="onHourChange()"
                    class="pl-4"
                  />
                </mat-form-field>
                <mat-form-field appearance="fill" class="ml-3">
                  <img alt="time-icon" src="/assets/images/time-icons.svg" />
                  <input
                    type="number"
                    matInput
                    formControlName="minutesControl"
                    [min]="0"
                    [max]="maxMinutes"
                    placeholder="mm"
                    (ngModelChange)="onMinuteChange()"
                    class="pl-4"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 mat-form-textarea mat-form-textarea-only"
          [ngClass]="{ 'edit-task-disable': dialogData?.isEditTask }"
        >
          <mat-form-field
            appearance="standard"
            class="custom-input-field w-100"
          >
            <textarea
              rows="1"
              class="project-field-m pending-approval-field"
              [ngStyle]="{ 'font-size': '14px' }"
              matInput
              formControlName="noteFormcontrol"
              placeholder="Notes"
              maxlength="1000"
              (input)="adjustTextareaHeight()"
              [(ngModel)]="content"
              #textareaRef
            ></textarea>
            <div class="project-icons">
              <img alt="discussion-icon" src="/assets/images/dicussionicon.svg" />
            </div>
          </mat-form-field>
        </div>
      </div>
      <div class="mx-0 model-footer-ctm">
        <div class="model-footer-ctm-top pt-4">
          <div class="row">
            <div class="col-6">
              <div class="total-time">
                <p>{{ "TOTAL_HRS" | translate }}</p>
                <h4>{{ totalHours | hourFormat }}</h4>
              </div>
            </div>
            <div class="col-6 text-end">
              <button
                class="btn btn--bordered me-2"
                mat-button
                mat-flat-button
                type="button"
                (click)="onClosed()"
              >
                {{ "CANCEl_BUTTON" | translate }}
              </button>
              <button
                class="btn btn--primary"
                [ngClass]="{ 'edit-task-disable': dialogData?.isEditTask }"
                type="submit"
                mat-button
                mat-flat-button
                (click)="submit()"
                [disabled]="!timeLogForm.valid || !selectedDate"
              >
                <span>{{ "ADD_Entry" | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</form>
