<h1 mat-dialog-title>{{ "TIMER_NOTE_TITLE" | translate }}</h1>
<mat-dialog-content>
  <mat-form-field appearance="standard" class="custom-input-field w-100">
    <mat-label>{{ "NOTES" | translate }}</mat-label>
    <textarea
      rows="1"
      class="project-field-m"
      [ngStyle]="{ 'font-size': '14px' }"
      matInput
      [(ngModel)]="notes"
      placeholder="Type here..."
    ></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    class="cancel-co-member-modal"
    mat-raised-button
    mat-dialog-close
    tabindex="-1"
    (click)="onCancelClick()"
  >
    {{ "CANCEL" | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="onConfirmClick()"
    tabindex="1"
  >
    {{ "YES" | translate }}
  </button>
</mat-dialog-actions>
