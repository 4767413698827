<ul
  class="list-unstyled mb-0 py-0 px-0 checklist-member-team"
  (click)="$event.stopPropagation()"
  *ngIf="memberListData"
>
  <li
    *ngIf="isShowSelectAll"
    [ngClass]="{ 'disable-class': isDisable == true }"
  >
    <mat-checkbox
      class="me-2 custom-checkbox fw-bold text-uppercase only-name"
      (change)="selectAllMembers($event)"
      [(ngModel)]="allCheck"
      >{{ listName }}</mat-checkbox
    >
  </li>
  <li
    [ngClass]="
      isDisable && isGroup
        ? 'group-disable-class'
        : isDisable
        ? 'disable-class'
        : ''
    "
    *ngFor="let ele of memberListData"
    class="d-flex align-items-center"
  >
    <mat-checkbox
      [(ngModel)]="ele.isCheck"
      (ngModelChange)="selectCheckBox(ele)"
      class="me-2 custom-checkbox"
    >
      <img alt="profile"
        *ngIf="ele.profile_image && ele.profile_image?.charAt(0) != '#'"
        src="{{ ele.profile_image }}"
        class="img-avatar last-img-cls-margin-decrease"
      />
      <div
        *ngIf="ele.profile_image && ele.profile_image?.charAt(0) == '#'"
        class="me-2 user-short-name"
        [ngStyle]="{ 'background-color': ele.profile_image }"
      >
        {{ utils.forShortName(ele.name) }}
      </div>
      <div class="trim-info text-capitalize">{{ ele.name }}</div>
    </mat-checkbox>
    <button
      mat-menu-item
      [matMenuTriggerFor]="menu"
      #menuTrigger="matMenuTrigger"
      (click)="openGroupMembers(ele)"
      [ngClass]="isGroup && isTask ? '' : 'd-none'"
      class="assignee-menu"
    ></button>
  </li>
  <mat-menu #menu="matMenu" class="custom-menu-list">
    <div class="set-cntnt">
      <div class="input-with-check custom-input-check-cls">
        <input
          type="text"
          placeholder="Write here"
          autocomplete="off"
          class="form-control c-s-input custom-input border-0 w-100 m-w-100"
          (click)="$event.stopPropagation()"
          [(ngModel)]="searchTerm"
          (input)="updateFilter()"
        />
      </div>
      <p class="grp-name">{{ groupName }}</p>
      <mat-checkbox
        class="me-2 custom-checkbox"
        *ngFor="let groupUser of groupUserList"
        [(ngModel)]="groupUser.isCheck"
        (ngModelChange)="assigneeSelect(groupUser)"
        (click)="$event.stopPropagation()"
        [disabled]="disabled"
      >
        <img
          alt="profile image"
          *ngIf="
            groupUser.profile_image && groupUser.profile_image?.charAt(0) != '#'
          "
          src="{{ groupUser.profile_image }}"
          class="img-avatar last-img-cls-margin-decrease"
        />
        <div
          *ngIf="
            groupUser.profile_image && groupUser.profile_image?.charAt(0) == '#'
          "
          class="me-2 user-short-name"
          [ngStyle]="{ 'background-color': groupUser.profile_image }"
        >
          {{ utils.forShortName(groupUser.first_name + groupUser.last_name) }}
        </div>
        <div class="trim-info text-capitalize">
          {{ groupUser.first_name }} {{ groupUser.last_name }}
        </div>
      </mat-checkbox>
    </div>
  </mat-menu>
</ul>
