import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TaskEmitService {
    private searchData: Subject<any> = new Subject<any>();
    searchData$: Observable<any> = this.searchData.asObservable();

    private statusData: Subject<any> = new Subject<any>();
    statusData$: Observable<any> = this.statusData.asObservable();

    public toggleData: Subject<any> = new Subject<any>();
    toggleData$: Observable<any> = this.toggleData.asObservable();

    private importTask: Subject<any> = new Subject<any>();
    importTask$: Observable<any> = this.importTask.asObservable();

    private statusDataKanban: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    statusDataKanban$: Observable<any> = this.statusDataKanban.asObservable();

    private sortData: BehaviorSubject<any> = new BehaviorSubject<any>('');
    sortData$: Observable<any> = this.sortData.asObservable();

    private filterData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    filterData$: Observable<any> = this.filterData.asObservable();

    private dateData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dateData$: Observable<any> = this.dateData.asObservable();

    private kanbanStatus: Subject<any> = new Subject<any>();
    kanbanStatus$: Observable<any> = this.kanbanStatus.asObservable();

    private usersList: Subject<any> = new Subject<any>();
    usersList$: Observable<any> = this.usersList.asObservable();

    private readByusersList: Subject<any> = new Subject<any>();
    readByusersList$: Observable<any> = this.readByusersList.asObservable();

    private recurrData: Subject<any> = new Subject<any>();
    recurrData$: Observable<any> = this.recurrData.asObservable();

    private tabChange: Subject<any> = new Subject<any>();
    tabChange$: Observable<any> = this.tabChange.asObservable();

    setSearchData(searchData: any) {
        this.searchData.next(searchData);
    }

    setUserList(usersList: any) {
        this.usersList.next(usersList);
    }
    readByUserList(readByusersList: any) {
        this.readByusersList.next(readByusersList);
    }

    setStatusData(status: any, type: any, statusName: any) {
        this.statusData.next({ status, type, statusName });
    }

    setToggleData(toggleData: any) {
        this.toggleData.next(toggleData);
    }

    afterImportTasks(data: any) {
        this.importTask.next(data);
    }

    setStatusKanban(statusDataKanban: any) {
        this.statusDataKanban.next(statusDataKanban);
    }

    setSortingData(sortData: any) {
        this.sortData.next(sortData);
    }

    setFilterData(filterData: any) {
        this.filterData.next(filterData);
    }

    setDateFilterData(dateData: any) {
        this.dateData.next(dateData);
    }

    setKanbanStatus(statusData: any) {
        this.kanbanStatus.next(statusData);
    }
    setrecurrData(recurrData: any) {
        this.recurrData.next(recurrData);
    }

    setTabChange(tab: any) {
        this.tabChange.next(tab);
    }
}
