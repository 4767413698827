import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class CheckListTemplateService {
    constructor(private apiService: ApiService) {}

    res: any;
    apiEndPoint = NAVIGATE_ROUTES;
    clientId: any;

    async setCheckTemplateSetting(data: any) {
        const url = `${this.apiEndPoint.CHECKLIST_SETTING}/check-list-manage`;
        await this.apiService.post(url, data).then((resData) => {});
        return of(this.res);
    }

    async getPermissionMemberList(
        companyId: any,
        userId: any,
        checkListId: any
    ): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.CHECKLIST_SETTING}/check-list-permissions?companyId=${companyId}&userId=${userId}&templateId=${checkListId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData.result;
        });
        return of(this.res);
    }

    async getCheckTemplateList(
        companyId: any,
        userId: any
    ): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.CHECKLIST_SETTING}?companyId=${companyId}&userId=${userId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData.result;
        });
        return of(this.res);
    }

    async addNewCheckList(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = this.apiEndPoint.CHECKLIST_SETTING;
        await this.apiService.post(url, data).then((resData) => {
            this.res.push(resData);
        });
        return of(this.res);
    }

    async editCheckList(
        data: any,
        userId: any,
        checklist_id: any
    ): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.CHECKLIST_SETTING}?id=${checklist_id}&userId=${userId}`;
        await this.apiService.put(url, data).then((resData) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async deleteCheckList(checklist_id: any, userId: any) {
        this.res = [];
        const url = `${this.apiEndPoint.CHECKLIST_SETTING}?id=${checklist_id}&userId=${userId}`;
        await this.apiService.delete(url).then((data) => {
            this.res = data;
        });
        return of(this.res);
    }

    async addPermission(data: any, checklist_id: any) {
        this.res = [];
        const url = `${this.apiEndPoint.CHECKLIST_SETTING}/permissions?id=${checklist_id}`;
        await this.apiService.post(url, data).then((resData) => {
            this.res.push(resData);
        });
        return of(this.res);
    }
}
