<div class="mub-confirm-dialog position-relative">

  <div
    *ngIf="showCloseButton"
    class="cross-ics-member pstn-absolute right--14 top--24 mt-2 z-ex"
    (click)="onClose()"
  >
    <img
      alt="cross icon"
      src="../../../../assets/images/crossblack.svg"
      width="24px"
      height="24px"
    />
  </div>
  <h1 mat-dialog-title *ngIf="title">{{ title }}</h1>
  <mat-dialog-content>
    <p [innerHTML]="message"></p>
    <p *ngIf="note !== ''">
      <span [innerHTML]="note"></span>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      class="cancel-co-member-modal"
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
      (click)="onCancelClick()"
    >
      {{ cancelButtonText }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="onConfirmClick()"
      tabindex="1"
    >
      {{ confirmButtonText }}
    </button>
  </mat-dialog-actions>
</div>
