import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userStateByUserid',
})
export class UserStateByUseridPipe implements PipeTransform {
    transform(id: any, onlineArr: any): any {
        let activeState = '';
        if (onlineArr.length && id) {
            onlineArr?.forEach((ele: any) => {
                if (ele?.user_id === id && ele.user_state === 'online') {
                    return (activeState = 'online');
                }
            });
        }
        return activeState;
    }
}
