import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientServiceService } from '../../services/organization/client-service.service';
import { UserDetailsService } from '../../services/user-details.service';
import { UtilsService } from '../../services/utils-service/utils.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TasksService } from '../../services/task-service/tasks.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shared-client',
  templateUrl: './shared-client.component.html',
  styleUrls: ['./shared-client.component.scss']
})
export class SharedClientComponent implements OnInit {
  selectedCompanyId: any;
  clientList: any[] = [];
  page: number = 1;
  clientCount: number;
  inputData: string = '';
  @Input() selection_type: string;
  @Output() selectedClient = new EventEmitter<any>();
  @Input() resetCount: boolean;
  clientSelect: any[] = [];
  clientArr: any[] = [];
  subscription: Subscription;
  constructor(
    private clientService: ClientServiceService,
    private userDetailService: UserDetailsService,
    public utils: UtilsService,
    private taskService: TasksService
  ) {

  }

  async ngOnInit() {
    this.selectedCompanyId =
      await this.userDetailService.getSelectedCompanyId();
    this.loadData();
    if (this.resetCount) {
      this.clientArr.length = 0;
      this.loadData();
    }
    this.subscription = this.taskService.removeIdFromPreview.subscribe(
      (data: any) => {
        if (data && data.listArrName=='client') {
          this.clientSelect.length=0;
          this.clientArr.length=0;
        }
      })
  }
  async loadData(searchData?: any) {
    (await this.clientService.getClientList(this.selectedCompanyId, this.page, 25, searchData)).subscribe(async (res: any) => {
      res.data.forEach((element: any) => {
        this.clientList.push(element);
      });
      this.clientCount = res.count
    });
  }
  handleScroll(event: Event): void {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop;
    const viewHeight = element.clientHeight;
    const contentHeight = element.scrollHeight;

    const isAtBottom = scrollPosition + viewHeight >= contentHeight;
    if (isAtBottom) {
      if (this.clientCount > (this.page * 25)) {
        this.page++;
        this.loadData(this.inputData);
      }
    }
  }
  onEnter() {
    if (/\S/.test(this.inputData)) {
      this.page = 1;
      this.clientList.length = 0;
      let searchText;
      searchText = this.inputData.replace(/\s+(?=\S)|(?<=\S)\s+/g, "");
      this.loadData(searchText)
    }
    else {
      let searchText;
      searchText = this.inputData.replace(/\s+(?=\S)|(?<=\S)\s+/g, "");
      if (searchText.length == 0) {
        this.page = 1;
        this.clientList.length = 0;
        this.loadData()
      }
    }
  }
  selectedClientData(clientObj: any, event: MatCheckboxChange) {
    if (this.selection_type == 'single') {
      if (event.checked) {
        this.clientSelect.length = 0;
        this.clientArr.length = 0;
        let postarr: any = { id: clientObj.id, name: clientObj.client_name, isCheck: true, profile_image: clientObj.profile_image };
        this.clientSelect.push(postarr);
        this.clientArr.push(clientObj.id)
        this.selectedClient.emit(this.clientSelect);
      }
      else {
        this.clientSelect.length = 0;
        this.clientArr.length = 0;
        this.selectedClient.emit(this.clientSelect);
      }
    }
    else {
      if (!this.clientArr.includes(clientObj)) {
        let postarr: any = { id: clientObj.id, name: clientObj.client_name, isCheck: true, profile_image: clientObj.profile_image };
        this.clientSelect.push(postarr);
        this.clientArr.push(clientObj.id)
        this.selectedClient.emit(this.clientSelect);
      }
    }
  }
}
