<div class="notification-main-popover-ctm h-auto">
  <div class="notification-title">
    <h4>Notification</h4>
    <p [routerLink]="['notification']">View All</p>
  </div>
</div>
<div class="notification-main-popover-ctm main-custom-notification-globally-cls" (click)="$event.stopPropagation()">
  <div class="notification-list-wrp" *ngFor="let list of notificationLists | keyvalue : originalOrder">
    <div class="notification-date-time" *ngIf="!checkDate(list.key)">
      {{ list.key | date : "EEEE dd MMM ' YY" }}
    </div>
    <div class="notification-date-time" *ngIf="checkDate(list.key)">
      {{ "Today, " }}{{ list.key | date : "EEEE dd MMM ' YY" }}
    </div>
    <div class="notification-list" *ngFor="let data of $any(list.value)" (click)="redirect(data)">
      <div class="inside-list-notification">
        <span class="outer-hover main-span user--img">
          <div (mouseenter)="onHoverInAssignee(data?.sender_id)">
            <img [src]="data.profile_image" alt="" *ngIf="data?.profile_image?.charAt(0) !== '#'" />
            <div *ngIf="data?.profile_image?.charAt(0) == '#'" class="user-short-name mr-0" [ngStyle]="{
              'background-color': data?.profile_image
            }">
              {{ utils.forShortName(data.sender_name) }}
            </div>
          </div>
          <span class="online--status" [ngClass]="{
              'default-active-status':
                (data.sender_id | userStateByUserid : onlineArr) == 'online'
            }"></span>
          <app-member-hover class="hoverimg math_ui-onHover-sub-block main-app-member app-six" [ngClass]="{
              'assignee_div-only':
                data?.profile_image?.charAt(0) == '#' ||
                !data?.profile_image ||
                data?.profile_image?.charAt(0) != 'h'
            }" [ngStyle]="{
              position: 'absolute',
              background: '#fff',
              'z-index': '3'
            }" [isOnlineByUserId]="true" [itemList]="hoverObject"></app-member-hover>
        </span>

        <div class="notification-des-section">
          <div class="notification-des" [innerHTML]="data.description | safeHtml"></div>
          <div class="notification-time">
            {{ data.createdAt | date : "h:mm a" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>