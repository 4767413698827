import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
    transform(text: string, search: any): string {
        const regex = new RegExp(search, 'gi');
        const match = text.match(regex);
        if (!match) {
            return text;
        }
        return search ? text.replace(regex, (match) => `<b>${match}</b>`) : text;
    }
}
