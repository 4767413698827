import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { UserDetailsService } from '../user-details.service';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ManagementServiceService {
    apiUrl: string = environment.userUrl;
    userId: any;
    res: any[] = [];
    apiEndPoint = NAVIGATE_ROUTES;
    responseData: any;
    fileSize = 5000000;

    constructor(
    public userService: UserDetailsService,
    private apiService: ApiService,
    private ToastrService: ToastrService
    ) {
        this.userId = this.userService.userId;
    }

    async updateAdminDetail(val: any): Promise<Observable<any>> {
        await this.apiService
            .put(this.apiEndPoint.UPDATE_PROFILE, val)
            .then((data) => {
                this.res = [];
                this.res.push(data);
            });
        return of(this.res).pipe(delay(1000));
    }

    async getAdminDetails(userId: number): Promise<Observable<any>> {
        this.responseData = new Subject();
        const res = await this.apiService.get(
            this.apiEndPoint.GET_USER_DETAILS + userId
        );
        this.responseData = res;
        return this.responseData;
    }

    async updateProfilePic(event: any): Promise<any> {
        return new Promise((resolve, _reject) => {
            if (event.target.files[0].size < this.fileSize) {
                if (
                    event.target.files[0].type === 'image/png' ||
          event.target.files[0].type === 'image/jpg' ||
          event.target.files[0].type === 'image/jpeg'
                ) {
                    const reader = new FileReader();
                    reader.onload = (_e) => {
                        const fileData = {
                            result: reader.result,
                            file: event.target.files[0],
                        };
                        resolve(fileData);
                    };
                    reader.readAsDataURL(event.target.files[0]);
                } else {
                    this.ToastrService.warning(
                        'Only png, jpeg and jpg image type allow to upload'
                    );
                    resolve(false);
                }
            } else {
                this.ToastrService.warning('Please select image file less then 5 MB');
                resolve(false);
            }
        });
    }
}
