import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, debounceTime, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class ProjectManagementService {
    queryURL = '';
    apiEndPoint = NAVIGATE_ROUTES;
    selectedProjectInfo = new BehaviorSubject<any>('');
    searchParamData = new BehaviorSubject<any>('');
    private removeuser: BehaviorSubject<any> = new BehaviorSubject<any>('');
    removeuser$: Observable<any> = this.removeuser.asObservable();
    private hideuser: BehaviorSubject<any> = new BehaviorSubject<any>('');
    hideuser$: Observable<any> = this.hideuser.asObservable();
    $debouncingSubject = new Subject();
    $searchObserver = this.$debouncingSubject
        .asObservable()
        .pipe(debounceTime(1000));
    $projectAccess = new Subject();

    constructor(private apiService: ApiService) {}

    setSearchParamData(searchData: any) {
        this.searchParamData.next(searchData);
    }
    removeUserData(removeuser:any){
        this.removeuser.next(removeuser);
    }
    hideUserData(hideuser:any){
        this.hideuser.next(hideuser);
    }
    async projectDataEvent(data: any) {
        this.selectedProjectInfo.next(data);
    }

    // get default view
    async getProjectDefaultView(projectId?: any) {
        this.queryURL = '';
        if (projectId) this.queryURL = `?project_id=${projectId}`;
        const response: any = await this.apiService.get(
            `${this.apiEndPoint.GET_PROJECT_DEFAULT_VIEW}${this.queryURL}`
        );
        return of(response?.data);
    }

    // set default view
    async setProjectDefaultView(data: any) {
        const response: any = await this.apiService.post(
            `${this.apiEndPoint.GET_PROJECT_DEFAULT_VIEW}`,
            data
        );
        return of(response?.data);
    }

    // delete project
    async deleteProject(projectId: any) {
        const response: any = await this.apiService.delete(
            `${this.apiEndPoint.PROJECT}?id=${projectId}`
        );
        return of(response);
    }

    // project listing
    async getProjectList(data: any) {
        this.queryURL = `page=${data.page}&limit=${data.limit}&type=${data.type}&filter=${data.filter}&start_date=${data.startDate}&end_date=${data.endDate}`;
        if (data.project_name)
            this.queryURL += `&searchString=${data.project_name}`;
        if (data.filterStartDate || data.filterEndDate)
            this.queryURL += `&filterStartDate=${data.filterStartDate}&filterEndDate=${data.filterEndDate}`;
        const response: any = await this.apiService.get(
            `${this.apiEndPoint.PROJECT}?${this.queryURL}`
        );
        return of(response);
    }

    // create a new project
    async createProject(data: any) {
        const response: any = await this.apiService.post(
            this.apiEndPoint.PROJECT,
            data
        );
        return of(response);
    }

    //update project
    async updateProject(data: any, id: string) {
        const response: any = await this.apiService.put(
            `${this.apiEndPoint.PROJECT}?id=${id}`,
            data
        );
        return of(response);
    }

    // get project details by id
    async getProjectDetails(id: any) {
        const response: any = await this.apiService.get(
            `${this.apiEndPoint.PROJECT_DETAIL_BY_ID}?project_id=${id}`
        );
        return of(response?.data);
    }

    // project favorite/ unfavorite
    async projectFavoriteUnfavorite(data: any) {
        const response: any = await this.apiService.post(
            this.apiEndPoint.PROJECT_FAVORITE,
            data
        );
        return of(response);
    }

    // delete project
    async deleteCoMember(data: any) {
        const response: any = await this.apiService.put(
            `${this.apiEndPoint.PROJECT_REMOVE_MEMBERS}`,
            data
        );
        return of(response);
    }

    // role access control
    async getCompanyAccesses() {
        const response: any = await this.apiService.get(
            this.apiEndPoint.GET_COMPANY_ACCESSES
        );
        return of(response.data);
    }

    // get project chart data
    async getProjectChartData(projectId: any) {
        const response: any = await this.apiService.get(
            `${this.apiEndPoint.GET_PROJECT_CHART_DATA}?project_id=${projectId}`
        );
        return of(response);
    }
}
