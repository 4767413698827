import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { UserDetailsService } from '../user-details.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root',
})
export class ManageOrganizationService {
    apiEndPoint = NAVIGATE_ROUTES;
    res: any[] = [];
    userId: any;
    companyId: any;

    constructor(
    public userService: UserDetailsService,
    private apiService: ApiService
    ) {
        this.userId = this.userService.userId;
    }

    async getCompanyList(userId: any): Promise<Observable<any>> {
        userId = CryptoJS.AES.encrypt(userId?.toString().trim(), '').toString();
        const url = `${this.apiEndPoint.COMPANY}?id=${userId}&type=owner`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getCollaborotList(userId: any): Promise<Observable<any>> {
        userId = CryptoJS.AES.encrypt(userId?.toString().trim(), '').toString();
        const url = `${this.apiEndPoint.COMPANY}?id=${userId}&type=other`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getCompanyCountList(userId: any): Promise<Observable<any>> {
        userId = CryptoJS.AES.encrypt(userId?.toString().trim(), '').toString();
        const url = `${this.apiEndPoint.COMPANY_COUNT}?userId=${userId}&type=owner`;

        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getCollaborotCountList(userId: any): Promise<Observable<any>> {
        userId = CryptoJS.AES.encrypt(userId?.toString().trim(), '').toString();
        const url = `${this.apiEndPoint.COMPANY_COUNT}?userId=${userId}&type=other`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getAllCompanyList(userId: any): Promise<Observable<any>> {
        userId = CryptoJS.AES.encrypt(userId?.toString().trim(), '').toString();
        const url = `${this.apiEndPoint.COMPANY}?id=${userId}&type=all`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getAllUsersCompany(userId: any): Promise<Observable<any>> {
        userId = CryptoJS.AES.encrypt(userId?.toString().trim(), '').toString();
        const url = `${this.apiEndPoint.COMPANY}/company-list?id=${userId}&device=web`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }
}
