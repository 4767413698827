<script src="https://www.google.com/jsapi"></script>

<form>
  <div class="row">
    <div class="col-md-12" *ngIf="documentPreviewTypes.document">
      <ngx-doc-viewer
        [url]="previewMatadata?.url"
        viewer="google"
        style="width: 100%; height: 93vh"
        (loaded)="documentLoaded()"
      >
      </ngx-doc-viewer>
    </div>
    <div
      class="col-md-12"
      *ngIf="documentPreviewTypes.pdf && previewMatadata.url"
    >
    </div>
    <div class="col-md-12" *ngIf="documentPreviewTypes.image">
      <img
        class="image-preview"
        [src]="previewMatadata?.url"
        [alt]="previewMatadata?.name"
      />
    </div>
    <div class="col-md-12" *ngIf="documentPreviewTypes.video">
      <video
        [poster]="documentPreviewTypes.thumbnaill"
        controls
        class="image-preview"
        preload="metadata"
      >
        <track
          label="English"
          kind="captions"
          srclang="en"
          src="resources/myvideo-en.vtt"
          default
        />
        <track
          label="Deutsch"
          kind="captions"
          srclang="de"
          src="resources/myvideo-de.vtt"
        />
        <track
          label="Español"
          kind="captions"
          srclang="es"
          src="resources/myvideo-es.vtt"
        />
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="not-support" *ngIf="documentPreviewTypes.notpriview">
      Preview for this document is not supported.
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button
        class="btn btn--primary"
        type="submit"
        mat-button
        mat-flat-button
        (click)="handelClosedDialogBox()"
      >
        Close
      </button>
    </div>
  </div>
</form>
