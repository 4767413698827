import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// form
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HomeModule } from './pages/home/home.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { QuillModule } from 'ngx-quill';
import { RequestInterceptorInterceptor } from './shared/interceptor/request-interceptor.interceptor';
import { MatMenuModule } from '@angular/material/menu';
import { StoreModule } from '@ngrx/store';
import { stateReducer } from './shared/ngrx/reducer/user-state.reducer';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { DatabaseModule } from '@angular/fire/database';
import { AuthModule } from '@angular/fire/auth';
import { MessagingService } from './shared/services/messaging-service/messaging.service';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { taskReducer } from './shared/ngrx/reducer/task-state.reducer';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] as any }, { background: [] as any }], // dropdown with defaults from theme
    [{ font: [] as any }],
    [{ align: [] as any }],
    ['clean'], // remove formatting button
];

export function playerFactory() {
    return player;
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        OverlayModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-left',
        }),
        LottieModule.forRoot({ player: playerFactory }),
        MatDatepickerModule,
        MatNativeDateModule,
        QuillModule.forRoot({
            modules: {
                toolbar: toolbarOptions,
            },
            format: 'object',
        }),
        MatMenuModule,
        StoreModule.forRoot({ userState: stateReducer, taskState: taskReducer }),
        DatabaseModule,
        AuthModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        ServiceWorkerModule.register('firebase-messaging-sw.js', {
            enabled: false,
        }),
        NgxDocViewerModule,
    ],
    providers: [
        MessagingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    exports: [TranslateModule, HomeModule],
})
export class AppModule {}
export class I18nModule {
    constructor(translate: TranslateService) {
        translate.addLangs(['en']);
        translate.setDefaultLang('en');
    }
}
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
