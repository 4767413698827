import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/storage-service/local-storage.service';
import * as CryptoJS from 'crypto-js';
import { PersonalizedUserEmit } from 'src/app/shared/services/personalized-user-service/personalized-user-emit.service';
import { ApiService } from 'src/app/shared/services/api-service/api.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { AddNotesComponent } from '../../notes/add-notes/add-notes.component';
@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
    company_id: any;
    public emitPersonalizedUser: PersonalizedUserEmit;
    constructor(
    private activatedRoute: ActivatedRoute,
    private localStorage: LocalStorageService,
    private router: Router,
    public injector: Injector,
    public apiService: ApiService,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    ) {
        this.emitPersonalizedUser =
      injector.get<PersonalizedUserEmit>(PersonalizedUserEmit);
        this.activatedRoute.queryParams.subscribe(async (params) => {
            const company = CryptoJS.AES.encrypt(
                atob(params['company_id']).toString().trim(),
                ''
            ).toString();
            await this.localStorage.setDataInIndexedDB('selectedCompanyId', company);
            await this.localStorage.setDataInIndexedDB(
                'navigateSelectedCompanyId',
                company
            );
            await this.localStorage.setData('navigateSelected', true);
            this.company_id = await this.localStorage.getDataFromIndexedDB(
                'selectedCompanyId'
            );
            const module = params['module'];
            this.emitPersonalizedUser.getCompanySwitch(company);
            this.apiService.showLoading();
            switch (module) {
            case 'task':
                const task_id = params['id'];
                localStorage.setData('task_id', task_id);
                setTimeout(() => {
                    this.emitPersonalizedUser.getCompanySwitch(company);
                    this.router.navigate(['/task/list']);
                }, 1000);
                break;
            case 'project':
                this.router.navigate(['/project']);
                break;
            case 'timesheet':
                this.router.navigate(['/time-sheet']);
                break;
            case 'discussion':
                this.router.navigate(['/discussion']);
                break;
            case 'note':
                this.router.navigate(['/notes']);
                this.notificationService.setShareNoteTab(1);
                this.dialog.open(AddNotesComponent, {
                    panelClass: [
                        'notes-modal',
                        'notes-modal-md',
                        'main-custom-notes-module',
                    ],
                    data: { noteId: params['id'] },
                    autoFocus: false,
                });
                break;    
            }
        });
    }

    ngOnInit() {
        // Intialize the component
    }
    click() {
        this.router.navigate(['/dashboard']);
    }
}
