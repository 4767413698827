import { EventEmitter, Input, Output, Component, OnInit } from '@angular/core';
import { UserDetailsService } from '../services/user-details.service';
import { TasksService } from '../services/task-service/tasks.service';
import { ApiService } from '../services/api-service/api.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-client-menu',
    templateUrl: './client-menu.component.html',
    styleUrls: ['./client-menu.component.scss'],
})
export class ClientMenuComponent implements OnInit {
    clientMatPreview: any = [];
    originalClientListData: any = [];
    selectedCompanyId: any;
    clientListData: any = [];
    selectedClient: any = [];
    clientListName = 'Client';
    subscription: Subscription;
    taskState: any;
    @Output('getSelectedClientEvent') getSelectedClientEvent = new EventEmitter();
    @Input() resetData: boolean;
    constructor(
        public userDetailService: UserDetailsService,
        private taskService: TasksService,
        private apiService: ApiService,
        private store: Store<{ taskState: TaskState }>
    ) {
    }

    async ngOnInit() {
        this.store.select('taskState').subscribe((ele: any) => {
            if (!ele.taskState) return;
            this.taskState = ele?.taskState;
        });
        this.selectedCompanyId =
            await this.userDetailService.getSelectedCompanyId();
          this.getclientList();
        this.removedIdFromMat();
        this.subscription = this.apiService.resetClientList.subscribe(
            (_res: any) => {
                this.originalClientListData.filter((ele: any) => {
                    ele.isCheck = false;
                });
                this.selectedClient = this.clientListData
                    .filter((clientData: any) => {
                        if (clientData.isCheck) {
                            return clientData.id;
                        }
                    })
                    .map((ele: any) => ele.id);
                this.onSelectClientCheckBox(this.selectedClient);
            }
        );
    }
    async getclientList() {
        const client = this.taskState?.reportConfig?.client_details;
        if (client) {
            client?.forEach((ele: any) => {
                const dataObj = {
                    id: ele.id,
                    name: ele.client_name + ' - ' + ele.company_name,
                    isCheck: false,
                    profile_image: ele.profile_image,
                };
                this.clientListData.push(dataObj);
            });
            this.originalClientListData = this.clientListData;
        }
    }
    async onSelectClientCheckBox(selectedId: any) {
        this.selectedClient = selectedId;
        this.setMatPreview();
    }
    setMatPreview() {
        this.clientMatPreview = this.originalClientListData.filter(
            (ele: any) => ele.isCheck
        );
        this.getSelectedClientEvent.emit(this.clientMatPreview);
    }

    search(event: any, originalArr: any, listArr: any) {
        const searchInput = event.target.value.toLowerCase();
        listArr = originalArr.filter((ele: any) => {
            const name = ele.name.toLowerCase();
            return name.includes(searchInput);
        });
        this.clientListData = [...listArr];
        for (const element of this.originalClientListData) {
            this.clientListData.forEach((data: any) => {
                if (element.id === data.id && element.isCheck) {
                    data.isCheck = true;
                }
            });
        }
    }
    removedIdFromMat() {
        this.subscription = this.taskService.removeIdFromPreview.subscribe(
            async (data: any) => {
                this.clientListData = await this.originalClientListData.filter(
                    (ele: any) => {
                        if (data.removeId === ele.id) {
                            ele.isCheck = false;
                        }
                        return ele;
                    }
                );
                this.selectedClient = await this.clientListData
                    .filter((clientData: any) => {
                        if (clientData.isCheck) {
                            return clientData.id;
                        }
                    })
                    .map((ele: any) => ele.id);
                this.onSelectClientCheckBox(this.selectedClient);
            }
        );
    }
    receiveClientData(data: any) {
        data.forEach((element: any) => {
            if (!this.selectedClient.includes(element.id)) {
                this.clientMatPreview.push(element);
                this.selectedClient.push(element.id)
                this.getSelectedClientEvent.emit(this.clientMatPreview);
            }
        });
    }
    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
