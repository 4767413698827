import { Injectable } from '@angular/core';
import { DateInput, Diff, NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { BehaviorSubject, of } from 'rxjs';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class TimeSheetService {
    queryURL = '';
    apiEndPoint = NAVIGATE_ROUTES;
    changeFilterValue = new BehaviorSubject<any>('');
    changeUserFilterValue = new BehaviorSubject<any>('');

    changeTabValue = new BehaviorSubject<any>('');
    getExportValue = new BehaviorSubject<any>('');
    refreshLogData = new BehaviorSubject<any>('');
    changeCustimiseValue = new BehaviorSubject<any>('');
    changeCustimiseValueClient = new BehaviorSubject<any>('');

    constructor(private _apiService: ApiService) { }

    async getChangeFilterValue(date: any) {
        this.changeFilterValue.next(date);
    }

    async getChangeUserFilterValue(data: any) {
        this.changeUserFilterValue.next(data);
    }
    async getChangeCustimiseValue(date: any) {
        this.changeCustimiseValue.next(date);
    }
    async getChangeCustimiseValueClient(date: any) {
        this.changeCustimiseValueClient.next(date);
    }

    async getChangeTabValue(date: any) {
        this.changeTabValue.next(date);
    }

    async exportTabValue(type: any) {
        this.getExportValue.next(type);
    }

    async refreshTimelogData(type: any) {
        this.refreshLogData.next(type);
    }

    //Add time log data in edit task
    async addTimeLogEntry(data: any) {
        const response: any = await this._apiService.postError(
            this.apiEndPoint.ON_OFF_TIMER,
            data
        );
        return of(response);
    }

    // get time log data for tasks screen
    async getTimeLog(data: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.GET_TIME_LOG,
            data
        );
        return of(response);
    }

    // get all tasks in edit task for filter task-subtask list
    async getAllTasks(taskId: any) {
        this.queryURL = `task_id=${taskId}`;
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_TASK_LIST_FOR_LOG}?${this.queryURL}`
        );
        return of(response);
    }

    // get all task list or project tasks
    async getAllTasksForLog(projectId?: any) {
        this.queryURL = `projectId=${projectId}`;
        const apiEndpoint = projectId
            ? `${this.apiEndPoint.GET_PROJECT_ALL_TASKS}?${this.queryURL}`
            : `${this.apiEndPoint.GET_PROJECT_ALL_TASKS}`;
        const response: any = await this._apiService.get(apiEndpoint);
        return of(response);
    }

    // get time sheet timer
    async getTimer(task_id: any, current_time: any) {
        this.queryURL = `task_id=${task_id}&current_time=${current_time}`;
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_TIMESHEET_TIMER}?${this.queryURL}`
        );
        return of(response);
    }

    // delete tome log data
    async deleteTimeLog(time_sheet_id: any) {
        this.queryURL = `time_sheet_id=${time_sheet_id}`;
        const response: any = await this._apiService.delete(
            `${this.apiEndPoint.DELETE_TIMELOG_DATA}?${this.queryURL}`
        );
        return of(response);
    }

    // get time log data for timesheet screen
    async getTimeLogTimeSheet(data: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.GET_TIMESHEET_TIMELOG_REPORT,
            data
        );
        return of(response);
    }

    // get time log data for timesheet screen user wise
    async getTimeSheetPendingApproval(data: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.GET_TIMESHEET_PENDING_APPROVAL_REPORT,
            data
        );
        return of(response);
    }

    // get timesheet comments by date and User Id
    async getCommentById(user_id: any, date: any) {
        this.queryURL = `user_id=${user_id}&date=${date}`;
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_COMMENT_BY_DATE}?${this.queryURL}`
        );
        return of(response);
    }

    // submit timesheet
    async timeSheetSubmit(
        data: any,
        pendingApproval: boolean,
        bulkApproveReject: boolean
    ) {
        let apiEndpoint: string;

        if (pendingApproval) {
            apiEndpoint = this.apiEndPoint.APPROVE_REJECT_TIMESHEET;
        } else if (bulkApproveReject) {
            apiEndpoint = this.apiEndPoint.APPROVE_REJECT_ALL_TIMESHEET;
        } else {
            apiEndpoint = this.apiEndPoint.SUBMIT_TIMESHEET;
        }

        const response: any = await this._apiService.post(apiEndpoint, data);

        return of(response);
    }

    // get time log details when multiple entry for same task
    async getDetailLog(taskId: any) {
        this.queryURL = `taskId=${taskId}`;
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_DETAILS_TIMELOG}?${this.queryURL}`
        );
        return of(response);
    }

    async getEstimatedTime(data: any) {
        const apiEndpoint = this.apiEndPoint.GET_ESTIMATED_TIME;
        const response: any = await this._apiService.post(apiEndpoint, data);
        return of(response);
    }

    addRemoveDateTime(date: DateInput, value: number | string, momentType: Diff) {
        const momentDate = moment.utc(date);
        momentDate.add(Number(value), momentType);
        return momentDate.toString();
    }
}
