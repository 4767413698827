import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MODULE_NAME, NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class CustomFieldTaskClientService {
    apiEndPoint = NAVIGATE_ROUTES;
    res: any;
    constructor(private apiService: ApiService) {}

    async getCustomFieldList(
        moduleId: any,
        companyId: any,
        isCustomize: any,
        moduleName: any
    ): Promise<Observable<any>> {
        this.res = [];
        let url = `${this.apiEndPoint.TASK_CLIENT_CUSTOM_FIELD}?moduleId=${moduleId}&companyId=${companyId}`;
        if (moduleName === MODULE_NAME.TASK) {
            url += `&customize=${isCustomize}`;
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async getModuleDetail(): Promise<Observable<any>> {
        this.res = [];
        const url = this.apiEndPoint.MODULE_DETAIL;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async addCustomFieldConfig(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = this.apiEndPoint.CUSTOM_FIELD_CONFIG;
        await this.apiService.post(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async getTimesheetFieldConfig(): Promise<Observable<any>> {
        this.res = [];
        const url = this.apiEndPoint.TIMESHEET_FIELD_CONFIG;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async getCustomField(
        userId: any,
        companyId: any,
        module: any
    ): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.CUSTOM_FIELD_CONFIG}?userId=${userId}&companyId=${companyId}&module=${module}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }
}
