import { Component, Input, OnInit } from '@angular/core';
import { ManagementServiceService } from '../../services/management-service/management.service';
import { UserDetailsService } from '../../services/user-details.service';
import { UtilsService } from '../../services/utils-service/utils.service';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-document-icon',
    templateUrl: './document-icon.component.html',
    styleUrls: ['./document-icon.component.scss'],
})
export class DocumentIconComponent implements OnInit {
    epochNow!: any;
    getAdminDetail: any;
  @Input('file') file: any;
  @Input('attachmentData') attachmentData: any;
  memberListData: any;
  src = '';
  userId: any;
  profile: any;
  userDetailData: any;
  taskState: any;

  constructor(
    public managementService: ManagementServiceService,
    public userDetail: UserDetailsService,
    public utils: UtilsService,
    private store1: Store<{ taskState: TaskState }>
  ) {}

  async ngOnInit(): Promise<void> {
      this.getSrc();
      this.userId = await this.userDetail.userId;
      this.epochNow = new Date().getTime();
      await this.getUserProfile();
      this.getMemberList();
      if (this.file.length) {
          this.getSrc();
      }
  }

  async getSrc() {
      this.src = await this.utils.getDocumentIcon(this.file, false);
  }

  async getUserProfile() {
      this.userDetailData = await this.userDetail?.getLoginUserData();
  }

  getMemberList() {
      this.store1.select('taskState').subscribe((ele: any) => {
          if (!ele?.taskState) return;
          this.taskState = ele?.taskState;
      });
      this.memberListData = this.taskState?.reportConfig?.company_members;
      this.memberListData.filter((member: any) => {
          if (member.user_id == this.userDetailData.id) {
              this.profile = member?.profile_image;
          }
      });
  }
}
