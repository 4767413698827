import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../services/api-service/api.service';

@Component({
    selector: 'app-document-preview',
    templateUrl: './document-preview.component.html',
    styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent
implements OnInit, AfterViewInit, OnDestroy
{
    imageRegex = /\.(gif|jpe?g|tiff?jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
    documentRegex = /\.(xlsx|xlx|xls|docx|doc|pptx|ppt|csv)$/;
    videoPreview = /\.(mp4)$/;
    pdfRegex = /\.(pdf)$/;
    previewMatadata: any = {};
    documentPreviewTypes: any = {
        image: false,
        document: false,
        video: false,
        notpriview: false,
        pdf: false,
    };

    constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DocumentPreviewComponent>,
    private sanitizer: DomSanitizer,
    public apiService: ApiService
    ) {
        this.handleCheckDocumentTypes();
        if (this.documentPreviewTypes.image || this.documentPreviewTypes.video) {
            this.previewMatadata = { ...this.data };
        }
        if (this.documentPreviewTypes.pdf) {
            this.previewMatadata = { ...this.data };
            window.open(this.previewMatadata.url, '_blank');
            this.dialogRef.close();
        }
        if (this.documentPreviewTypes.document) {
            this.previewMatadata = { ...this.data };
            this.apiService.showLoading();
        }
    }

    ngOnInit(): void {
        // Intialize the component
    }

    handleCheckDocumentTypes() {
        if (this.imageRegex.test(this.data.docType)) {
            this.documentPreviewTypes.image = true;
        } else if (this.documentRegex.test(this.data.docType)) {
            this.documentPreviewTypes.document = true;
        } else if (this.videoPreview.test(this.data.docType)) {
            this.documentPreviewTypes.video = true;
        } else if (this.pdfRegex.test(this.data.docType)) {
            this.documentPreviewTypes.pdf = true;
        } else {
            this.documentPreviewTypes.notpriview = true;
        }
    }

    ngAfterViewInit(): void {
        // After intialize the component
    }

    handelClosedDialogBox() {
        this.dialogRef.close();
    }
    ngOnDestroy() {
        this.previewMatadata.length = 0;
    }
    documentLoaded(): void {
        this.apiService.hideLoading();
    }
}
