import { ApiService } from 'src/app/shared/services/api-service/api.service';
import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TasksService } from '../../services/task-service/tasks.service';
import { CustomFieldTaskClientService } from '../../services/custom-field-client-task/custom-field-task-client.service';
import { UserDetailsService } from '../../services/user-details.service';
import { CustomFieldService } from '../../services/organization-settings/custom-field.service';
import { MODULE_NAME } from '../../global/constants';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'app-client-info-preview',
    templateUrl: './client-info-preview.component.html',
    styleUrls: ['./client-info-preview.component.scss'],
})
export class ClientInfoPreviewComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ClientInfoPreviewComponent>,
        public taskService: TasksService,
        public clientCustomList: CustomFieldTaskClientService,
        private userDetailService: UserDetailsService,
        private customFieldService: CustomFieldService,
        public apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dialogRef.disableClose = true;
    }

    clientInfo: any;
    companyId: any;
    encryptCompanyId: any;
    moduleId: string;
    moduleName: string;
    isCustomize = 0;
    fields: any = [];
    countries: any = [];
    customFieldData: any = [];

    async ngOnInit() {
        this.companyId = await this.userDetailService.getSelectedCompanyId();
        this.encryptCompanyId = this.companyId;
        await this.getModuleData();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    async getClientDetails(clientId: any) {
        (await this.taskService.getClientDetail(clientId))
            .pipe(debounceTime(1500))
            .subscribe(async (resData: any) => {
                if (resData) {
                    this.clientInfo = {
                        name: resData?.client_name,
                        email: resData?.email,
                        gender: resData?.gender,
                        mobileNumber: resData?.mobile_number,
                        companyName: resData?.company_name,
                        address: resData?.company_address,
                        isd_code: resData?.isd_code,
                    };

                    const customData = resData?.client_custom_field_values;
                    const selectedVehicles = _.groupBy(customData, function (item) {
                        return item.field_id;
                    });
                    const mapData = _.map(selectedVehicles, function (data) {
                        return data;
                    });
                    await this.setCustomFieldData(mapData);
                }
            });
    }

    copyInfo(clientInfo: any) {
        const customData = this.getObjectByLabel(this.customFieldData);
        const clientData = {
            'Company Name': clientInfo?.companyName,
            'Client Name': clientInfo?.name,
            Email: clientInfo?.email ? clientInfo?.email : '-',
            Address: clientInfo?.address ? clientInfo?.address : '-',
            'Mobile Number': clientInfo?.mobileNumber
                ? '+' + clientInfo?.isd_code + ' ' + clientInfo?.mobileNumber
                : '-',
            Gender: clientInfo?.gender ? clientInfo?.gender : '-',
        };

        const finalData = Object.assign(clientData, customData);
        let infoText = 'Client information:\n';
        let otherText = 'Other:\n';
        for (const key in finalData) {
            if (
                key === 'Company Name' ||
                key === 'Client Name' ||
                key === 'Email' ||
                key === 'Address' ||
                key === 'Mobile Number' ||
                key === 'Gender'
            ) {
                infoText += `${key} : ${finalData[key]}\n`;
            } else {
                otherText += `${key} : ${finalData[key]}\n`;
            }
        }
        const plaintext = infoText + '\n' + otherText;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = plaintext;
        document.body.appendChild(selBox);
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.apiService.showSuccess('Text copied successfully.');
        this.dialogRef.close();
    }

    getObjectByLabel(arr: any[]): any {
        return arr.reduce((result, item) => {
            result[item.label] = item.value;
            return result;
        }, {});
    }

    async getModuleData() {
        (await this.clientCustomList.getModuleDetail()).subscribe(
            async (res: any) => {
                if (res) {
                    this.moduleId = res.result.find(
                        (moduleData: any) => moduleData.module_name === MODULE_NAME.CLIENT
                    ).id;
                    this.moduleName = res.result.find(
                        (moduleData: any) => moduleData.module_name === MODULE_NAME.CLIENT
                    ).module_name;
                    await this.getCustomField();
                }
            }
        );
    }

    async getCustomField() {
        (
            await this.clientCustomList.getCustomFieldList(
                this.moduleId,
                this.encryptCompanyId,
                this.isCustomize,
                this.moduleName
            )
        ).subscribe(async (resData: any) => {
            if (resData) {
                await this.getCountries();
                resData.result.forEach(async (element: any) => {
                    element.field['name'] = element.field.label.replace(/\s/g, '');
                    element.field['option'] = element.optionDetails;
                    this.fields.push(element.field);
                });
                await this.getClientDetails(this.data.clientId);
            }
        });
    }

    async getCountries() {
        (await this.customFieldService.getCurrencyWithCountries()).subscribe(
            (res: any) => {
                this.countries = res.result;
            }
        );
    }

    async setCustomFieldData(fieldData: any) {
        const customFieldData: any = [];
        let fieldValue: any = {};
        for (const data of fieldData) {
            const fieldID = this.fields.find(
                (field: any) => {
                    return field.id == data[0].field_id
                }
            );
            switch (fieldID.field_type) {
                case 'date': {
                    const date = new Date(parseInt(data[0]?.value));
                    fieldValue = {
                        label: fieldID.label,
                        value: data[0]?.value ? moment(date).format('DD-MM-YYYY') : '-',
                    };
                    customFieldData.push(fieldValue);
                    break;
                }
                case 'checklist': {
                    if (!fieldID?.is_single_select) {
                        let multiSelectValue = data;
                        multiSelectValue = multiSelectValue?.map((v: any) => {
                            return v.value;
                        });
                        fieldValue = {
                            label: fieldID.label,
                            value: multiSelectValue ? multiSelectValue.join(', ') : '-',
                        };
                        customFieldData.push(fieldValue);
                    } else {
                        fieldValue = {
                            label: fieldID.label,
                            value: data[0]?.value ? data[0]?.value : '-',
                        };
                        customFieldData.push(fieldValue);
                    }
                    break;
                }
                case 'currency': {
                    fieldValue = {
                        label: fieldID.label,
                        value: data[0]?.value ? data[0]?.value : '-',
                    };
                    customFieldData.push(fieldValue);
                    break;
                }
                default: {
                    fieldValue = {
                        label: fieldID.label,
                        value: data[0]?.value ? data[0]?.value : '-',
                    };
                    customFieldData.push(fieldValue);
                    break;
                }
            }
        }
        this.customFieldData = customFieldData;
    }

    closeModel() {
        this.dialogRef.close();
    }
}
