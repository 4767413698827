<div class="mub-add-service rt-add-service">
  <div class="accordion action-btn-h" id="accordionExample ">
    <div class="accordion-item">
      <form [formGroup]="addServiceForm">
        <mat-accordion>
          <mat-expansion-panel
            [disabled]="data?.isOpenFromTaskModule"
            [expanded]="step == 0"
            (opened)="setStep(0)"
            class="mat-elevation-z0"
          >
            <mat-expansion-panel-header>
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button accordian-wospc"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  *ngIf="!data?.isOpenFromTaskModule"
                >
                  <h2 class="page-title mb-0" *ngIf="addServiceButton">
                    {{ "ADD_SERVICE_BTN" | translate }}
                  </h2>
                  <h2 class="page-title mb-0 pb-2" *ngIf="!addServiceButton">
                    {{ "EDIT_SERVICE_BUTTON" | translate }}
                  </h2>
                </button>
                <h2
                  class="page-title mb-0"
                  *ngIf="addServiceButton && data?.isOpenFromTaskModule"
                >
                  {{ "ADD_SERVICE_BTN" | translate }}
                </h2>
              </h2>
            </mat-expansion-panel-header>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="row">
                  <div
                    class="col-md-12 col-sm-12 col-12 mt-2 order-md-0 order-sm-0 order-1"
                  >
                    <div class="row">
                      <div class="col-7">
                        <mat-form-field
                          appearance="standard"
                          class="custom-input-field w-100"
                        >
                          <mat-label>{{
                            "ADD_SERVICE_NAME" | translate
                          }}</mat-label>
                          <img
                            matPrefix
                            src="assets/images/filedoc.svg"
                            alt="icon"
                            draggable="false"
                          />
                          <input
                            class="capitalizeText"
                            matInput
                            placeholder=""
                            formControlName="serviceNameFormControl"
                            trim="blur"
                            appTitleCase
                            (keyup)="onInputChangeName()"
                          />
                        </mat-form-field>
                        <div
                          *ngIf="!data?.isOpenFromTaskModule"
                          class="d-flex justify-content-between border-bottom"
                        >
                          <div>
                            <p class="block-title mb-1">
                              {{ "RECURRING_SERVICE" | translate }}
                            </p>
                            <p class="cmn-txt-sm font-weight-midium mb-0">
                              {{ "RECURRING_SERVICE_DETAIL" | translate }}
                            </p>
                          </div>
                          <div class="">
                            <mat-slide-toggle
                              (change)="checkToggle()"
                              [disabled]="!addServiceButton"
                              class="ms-3"
                              formControlName="toggleControl"
                            >
                            </mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                      <div class="col-5">
                        <mat-form-field
                          class="example-chip-list custom-input-field w-100"
                          appearance="standard"
                        >
                          <mat-label>{{
                            "SERVICE_CREATOR_NAME" | translate
                          }}</mat-label>
                          <img
                            matPrefix
                            src="assets/icons/full_name.svg"
                            alt="icon"
                            draggable="false"
                          />

                          <input
                            matInput
                            placeholder="Service Creator Name"
                            aria-label="Country"
                            (ngModelChange)="highlight($event)"
                            [formControl]="creatorname"
                            [matAutocomplete]="auto"
                          />
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                              *ngFor="let ele of serviceCreatorName"
                              [value]="ele.name"
                            >
                              <div
                                class="d-flex align-items-center"
                                (click)="Set_Cre_Name(ele.id)"
                              >
                                <img
                                  alt="icon"
                                  draggable="false"
                                  *ngIf="
                                    ele.profile?.charAt(0) !== '#' &&
                                    ele.profile?.charAt(0) == 'h'
                                  "
                                  class="mx-2"
                                  style="
                                    width: 2rem;
                                    height: 2rem;
                                    border-radius: 100%;
                                  "
                                  src="{{ ele.profile }}"
                                />
                                <div
                                  *ngIf="
                                    ele.profile?.charAt(0) == '#' ||
                                    !ele.profile ||
                                    ele.profile?.charAt(0) != 'h'
                                  "
                                  class="user-short-name mr-0"
                                  [ngStyle]="{
                                    'background-color': ele?.profile_image
                                  }"
                                >
                                  {{ utils.forShortName(ele.name) }}
                                </div>
                                <span
                                  [innerHTML]="
                                    ele.name | highlight : toHighlight
                                  "
                                  style="margin-left: 0.7em"
                                ></span>
                              </div>
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                        <ng-container *ngIf="checked">
                          <mat-form-field
                            appearance="standard"
                            class="custom-input-field w-100 mui_date"
                          >
                            <mat-label>{{
                              "DUE_DATE_LABEL" | translate
                            }}</mat-label>
                            <img
                              matPrefix
                              src="assets/icons/calendericon.svg"
                              alt="icon"
                              draggable="false"
                            />
                            <div>
                              <input
                                matInput
                                formControlName="dueDateFormControl"
                                [ngClass]="
                                  !addServiceButton ? 'opacity-50' : ''
                                "
                                [readonly]="true"
                                [matDatepicker]="picker"
                                [min]="minDate"
                                (click)="picker.open()"
                                (dateChange)="setmaxDate()"
                                autocomplete="off"
                              />
                            </div>
                            <mat-datepicker
                              [ngClass]="!addServiceButton ? 'opacity-50' : ''"
                              [disabled]="!addServiceButton"
                              #picker
                            ></mat-datepicker>
                          </mat-form-field>
                        </ng-container>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-7">
                        <ng-container *ngIf="checked">
                          <div class="mt-2 mui_frequency">
                            <label class="inner-label w-100 mb-2">{{
                              "SELECT_FREQUENCY" | translate
                            }}</label>
                            <ul class="list-unstyled a-r-list border-bottom">
                              <li
                                *ngFor="
                                  let values of frequencyOptions;
                                  let i = index
                                "
                              >
                                <mat-checkbox
                                  class="example-margin c-checkbox-button"
                                  [ngClass]="
                                    !addServiceButton ? 'opacity-50' : ''
                                  "
                                  [disabled]="!addServiceButton"
                                  (change)="checkFrequency(values)"
                                  [checked]="selected === i"
                                  (change)="selected = i"
                                >
                                  {{ values.option | translate }}
                                </mat-checkbox>
                              </li>
                            </ul>
                          </div>
                        </ng-container>
                      </div>
                      <div class="col-xl-5">
                        <div
                          class="mt-2"
                          *ngIf="
                            frequencyDetails[0] &&
                            frequencyDetails[0].option == 'Weekly'
                          "
                        >
                          <label class="inner-label w-100 mb-2">{{
                            "SELECT_WEEK_DAYS" | translate
                          }}</label>
                          <ul class="list-unstyled list-inline">
                            <li
                              class="list-inline-item me-3"
                              *ngFor="
                                let days of weekDays | keyvalue;
                                let i1 = index
                              "
                            >
                              <mat-checkbox
                                class="c-text-checkbox"
                                [disabled]="!addServiceButton"
                                [ngClass]="
                                  !addServiceButton ? 'opacity-50' : ''
                                "
                                [checked]="weekDays[$any(days.key)]"
                                (change)="checkWeekDay2(days, $event)"
                              >
                                {{ weekDaysLable[i1] }}
                              </mat-checkbox>
                            </li>
                          </ul>
                          <div class="validation" *ngIf="count <= 0">
                            {{ "DAY_REQUIRED" | translate }}
                          </div>
                        </div>
                        <div
                          class="mt-2"
                          *ngIf="
                            frequencyDetails[0] &&
                            frequencyDetails[0].option == 'Monthly'
                          "
                        >

                          <mat-form-field
                            appearance="standard"
                            class="custom-input-field w-100"
                          >
                            <mat-label>Recurring Date</mat-label>
                            <img
                              matPrefix
                              src="assets/icons/calendericon.svg"
                              alt="icon"
                              draggable="false"
                            />
                            <input
                              matInput
                              [matDatepicker]="picker"
                              (click)="picker.open()"
                              [ngClass]="!addServiceButton ? 'opacity-50' : ''"
                              [readonly]="!addServiceButton"
                              [min]="minDate"
                              [max]="maxDate"
                              autocomplete="off"
                              formControlName="recurringDateFormControl"
                            />
                            <mat-datepicker
                              [ngClass]="!addServiceButton ? 'opacity-50' : ''"
                              [disabled]="!addServiceButton"
                              #picker
                            ></mat-datepicker>
                          </mat-form-field>
                          <mat-error
                            class="validation"
                            *ngIf="
                              addServiceForm.controls[
                                'recurringDateFormControl'
                              ]?.hasError('required') &&
                              addServiceForm.controls[
                                'recurringDateFormControl'
                              ].touched
                            "
                          >
                            {{ "DATE_REQUIRED" | translate }}
                          </mat-error>
                        </div>
                        <div
                          class="mt-2"
                          *ngIf="
                            (frequencyDetails[0] &&
                              frequencyDetails[0].option == 'Quarterly') ||
                            (frequencyDetails[0] &&
                              frequencyDetails[0].option == 'halfyearly') ||
                            (frequencyDetails[0] &&
                              frequencyDetails[0].option == 'Yearly')
                          "
                        >
                          <mat-form-field
                            appearance="standard"
                            class="custom-input-field w-100"
                          >
                            <mat-label>Recurring Date</mat-label>
                            <img
                              matPrefix
                              src="assets/icons/calendericon.svg"
                              alt="icon"
                              draggable="false"
                            />
                            <input
                              matInput
                              [matDatepicker]="picker"
                              (click)="picker.open()"
                              [ngClass]="!addServiceButton ? 'opacity-50' : ''"
                              [readonly]="!addServiceButton"
                              [min]="minDate"
                              [max]="maxDate"
                              autocomplete="off"
                              formControlName="recurringDateFormControl"
                            />
                            <mat-datepicker
                              [ngClass]="!addServiceButton ? 'opacity-50' : ''"
                              [disabled]="!addServiceButton"
                              #picker
                            ></mat-datepicker>
                          </mat-form-field>
                          <mat-error
                            class="validation"
                            *ngIf="
                              addServiceForm.controls[
                                'recurringDateFormControl'
                              ]?.hasError('required') &&
                              addServiceForm.controls[
                                'recurringDateFormControl'
                              ].touched
                            "
                          >
                            {{ "DATE_REQUIRED" | translate }}
                          </mat-error>
                        </div>
                        <div
                          class="mt-2 enterADay"
                          *ngIf="
                            frequencyDetails[0] &&
                            frequencyDetails[0].option == 'Custom'
                          "
                        >
                          <mat-form-field>
                            <mat-label>{{ "ENTER_DAY" | translate }}</mat-label>
                            <input
                              matInput
                              type="number"
                              [(ngModel)]="number"
                              (ngModelChange)="checkDueDate(number)"
                              [ngClass]="!addServiceButton ? 'opacity-50' : ''"
                              [readonly]="!addServiceButton"
                              formControlName="onDayFormControl"
                              placeholder=""
                            />
                            <mat-error
                              class="validation"
                              *ngIf="
                                addServiceForm.controls[
                                  'onDayFormControl'
                                ]?.hasError('required') &&
                                addServiceForm.controls['onDayFormControl']
                                  .touched
                              "
                            >
                              {{ "DAY_REQUIRED" | translate }}
                            </mat-error>
                            <mat-error
                              *ngIf="
                                addServiceForm.controls[
                                  'onDayFormControl'
                                ]?.hasError('min')
                              "
                            >
                              {{ "MIN_REQ_NO_1" | translate }}
                            </mat-error>
                            <mat-error
                              *ngIf="
                                addServiceForm.controls[
                                  'onDayFormControl'
                                ]?.hasError('max')
                              "
                            >
                              {{ "MAX_NO_10000" | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="another-expansion">
          <mat-accordion class="flex-grow-1" *ngIf="checked">
            <mat-expansion-panel
              [expanded]="step == 2"
              (opened)="setStep(2)"
              (closed)="setStep(0)"
              class="mat-elevation-z0 h-100 d-flex flex-column"
            >
              <mat-expansion-panel-header>
                <div class="row">
                  <div class="col-12">
                    <h2 *ngIf="addServiceButton" class="page-title mb-0">
                      {{ "ADD_CLIENT" | translate }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <h2 *ngIf="!addServiceButton" class="page-title mb-0">
                      {{ "UPDATE_CLIENT" | translate }}
                    </h2>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <ng-container>
                
              
                <div formArrayName="subTask" class="flex-grow-1">
                  
                  <ng-container>
                    <!-- Commented till we work on search based data patch
                       <div class="d-flex align-items-center">
                    <input type="text" #searchInputval (keydown.enter)="searchClient($event, searchInputval.value)" class="search-area" placeholder="Type here and press Enter or click 'Search' button to search"/>
                  <button (click)="onButtonClickSearchClient(searchInputval.value)" class="search-button">Search</button>
                </div> -->
                    <ul
                      class="list-unstyled mb-0 rt-add-services-content content"
                      (click)="$event.stopPropagation()"
                      #scrollContainer
                      (scroll)="handleScroll($event)"
                      style="overflow-y: auto;"
                    >
                      <li
                        [formGroupName]="i"
                        *ngFor="
                          let ele of serviceList
                            | filterData : searchText : 'name';
                          let i = index
                        "
                      >
                        <div class="row custom-row as__custom-row">
                          <div
                            class="col-lg-6 custom-coled align-self-center coled1"
                          >
                            <mat-checkbox
                              class="me-2 custom-checkbox"
                              (change)="setReporting($event, ele.id, i)"
                              formControlName="client"
                            >
                              <div>
                                <div class="d-flex align-items-center gap-2">
                                  <img
                                    *ngIf="
                                      ele.profile?.charAt(0) !== '#' &&
                                      ele.profile?.charAt(0) == 'h'
                                    "
                                    class="mx-2"
                                    alt="icon"
                                    draggable="false"
                                    style="
                                      width: 2rem;
                                      height: 2rem;
                                      border-radius: 100%;
                                    "
                                    src="{{ ele.profile }}"
                                  />
                                  <div
                                    *ngIf="
                                      ele.profile?.charAt(0) == '#' ||
                                      !ele.profile ||
                                      ele.profile?.charAt(0) != 'h'
                                    "
                                    class="user-short-name mr-0"
                                    [ngStyle]="{
                                      'background-color': ele.profile
                                    }"
                                  >
                                    {{ utils.forShortName(ele.name) }}
                                  </div>
                                  <span
                                    *ngIf="toHighlightclient != ''"
                                    [innerHTML]="
                                      ele?.name | highlight : toHighlightclient
                                    " class="trim-info"
                                  ></span>
                                  <span
                                    *ngIf="toHighlightclient === ''"
                                    [innerHTML]="ele?.name" class="trim-info"
                                  ></span>
                                </div>
                              </div>
                            </mat-checkbox>
                          </div>
                          <div class="col-lg-6 custom-coled align-self-center">
                            <div class="mui_add-client">
                              <span class="d-flex" mat-button [matMenuTriggerFor]="dotsMenu" 
                              [ngClass]="{
                                'disable-cls':!getClientInfo(i)
                              }"
                              [ngStyle]="{'cursor': 'pointer'}"
                              >
                                <span *ngIf="!selectedMembers[i]?.length">
                                  <img  _ngcontent-jbu-c269=""
                                        matprefix=""
                                        src="assets/icons/full_name.svg"
                                        alt="icon"
                                        draggable="false"
                                        class="ng-tns-c28-20"
                                      />
                                    Members
                                </span>
                                <mat-chip-list>
                                  <mat-chip
                                    *ngFor="
                                      let user of selectedMembers[i]
                                    "
                                    [removable]="true"
                                    (removed)="
                                      onToppingRemoved(user.id, i)
                                    "
                                    class="u-m-chip m-s-u-chip"
                                  >
                                    <img
                                      *ngIf="
                                        user?.profile_image?.charAt(0) !==
                                        '#'
                                      "
                                      src="{{ user?.profile_image }}"
                                      alt="icon"
                                      draggable="false"
                                      class="me-3"
                                    />
                                    <div
                                      *ngIf="
                                        user.profile_image?.charAt(0) ==
                                        '#'
                                      "
                                      class="user-short-name mr-0"
                                      [ngStyle]="{
                                        'background-color':
                                          user?.profile_image
                                      }"
                                    >
                                      {{ utils.forShortName(user?.name) }}
                                    </div>
                                    {{ user?.name }}
                                    <mat-icon matChipRemove
                                      >cancel</mat-icon
                                    >
                                  </mat-chip>
                                </mat-chip-list>
                              </span>
                              <mat-menu #dotsMenu="matMenu" class="as__custom-row" (closed)="onInputChange({target:{value:''}}, i);resetInput(i)">
                                <div class="col-sm-12 text-right bg-dark-grey d-flex align-items-center d-flex pe-0 ps-3">
                                    <mat-checkbox
                                      *ngIf="filteredProviders[i]?.length"
                                      (click)="$event.stopPropagation()"
                                      id="allSelected{{ i }}"
                                      [(ngModel)]="allSelected[i]"
                                      [ngModelOptions]="{
                                        standalone: true
                                      }"
                                      (change)="selectAll(i)"
                                      class="p-0 custom-checkbox__only-border"
                                    ></mat-checkbox>
                                    <input
                                      (click)="$event.stopPropagation()"
                                      #multiUserSearch
                                      type="text"
                                      autocomplete="off"
                                      placeholder="Type here to Search"
                                      (keydown)="$event.stopPropagation()"
                                      class="form-control c-s-input custom-input border-0 w-100 m-w-100"
                                      (input)="onInputChange($event, i)"
                                    />
                                 </div>

                                <div class="scroll-fix">

                                
                                <div class="d-flex" *ngFor="let data of filteredProviders[i]">
                                  <mat-checkbox
                                    (click)="$event.stopPropagation()"
                                      [checked]="data.selected"
                                      
                                      (change)="selectServiceMember($event,i,data)"
                                      class="p-2 custom-checkbox__only-border mx-2"
                                    >
                                    <img
                                      *ngIf="
                                        data?.profile_image?.charAt(0) !==
                                        '#'
                                      "
                                      src="{{ data?.profile_image }}"
                                      alt="icon"
                                      draggable="false"
                                      class="me-2 user-img"
                                    />
                                    <div
                                      *ngIf="
                                        data?.profile_image?.charAt(0) ==
                                        '#'
                                      "
                                      class="user-short-name me-2"
                                      [ngStyle]="{
                                        'background-color':
                                          data?.profile_image
                                      }"
                                    >
                                      {{ utils.forShortName(data?.name) }}
                                    </div>
                                    <span [innerHTML]="data?.name"></span>
                                  </mat-checkbox>
                                </div>
                                <div class="text-center" *ngIf="!filteredProviders[i]?.length">No data found</div>
                                </div>
                              </mat-menu>
                            </div>

                          </div>
                        </div>
                      </li>
                    </ul>
                  </ng-container>
                </div>
                
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="row mt-4">
            <div class="col-12 d-flex justify-content-end px-4">
              <button
                class="btn btn--bordered me-2"
                mat-button
                mat-flat-button
                (click)="cancelClick()"
                type="button"
              >
                {{ "CANCEl_BUTTON" | translate }}
              </button>
              <button
                class="btn btn--primary"
                type="submit"
                mat-button
                mat-flat-button
                (click)="addService()"
                *ngIf="addServiceButton"
                [ngClass]="{
                  'default-status':
                    this.count <= 0 &&
                    frequencyDetails[0] &&
                    frequencyDetails[0].option == 'Weekly'
                }"
                [disabled]="!addServiceForm.valid"
              >
                {{ "ADD_SERVICE_BTN" | translate }}
              </button>
              <button
                class="btn btn--primary"
                type="submit"
                mat-button
                mat-flat-button
                (click)="addService()"
                *ngIf="!addServiceButton"
                [disabled]="addServiceForm.invalid || !addServiceForm.touched"
              >
                {{ "EDIT_SERVICE_BUTTON" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>