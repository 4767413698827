import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { UserDetailsService } from '../user-details.service';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class TasksService {
    userId: any;
    taskId: any;
    apiEndPoint = NAVIGATE_ROUTES;
    res: any;
    searchText = new ReplaySubject<any>(1);
    dueDateDataObj = new BehaviorSubject<Object>({});
    removeAssignee = new BehaviorSubject<any>('');
    removeIdFromPreview = new BehaviorSubject<any>('');
    getTotalCountMember = new BehaviorSubject<any>('');
    subTaskDetailsData = new BehaviorSubject<any>('');
    mainAssigneeGroupIdData = new BehaviorSubject<any>('');
    selectedCompanyId: any;
    userDetail: any;
    companyId: any;
    enc_user_id: any;
    startDate: any = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    endDate: any = new Date(new Date().setHours(23, 59, 59)).getTime();
    taskName = '';
    membersCount: number;
    bulkEditData = new BehaviorSubject<any>('');
    selectedBulkEditIdData = new BehaviorSubject<any>('');
    markAsCompleteCheckBoxDisableData = new BehaviorSubject<any>('');
    customHeaderData = new BehaviorSubject<any>('');
    projectMembersIds = new BehaviorSubject<any>('');
    selectedTaskDetails = new BehaviorSubject<any>('');
    openAnnouncement = new BehaviorSubject<any>(false);
    checklistCommentSearchText = new BehaviorSubject<any>('');
    getUpdateTaskAfterDeleteSubtask = new BehaviorSubject<any>('');

    constructor(
    public injector: Injector,
    private apiService: ApiService,
    private http: HttpClient
    ) {
        this.userDetail = injector.get<UserDetailsService>(UserDetailsService);
        this.userId = this.userDetail.userId;
    }
    async checklistCommentSearch(data: any) {
        this.checklistCommentSearchText.next(data);
    }

    async projectIdEvent(data: any) {
        this.projectMembersIds.next(data);
    }

    async customHeaderEvent(data: any) {
        this.customHeaderData.next(data);
    }

    async markAsCompleteCheckBoxEvent(data: any) {
        this.markAsCompleteCheckBoxDisableData.next(data);
    }

    async bulkEdit(data: any) {
        this.bulkEditData.next(data);
    }

    async bulkEditTaskId(data: any) {
        this.selectedBulkEditIdData.next(data);
    }

    async mainAssigneeGroupId(data: any) {
        this.mainAssigneeGroupIdData.next(data);
    }

    async subTaskDetails(data: any) {
        this.subTaskDetailsData.next(data);
    }

    async removeAssignees(selectedId: any) {
        await this.removeAssignee.next(selectedId);
    }

    async removeIdFromMatPreview(removeId: any) {
        await this.removeIdFromPreview.next(removeId);
    }

    async searchUpdate(searchText: any) {
        await this.searchText.next(searchText);
    }

    async dueDateObj(data: any) {
        await this.dueDateDataObj.next(data);
    }

    async getTotalCountMembers(count: any) {
        this.getTotalCountMember.next(count);
    }

    async getUpdateTaskAfterDeleteSubtasks(data: any) {
        this.getUpdateTaskAfterDeleteSubtask.next(data);
    }

    async getProjectList(isAdmin?: any) {
        let url =  `${this.apiEndPoint.PROJECT}/list`;
        if(isAdmin==='0'){
            url += `?my_project=${1}`;
        }
        const response: any = await this.apiService.get(url);
        return of(response?.data);
    }

    async getTasksList(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASKS_URL_OPEN_SEARCH}`;

        await this.apiService.post(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    downloadExcelFile(data: any): Observable<Blob> {
        const url = `${this.apiEndPoint.EXPORT_TASK}`;
        return this.http.post(url, data, {
            responseType: 'blob',
        });
    }

    async getGroupDetails(groupId: any): Promise<Observable<any>> {
        const group_id = CryptoJS.AES.encrypt(
            groupId?.toString().trim(),
            ''
        ).toString();
        const url = `${this.apiEndPoint.TEAM_GROUP_MANAGEMENT}/groupDetail?groupId=${group_id}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res.push(resData);
        });
        return of(this.res);
    }

    async getPriority(): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.TASK_PRIORITY}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res.push(resData.result);
        });
        return of(this.res);
    }

    async getUserListByGroup(data: any): Promise<Observable<any>> {
        const url = this.apiEndPoint.GET_USER_LIST_BY_GROUP;
        await this.apiService.post(url, data).then((_resdata) => {
            this.res = [];
            this.res = _resdata;
        });
        return of(this.res);
    }

    async getClientDetail(clientId: any) {
        clientId = CryptoJS.AES.encrypt(clientId?.toString().trim(), '').toString();
        const url = `${this.apiEndPoint.CLIENT}` + `clientDetail?id=${clientId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getCompanyMemberList(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.GET_MEMBER}?companyId=${companyId}&userType=employee`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getGroupList(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.TEAM_GROUP_MANAGEMENT}?companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getClientList(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.CLIENT}?id=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getServiceList(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.SERVICE_MANAGEMENT}?companyId=${companyId}&type='recurring'`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async createTask(
        data: any,
        _userId: any,
        _companyId: any
    ): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.TASKS_URL}`;
        const res: any = [];
        await this.apiService.post(url, data).then((_resdata: any) => {
            if (_resdata) {
                res.push(_resdata);
            }
        });
        return of(res);
    }
    async editTask(
        data: any,
        taskId: any,
        status?: any
    ): Promise<Observable<any>> {
        this.res = [];
        let url = `${this.apiEndPoint.TASKS_URL}?id=${taskId}`;
        if (status === 1) {
            url += `&status=${status}`;
        }

        await this.apiService.put(url, data).then((resdata) => {
            this.res = resdata;
        });
        return of(this.res);
    }

    async deleteTask(
        taskId: any,
        userId: any,
        companyId: any,
        status?: any
    ): Promise<Observable<any>> {
        this.res = [];
        let url = `${this.apiEndPoint.TASKS_URL}?id=${taskId}&companyId=${companyId}&userId=${userId}`;
        if (status === 1) {
            url += `&status=${status}`;
        }
        await this.apiService.delete(url).then((data) => {
            this.res = data;
        });
        return of(this.res);
    }

    async getTaskDetailById(taskId: any, _companyId: any, _moduleId: any) {
        const url = `${this.apiEndPoint.taskDetailByIdAtlas}?task_id=${taskId}`;
        this.taskId = taskId;
        this.res = [];
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
            this.res['task_id'] = resData._id;
        });
        return of({ _source: this.res, _id: this.res?._id });
    }

    async pinTaskAdd(data: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.PIN_TASK_URL}`;
        this.res = [];
        await this.apiService.post(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async updatePriorityOnTasklist(
        data: any,
        taskId: any
    ): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASK_PRIORITY}?taskId=${taskId}`;
        await this.apiService.put(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async updateStatusOnTasklist(
        data: any,
        taskId: any
    ): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASK_STATUS}?taskId=${taskId}`;
        await this.apiService.put(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async addReminder(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = this.apiEndPoint.TASK_REMINDER;
        await this.apiService.post(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async getReminder(taskId: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASK_REMINDER}?id=${taskId}`;
        await this.apiService.get(url).then((resData: any) => {
            if (resData) {
                this.res = resData;
            }
        });
        return of(this.res);
    }

    async editReminder(taskId: any, data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASK_REMINDER}?id=${taskId}`;
        await this.apiService.put(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async deleteReminder(taskId: any, reminderId: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASK_REMINDER}?task_id=${taskId}&id=${reminderId}`;
        await this.apiService.delete(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async editReminderFlag(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASK_UPDATE_REMINDER}`;
        await this.apiService.put(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async updateStatusAndDeleteChecklist(
        checkListId: any,
        taskId: any,
        isDelete: any
    ) {
        this.res = [];
        const url = `${this.apiEndPoint.TASKS_URL}/checklist-status-update?checkListId=${checkListId}&taskId=${taskId}&isDelete=${isDelete}`;
        await this.apiService.get(url).then((resData: any) => {
            if (resData) {
                this.res = resData;
            }
        });
        return of(this.res);
    }

    async deleteDocumentFile(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.REMOVE_TASK_ATTATCHMENT}/${data}`;
        await this.apiService.delete(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async createComment(data: any, companyId: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.ADD_COMMENT}?userId=${this.userId}&companyId=${companyId}`;
        await this.apiService.post(url, data).then((resData: any) => {
            this.res.push(resData);
        });
        return of(this.res);
    }

    async getComment(data: any): Promise<Observable<any>> {
        let res: any = [];
        const url =
      this.apiEndPoint.GET_COMMENT +
      `?userId=${this.userId}&task_id=${data.taskId}&companyId=${data.companyId}&page=${data.page}&limit=${data.limit}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async taskMarkAsCompleted(data: any, status?: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASKS_URL}/mark-as-compelete-by-taskId?status=${status ?? 0}`;
        await this.apiService.post(url, data).then((resData: any) => {
            this.res.push(resData);
        });
        return of(this.res);
    }

    async pinTaskDragDrop(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASK_ORDER}`;
        await this.apiService.post(url, data).then((resData: any) => {
            this.res.push(resData);
        });
        return of(this.res);
    }

    async taskReadUnread(taskId: any, data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASK_READ_UNREAD}?taskId=${taskId}`;
        await this.apiService.post(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async getRecycleBinTasksList(companyId: any,projectId?:any): Promise<Observable<any>> {
        this.res = [];
        let url = `${this.apiEndPoint.TASK_RECYCLE_BIN_TASKS_LISTS}?companyId=${companyId}`;
        if(projectId){
            url += `&projectId=${projectId}`;
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async restoreTask(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.RESTORE_RECYCLE_BIN_TASK}`;
        await this.apiService.put(url, data).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async permanentDeleteTask(selectedData: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.PERMANENT_DELETE_TASK}`;
        await this.apiService.deleteWithForm(url, selectedData).then((data) => {
            this.res = data;
        });
        return of(this.res);
    }

    async getTaskActivityLog(
        taskId: any,
        companyId: any,
        searchString: any,
        startDate?: any,
        endDate?: any
    ): Promise<Observable<any>> {
        let url = `${this.apiEndPoint.TASK_ACTIVITY_LOG}?taskId=${taskId}&companyId=${companyId}&searchString=${searchString}`;
        if (startDate) {
            url = url + `&startDate=${startDate}`;
        }
        if (endDate) {
            url = url + `&endDate=${endDate}`;
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async bulkDeleteTask(selectedTaskId: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.BULK_DELETE_TASK}`;
        await this.apiService.put(url, selectedTaskId).then((data) => {
            this.res = data;
        });
        return of(this.res);
    }

    async getReadUnreadData(taskID: any): Promise<Observable<any>> {
        let res: any = [];
        const url = `${this.apiEndPoint.READ_UNREAD_DATA}?taskId=${taskID}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getTimeLog(taskId: any, companyId: any): Promise<Observable<any>> {
        let res: any = [];
        const url = `${this.apiEndPoint.GET_TIME_LOG}?taskId=${taskId}&companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async onOffTimer(data: any): Promise<Observable<any>> {
        let res: any = [];
        const url = `${this.apiEndPoint.ON_OFF_TIMER}`;
        await this.apiService.post(url, data).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async uploadDoc(
        data: any,
        userId: any,
        compsnyId: any
    ): Promise<Observable<any>> {
        let res: any = [];
        const url = `${this.apiEndPoint.DOC_ADD_IN_TASK}?userId=${userId}&companyId=${compsnyId}`;
        await this.apiService.post(url, data).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async bulkEditTask(data: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.TASKS_URL}/bulk-edit`;
        await this.apiService.put(url, data).then((resdata) => {
            this.res = [];
            this.res = resdata;
        });
        return of(this.res);
    }

    async addFavouriteSort(data: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.TASK_FAV_FILTER}`;
        await this.apiService.post(url, data).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getFavouriteSort(): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.TASK_FAV_FILTER}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    // async readAnnouncement(current_date_time: any) {
    //     const response: any = await this.apiService.get(
    //         `${this.apiEndPoint.TASKS_URL}/announcement?current_date_time=${current_date_time}`
    //     );
    //     return of(response);
    // }

    async hideShowAnnouncement(flag: boolean) {
        this.openAnnouncement.next(flag);
    }

    async offlineCommentSave(data: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.SAVE_OFFLINE_COMMENT}`;
        await this.apiService.post(url, { comments: data }).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getNextPreviousTask(taskId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.GET_NEXT_PREVIOUS_TASK}${taskId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async stopRecurringTask(taskId: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.STOP_RECURRING_TASK}?id=${taskId}`;
        await this.apiService.put(url, {}).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }
    async CalendarTask(data: any): Promise<Observable<any>> {
        await this.apiService
            .post(this.apiEndPoint.GET_CALENDAR_DATA, data)
            .then((resData: any) => {
                this.res = [];
                this.res = resData;
            });
        return of(this.res);
    }

    recurringTaskIcon(
        isRecurringFromTask: any,
        isRecurringFromService: any,
        recurringIcon: any,
        recurringParentId: any
    ) {
        if (recurringIcon) {
            switch (recurringIcon) {
            case 'CT':
                return 'assets/images/TC.svg';
            case 'WT':
                return 'assets/images/TW.svg';
            case 'MT':
                return 'assets/images/TM.svg';
            case 'YT':
                return 'assets/images/TY.svg';
            case 'QT':
                return 'assets/images/TQ.svg';
            case 'HT':
                return 'assets/images/Group 14543.svg';
            case 'CS':
                return 'assets/images/SC.svg';
            case 'WS':
                return 'assets/images/SW.svg';
            case 'MS':
                return 'assets/images/SM.svg';
            case 'YS':
                return 'assets/images/SY.svg';
            case 'QS':
                return 'assets/images/SQ.svg';
            case 'HS':
                return 'assets/images/SH.svg';
            }
        }

        if (
            ((isRecurringFromTask !== 'false' && isRecurringFromTask) ||
        isRecurringFromService ||
        recurringParentId) &&
      !recurringIcon
        ) {
            return 'assets/images/reverticon.svg';
        }
    }

    recurringTaskFullName(
        isRecurringFromTask: any,
        isRecurringFromService: any,
        recurringIcon: any,
        recurringParentId: any
    ) {
        if (recurringIcon) {
            switch (recurringIcon) {
            case 'CT':
                return 'Task Custom';
            case 'WT':
                return 'Task Weekly';
            case 'MT':
                return 'Task Monthly';
            case 'YT':
                return 'Task Yearly';
            case 'QT':
                return 'Task Quaterly';
            case 'HT':
                return 'Task Half Yearly';
            case 'CS':
                return 'Service Custom';
            case 'WS':
                return 'Service Weekly';
            case 'MS':
                return 'Service Monthly';
            case 'YS':
                return 'Service Yearly';
            case 'QS':
                return 'Service Quaterly';
            case 'HS':
                return 'Service Half Yearly';
            }
        }

        if (
            ((isRecurringFromTask !== 'false' && isRecurringFromTask) ||
        isRecurringFromService ||
        recurringParentId) &&
      !recurringIcon
        ) {
            return 'Task Recurring';
        }
    }

    recurringTaskShortName(recurringType: any) {
        switch (recurringType) {
        case 'custom':
            return 'CT';
        case 'weekly':
            return 'WT';
        case 'monthly':
            return 'MT';
        case 'quarterly':
            return 'QT';
        case 'halfyearly':
            return 'HT';
        case 'yearly':
            return 'YT';
        default:
            return '';
        }
    }

    async getRegularServiceList(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.SERVICE_MANAGEMENT}/list?companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getTaskCommentRedDotId(data: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.TASK_UNREAD_MESSAGES}`;
        await this.apiService.post(url, data).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }
}
