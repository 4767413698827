import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HeaderComponent } from './header/header.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { darkTheme } from './theme/dark-theme';
import { lightTheme } from './theme/light-theme';
import { ThemeModule } from './theme/theme.module';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [HomeComponent, HeaderComponent, SideBarComponent],
    imports: [
        CommonModule,
        NgxSpinnerModule,
        HomeRoutingModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSidenavModule,
        TranslateModule,
        ThemeModule.forRoot({
            themes: [lightTheme, darkTheme],
            active: 'light',
        }),
        MatMenuModule,
        SharedModule,
    ],
    exports: [HeaderComponent],
})
export class HomeModule {}
