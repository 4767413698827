<div class="attachment-list-block">
  <img [src]="src" alt="file icon" height="20" width="20" />
  <span class="me-auto doc-name">{{ file.name }}</span>
  <span class="user--img ms-auto" *ngIf="!file.user_details">
    <img
      *ngIf="profile?.charAt(0) != '#'"
      [src]="profile"
      alt=""
      class="img-avatar"
    />
    <div
      *ngIf="profile?.charAt(0) == '#'"
      class="user-short-name mr-0"
      [ngStyle]="{ 'background-color': profile }"
    >
      {{ utils.forShortName(userDetailData?.user_name) }}
    </div>
  </span>
  <span class="user--img ms-auto" *ngIf="file.user_details">
    <img
      *ngIf="file.user_details.profile_image?.charAt(0) != '#'"
      [src]="file.user_details.profile_image"
      alt=""
      class="img-avatar"
    />
    <div
      *ngIf="file.user_details.profile_image?.charAt(0) == '#'"
      class="user-short-name mr-0"
      [ngStyle]="{ 'background-color': file.user_details.profile_image }"
    >
      {{
        utils.forShortName(
          file.user_details?.first_name + " " + file.user_details?.last_name
        )
      }}
    </div>
  </span>
  <time *ngIf="!file.created_at">{{ epochNow | date }}</time>
  <time *ngIf="file.created_at">{{ file.created_at | date }}</time>

  <span>{{ (file.size * 0.000001).toFixed(3) }} mb</span>
</div>
