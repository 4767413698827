import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class OrganizationSettingsService {
    constructor(
    private apiService: ApiService
    ) {}

    res: any;
    apiEndPoint = NAVIGATE_ROUTES;
    clientId: any;

    async getMemberList(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.GET_MEMBER}?companyId=${companyId}&userType=employee`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async setGeneralSettings(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = this.apiEndPoint.ORGANIZATION_SETTING;
        await this.apiService.post(url, data).then((resdata) => {
            this.res = [];
            this.res.push(resdata);
        });
        return of(this.res);
    }

    async getGeneralSettings(
        companyId: any,
        userId: any
    ): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.ORGANIZATION_SETTING}?userId=${userId}&companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async saveIpConfig(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = this.apiEndPoint.SAVE_IP_ADDRESS_CONFIG
        await this.apiService.post(url, data).then((resdata) => {
            this.res = [];
            this.res.push(resdata);
        });
        return of(this.res);
    }


    async getIPSettings(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.GET_COMPANY_IP_SETTINGS}?companyId=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }
}
