import {
    Component,
    Inject,
    Injector,
    OnInit,
    Optional,
    ViewChild,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { NOTES_COLOR_CODE } from 'src/app/shared/global/constants';
import { ApiService } from 'src/app/shared/services/api-service/api.service';
import { NotesService } from 'src/app/shared/services/notes/notes.service';

@Component({
    selector: 'app-add-notes',
    templateUrl: './add-notes.component.html',
    styleUrls: ['./add-notes.component.scss'],
})
export class AddNotesComponent implements OnInit {
  @ViewChild('editor') editor: any;
  description: any;
  notesData: any;
  title: any;
  descriptionText: any;
  public noteService: NotesService;
  public apiService: ApiService;
  colorCode = NOTES_COLOR_CODE;
  color: any;
  noteId: any;
  content: any;
  selectColor = '#FEF0D3';
  isPin = false;
  notesId: any;
  isSharedNote = false;
  saveDisable = false;
  constructor(
    public injector: Injector,
    public translate: TranslateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public noteData: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddNotesComponent>
  ) {
      this.noteService = injector.get<NotesService>(NotesService);
      this.apiService = injector.get<ApiService>(ApiService);
  }

  async ngOnInit(): Promise<void> {
      if (this.noteData) {
          this.notesId = this.noteData.noteId;
          this.getNoteData();
          this.isSharedNote = this.noteData.hasOwnProperty('can_edit');
      }
  }

  quillConfig: any = {
      toolbar: [
          [
              { header: [] },
              'bold',
              'italic',
              'underline',
              'strike',
              { color: [] },
              { background: [] },
              { list: 'ordered' },
              { list: 'bullet' },
              { align: [false, 'right', 'center'] },
              { indent: '-1' },
              { indent: '+1' },
              'link',
              'blockquote',
              'clean',
          ],
      ],
  };

  addNoteData(data: any) {
      this.notesData = data.content.ops;
      this.descriptionText = data.text;
  }

  noteTitle(title: any) {
      this.title = title.target.value;
  }

  selectedColor(color: any) {
      this.color = color;
  }

  async save() {
      this.saveDisable = true;
      const noteObj: any = {
          title: this.title,
          description: this.notesData,
          description_text: this.descriptionText,
      };
      if (this.selectColor) {
          noteObj['bg_color'] = this.selectColor;
      } else {
          noteObj['bg_color'] = '#E0E0E0';
      }
      if (this.notesId) {
          noteObj['_id'] = this.notesId;
      }

      this.apiService.showLoading();
      if (this.notesId) {
          this.editNote(noteObj);
      } else {
        (await this.noteService.addNote(noteObj)).subscribe((res: any) => {
            this.noteId = res.data._id;
            this.apiService.showSuccess(res.message);
        });
    }
      this.apiService.hideLoading();
      this.dialogRef.close(noteObj);
      this.saveDisable = false;
      this.noteService.noteDetail(noteObj);
  }

  async getNoteData() {
      (await this.noteService.getNoteDetailById(this.notesId)).subscribe(
          (res: any) => {
              this.title = res.data[0].title;
              this.description = res.data[0].description_quill;
              this.editor.quillEditor.setContents(res.data[0].description_quill);
              this.selectColor = res.data[0].bg_color;
              this.apiService.hideLoading();
          }
      );
  }

  async editNote(noteObj: any) {
      (await this.noteService.editNote(noteObj)).subscribe((res: any) => {
          if (res) {
              this.apiService.showSuccess(res.message);
          }
      });
  }

  async deleteNote() {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
              message: this.translate.instant('DELETE_NOTE_CONFIRM_MSG'),
              buttonText: {
                  ok: 'Yes',
                  cancel: 'No',
              },
          },
      });
      dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
          if (confirmed) {
              (await this.noteService.deleteNote(this.notesId)).subscribe(
                  (res: any) => {
                      if (res) {
                          this.apiService.showSuccess(res.message);
                          const noteObj: any = {
                            title: this.title,
                            description: this.notesData,
                            description_text: this.descriptionText,
                        };
                          this.dialogRef.close(noteObj);
                      }
                  }
              );
          }
      });
  }

  async pinNote(pin: any) {
      if (pin == 'true') {
          this.noteData.isPin = 'false';
      } else {
          this.noteData.isPin = 'true';
      }
      (await this.noteService.pinNote(this.notesId)).subscribe((res: any) => {
          if (res) {
              this.apiService.showSuccess(res.message);
              this.isPin = true;
          }
      });
  }

  cancelClick() {
    const noteObj: any = {
        title: this.title,
        description: this.notesData,
        description_text: this.descriptionText,
    };
      this.dialogRef.close(noteObj);
  }
}
