<mat-chip-list>
  <mat-chip
    *ngFor="let user of selectedMembers"
    [removable]="true"
    (removed)="onToppingRemoved(user.id, listArrName)"
    class="u-m-chip m-s-u-chip"
  >
    <img
      *ngIf="user?.profile_image && user?.profile_image?.charAt(0) != '#'"
      src="{{ user?.profile_image }}"
      alt=""
      class="me-1"
    />
    <div
      *ngIf="user?.profile_image && user?.profile_image?.charAt(0) == '#'"
      class="user-short-name mr-0"
      [ngStyle]="{ 'background-color': user?.profile_image }"
    >
      {{ utils.forShortName(user.name) }}
    </div>
    <strong [ngStyle]="{ color: 'black' }" *ngIf="isStrong">{{
      user.name
    }}</strong>
    <p *ngIf="!isStrong" class="trim-info">{{ user.name }}</p>
    <div *ngIf="isInfoShow">
      <img
        *ngIf="listArrName === 'client'"
        [ngStyle]="{ 'pointer-events': 'auto' }"
        (click)="$event?.stopPropagation()"
        (click)="clientInfo(user.id)"
        src="assets/images/greyinfocircle.svg"
        alt=""
        class="ms-1"
      />
      <a
        (click)="getTeamMemberDetails(user.id)"
        *ngIf="listArrName === 'Team'"
        [matMenuTriggerFor]="teamInfo"
        (click)="$event?.stopPropagation()"
      >
        <img src="assets/images/greyinfocircle.svg" alt="" class="ms-1" />
      </a>
      <mat-menu #teamInfo="matMenu" class="multi-select-chip-menu">
        <div
          class="d-flex align-items-center"
          *ngFor="let ele of groupMemberInfo"
          mat-menu-item
        >
          <img
            alt="avtar"
            *ngIf="ele.profile_image && ele.profile_image?.charAt(0) != '#'"
            src="{{ ele.profile_image }}"
            class="img-avatar"
          />
          <div
            *ngIf="ele.profile_image && ele.profile_image?.charAt(0) == '#'"
            class="mx-2 user-short-name"
            [ngStyle]="{ 'background-color': ele.profile_image }"
          >
            {{ utils.forShortName(ele.name) }}
          </div>
          {{ ele.name }}
        </div>
      </mat-menu>
    </div>
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
</mat-chip-list>
