<form
  [formGroup]="form"
  (ngSubmit)="onSubmit.emit(form.value)"
  class="form-horizontal custom-field-box"
>
  <table aria-describedby="" class="table table-bordered custom-field-table">
    <tbody>
      <tr *ngFor="let field of fields">
        <ng-container [ngSwitch]="field.field_type">
          <ng-container *ngSwitchCase="'text'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <mat-form-field appearance="standard" class="w-100">
                <input
                  matInput
                  [formControlName]="field.name"
                  [placeholder]="field.label"
                  [maxlength]="100"
                  [attr.type]="field.field_type"
                  [id]="field.name"
                  [name]="field.name"
                  trim="blur"
                />
                <mat-error
                  *ngIf="form.controls[field.name].hasError('required')"
                >
                  {{ field.label }} {{ "IS_REQUIRED" | translate }}
                </mat-error>
                <mat-error
                  *ngIf="form.controls[field.name].hasError('pattern')"
                >
                  {{ "PLEASE_ENTER_VALID" | translate }} {{ field.label }}.
                </mat-error>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'textarea'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <mat-form-field appearance="standard" class="w-100">
                <textarea
                  matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5"
                  [formControlName]="field.name"
                  [id]="field.name"
                  trim="blur"
                  [placeholder]="field.label"
                  class="m-0"
                ></textarea>
                <mat-error
                  *ngIf="form.controls[field.name].hasError('required')"
                >
                  {{ field.label }} {{ "IS_REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'numeric'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <mat-form-field
                appearance="standard"
                class="w-100"
                *ngIf="
                  field.field_type == 'numeric' && field.sub_type == 'number'
                "
              >
                <input
                  matInput
                  [formControlName]="field.name"
                  [placeholder]="field.label"
                  [attr.type]="field.field_type"
                  [id]="field.name"
                  [name]="field.name"
                  numbersOnly
                />
                <mat-error
                  *ngIf="form.controls[field.name].hasError('required')"
                >
                  {{ field.label }} {{ "IS_REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                appearance="standard"
                class="w-100"
                *ngIf="
                  field.field_type == 'numeric' &&
                  (field.sub_type == 'decimal' ||
                    field.sub_type == 'percentage')
                "
              >
                <input
                  matInput
                  [formControlName]="field.name"
                  [placeholder]="field.label"
                  [attr.type]="field.field_type"
                  [id]="field.name"
                  [name]="field.name"
                  appDecimalOnly
                />
                <mat-error
                  *ngIf="form.controls[field.name].hasError('required')"
                >
                  {{ field.label }} {{ "IS_REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                appearance="standard"
                class="w-100"
                *ngIf="
                  field.field_type === 'numeric' &&
                  field.sub_type === 'autonumber'
                "
              >
                <input
                  matInput
                  [formControlName]="field.name"
                  [placeholder]="field.label"
                  [attr.type]="field.field_type"
                  [id]="field.name"
                  [name]="field.name"
                  [readonly]="true"
                />
                <mat-error
                  *ngIf="form.controls[field.name].hasError('required')"
                >
                  {{ field.label }} {{ "IS_REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'date'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <div class="app-left-field-date">
                <span
                  *ngIf="form.controls[field.name].value"
                  [matMenuTriggerFor]="duedate"
                  class="span-one"
                >
                  {{ form.controls[field.name].value | date : "dd/MM/yyyy" }}
                </span>
                <span
                  *ngIf="!form.controls[field.name].value"
                  [matMenuTriggerFor]="duedate"
                  class="span-two"
                >
                  {{ field.label }}
                </span>
                <mat-menu
                  #duedate="matMenu"
                  class="custom-sub-menu-dropdown-menu calender-sub-menu p-0"
                  xPosition="before"
                >
                  <mat-calendar
                    [(selected)]="form.controls[field.name].value"
                    [minDate]="minDate"
                    (selectedChange)="setDate($event, field.name)"
                  ></mat-calendar>
                </mat-menu>
              </div>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'email'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <mat-form-field appearance="standard" class="w-100">
                <input
                  matInput
                  [formControlName]="field.name"
                  [placeholder]="field.label"
                  trim="blur"
                  [attr.type]="field.field_type"
                  [id]="field.name"
                  [name]="field.name"
                />
                <mat-error
                  *ngIf="form.controls[field.name].hasError('required')"
                >
                  {{ field.label }} {{ "IS_REQUIRED" | translate }}
                </mat-error>
                <mat-error
                  *ngIf="form.controls[field.name].hasError('pattern')"
                >
                  {{ "PLEASE_ENTER_VALID" | translate }} {{ field.label }}.
                </mat-error>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'phone'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <mat-form-field appearance="standard" class="w-100">
                <ngx-mat-intl-tel-input
                  placeholder="'MOBILE_PLACEHOLDER'|translate"
                  [formControlName]="field.name"
                  name="field.name"
                  [cssClass]="'custom'"
                  [enableSearch]="true"
                  [preferredCountries]="preferredCountries"
                  #phone
                >
                </ngx-mat-intl-tel-input>
                <mat-error
                  *ngIf="form.controls[field.name].hasError('required')"
                >
                  {{ field.label }} {{ "IS_REQUIRED" | translate }}</mat-error
                >
                <mat-error
                  *ngIf="form.controls[field.name]?.errors?.['validatePhoneNumber']"
                >
                  {{ "MOBILE_NUMBER_VALID_MSG" | translate }}</mat-error
                >
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'url'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <mat-form-field appearance="standard" class="w-100">
                <input
                  matInput
                  [formControlName]="field.name"
                  [placeholder]="field.label"
                  trim="blur"
                  [attr.type]="field.field_type"
                  [id]="field.name"
                  [name]="field.name"
                />
                <mat-error
                  *ngIf="form.controls[field.name].hasError('required')"
                >
                  {{ field.label }} {{ "IS_REQUIRED" | translate }}
                </mat-error>
                <mat-error
                  *ngIf="form.controls[field.name].hasError('pattern')"
                >
                  {{ "PLEASE_ENTER_VALID" | translate }} {{ field.label }}.
                </mat-error>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'checklist'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <ng-container *ngIf="!field.is_single_select">
                <mat-form-field appearance="standard" class="w-100">
                  <mat-select
                    name="field.name"
                    [formControlName]="field.name"
                    [placeholder]="field.label"
                    [multiple]="true"
                  >
                    <mat-option
                      *ngFor="let option of field.option"
                      [value]="option.id"
                      >{{ option.option }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    *ngIf="form.controls[field.name].hasError('required')"
                  >
                    {{ field.label }} {{ "IS_REQUIRED" | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="field.is_single_select">
                <mat-form-field appearance="standard" class="w-100">
                  <mat-select
                    name="field.name"
                    [formControlName]="field.name"
                    [placeholder]="field.label"
                  >
                    <mat-option
                      *ngFor="let option of field.option"
                      [value]="option.id"
                      >{{ option.option }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    *ngIf="form.controls[field.name].hasError('required')"
                  >
                    {{ field.label }} {{ "IS_REQUIRED" | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'radio'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <mat-radio-group [formControlName]="field.name" class="px-2">
                <mat-radio-button
                  *ngFor="let option of field.option"
                  class="c-radio-button me-2"
                  [value]="option.id"
                >
                  {{ option.option }}</mat-radio-button
                >
              </mat-radio-group>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'currency'">
            <th class="text-capitalize">
              {{ field.label }}
              <strong class="text-danger" *ngIf="field.is_mandatory">*</strong>
            </th>
            <td>
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <mat-form-field
                    appearance="standard"
                    class="custom-input-select-field"
                    style="max-width: 100px"
                  >
                    <mat-select
                      [formControlName]="field.country_id"
                      [placeholder]="field.label"
                      [id]="field.country_id"
                    >
                      <mat-option
                        *ngFor="let country of countries"
                        [value]="country.id"
                      >
                        {{ country.currency_symbol }}-{{ country.currency }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    appearance="standard"
                    class="custom-input-select-field w-100"
                  >
                    <input
                      matInput
                      [formControlName]="field.name"
                      [placeholder]="field.label"
                      [attr.type]="field.field_type"
                      [id]="field.name"
                      [name]="field.name"
                      numbersOnly
                    />
                    <mat-error
                      *ngIf="form.controls[field.name].hasError('required')"
                    >
                      {{ field.label }} {{ "IS_REQUIRED" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </tbody>
  </table>
</form>
