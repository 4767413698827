import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KEYNAME } from '../../global/constants';

@Component({
    selector: 'app-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnInit {
    isNextEnable = true;
    isPrevEnable = true;
    imagePath: string = null;
    imageArray: any = [];
    imageIndex: number;
    constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImagePreviewComponent>
    ) {}

    ngOnInit(): void {
        this.imagePath = this.data.imageArray[this.data.imageIndex];
        this.imageIndex = this.data.imageIndex;
    }
    closeImageModal() {
        this.dialogRef.close();
    }

    downloadImage(path: string) {
        const link = document.createElement('a');
        const parts = path.split('/');
        const filename = parts[parts.length - 1];
        link.setAttribute('download', filename);
        link.href = path;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    loadImage(type: string) {
        const maxvalue = this.data.imageArray.length;
        if (type === 'prev') {
            if (this.imageIndex > 0 && this.imageIndex < maxvalue) {
                this.imageIndex = this.imageIndex - 1;
                this.imagePath = this.data.imageArray[this.imageIndex];
            }
        } else {
            if (this.imageIndex < maxvalue - 1) {
                this.imageIndex = this.imageIndex + 1;
                this.imagePath = this.data.imageArray[this.imageIndex];
            }
        }
    }
    
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if(event.key === KEYNAME.ALLOW_RIGHT){
            this.loadImage('next')
        }else if(event.key === KEYNAME.ALLOW_LEFT){
            this.loadImage('prev')
        }
    }
}
