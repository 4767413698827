import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { HEADER_NAVIGATION } from 'src/app/shared/global/constants';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import { ThemeService } from '../theme/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GuideModelComponent } from 'src/app/shared/components/guide-model/guide-model.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TasksService } from 'src/app/shared/services/task-service/tasks.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth-service/auth.service';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public utils: UtilsService;
    headerNav: any;
    user: any;
    userImgPath: any = '';
    unreadNotificationCount: any;
    private dialogRef: MatDialogRef<GuideModelComponent>;
    subscription: Subscription;
    @ViewChild(MatMenuTrigger) notification: MatMenuTrigger;
    isShowNotificationList = false;
    isToggled = false;
    showAnnouncement: any = false;
    @Output() dataEvent: EventEmitter<any> = new EventEmitter<any>();
    public userDetail: UserDetailsService;
    private taskService: TasksService;
    private notificationService: NotificationService;


//   async readAnnouncement() {
//       const current_date_time = moment(new Date()).valueOf();
//       (await this.taskService.readAnnouncement(current_date_time)).subscribe(
//           async (res: any) => {
//               if (res && res.result) {
//                   this.showAnnouncement = true;
//               }
//           }
//       );
//   }

    constructor(
        private themeService: ThemeService,
        private injector: Injector,
        private translate: TranslateService,
        private router: Router,
        private matDialog: MatDialog,
        public authService: AuthService
    ) {
        this.userDetail = injector.get<UserDetailsService >(UserDetailsService);
        this.taskService = injector.get<TasksService >(TasksService);
        this.notificationService = injector.get<NotificationService >(NotificationService);
        this.headerNav = [HEADER_NAVIGATION];
        this.utils = injector.get<UtilsService>(UtilsService);
        translate.setDefaultLang('en');
    }

    async ngOnInit() {
        this.user = await this.userDetail.getUserName();
        const active = this.themeService.getActiveTheme();
        this.themeService.setTheme(active.name);
        this.subscription = await this.userDetail.updateUserImg.subscribe(
            async (_user) => {
                this.userImgPath = (await this.userDetail.getUserImagePath()) || '';
            }
        );
        this.subscription = await this.userDetail.updateUserName.subscribe(
            async (_user) => {
                this.user = await this.userDetail.getUserName();
            }
        );
        this.subscription = this.taskService.openAnnouncement.subscribe((flag) => {
            this.showAnnouncement = flag;
        });
        this.subscription = this.notificationService.addNotification$.subscribe(
            (data: any) => {
                if (data) {
                    this.notificationCount();
                    this.notification.closeMenu();
                }
            }
        );
        this.notificationCount();
    }
    openAnnouncement() {
        this.showAnnouncement = false;
        this.router.navigate(['/dashboard']);
    }

    toggle() {
        const active = this.themeService.getActiveTheme();
        if (active.name === 'light') {
            this.themeService.setTheme('dark');
        } else {
            this.themeService.setTheme('light');
        }
    }

    openGuideModal() {
        this.dialogRef = this.matDialog.open(GuideModelComponent, {
            panelClass: ['custom-modal', 'custom-modal-md', 'guide-modal'],
        });
        this.dialogRef.afterClosed().subscribe((_res) => {
            // Data back from dialog
        });
    }

    async notificationCount() {
        (await this.notificationService.getNotificationCount()).subscribe(
            (res: any) => {
                this.unreadNotificationCount = res.data?.unread_notification_count;
            }
        );
    }

    redirectToNotificationList() {
        this.router.navigate(['/notification']);
    }
    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
    clickToggle() {
        this.isToggled = !this.isToggled;
        this.dataEvent.emit(this.isToggled);
    }
}
