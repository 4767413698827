import { Injectable } from '@angular/core';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private indexData: Subject<any> = new Subject<any>();
    indexData$: Observable<any> = this.indexData.asObservable();

    private selectedDate: Subject<any> = new Subject<any>();
    selectedDate$: Observable<any> = this.selectedDate.asObservable();

    private userIds: Subject<any> = new Subject<any>();
    userIds$: Observable<any> = this.userIds.asObservable();

    private selectedModule: Subject<any> = new Subject<any>();
    selectedModule$: Observable<any> = this.selectedModule.asObservable();

    private notificationList: Subject<any> = new Subject<any>();
    notificationList$: Observable<any> = this.notificationList.asObservable();

    private shareNoteTab: BehaviorSubject<any> = new BehaviorSubject(0);
    shareNoteTab$: Observable<any> = this.shareNoteTab.asObservable();

    private addNotification: Subject<any> = new Subject<any>();
    addNotification$: Observable<any> = this.addNotification.asObservable();

    private timesheetTab: BehaviorSubject<any> = new BehaviorSubject(0);
    timesheetTab$: Observable<any> = this.timesheetTab.asObservable();

    apiEndPoint = NAVIGATE_ROUTES;

    constructor(private apiService: ApiService) {}

    setIndexData(index: any) {
        this.indexData.next(index);
    }

    setUserId(id: any) {
        this.userIds.next(id);
    }

    setModule(module: any) {
        this.selectedModule.next(module);
    }

    setDate(date: any) {
        this.selectedDate.next(date);
    }

    setNotificationList(list: any) {
        this.notificationList.next(list);
    }

    setShareNoteTab(index: any) {
        this.shareNoteTab.next(index);
    }

    setTimesheetTab(index: any) {
        this.timesheetTab.next(index);
    }

    setNotification(add: any) {
        this.addNotification.next(add);
    }

    async getUserOfNotification(
        notificationSettingId: any,
        company_id: any
    ): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.NOTIFICATION_USER}${notificationSettingId}?company_id=${company_id}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async addUserOfNotification(
        userObj: any,
        notificationSettingId: any,
        company_id: any
    ): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.NOTIFICATION_USER}${notificationSettingId}?company_id=${company_id}`;
        await this.apiService.post(url, userObj).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getNotificationSetting(companyId: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.NOTIFICATION_SETTING}?company_id=${companyId}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async changeNotificationSetting(
        notificationObj: any,
        company_id: any
    ): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.NOTIFICATION_PERMISSION}?company_id=${company_id}`;
        await this.apiService.post(url, notificationObj).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getNotificationList(data: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.NOTIFICATION_LIST}`;
        await this.apiService.post(url, data).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async markAsReadAll(data: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.READ_ALL_NOTIFICATION}`;
        await this.apiService.post(url, data).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getUnreadNotificationList(): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.NOTIFICATION_LIST}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async getNotificationCount(): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.NOTIFICATION_COUNT}`;
        await this.apiService.get(url).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async whatsappNotification(companyId: any): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.WHATSAPP_NOTIFICATION}`;
        await this.apiService.post(url, companyId).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }

    async changeDailyTaskNotificationSetting(
        notificationObj: any
    ): Promise<Observable<any>> {
        let res: any[] = [];
        const url = `${this.apiEndPoint.DAILY_TASK_NOTIFICATION_PERMISSION}`;
        await this.apiService.post(url, notificationObj).then((resData: any) => {
            res = resData;
        });
        return of(res);
    }
}
