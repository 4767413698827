<div *ngIf="isDialog" class="mb-2">
  <h3 class="page-title">{{ "SHARE_WITH_PEOPLE_AND_TEAM" | translate }}</h3>
  <a class="d-flex align-items-center grey-custom-text"
    ><img src="assets/images/plusmembericon.svg" alt="" class="me-3" />{{
      "ADD_PEOPLE_AND_TEAM" | translate
    }}</a
  >
</div>
<div class="input-with-check custom-input-check-cls">
  <input
    type="text"
    [(ngModel)]="searchText"
    (ngModelChange)="getUpdateList(searchText)"
    class="form-control c-s-input custom-input border-0 w-100 m-w-100"
    placeholder="Write here"
    autocomplete="off"
  />
</div>
<div class="d-flex my-3 align-items-center" *ngIf="isDialog">
  <div class="d-flex me-auto owner-detail-block align-items-center">
    <img
      *ngIf="owner?.profile_image && owner?.profile_image?.charAt(0) != '#'"
      src="{{ owner?.profile_image }}"
      alt=""
      class="img-avatar user-short-name mx-auto"
    />
    <span
      class="user-short-name mx-auto"
      *ngIf="!owner?.profile_image || owner?.profile_image?.charAt(0) == '#'"
    >
      {{ owner?.avatar }}</span
    >
    <div class="user-detail mx-1">
      <h3 class="text-capitalize">
        {{ owner?.first_name }} {{ owner?.last_name }}
      </h3>
      <p>{{ owner?.email }}</p>
    </div>
  </div>
  <small>Owner</small>
</div>
<div class="scroll-fix">
  <div>
    <div
      *ngIf="originalGroupList.length !== 0 && groupListData.length !== 0"
      class="share-member-list"
    >
      <app-check-box-common
        [memberListData]="groupListData"
        [listName]="groupListName"
        [isDisable]="isDisableGroup"
        (onSelectCheckBox)="onSelectGroupCheckBox($event)"
        [isGroup]="true"
        (assigneeSelected)="checkAssignee($event)"
        (assigneeDeSelected)="unAssign($event)"
        [getAssignee]="selectedAssignees"
        [isTask]="router.url.includes('/task/list')||router.url.includes('/project')"
        [followerData]="removeAssigneesFromFollower"
      >
      </app-check-box-common>
    </div>
    <div *ngIf="originalGroupList?.length !== 0 && groupListData.length === 0">
      <div class="fw-bold text-uppercase font-decrease-size empty-label-set">
        TEAM
      </div>
      <div class="text-center no-data-available">
        {{ "NO_DATA_AVAILABLE" | translate }}
      </div>
    </div>
  </div>
  <div
    class="text-center no-data-available"
    *ngIf="originalMemberList?.length === 0"
  >
    {{ "NO_DATA_AVAILABLE" | translate }}
  </div>
  <div
    *ngIf="originalMemberList?.length !== 0 && membersListData?.length !== 0"
    class="share-member-list"
  >
    <app-check-box-common
      [memberListData]="membersListData"
      [listName]="assigneesListName"
      [isDisable]="isDisableAssignee"
      (onSelectCheckBox)="onSelectAssigneeCheckBox($event)"
      [getAssignee]="selectedAssignees"
    >
    </app-check-box-common>
  </div>
  <div
    *ngIf="originalMemberList?.length !== 0 && membersListData?.length === 0"
  >
    <div class="fw-bold text-uppercase font-decrease-size empty-label-set">
      {{ "MEMBERS" | translate }}
    </div>
    <div class="text-center no-data-available">
      {{ "NO_DATA_AVAILABLE" | translate }}
    </div>
  </div>
</div>
<div class="row mt-4" *ngIf="isDialog">
  <div class="col-12 d-flex justify-content-end">
    <button
      mat-button
      mat-flat-button
      class="btn btn--bordered me-2"
      tabindex="-1"
      (click)="cancel()"
    >
      {{ "CANCEL" | translate }}
    </button>
    <button
      mat-button
      mat-flat-button
      color="primary"
      class="btn btn--primary"
      tabindex="1"
      (click)="saveAssign()"
    >
      {{ "SAVE" | translate }}
    </button>
  </div>
</div>
