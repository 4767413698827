import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hourFormat',
})
export class HourFormatPipe implements PipeTransform {
    transform(value: any) {
        if (value && value !== '-') {
            let hms = '00:00:00';
            const values = value.split(':');
            if (values.length === 3) {
                hms =
          (values[0].length === 1 ? ('0' + values[0]).slice(-2) : values[0]) +
          ':' +
          ('0' + values[1]).slice(-2);
            } else if (values.length === 2) {
                hms =
          (values[0].length === 1 ? ('0' + values[0]).slice(-2) : values[0]) +
          ':' +
          ('0' + values[1]).slice(-2);
            }
            return hms;
        } else {
            return value;
        }
    }
}
