import { Component, OnInit, Inject, Injector, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MarkAsApprovalService } from 'src/app/shared/services/task-service/mark-as-approval.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import * as CryptoJS from 'crypto-js';
import { TasksService } from 'src/app/shared/services/task-service/tasks.service';
import {
    discussionSocketEvents,
    DISCUSSION_COMMENT_TYPE,
} from 'src/app/shared/global/constants';
import { DiscussionSocketService } from 'src/app/shared/services/discussion/discussion-socket.service';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import { TaskEmitService } from 'src/app/shared/services/task-service/task-emit.service';
import { TimesheetSettingService } from 'src/app/shared/services/organization-settings/timesheet-setting.service';
import { Subscription, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { SaveReportService } from 'src/app/shared/services/report-service/save-report.service';
import { taskState as TaskState } from 'src/app/shared/ngrx/reducer/task-state.reducer';
import { taskState } from 'src/app/shared/ngrx/action/task-state.actions';
import * as _ from 'lodash';
import { CreateTaskComponent } from '../create-task/create-task.component';
@Component({
    selector: 'app-add-edit-task',
    templateUrl: './add-edit-task.component.html',
    styleUrls: ['./add-edit-task.component.scss'],
})
export class AddEditTaskComponent implements OnInit, OnDestroy {
    selectedRoom: any = {};
    task_id: any;
    userId: any;
    markAsApprovalData: any;
    decryptedCompanyId: any;
    companyId: any;
    userRolePermission: any;
    private userDetail: UserDetailsService;
    memberId: any;
    private taskService: TasksService;
    selectedCompanyId: any;
    timeLogDetail: any;
    alluserList: any[] = [];
    readByUserList: any = [];
    timeLogData: any;
    public emitTaskService: TaskEmitService;
    subscription: Subscription;
    taskState: any;
    taskData: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public markAsApprovalService: MarkAsApprovalService,
        private injector: Injector,
        public timeSheetSettingService: TimesheetSettingService,
        private discussionSocketService: DiscussionSocketService,
        public utilsService: UtilsService,
        private store: Store<{ taskState: TaskState }>,
        public reportService: SaveReportService,
        public dialogRef: MatDialogRef<CreateTaskComponent>
    ) {
        this.dialogRef.disableClose = true;
        this.task_id = this.data?.editTaskId;
        this.emitTaskService = injector.get<TaskEmitService>(TaskEmitService);
        this.userDetail = injector.get<UserDetailsService>(UserDetailsService);
        this.taskService = injector.get<TasksService>(TasksService);
    }

    ngOnDestroy() {
        this.selectedRoom = null;
        this.taskService.selectedTaskDetails.next('');
        this.subscription?.unsubscribe();
    }

    async ngOnInit() {
        this.updateStoreData();
        this.store.select('taskState').subscribe((ele: any) => {
            if (!ele.taskState) return;
            this.taskState = ele?.taskState;
        });
        this.getTaskDetails();
        this.companyId = await this.userDetail.getSelectedCompanyId();
        this.userId = await this.userDetail.userId;
        this.decryptedCompanyId = CryptoJS.AES.decrypt(
            this.companyId?.trim(),
            ''
        ).toString(CryptoJS.enc.Utf8);
        this.userRolePermission = await this.userDetail.getUserRolePermissions();
        this.memberId = this.userRolePermission?.memberId;
        this.selectedCompanyId = await this.userDetail.getSelectedCompanyId();
        this.task_id = this.data?.editTaskId;
        this.getTimeSheetDetail(this.decryptedCompanyId);
        await this.getMarsAsApprovalFlowData();
        this.subscription = this.emitTaskService.usersList$.subscribe(
            (data: any) => {
                this.alluserList = data;
            }
        );
        this.subscription = this.emitTaskService.readByusersList$.subscribe(
            (data: any) => {
                this.readByUserList = data;
            }
        );
    }

    async getMarsAsApprovalFlowData() {
        const markAsApprove = this.taskState?.markAsApprove;
        this.markAsApprovalData = markAsApprove;
    }

    getTaskDetails() {
        this.subscription = this.taskService.selectedTaskDetails.subscribe(
            (response) => {
                if (response) {
                    this.taskData = response;
                    const userIDs = response?.user_ids;
                    const groupIDs = response?.group_ids;

                    let result: any;

                    if (userIDs && userIDs.length > 0) {
                        result = [...userIDs];
                    } else if (groupIDs && groupIDs.length > 0) {
                        result = [...groupIDs];
                    } else {
                        result = [];
                    }

                    this.selectedRoom = {
                        _id: response.task_id,
                        user_id: response.owner.user_id,
                        title: response.task_name,
                        project_id: null,
                        task_id: response.task_id,
                        color: '#DC3535',
                        created_by: response.owner,
                        accesses: result,
                        type: DISCUSSION_COMMENT_TYPE.TASK,
                        avatar: this.utilsService.forShortName(response?.task_name),
                        main_avatar: this.utilsService.forShortName(response?.task_name),
                        profile_image: '#DC3535',
                        taskComment: true,
                    };
                    if (response.task_id) {
                        this.discussionSocketService.emit(
                            discussionSocketEvents.LEAVE_ROOM,
                            {
                                id: this.selectedRoom._id,
                            }
                        );

                        this.discussionSocketService.emit(
                            discussionSocketEvents.JOIN_ROOM,
                            {
                                id: this.selectedRoom._id,
                            }
                        );
                    }
                }
            }
        );
    }

    async onTabChanged(event: any) {
        if (event?.index == 2) {
            this.emitTaskService.setUserList(this.alluserList);
            this.emitTaskService.readByUserList(this.readByUserList);
        }
    }

    async getTimeSheetDetail(company_id: any) {
        (
            await this.timeSheetSettingService.getTimesheetMasterSetting(company_id)
        ).subscribe((res: any) => {
            if (res) {
                this.timeLogData = res.result;
            }
        });
    }

    async updateStoreData() {
        (await this.reportService.filterReport()).subscribe(async (res: any) => {
            const userKeyByValue = _.keyBy(res.result, 'id');
            res.result.group_details.map((ele: any) => userKeyByValue[ele]);
            this.store
                .select('taskState')
                .pipe(take(1))
                .subscribe((ele) => {
                    const old = JSON.parse(JSON.stringify(ele.taskState));
                    old['reportConfig'] = res?.result;
                    this.store.dispatch(taskState({ data: old }));
                });
        });
    }

    updateTaskId(event: any) {
        this.task_id = null;
        setTimeout(() => {
            this.task_id = event?.task_id;
        }, 100);
    }

    closeDialog() {
        this.taskService.subTaskDetails([]);
        this.taskService.selectedTaskDetails.next('');
        this.discussionSocketService.emit(
            discussionSocketEvents.LEAVE_ROOM,{id: this.selectedRoom?._id}
        );
        this.dialogRef.close(false);
    }
}
