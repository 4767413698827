import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api-service/api.service';
import { UserDetailsService } from '../user-details.service';
import { NAVIGATE_ROUTES } from '../../global/constants';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionPlanService {
    apiUrl: string = environment.userUrl;
    responseData: any[] = [];
    userId: any;
    apiEndPoint = NAVIGATE_ROUTES;
    activePlan: any;
    activePlanData: any;

    constructor(
    private apiService: ApiService,
    public userDetail: UserDetailsService
    ) {
        this.userId = CryptoJS.AES.encrypt(
            this.userDetail.userId?.toString().trim(),
            ''
        ).toString();
    }

    async getSubscriptionDetail(data: any): Promise<Observable<any>> {
        this.responseData = [];
        const url = `${this.apiEndPoint.GET_SUBSCRIPTION_ACTIVE_PLAN}?user_id=${this.userId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.responseData.push(resData);
            this.activePlanData = resData;
        });
        return of(this.responseData);
    }

    async subscribeFreeTrialPlan(data: any): Promise<Observable<any>> {
        this.responseData = [];
        const url = `${this.apiEndPoint.FREE_TRIAL_SUBSCRIPTION}?user_id=${this.userId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.responseData.push(resData);
        });
        return of(this.responseData);
    }
}
