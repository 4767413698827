import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PersonalizedUserEmit {
    private userSelect = new ReplaySubject<any>(1);
    userSelect$: Observable<any> = this.userSelect.asObservable();
    private userdataSelect = new ReplaySubject<any>(1);
    userdataSelect$: Observable<any> = this.userdataSelect.asObservable();

    private tabIndex: Subject<any> = new Subject<any>();
    tabIndex$: Observable<any> = this.tabIndex.asObservable();

    private closeData: BehaviorSubject<any> = new BehaviorSubject<any>('');
    closeData$: Observable<any> = this.closeData.asObservable();

    private companySwitch: BehaviorSubject<any> = new BehaviorSubject<any>('');
    companySwitch$: Observable<any> = this.companySwitch.asObservable();

    private tabNavigate: BehaviorSubject<any> = new BehaviorSubject<any>('');
    tabNavigate$: Observable<any> = this.tabNavigate.asObservable();

    getUserID(userSelected: any) {
        this.userSelect.next(userSelected);
    }

    getUserDetails(userdataSelect: any) {
        this.userdataSelect.next(userdataSelect);
    }

    getTabIndex(tabIndex: any) {
        this.tabIndex.next(tabIndex);
    }

    getCloseStatus(closeData: any) {
        this.closeData.next(closeData);
    }
    getCompanySwitch(companySwitch: any) {
        this.companySwitch.next(companySwitch);
    }
    getTabNavigate(tabNavigate: any) {
        this.tabNavigate.next(tabNavigate);
    }
}
