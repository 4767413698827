import { Observable, of } from 'rxjs';
import { ApiService } from './../api-service/api.service';
import { Injectable } from '@angular/core';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { UserDetailsService } from '../user-details.service';

@Injectable({
    providedIn: 'root',
})
export class GetNewMemberService {
    apiEndPoint = NAVIGATE_ROUTES;
    res: any[] = [];
    company_id: any;

    constructor(
    private apiService: ApiService,
    public userDetail: UserDetailsService
    ) {}
    async getCompanyID() {
        this.company_id = await this.userDetail.getCompanyId();
        return this.company_id;
    }

    async getStaffMember(): Promise<Observable<any>> {
        const company_id = await this.getCompanyID();
        this.res.length = 0;
        const url = `${this.apiEndPoint.GET_MEMBER}?companyId=${company_id}&userType=employee`;
        await this.apiService.get(url).then((resData: any) => {
            this.res.length = 0;
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getStaffMember2(company_id: any): Promise<Observable<any>> {
        this.res.length = 0;
        const url = `${this.apiEndPoint.GET_MEMBER}?companyId=${company_id}&userType=employee`;
        await this.apiService.get(url).then((resData: any) => {
            this.res.length = 0;
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getCoMember(): Promise<Observable<any>> {
        const company_id = await this.getCompanyID();
        this.res.length = 0;
        const url = `${this.apiEndPoint.GET_MEMBER}?companyId=${company_id}&userType=outsider`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getPendingMember(): Promise<Observable<any>> {
        const company_id = await this.getCompanyID();
        const url = `${this.apiEndPoint.GET_MEMBER}?companyId=${company_id}&userType=invitee`;
        await this.apiService.get(url).then((resData: any) => {
            this.res.length = 0;
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getStaffMemberInCompany(): Promise<Observable<any>> {
        const company_id = await this.getCompanyID();
        const url = `${this.apiEndPoint.GET_MEMBER}?companyId=${company_id}&userType=employee`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            const responseData = resData;
            for (let i = 0; i < responseData?.length; i++) {
                this.res.push({
                    first_name: resData[i].first_name,
                    last_name: resData[i].last_name,
                    designation: resData[i].designation,
                    roles_assigned: resData[i].roles_assigned,
                    profile_image: resData[i].profile_image,
                    email: resData[i].email,
                });
            }
        });
        return of(this.res);
    }

    async getCoMemberInCompany(): Promise<Observable<any>> {
        const company_id = await this.getCompanyID();
        const url = `${this.apiEndPoint.GET_MEMBER}?companyId=${company_id}&userType=outsider`;
        await this.apiService.get(url).then((response: any) => {
            this.res = [];
            const responseValue = response;
            for (let i = 0; i < responseValue?.length; i++) {
                this.res.push({
                    first_name: response[i].first_name,
                    last_name: response[i].last_name,
                    designation: response[i].designation,
                    roles_assigned: response[i].roles_assigned,
                    profile_image: response[i].profile_image,
                    email: response[i].email,
                });
            }
        });
        return of(this.res);
    }

    async getPendingMemberInCompany(): Promise<Observable<any>> {
        const company_id = await this.getCompanyID();
        const url = `${this.apiEndPoint.GET_MEMBER}?companyId=${company_id}&userType=invitee`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            const responseData = resData;
            for (let i = 0; i < responseData?.length; i++) {
                this.res.push({
                    first_name: resData[i].first_name,
                    last_name: resData[i].last_name,
                    designation: resData[i].designation,
                    roles_assigned: resData[i].roles_assigned,
                    profile_image: resData[i].profile_image,
                    email: resData[i].email,
                });
            }
        });
        return of(this.res);
    }
}
