import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        return moment(value).calendar(null, {
            lastWeek: 'dddd',
            lastDay: '[Yesterday]',
            sameDay: args[0] ? 'hh:mm A' : '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: args[0] ? 'dddd' : 'dd',
            sameElse: function () {
                return '[' + moment(value).format('DD MMM, YYYY') + ']';
            },
        });
    }
}
