<div class="checklist-comment">
  <div class="d-flex align-items-center justify-content-between my-3">
    <div class="checklist-comon">
      <h4>Checklist Comment ({{ data?.checkListName }})</h4>
    </div>
    <div class="cross-arrow" (click)="close()">
      <svg
        width="16"
        height="16"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.7393 1.55L11.6893 0.5L6.73926 5.45L1.78926 0.5L0.739258 1.55L5.68926 6.5L0.739258 11.45L1.78926 12.5L6.73926 7.55L11.6893 12.5L12.7393 11.45L7.78926 6.5L12.7393 1.55Z"
          fill="#424242"
        />
      </svg>
    </div>
  </div>
</div>
<div
  class="mub-dms-checklist-comment-block new-custom-menu-checklist-cls-only checklist-comment-editor"
>
  <app-chat-room [selectedRoom]="data" class="chat-room-modal-cls">
  </app-chat-room>
</div>
