import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
import { UserDetailsService } from '../user-details.service';

@Injectable({
    providedIn: 'root',
})
export class MarkAsApprovalService {
    userDetail: any;
    companyId: any;
    userId: any;
    apiEndPoint = NAVIGATE_ROUTES;
    res: any;
    constructor(public injector: Injector, private apiService: ApiService) {
        this.userDetail = injector.get<UserDetailsService>(UserDetailsService);
        this.userId = this.userDetail.userId;
    }

    async getMarsAsApprovalFlow(
        companyId: any,
        userId: any
    ): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.MARK_AS_APPROVAL_FLOW}?companyId=${companyId}&userId=${userId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }

    async taskUnderReview(data: any, status?: any): Promise<Observable<any>> {
        this.res = [];
        const result = status ?? 0;
        const url = `${this.apiEndPoint.MARK_AS_UNDER_REVIEW}?status=${
            result
        }`;
        await this.apiService.post(url, data).then((_resdata) => {
            this.res.push(_resdata);
        });
        return of(this.res);
    }

    async taskApproveReject(data: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.MARK_AS_APPROVE_REJECT}`;
        await this.apiService.post(url, data).then((res) => {
            this.res.push(res);
        });
        return of(this.res);
    }
}
