import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocumentPreviewComponent } from 'src/app/shared/components/document-preview/document-preview.component';
import { AttachmentServiceService } from 'src/app/shared/services/attachment-service/attachment-service.service';
import { TasksService } from 'src/app/shared/services/task-service/tasks.service';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';

@Component({
    selector: 'app-task-attachment',
    templateUrl: './task-attachment.component.html',
    styleUrls: ['./task-attachment.component.scss'],
})
export class TaskAttachmentComponent implements OnInit {
    isListView = true;
    isGridView = false;
    searchText: any;
    attatchmentDetail: any[] = [];
    commentDocument: any[] = [];
    public attachmentService: AttachmentServiceService;
    public taskService: TasksService;
    public utils: UtilsService;
    _viewPreviewComponent: MatDialogRef<DocumentPreviewComponent>;
    @Input() taskId: any;

    constructor(private injector: Injector, private matDialog: MatDialog) {
        this.attachmentService = injector.get<AttachmentServiceService>(
            AttachmentServiceService
        );
        this.taskService = injector.get<TasksService>(TasksService);
        this.utils = injector.get<UtilsService>(UtilsService);
    }

    ngOnInit(): void {
        if (this.taskId) {
            this.getAttachmentDetail();
        }
    }

    listView() {
        this.isListView = true;
        this.isGridView = false;
    }

    gridView() {
        this.isGridView = true;
        this.isListView = false;
    }

    async getAttachmentDetail() {
        (await this.attachmentService.getDocumentList(this.taskId)).subscribe(
            (res: any) => {
                if (res.data) {
                    if (res.data.taskAttachment) {
                        res?.data?.taskAttachment?.sort((a: any, b: any) => {
                            const dateA: any = new Date(a.created_at);
                            const dateB: any = new Date(b.created_at);
                            return dateB - dateA;
                        });
                        res.data.taskAttachment.forEach(async (doc: any) => {
                            const lastDotIndex = doc.file_path.lastIndexOf('.');
                            if (lastDotIndex !== -1) {
                                const stringAfterLastDot = doc.file_path.slice(lastDotIndex);
                                doc['type'] = stringAfterLastDot;
                                doc['icon'] = await this.utils.getDocumentIcon(doc, false);
                                this.attatchmentDetail.push(doc);
                            }
                        });
                    }
                    if (res.data.commentAttachment) {
                        res?.data?.commentAttachment?.sort((a: any, b: any) => {
                            const dateA: any = new Date(a.created_at);
                            const dateB: any = new Date(b.created_at);
                            return dateB - dateA;
                        });
                        res.data.commentAttachment.forEach(async (doc: any) => {
                            const lastDotIndex = doc.file_path.lastIndexOf('.');
                            if (lastDotIndex !== -1) {
                                const stringAfterLastDot = doc.file_path.slice(lastDotIndex);
                                doc['type'] = stringAfterLastDot;
                                doc['icon'] = await this.utils.getDocumentIcon(doc, false);
                                this.commentDocument.push(doc);
                            }
                        });
                    }
                }
            }
        );
    }

    applyFilter(event: Event) {
        this.searchText = (event.target as HTMLInputElement).value;
    }

    downloadDoc(doc: any, document: any) {
        this._viewPreviewComponent = this.matDialog.open(DocumentPreviewComponent, {
            disableClose: true,
            panelClass: ['custom-modal', 'custom-modal-md', 'custom-preview-file'],
            data: {
                url: doc,
                name: document?.name,
                thumbnaill: document?.thumb_path,
                docType: document?.type,
            },
        });
    }
}
