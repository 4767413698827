<ul class="math_ui-onHover-sub-block">
  <li class="d-flex align-items-center gap-2 justify-content-start">
    <div class="assignee_status-quo">
      <img
        [src]="itemList?.profile_image"
        *ngIf="
          itemList?.profile_image?.charAt(0) !== '#' &&
          itemList?.profile_image?.charAt(0) == 'h'
        "
        class="img-avatar"
        alt="assignee profile"
      />
      <div
        *ngIf="
          itemList?.profile_image?.charAt(0) == '#' ||
          !itemList?.profile_image ||
          itemList?.profile_image?.charAt(0) != 'h'
        "
        class="user-short-name mr-0 margin-right-none1"
        [ngStyle]="{
          'background-color': itemList?.profile_image
        }"
      >
        <span *ngIf="!itemList?.user_name">{{
          utils.forShortName(itemList?.first_name + " " + itemList?.last_name)
        }}</span>
        <span *ngIf="itemList?.user_name">{{
          utils.forShortName(itemList?.user_name)
        }}</span>
      </div>
      <span
        *ngIf="!isOnlineByUserId"
        class="online--status online--status-inside online-status-sidebar"
        [ngClass]="{
          'default-active-status':
            (itemList?.id | userState : onlineArr) == 'online',
          'default-red-active-status':
            (itemList?.id | userLastSeenPipe : onlineArr : isOnlineByUserId)
              ?.length > 8 &&
            (itemList?.id | userState : onlineArr) !== 'online'
        }"
      ></span>
      <span
        class="online--status online--status-inside online-status-sidebar"
        *ngIf="isOnlineByUserId && itemList?.user_id && !itemList?.sender_id"
        [ngClass]="{
          'default-active-status':
            (itemList?.user_id | userStateByUserid : onlineArr) == 'online'
        }"
      ></span>
      <span
        class="online--status online--status-inside online-status-sidebar"
        *ngIf="isOnlineByUserId && itemList?.sender_id"
        [ngClass]="{
          'default-active-status':
            (itemList?.sender_id | userStateByUserid : onlineArr) == 'online'
        }"
      ></span>
    </div>
    <div class="d-flex flex-column align-items-start justify-content-center">
      <span
        class="tip_name text-capitalize text-dwindle d-80 un"
        *ngIf="itemList?.first_name"
        >{{ itemList?.first_name + " " + itemList?.last_name }}</span
      >
      <span
        class="tip_name text-capitalize text-dwindle d-80 ud"
        *ngIf="itemList?.user_name"
        >{{ itemList?.user_name }}</span
      >
      <span
        class="tip_name text-capitalize text-dwindle d-80 us"
        *ngIf="itemList?.sender_name"
        >{{ itemList?.sender_name }}</span
      >
      <span
        *ngIf="
          (itemList?.id | userState : onlineArr) !== 'online' &&
          !isOnlineByUserId
        "
        class="tip_lastseen d-flex"
        ><p
          *ngIf="
            (
              itemList?.id || itemList?.user_id
              | userLastSeenPipe : onlineArr : isOnlineByUserId
            )?.length === 8
          "
        >
          Last Seen:
        </p>
        {{
          itemList?.id || itemList?.user_id
            | userLastSeenPipe : onlineArr : isOnlineByUserId
        }}</span
      >
      <span
        *ngIf="
          (itemList?.sender_id || itemList?.user_id
            | userStateByUserid : onlineArr) !== 'online' && isOnlineByUserId
        "
        class="tip_lastseen d-flex"
        ><p
          *ngIf="
            (
              itemList?.sender_id || itemList?.user_id
              | userLastSeenPipe : onlineArr : isOnlineByUserId
            )?.length === 8
          "
        >
          Last Seen:
        </p>
        {{
          itemList?.sender_id || itemList?.user_id
            | userLastSeenPipe : onlineArr : isOnlineByUserId
        }}
      </span>
      <span
        *ngIf="
          (itemList?.id | userState : onlineArr) == 'online' &&
          !isOnlineByUserId
        "
        class="tip_lastseen"
        >Online</span
      >
      <span
        *ngIf="
          (itemList?.sender_id || itemList?.user_id
            | userStateByUserid : onlineArr) == 'online' && isOnlineByUserId
        "
        class="tip_lastseen"
        >Online</span
      >
    </div>
  </li>
</ul>
