import { Injectable } from '@angular/core';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { Observable, of } from 'rxjs';
import { ApiService } from '../api-service/api.service';
import { UserDetailsService } from '../user-details.service';

@Injectable({
    providedIn: 'root',
})
export class CompanyDetailsService {
    apiEndPoint = NAVIGATE_ROUTES;
    res: any;
    userId: any;

    constructor(
    private apiService: ApiService,
    public userDetail: UserDetailsService
    ) {
        this.userId = this.userDetail.encryptUserId;
    }

    async getCompanyDetails(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.COMPANY}/companyDetail?id=${companyId}&userId=${this.userId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async updateCompanyDetails(
        companyId: any,
        data: any
    ): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.COMPANY}?id=${companyId}`;
        await this.apiService.put(url, data).then((resData) => {
            this.res = [];
            this.res.push(resData);
        });
        return of(this.res);
    }
}
