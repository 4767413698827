import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hoursSecondFormat',
})
export class HoursSecondFormatPipe implements PipeTransform {
    transform(value: any) {
    // Check if the input value is a valid string
        if (!value || typeof value !== 'string') {
            return value;
        }

        // Remove any leading or trailing spaces
        value = value.trim();

        // Split the string into hours and minutes
        const parts = value.split(':');

        // Check if the string has valid hours and minutes
        if (
            (parts.length === 3 || parts.length === 2) &&
      /^\d+$/.test(parts[0]) &&
      /^\d+$/.test(parts[1])
        ) {
            const hours = parseInt(parts[0], 10);
            const minutes = parseInt(parts[1], 10);

            // Format the hours and minutes as HH:mm
            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
                .toString()
                .padStart(2, '0')}`;
            return formattedTime;
        }

        // Return the original value if it doesn't match the expected format
        return value;
    }
}
