<div class="mub-add-client rt-mub-add-client">
  <form [formGroup]="clientDetailForm">
    <div class="row brdr">
      <div class="col-md-6 border-end pe-4">
        <mat-accordion>
          <mat-expansion-panel
            [expanded]="step == 0"
            (opened)="setStep(0)"
            class="mat-elevation-z0"
          >
            <mat-expansion-panel-header>
              <div class="row">
                <div class="col-12">
                  <h2 *ngIf="!isEdit?.editClient" class="page-title mb-0">
                    {{ "ADD_CLIENT" | translate }}
                  </h2>
                  <h2 *ngIf="isEdit?.editClient" class="page-title mb-0">
                    {{ "UPDATE_CLIENT_DETAIL" | translate }}
                  </h2>
                </div>
              </div>
            </mat-expansion-panel-header>

            <div class="row">
              <div
                class="col-md-8 col-sm-8 col-12 mt-2 order-md-0 order-sm-0 order-1"
              >
                <div class="row">
                  <div class="col-12 mt-2">
                    <mat-form-field
                      appearance="standard"
                      class="custom-input-field w-100"
                    >
                      <mat-label>{{ "COMPANY_NAME" | translate }}</mat-label>
                      <img
                        matPrefix
                        src="assets/icons/orgicon.svg"
                        alt="orgicon-icon"
                        draggable="false"
                      />
                      <input
                        class="capitalizeText"
                        matInput
                        formControlName="companyName"
                        appTitleCase
                        placeholder=""
                        trim="blur"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-12 mt-2">
                    <mat-form-field
                      appearance="standard"
                      class="custom-input-field w-100"
                    >
                      <mat-label>{{ "CLIENT_NAME" | translate }}</mat-label>
                      <img
                        matPrefix
                        src="assets/images/usercircle.svg"
                        alt="icon"
                        draggable="false"
                      />
                      <input
                        class="capitalizeText"
                        matInput
                        formControlName="client_name"
                        appTitleCase
                        placeholder=""
                        trim="blur"
                      />
                      <mat-error
                        *ngIf="
                          clientDetailForm.controls['client_name'] &&
                          clientDetailForm.controls['client_name'].hasError(
                            'pattern'
                          )
                        "
                      >
                        {{ "NAME_CONTAIN_ONLY_CHAR" | translate }}</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="col-12 mt-2">
                    <mat-form-field
                      appearance="standard"
                      class="custom-input-field w-100"
                    >
                      <mat-label>{{ "EMAIL" | translate }}</mat-label>
                      <img
                        matPrefix
                        src="assets/icons/login-email-logo.svg"
                        alt="icon"
                        draggable="false"
                      />
                      <input
                        type="email"
                        matInput
                        formControlName="clientEmail"
                        trim="blur"
                      />
                      <mat-error
                        *ngIf="
                          clientDetailForm.controls['clientEmail'].hasError(
                            'required'
                          )
                        "
                      >
                        {{ "EMAIL_REQUIRED" | translate }}
                      </mat-error>
                      <mat-error
                        *ngIf="
                          clientDetailForm.controls['clientEmail'] &&
                          clientDetailForm.controls['clientEmail'].hasError(
                            'pattern'
                          )
                        "
                      >
                        {{ "INVALID_EMAIL" | translate }}</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div
                class="col-md-4 col-sm-4 col-12 mt-2 d-flex justify-content-md-end justify-content-sm-end justify-content-center order-md-1 order-sm-1 order-0"
              >
                <div class="user-profile-pic-section rounded">
                  <mat-form-field
                  [ngStyle]="dataImage?.charAt(0) != '#' ||
                  !dataImage ? 
                  {
                    'background-image': 'url(' + dataImage + ')',
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center'
                  } 
                  : 
                  {
                    'background-color': dataImage,
                    'background-image': 'url(../assets/icons/white-edit.svg)','background-repeat': 'no-repeat','background-position': 'right 30px center'}"
                    class="text-center mb-0 h-100 user-icon no-underline round"
                  >
                    <label class="text-center">
                      <input
                        #clientImg
                        type="file"
                        name="uploadFile"
                        id="uploadFile"
                        class="uploadFile"
                        (change)="uploadFileEvt($event)"
                        accept="image/*"
                        formControlName="clientImg"
                      />
                      <input matInput name="img" readonly />
                      <label
                        *ngIf="
                          (dataImage?.charAt(0) == '#' ||
                            !dataImage ||
                            dataImage?.charAt(0) != 'h') &&
                          isEdit?.editClient
                        "
                        style="cursor: pointer !important"
                        class="abbrevation-text"
                        >{{
                          utils.forShortName(
                            clientDetailForm.controls["client_name"].value
                          )
                        }}</label
                      >
                      <label
                        *ngIf="!isEdit?.editClient && !dataImage"
                        style="cursor: pointer !important"
                        class="short-name"
                        >{{
                          utils.forShortName(
                            clientDetailForm.controls["client_name"].value
                          )
                        }}</label
                      >
                    </label>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mt-2">
                <mat-form-field
                  appearance="standard"
                  class="custom-input-field w-100"
                >
                  <mat-label>{{ "MOBILE_NUMBER" | translate }}</mat-label>
                  <img
                    matPrefix
                    src="./assets/icons/phoneicon.svg"
                    alt="icon"
                    draggable="false"
                  />
                  <ngx-mat-intl-tel-input
                    formControlName="clientMobile"
                    [preferredCountries]="preferredCountries"
                    [enablePlaceholder]="true"
                    [cssClass]="'custom'"
                    [enableSearch]="true"
                    (countryChanged)="changeCountryCode($event)"
                    name="phone"
                    #phone
                    trim="blur"
                  >
                  </ngx-mat-intl-tel-input>
                  <mat-error
                    *ngIf="
                      clientDetailForm.controls['clientMobile'].hasError(
                        'required'
                      )
                    "
                  >
                    {{ "MOBILE_NUMBER_REQUIRED" | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="clientDetailForm.controls['clientMobile']?.errors?.['validatePhoneNumber']"
                  >
                    {{ "MOBILE_NUMBER_VALID_MSG" | translate }}</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-md-6 d-flex align-items-center mt-2">
                <label class="inner-label me-2">{{
                  "GENDER" | translate
                }}</label>
                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="gender"
                >
                  <mat-radio-button
                    class="c-radio-button me-2"
                    [disableRipple]="true"
                    value="male"
                  >
                    {{ "MALE" | translate }}
                  </mat-radio-button>
                  <mat-radio-button
                    class="c-radio-button"
                    [disableRipple]="true"
                    value="female"
                  >
                    {{ "FEMALE" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-2">
                <mat-form-field
                  appearance="standard"
                  class="custom-input-field w-100"
                >
                  <mat-label>{{ "COMPANY_ADDRESS" | translate }}</mat-label>
                  <img
                    matPrefix
                    src="assets/icons/locationicon.svg"
                    alt="icon"
                    draggable="false"
                  />
                  <input
                    matInput
                    formControlName="companyAddress"
                    appTitleCase
                    placeholder=""
                    trim="blur"
                  />
                  <mat-error
                    *ngIf="
                      clientDetailForm.controls['companyAddress'].hasError(
                        'required'
                      )
                    "
                  >
                    {{ "COMPANY_ADDRESS_REQUIRED" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-2">
                <mat-form-field
                  appearance="standard"
                  class="custom-input-field w-100"
                >
                  <mat-label>{{ "CLIENT_ATTACHMENTS" | translate }}</mat-label>
                  <img
                    matPrefix
                    src="assets/icons/attachment.svg"
                    alt="icon"
                    draggable="false"
                  />
                  <input
                    #myFileInput
                    matInput
                    type="file"
                    multiple
                    class="custom-file-upload"
                    (change)="handelSelectAttachment($event)"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <mat-chip-list class="custom-attachment-list">
                <mat-chip
                  *ngFor="let attachment of attachments; let i = index"
                  class="u-m-chip m-s-u-chip"
                  [attr.data-index]="i"
                >
                  <img
                    [src]="attachment?.image"
                    alt="icon"
                    draggable="false"
                    class="me-2"
                  />
                  <p class="custom-name text-truncate custom-width-truncate">
                    {{ attachment?.file?.name }}
                  </p>
                  <mat-icon
                    matChipRemove
                    (click)="handelRemoveUploadAttachment(i)"
                    >cancel</mat-icon
                  >
                </mat-chip>

                <mat-chip
                  *ngFor="let attachment of attachment_details"
                  class="u-m-chip m-s-u-chip"
                  [attr.data-index]="attachment?.item?._id"
                  (dblclick)="handleSelectFile(attachment?.item)"
                >
                  <img
                    [src]="attachment?.image"
                    alt="icon"
                    draggable="false"
                    class="me-2"
                  />
                  <p class="custom-name text-truncate custom-width-truncate">
                    {{ attachment?.item?.name }}
                  </p>
                  <mat-icon
                    matChipRemove
                    (click)="handleDeleteAttachment(attachment?.item?._id)"
                    >cancel</mat-icon
                  >
                </mat-chip>
              </mat-chip-list>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="col-md-6 ps-4 pr-0">
        <ng-scrollbar #scrollable>
          <mat-accordion>
            <mat-expansion-panel
              [expanded]="step == 0"
              (opened)="setStep(0)"
              class="mat-elevation-z0"
            >
              <mat-expansion-panel-header>
                <div class="row">
                  <div class="col-12">
                    <h2 class="page-title mb-0">
                      {{ "CUSTOM_FIELD" | translate }}
                    </h2>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <div *ngIf="fields.length > 0">
                <app-custom-filed
                  [fields]="fields"
                  [form]="customForm"
                ></app-custom-filed>
              </div>
              <div
                *ngIf="
                  (fields.length === 0 || !is_client_active) &&
                  showCollaboration
                "
              >
                <div class="no-items-block mx-auto">
                  <img
                    src="assets/icons/noitemicon.svg"
                    alt="icon"
                    draggable="false"
                    class="mb-4"
                  />
                  <h4 class="mb-2">
                    {{ "COLLABORATION_MESSAGE" | translate }}
                  </h4>
                  <p>{{ "COLLABORATION_MESSAGE_LINES" | translate }}</p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-scrollbar>
      </div>
    </div>
    <mat-accordion class="flex-grow-1 rt-add-services">
      <mat-expansion-panel
        [expanded]="step == 2"
        (opened)="setStep(2)"
        (closed)="setStep(0)"
        class="mat-elevation-z0 h-100 d-flex flex-column"
      >
        <mat-expansion-panel-header>
          <div class="row">
            <div class="col-12">
              <h2 *ngIf="!isEdit?.editClient" class="page-title mb-0">
                {{ "ADD_SERVICE" | translate }}
              </h2>
            </div>
            <div class="col-12">
              <h2 *ngIf="isEdit?.editClient" class="page-title mb-0">
                {{ "EDIT_SERVICE_BUTTON" | translate }}
              </h2>
            </div>
          </div>
        </mat-expansion-panel-header>
        <ng-container>
          <div
            formArrayName="subTask"
            class="flex-grow-1 rt-add-services-content"
          >
            <ul class="list-unstyled mb-0" (click)="$event.stopPropagation()">
              <div
                class="d-flex align-items-center mb-3 gap-2 search-status"
                (click)="$event.stopPropagation()"
              >
                <input
                  type="text"
                  autocomplete="off"
                  placeholder="{{ 'TYPE_HERE_TO_SEARCH' | translate }}"
                  class="form-control c-s-input custom-input custom-input-design custom-w-one border-0"
                  (keyup)="applyFilter($event)"
                />
              </div>
              <li
                [formGroupName]="i"
                *ngFor="
                  let ele of serviceList;
                  let i = index
                "
              >
                <div class="row custom-row as__custom-row" [ngStyle]="{
                  'display': (searchText && !ele.name.toLowerCase().includes(searchText))  ? 'none':''}">
                  <div class="col-lg-6 custom-coled align-self-center coled1">
                    <mat-checkbox
                      class="me-2 custom-checkbox"
                      (change)="setReporting($event, ele.id, i)"
                      formControlName="service"
                    >
                      <div class="d-flex align-items-center flex-start gap-2">
                        <img
                          *ngIf="ele.profile"
                          class="mx-2"
                          alt="icon"
                          draggable="false"
                          style="width: 2rem; height: 2rem; border-radius: 100%"
                          src="{{ ele.profile }}"
                        />
                        <div
                          *ngIf="!ele.profile"
                          class="user-short-name mr-0 text-capitalize sdsd"
                          [ngStyle]="{ 'background-color': ele.profile }"
                        >
                          {{ utils.forShortName(ele.name) }}
                        </div>
                        <span
                          *ngIf="toHighlight != ''"
                          [innerHTML]="ele?.name | highlight : toHighlight"
                          class="text-capitalize d-inline"
                        ></span>
                        <span
                          *ngIf="toHighlight === ''"
                          [innerHTML]="ele?.name"
                          class="text-capitalize d-inline"
                        ></span>
                      </div>
                    </mat-checkbox>
                  </div>
                  <div class="col-lg-6 custom-coled align-self-center">
                    <div>
                      <span class="d-flex" mat-button [matMenuTriggerFor]="dotsMenu" 
                      [ngClass]="{
                        'disable-cls':!getServiceInfo(i)
                      }"
                      [ngStyle]="{'cursor': 'pointer'}"
                      
                              >
                                <span *ngIf="!selectedMembers[i]?.length">
                                  <img  _ngcontent-jbu-c269=""
                                        matprefix=""
                                        src="assets/icons/full_name.svg"
                                        alt="icon"
                                        draggable="false"
                                        class="ng-tns-c28-20"
                                      />
                                    Members
                                </span>
                                <mat-chip-list>
                                  <mat-chip
                                    *ngFor="
                                      let user of selectedMembers[i]
                                    "
                                    [removable]="true"
                                    (removed)="
                                      onToppingRemoved(user.id, i)
                                    "
                                    class="u-m-chip m-s-u-chip"
                                  >
                                    <img
                                      *ngIf="
                                        user?.profile_image?.charAt(0) !==
                                        '#'
                                      "
                                      src="{{ user?.profile_image }}"
                                      alt="icon"
                                      draggable="false"
                                      class="me-3"
                                    />
                                    <div
                                      *ngIf="
                                        user.profile_image?.charAt(0) ==
                                        '#'
                                      "
                                      class="user-short-name mr-0"
                                      [ngStyle]="{
                                        'background-color':
                                          user?.profile_image
                                      }"
                                    >
                                      {{ utils.forShortName(user?.name) }}
                                    </div>
                                    {{ user?.name }}
                                    <mat-icon matChipRemove
                                      >cancel</mat-icon
                                    >
                                  </mat-chip>
                                </mat-chip-list>
                      </span>
                      <mat-menu #dotsMenu="matMenu" class="as__custom-row" (closed)="onInputChange({target:{value:''}}, i);resetInput(i)">
                        <div class="col-sm-12 text-right bg-dark-grey d-flex align-items-center d-flex pe-0 ps-3">
                            <mat-checkbox
                              (click)="$event.stopPropagation()"
                              *ngIf="filteredProviders[i]?.length"
                              id="allSelected{{ i }}"
                              [(ngModel)]="allSelected[i]"
                              [ngModelOptions]="{
                                standalone: true
                              }"
                              (change)="selectAll(i)"
                              class="p-0 custom-checkbox__only-border"
                            ></mat-checkbox>
                            <input
                              (click)="$event.stopPropagation()"
                              
                              #multiUserSearch
                              type="text"
                              autocomplete="off"
                              placeholder="Type here to Search"
                              (keydown)="$event.stopPropagation()"
                              class="form-control c-s-input custom-input border-0 w-100 m-w-100"
                              (input)="onInputChange($event, i)"
                            />
                         </div>

                        <div class="scroll-fix">

                        
                        <div class="d-flex" *ngFor="let data of filteredProviders[i]">
                          <mat-checkbox
                            (click)="$event.stopPropagation()"
                              [checked]="data.selected"
                              
                              (change)="selectClientMember($event,i,data)"
                              class="p-2 custom-checkbox__only-border mx-2"
                            >
                            <img
                              *ngIf="
                                data?.profile_image?.charAt(0) !==
                                '#'
                              "
                              src="{{ data?.profile_image }}"
                              alt="icon"
                              draggable="false"
                              class="me-2 user-img"
                            />
                            <div
                              *ngIf="
                                data?.profile_image?.charAt(0) ==
                                '#'
                              "
                              class="user-short-name me-2"
                              [ngStyle]="{
                                'background-color':
                                  data?.profile_image
                              }"
                            >
                              {{ utils.forShortName(data?.name) }}
                            </div>
                            <span [innerHTML]="data?.name"></span>
                          </mat-checkbox>
                        </div>
                        <div class="text-center" *ngIf="!filteredProviders[i]?.length">No data found</div>
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </li>
              <div *ngIf="isVisible" >{{"NO_DATA_FOUND" | translate}}</div>
            </ul>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="row mt-auto footer-action-block pr-3">
      <div class="col-12 d-flex justify-content-end">
        <button
          class="btn btn--bordered me-2"
          mat-button
          mat-flat-button
          (click)="closeDialog()"
          type="button"
        >
          {{ "CANCEl_BUTTON" | translate }}
        </button>
        <button
          *ngIf="!isEdit?.editClient"
          class="btn btn--primary"
          type="submit"
          [disabled]="!clientDetailForm.valid"
          (click)="submit() && onSubmit.emit(this.customForm?.value)"
          mat-button
          mat-flat-button
        >
          {{ "ADD_CLIENT" | translate }}
        </button>
        <button
          *ngIf="isEdit?.editClient"
          class="btn btn--primary"
          type="submit"
          [disabled]="
            !(
              (clientDetailForm.valid &&
                (clientDetailForm.dirty || customForm?.dirty)) ||
              attachments.length > 0
            )
          "
          (click)="editClientDetails(clientDetailForm.value)"
          mat-button
          mat-flat-button
        >
          {{ "UPDATE_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
