import { CountryISO } from 'ngx-intl-tel-input';
import { CustomFieldService } from './../../services/organization-settings/custom-field.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-custom-filed',
    templateUrl: './custom-filed.component.html',
    styleUrls: ['./custom-filed.component.scss'],
})
export class CustomFiledComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  @Input() fields: any;
  @Input() form: any = FormGroup;
  countries: any;
  preferredCountries: CountryISO[] = [CountryISO.India];
  tArray: any = [];
  selectedCountry: any = [];
  minDate: any;
  taskState: any;

  constructor(
    private customFieldService: CustomFieldService,
    private translate: TranslateService,
    private store: Store<{ taskState: TaskState }>
  ) {
      translate.setDefaultLang('en');
  }

  async ngOnInit() {
      this.store.select('taskState').subscribe((ele: any) => {
          if (!ele.taskState) return;
          this.taskState = ele?.taskState;
      });
      await this.getCountries();
      this.minDate = new Date();
  }

  async getCountries() {
      this.countries = this.taskState?.country;
  }
  setDate(event: any, controlName: any) {
      this.form.controls[controlName].patchValue(event);
  }
}
