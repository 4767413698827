<div [style]="{ 'background-color': selectColor }" class="p-4 rt-add-notes">
  <div class="row mb-4">
    <div class="col-12">
      <h2 class="page-title mb-0">
        <input
          placeholder="Add Title"
          class="form-control mt-0"
          matInput
          type="text"
          (change)="noteTitle($event)"
          [(ngModel)]="title"
        />
      </h2>
    </div>
  </div>
  <div>
    <quill-editor
      [modules]="quillConfig"
      (onContentChanged)="addNoteData($event)"
      #editor
      class="add-notes-class"
    >
    </quill-editor>
  </div>
  <div class="mt-2">
    <div
      class="notes-edit-icons notes-edit-icons-ctm in-oneline-cls-add"
      [ngClass]="{ 'icon-set': noteData }"
    >
      <div
        class="pin-icon"
        *ngIf="noteData?.isPin == 'true'"
        style="width: fit-content"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          (click)="pinNote('true')"
          (click)="$event.stopPropagation()"
          *ngIf="notesId"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.295 6.655L15 5.95L10 1L9.345 1.71L9.935 2.3L4.19 7.16L3.33 6.305L2.625 7L5.455 9.84L1 14.29L1.705 15L6.16 10.545L9 13.375L9.695 12.665L8.84 11.81L13.7 6.065L14.295 6.655ZM8.13 11.1L4.9 7.87L10.645 3L13 5.355L8.13 11.1Z"
            fill="white"
          />
        </svg>
      </div>
      <span class="custom-all-pin" *ngIf="noteData?.isPin == 'false'">
        <img
          src="assets/icons/pin.svg"
          alt="icon"
          draggable="false"
          (click)="pinNote('false')"
          *ngIf="notesId"
        />
      </span>
      <span class="d-flex justify-content-end">
        <img
          src="assets/icons/delete.svg"
          alt="icon"
          class="me-2"
          draggable="false"
          (click)="deleteNote()"
          *ngIf="noteData?.noteId && (!isSharedNote || noteData.can_delete)"
        />
        <button
          class="color-indicator"
          type="button"
          mat-stroked-button
          [matMenuTriggerFor]="color"
        >
          <img
            src="assets/icons/color.svg"
            alt="icon"
            draggable="false"
            class="me-2"
          />
        </button>
      </span>
    </div>
  </div>
  <div>
    <mat-menu #color>
      <ul
        class="add-nots-ctm list-unstyled mt-2 d-flex align-items-start flex-column"
      >
        <li class="me-2 mb-2">
          <span
            *ngFor="let code of colorCode; let i = index"
            class="me-3"
            (click)="$event.stopPropagation()"
          >
            <mat-radio-group [(ngModel)]="selectColor">
              <mat-radio-button
                [value]="code.value"
                class="custom-color-radiobutton"
                [ngStyle]="{
                  'background-color': code.label,
                  height: '25px',
                  width: '25px',
                  'border-radius': '50%'
                }"
                (change)="selectedColor(code.value)"
              >
              </mat-radio-button>
            </mat-radio-group>
          </span>
        </li>
      </ul>
    </mat-menu>
  </div>
  <div class="row mt-4">
    <div class="col-12 d-flex justify-content-end">
      <button
        class="btn btn--bordered me-2"
        mat-button
        mat-flat-button
        type="button"
        (click)="cancelClick()"
      >
        Cancel
      </button>
      <button
        *ngIf="!notesId"
        class="btn btn--primary"
        type="submit"
        mat-button
        mat-flat-button
        (click)="save()"
        [disabled]="!title || !selectColor || saveDisable"
      >
        Save
      </button>
      <button
        *ngIf="notesId && (!isSharedNote || noteData.can_edit)"
        class="btn btn--primary"
        type="submit"
        mat-button
        mat-flat-button
        [disabled]="saveDisable"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </div>
</div>
