<div class="checklist-box">
  <h4 class="block-title block-title-16 px-4">
    {{ "IMPORT_CHECKLIST" | translate }}
  </h4>
  <mat-accordion>
    <mat-expansion-panel
      class="checklist-expansion-panel"
      *ngFor="let a of customTemplate"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <input
            type="checkbox"
            [(ngModel)]="a.checked"
            class="p-0 custom-checkbox__only-border me-3"
            (change)="checkSelected($event, a)"
          />
          <div class="d-flex align-items-center checklist-user">
            <img *ngIf="a.image" src="{{ a.image }}" alt="" />
            <label
              *ngIf="!a.image"
              style="cursor: pointer !important"
              class="short-name mr-1"
              placement="auto"
              >{{ utils.forShortName(a.title) }}</label
            >
            <div class="m-1 checklist-title-wrap">
              <h4>{{ a.title }}</h4>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul class="checklist-lists">
        <li *ngFor="let labelValue of a.check_list_label">
          {{ labelValue }}
        </li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
  <button
    class="btn anchor-link my-3 float-end me-4"
    (click)="checkedChecklist()"
    [disabled]="checklistData.length == 0"
  >
    Submit
  </button>
</div>
