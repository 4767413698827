import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class AttachmentServiceService {
    apiEndPoint = NAVIGATE_ROUTES;
    res: any;
    constructor(private apiService: ApiService) {}

    async getDocumentList(taskId: any): Promise<Observable<any>> {
        this.res = [];
        const url = `${this.apiEndPoint.TASK_ATTACHMENT}?taskId=${taskId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = resData;
        });
        return of(this.res);
    }
}
