<div class="row">
  <div class="col-12 mb-3">
    <h4 class="page-title mb-0">Client information</h4>
  </div>
  <div class="col-12">
    <ul class="list-unstyled client-info-list mt-3">
      <li>
        <div class="client-card">
          <h4 class="client-company-name" *ngIf="clientInfo?.companyName">
            {{ clientInfo?.companyName }}
          </h4>
          <p class="client-name text-capitalize">
            {{ clientInfo?.name
            }}<span
              class="badge-btn text-capitalize mx-2"
              *ngIf="clientInfo?.gender"
              >{{ clientInfo?.gender }}</span
            >
          </p>

          <div class="client-contact-info-list mt-4">
            <div class="row align-items-center">
              <div class="col-lg-2 my-1">
                <div class="img-box">
                  <img src="assets/images/bluemobile.svg" alt="" />
                </div>
              </div>
              <div class="col-lg-10 my-1">
                <p class="mb-0">
                  {{
                    clientInfo?.mobileNumber
                      ? "+" +
                        clientInfo?.isd_code +
                        " " +
                        clientInfo?.mobileNumber
                      : "-"
                  }}
                </p>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-2 my-1">
                <div class="img-box">
                  <img src="assets/images/bluemail.svg" alt="" />
                </div>
              </div>
              <div class="col-lg-10 my-1">
                <p class="mb-0">
                  {{ clientInfo?.email ? clientInfo?.email : "-" }}
                </p>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-2 my-1">
                <div class="img-box">
                  <img src="assets/images/bluemarker.svg" alt="" />
                </div>
              </div>
              <div class="col-lg-10 my-1">
                <p class="mb-0">
                  {{ clientInfo?.address ? clientInfo?.address : "-" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li *ngIf="customFieldData.length > 0">
        <div class="other-info-card">
          <h4 class="client-company-name">Other</h4>
          <div class="other-detail-block">
            <div *ngFor="let fieldData of customFieldData">
              <div class="label-block">{{ fieldData.label }}</div>
              <div class="other-detail-value-block">{{ fieldData.value }}</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="col-12 d-flex justify-content-end mt-3">
    <button
      (click)="closeDialog()"
      class="btn btn--bordered me-2"
      mat-button
      mat-flat-button
    >
      Cancel
    </button>
    <button
      (click)="copyInfo(clientInfo)"
      class="btn btn--primary"
      mat-button
      mat-flat-button
    >
      Copy to Clipboard
    </button>
  </div>
</div>
