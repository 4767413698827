import { SubscriptionPlanService } from './../services/subscription-service/subscription-plan.service';
import { Injectable, Injector } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    NavigationEnd,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { UserDetailsService } from '../services/user-details.service';
import { PLAN_TYPE } from '../global/constants';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate {
    private subscribeGuard: Subscription;

    canActivate(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree {
        const url: string = state.url;
        return this.checkSubscription(url);
    }

    public subscriptionPlan: SubscriptionPlanService;
    userId: any;

    constructor(
    private router: Router,
    private injector: Injector,
    public userDetail: UserDetailsService
    ) {
        this.subscriptionPlan = injector.get<SubscriptionPlanService>(
            SubscriptionPlanService
        );
        if (this.userDetail?.userId) {
            this.userId = CryptoJS.AES.encrypt(
                this.userDetail.userId?.toString().trim(),
                ''
            ).toString();
        }
    }

    checkSubscription(_url: string): true | UrlTree {
        this.subscribeGuard = this.router.events.subscribe(async (event) => {
            if (
                event instanceof NavigationEnd &&
        localStorage.getItem('authorization')
            ) {
                (
                    await this.subscriptionPlan.getSubscriptionDetail(this.userId)
                ).subscribe((resData: any) => {
                    if (
                        event.url === '/subscription/plans' ||
            event.url === '/management' ||
            event.url === '/management/subscription'
                    ) {
                        if (this.subscribeGuard) {
                            this.subscribeGuard.unsubscribe();
                        }
                        return true;
                    }
                    if (resData[0]?.expired && !resData[0].expiredPlan) {
                        this.router.navigateByUrl('/subscription/plans');
                    } else if (
                        resData[0]?.expired &&
            resData[0]?.expiredPlan?.user_plan?.plan?.type != PLAN_TYPE.FREE
                    ) {
                        this.router.navigateByUrl('/management/subscription');
                    } else if (
                        resData[0]?.expired &&
            resData[0]?.expiredPlan?.user_plan?.plan?.type === PLAN_TYPE.FREE
                    ) {
                        this.router.navigateByUrl('/subscription/plans');
                    } else {
                        if (this.subscribeGuard) {
                            this.subscribeGuard.unsubscribe();
                        }
                        return true;
                    }
                    if (this.subscribeGuard) {
                        this.subscribeGuard.unsubscribe();
                    }
                });
            }
            return true;
        });
        return true;
    }
}
