import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification/notification.service';
import { KeyValue } from '@angular/common';
import { UtilsService } from '../../services/utils-service/utils.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AddNotesComponent } from 'src/app/pages/notes/add-notes/add-notes.component';
import { AddEditProjectComponent } from 'src/app/pages/project-management/add-edit-project/add-edit-project.component';
import { AddEditTaskComponent } from 'src/app/pages/task-management/add-edit-task/add-edit-task.component';
import { DISCUSSION_TYPES } from '../../global/constants';
import { Subscription } from 'rxjs';
import { UserState } from '../../ngrx/reducer/user-state.reducer';
import { Store } from '@ngrx/store';
import { taskState as TaskState } from 'src/app/shared/ngrx/reducer/task-state.reducer';
@Component({
    selector: 'app-notification-preview',
    templateUrl: './notification-preview.component.html',
    styleUrls: ['./notification-preview.component.scss'],
})
export class NotificationPreviewComponent implements OnInit {
    notificationList: any = [];
    onlineArr: any = [];
    notificationLists: any = [];
    today: any;
    public notificationService: NotificationService;
    public utils: UtilsService;
    subscription: Subscription;
    taskState: any[] = [];
    hoverObject:any;
    constructor(
        private router: Router,
        public injector: Injector,
        public dialog: MatDialog,
        private store: Store<{ userState: UserState }>,
        private store1: Store<{ taskState: TaskState }>
    ) {
        this.notificationService =
            injector.get<NotificationService>(NotificationService);
        this.utils = injector.get<UtilsService>(UtilsService);
        this.today = new Date();
    }

    ngOnInit(): void {
        this.subscription = this.notificationService.addNotification$.subscribe(
            (data: any) => {
                if (data) {
                    this.getNotificationList();
                }
            }
        );
        this.getNotificationList();
        this.store.select('userState').subscribe((ele: any) => {
            this.onlineArr = ele.updateState;
        });
        setTimeout(() => {
            this.store1.select('taskState').subscribe((ele: any) => {
                this.taskState = ele?.taskState?.reportConfig?.company_members
            });
        }, 1000);
    }

    async getNotificationList() {
        const notificationObj = {
            flag: 0,
            is_mobile: 0,
        };
        (
            await this.notificationService.getNotificationList(notificationObj)
        ).subscribe((res: any) => {
            this.notificationList = res.data;
            this.notificationList?.sort((a: any, b: any) => {
                const date1: any = new Date(a.createdAt);
                const date2: any = new Date(b.createdAt);
                return date2 - date1;
            });
            this.notificationList = this.notificationList?.slice(0, 5);
            this.notificationLists = this.groupBy(this.notificationList, 'createdAt');
        });
    }

    groupBy(date: any, key: any) {
        return date
            .map((d: any) => {
                const onlyDate = new Date(d[key]);
                onlyDate.setHours(0, 0, 0);
                d['onlyDate'] = onlyDate;
                return d;
            })
            .reduce(function (rv: any, x: any) {
                (rv[x['onlyDate']] = rv[x['onlyDate']] || []).push(x);
                return rv;
            }, {});
    }

    originalOrder = (
        a: KeyValue<number, string>,
        b: KeyValue<number, string>
    ): number => {
        return 0;
    };

    checkDate(date: any) {
        const date1 = moment(this.today).format('ll');
        const date2 = moment(date).format('ll');
        if (date1 === date2) {
            return true;
        } else {
            return false;
        }
    }

    async redirect(notification: any) {
        if (notification.redirect_obj.is_delete != 1) {
            switch (notification.type) {
                case 'task':{
                    const taskId = notification.redirect_obj.task_id;
                    this.dialog.open(AddEditTaskComponent, {
                        panelClass: ['custom-modal', 'add-task-modal'],
                        data: {
                            isEditTask: true,
                            editTaskId: taskId,
                        },
                    });
                    break;
                }
                case 'project':{
                    const projectId = notification.redirect_obj.project_id;
                    this.dialog.open(AddEditProjectComponent, {
                        panelClass: [
                            'custom-modal',
                            'custom-modal-md',
                            'add-client-modal',
                            'custom-model-width',
                        ],
                        data: { projectId: projectId },
                    });
                    this.router.navigate(['/project']);
                    break;
                }
                case 'dms':{
                    const dmsId = notification.redirect_obj.dms_id;
                    this.router.navigate(['drive/my', dmsId]);
                    break;
                }
                case 'discussion':{
                    const discussionId = notification.redirect_obj.discussion_id;
                    this.router.navigate(['/discussion'], {
                        queryParams: {
                            discussion_id: discussionId,
                            discussionType: DISCUSSION_TYPES['GENERAL'],
                        },
                        skipLocationChange: true,
                    });
                    break;
                }
                case 'notes':{
                    const noteId = notification.redirect_obj.note_id;
                    this.router.navigate(['/notes']);
                    this.notificationService.setShareNoteTab(1);
                    const noteData = { noteId: noteId };
                    this.dialog.open(AddNotesComponent, {
                        panelClass: [
                            'notes-modal',
                            'notes-modal-md',
                            'main-custom-notes-module',
                        ],
                        data: noteData,
                        autoFocus: false,
                    });
                    break;
                }
                case 'timesheet':{
                    this.router.navigate(['/time-sheet']);
                    this.notificationService.setTimesheetTab(1);
                    break;
                }
                default:
                    break;
            }
        }

        const id = {
            notification_id: notification._id,
        };
        (await this.notificationService.markAsReadAll(id)).subscribe(() => {
            this.notificationService.setNotification(true);
            this.getNotificationList();
        });
    }
    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
    onHoverInAssignee(data: any) {
        this.taskState.forEach(element => {
            if (data == element.user_id) {
                this.hoverObject = {
                    id: element.id,
                    member_id: element.member_id,
                    user_id: element.user_id,
                    name: element.first_name+' '+element.last_name,
                    profile_image: element.profile_image,
                    user_name: element.first_name+' '+element.last_name
                }
            }
        });
    }
}
