/**
 * @Directive Drop Zone
 */

import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { AlertService } from '../services/alert-service/alert.service';

export enum FileSizeType {
  MB = 'MB',
  GB = 'GB',
}

@Directive({
    selector: '[appDropZone]',
})
export class DropZoneDirective {
  @Output() onFileDropped = new EventEmitter<any>();
  @Input() maxFileSize: number;
  @Input() fileUploadLimit = 2;
  @Input() totalUploadedSize = 0;

  constructor(private _alertService: AlertService) {}

  // Calculate file size in MB or GB
  calculateFileSize(size: number, type: string) {
      switch (type) {
      case FileSizeType['MB']:
          return size / 1024 / 1024;
      case FileSizeType['GB']:
          return size / 1024 / 1024 / 1024;
      }
  }

  // Compare if actual file size shold be less then max file size
  isConsiderableSize(size: number) {
      return size <= this.maxFileSize;
  }

  @HostBinding('style.opacity') private opacity = '1';
  @HostBinding('style.border') private border = 'null';

  @HostListener('dragover', ['$event']) public onDragOver(
      event: DragEvent
  ): any {
      event.preventDefault();
      event.stopPropagation();

      this.opacity = '0.8';
      this.border = '2px dashed #bbb';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(
      event: DragEvent
  ): any {
      event.preventDefault();
      event.stopPropagation();
      this.opacity = '1';
      this.border = 'none';
  }

  @HostListener('drop', ['$event']) public ondrop(event: DragEvent): any {
      event.preventDefault();
      event.stopPropagation();
      this.opacity = '1';
      this.border = 'none';
      const totalFileUpload = 0;
      const isMaximumUploadLimitExited = false;
      const files: any = [...(event.dataTransfer.files as any)];
      this.onFileDropped.emit(files);
  }
}
