import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    message = 'Are you sure want to delete?';
    confirmButtonText = 'Yes';
    cancelButtonText = 'No';
    title: string;
    note = '';
    showCloseButton = false;
    constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    public translate: TranslateService
    ) {
        dialogRef.disableClose = true;
        if (data) {
            this.title = data.title || '';
            this.message = data.message || this.message;
            this.note = data.note || this.note;
            this.showCloseButton = data.showCloseButton || this.showCloseButton;

            if (data.buttonText) {
                this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
                this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
            }
        }
    }
    ngOnInit(): void {
    // Intialize component
    }

    onConfirmClick(): void {
        this.dialogRef.close(true);
    }
    onCancelClick(): void {
        this.dialogRef.close(false);
    }
    onClose() {
        this.dialogRef.close();
    }
}
