<form [formGroup]="taskDetailForm" class="h-100">
  <div formArrayName="subTask" class="col-12 px-0 position-custom">
    <div
      *ngFor="let email of subTasksList.controls; let i = index"
      [formGroupName]="i"
      class="task-acion-bar my-1"
    >
      <div
        class="me-auto flex-grow-1"
        [ngClass]="{
          'disble-close-task':
            taskDetailForm.get('subTask').value[i].subTaskStatus
              ?.status_name === 'closed'
        }"
      >
        <input
          type="text"
          formControlName="subTaskName"
          trim="blur"
          class="form-control"
          placeholder="Write your sub task"
          (keydown)="onKeyDown($event)"
          (keyup)="quickReplyCheck($event, 'checkList', i)"
        />
      </div>

      <div
        [ngClass]="{
          'disble-close-task':
            taskDetailForm.get('subTask').value[i].subTaskStatus
              ?.status_name === 'closed'
        }"
      >
        <span mat-button [matMenuTriggerFor]="statusmenuSubTask">
          <span
            class="badge badge--primary caret custom-badge status-badge"
            [ngStyle]="{
              color: taskDetailForm.get('subTask').value[i]?.subTaskStatus
                ?.status_color
                ? taskDetailForm.get('subTask').value[i].subTaskStatus
                    ?.status_color
                : statusList[0]?.status_color,
              'background-color': taskDetailForm.get('subTask').value[i]
                ?.subTaskStatus?.status_color
                ? taskDetailForm.get('subTask').value[i].subTaskStatus
                    ?.status_color + '15'
                : statusList[0]?.status_color + '15'
            }"
          >
            <span
              class="s-icon mx-1"
              [ngStyle]="{
                'background-color': taskDetailForm.get('subTask').value[i]
                  ?.subTaskStatus?.status_color
                  ? taskDetailForm.get('subTask').value[i].subTaskStatus
                      ?.status_color
                  : statusList[0]?.status_color
              }"
            ></span>
            <span class="text-capitalize">
              {{
                taskDetailForm.get("subTask").value[i]?.subTaskStatus
                  ?.status_name
              }}</span
            >
          </span>
        </span>
        <mat-menu
          #statusmenuSubTask="matMenu"
          class="custom--status-menu"
          xPosition="before"
        >
          <button
            mat-menu-item
            *ngFor="let status of statusList"
            (click)="setSubTaskStatus(status, i)"
          >
            <span
              class="badge badge--primary text-capitalize"
              [ngStyle]="{
                color: status.status_color,
                'background-color': status.status_color + '15'
              }"
            >
              <span
                class="s-icon mx-1"
                [ngStyle]="{
                  'background-color': status.status_color
                }"
              ></span>
              <span class="text-capitalize">{{ status.status_name }}</span>
            </span>
          </button>
        </mat-menu>
      </div>
      <div
        [ngClass]="{
          'disble-close-task':
            taskDetailForm.get('subTask').value[i].subTaskStatus
              ?.status_name === 'closed'
        }"
      >
        <span
          mat-button
          [matMenuTriggerFor]="prioritymenu"
          class="badge badge--primary caret custom-badge priority-badge priority-menu"
          [ngStyle]="{
            color: taskDetailForm.get('subTask').value[i]?.subTaskPriority
              ?.priority_color
              ? taskDetailForm.get('subTask').value[i]?.subTaskPriority
                  ?.priority_color
              : priorityList[0]?.priority_color,
            'background-color': taskDetailForm.get('subTask').value[i]
              ?.subTaskPriority?.priority_color
              ? taskDetailForm.get('subTask').value[i]?.subTaskPriority
                  ?.priority_color + '15'
              : priorityList[0]?.priority_color + '15'
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12.36 6L12.44 6.39L12.76 8H18V14H14.64L14.56 13.61L14.24 12H7V6H12.36ZM14 4H5V21H7V14H12.6L13 16H20V6H14.4L14 4Z"
              [attr.fill]="
                taskDetailForm.get('subTask').value[i]?.subTaskPriority
                  ?.priority_color
                  ? taskDetailForm.get('subTask').value[i]?.subTaskPriority
                      ?.priority_color
                  : priorityList[0]?.priority_color
              "
            ></path>
          </svg>
          <span class="text-capitalize"
            >{{
              taskDetailForm.get("subTask").value[i]?.subTaskPriority
                ?.priority_name
                ? taskDetailForm.get("subTask").value[i]?.subTaskPriority
                    ?.priority_name
                : priorityList[0]?.priority_name
            }}
          </span>
        </span>
        <mat-menu
          #prioritymenu="matMenu"
          class="custom--status-menu custom-menu-list p-0 priority-menu"
          xPosition="before"
        >
          <button
            mat-menu-item
            *ngFor="let ele of priorityList"
            (click)="setSubTaskPriority(ele, i)"
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.36 6L12.44 6.39L12.76 8H18V14H14.64L14.56 13.61L14.24 12H7V6H12.36ZM14 4H5V21H7V14H12.6L13 16H20V6H14.4L14 4Z"
                  [attr.fill]="ele?.priority_color"
                ></path>
              </svg>
              <span class="text-capitalize">{{
                ele?.priority_name
              }}</span></span
            >
          </button>
        </mat-menu>
      </div>
      <a
        [ngClass]="{
          'disble-close-task':
            taskDetailForm.get('subTask').value[i].subTaskStatus
              ?.status_name === 'closed'
        }"
        [matMenuTriggerFor]="assignee"
      >
        <img
          src="assets/icons/perdonwithleftarrow.svg"
          class="me-2"
          alt="arrow"
        />
      </a>
      <mat-menu
        #assignee="matMenu"
        class="multi-select-chip-menu custom-menu-list custom-project-popup"
      >
        <app-group-assignee-common-checkbox
          (click)="$event.stopPropagation()"
          [isSubTask]="true"
          [coMembersList]="coMembersList"
          [selectedAssigneesFromBE]="{
            assignees: taskDetailForm.get('subTask').value[i].subTaskAssignee
              ? taskDetailForm.get('subTask').value[i].subTaskAssignee
              : [],
            groups: taskDetailForm.get('subTask').value[i].subTaskGroup
              ? taskDetailForm.get('subTask').value[i].subTaskGroup
              : [],
            mainTask: false
          }"
          (onSelectCheckBox)="onSelectSubTaskCheckBox($event, i)"
        >
        </app-group-assignee-common-checkbox>
      </mat-menu>

      <div
        [ngClass]="{
          'disble-close-task':
            taskDetailForm.get('subTask').value[i].subTaskStatus
              ?.status_name === 'closed'
        }"
      >
        <a
          [matMenuTriggerFor]="subtaskduedate"
          [matTooltip]="
            dateNow(
              taskDetailForm.get('subTask').value[i].subTaskStartDate,
              taskDetailForm.get('subTask').value[i]?.subTaskDueDate
            )
          "
        >
          <img
            src="assets/icons/calendericon.svg"
            class="me-2"
            alt="calender"
          />
        </a>
        <mat-menu
          #subtaskduedate="matMenu"
          class="custom-sub-menu-dropdown-menu calender-sub-menu p-0"
          xPosition="before"
        >
          <app-task-due-date-common
            [dueDateData]="{
              endDate: taskDetailForm.get('subTask').value[i]?.subTaskDueDate,
              startDate:
                taskDetailForm.get('subTask').value[i].subTaskStartDate,
              isUpdate: true
            }"
            [isTimeSheetSetting]="isTimeSheetSetting"
            [isSubTask]="true"
            [maxDateProject]="maxDateProject"
            [minDateProject]="minDateProject"
            (selectedDate)="selectedSubTaskDate($event, i)"
          >
          </app-task-due-date-common>
        </mat-menu>
      </div>

      <div>
        <img
          alt="delete"
          src="assets/images/deletedarkicon.svg"
          (click)="deleteSubTask(i)"
        />
      </div>

      <div
        *ngIf="
          isEnableDropdown?.isCheckListEnable[i] && quickReplyFilter?.length > 0
        "
        class="form-group-inner custom-placeholder-form-group custon-data-list custon-data-list-4--subtask mt-0"
      >
        <ng-container *ngFor="let reply of quickReplyFilter">
          <div
            (click)="handleStaticResultSelected(reply, 'checkList', i)"
            class="items"
          >
            <label class="mb-0">
              {{ reply.title }}
            </label>
            <span>{{ reply.message }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</form>
