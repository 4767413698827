<div
  class="add-edit-task-main-block position-relative task-list-date-only rt-createTaskFormTaskList"
>
  <div
    class="cross-ics-member pstn-absolute right-14 mt-2 z-ex"
    (click)="closeDialog()"
  >
    <img alt=""
      src="../../../../assets/images/crossblack.svg"
      width="24px"
      height="24px"
    />
  </div>

  <div class="left--block task-list-date-left--block">
    <app-create-task
      (isUpdateTaskid)="updateTaskId($event)"
      class="flex-grow-1 task-list-date-create-task-block rt-createTaskFormTaskList-left"
    ></app-create-task>
  </div>
  <div
    class="right--block mub-dms-comment-block-add-edit rt-createTaskFormTaskList-right"
    *ngIf="data?.email?.length <= 1"
  >
    <mat-tab-group
      animationDuration="0ms"
      class="custom-tab add-task-rght-block-tab attachment-design"
      (selectedTabChange)="onTabChanged($event)"
    >
      <mat-tab label="Comment" class="common-right-comment-editor">
        <ng-template matTabContent>
          <app-chat-room
            class="task-comment common-right-comment-editor"
            [selectedRoom]="selectedRoom"
          ></app-chat-room>
        </ng-template>
      </mat-tab>
      <mat-tab label="Attachment">
        <ng-template matTabContent>
          <app-task-attachment
            *ngIf="task_id"
            [taskId]="task_id"
          ></app-task-attachment>
        </ng-template>
      </mat-tab>
      <mat-tab label="Log Activity">
        <ng-template matTabContent>
          <app-log-management-tab
            *ngIf="task_id"
            [taskId]="task_id"
          ></app-log-management-tab>
        </ng-template>
      </mat-tab>
      <mat-tab label="Time Log" *ngIf="this.timeLogData?.is_enabled">
        <ng-template matTabContent>
          <app-time-log-tab
            *ngIf="task_id"
            [taskId]="task_id"
            [status]="taskData?.status"
            class="time-log-tab-forTaskListRightTab"
          ></app-time-log-tab>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
