import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlighter',
})
export class HighlighterPipe implements PipeTransform {
    transform(value: any, ...args: any[]): unknown {
        const [search, item, selectedItem] = args;

        if (!search || item._id !== selectedItem) {
            return value;
        }

        const re = new RegExp(search, 'gi');
        const list = value.replace(
            re,
            '<span class=\'highlighted-text\'>' + search + '</span>'
        );
        return list;
    }
}
