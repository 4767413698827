import { createReducer, on } from '@ngrx/store';
import * as UserStates from '../action/user-state.actions';

export interface UserState {
  updateState: [];
}

export const initialState: UserState = {
    updateState: [],
};

export const reducer = createReducer(
    initialState,
    on(UserStates.updateUserStatus, (state: UserState, action): any => {
        return {
            ...state,
            updateState: action.data,
        };
    }),
    on(UserStates.changeUserStatus, (state: UserState, action): any => {
        const [user] = action.data;
        const activeUser = state.updateState.map((ele: any) => {
            return {
                ...ele,
                user_state:
          ele.user_id === user.user_id ? user.user_state : ele.user_state,
            };
        });

        return {
            ...state,
            updateState: activeUser,
        };
    })
);

export function stateReducer(state: any, action: any) {
    return reducer(state, action);
}
