import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { SocketEvents } from '../global/constants';
import { LocalStorageService } from './storage-service/local-storage.service';
import { UserState } from '../ngrx/reducer/user-state.reducer';
import {
    changeUserStatus,
    updateUserStatus,
} from '../ngrx/action/user-state.actions';
import { UserDetailsService } from './user-details.service';
@Injectable({
    providedIn: 'root',
})
export class SocketService {
    socket = io(environment.socketUrl, {
        query: {
            authorization:
        'Bearer ' +
        `${this.localStorageService.getLocalStore('authorization')}`,
        },
        transports: ['websocket', 'polling'],
    });
    socketConfig: any;

    constructor(
    private localStorageService: LocalStorageService,
    private store: Store<UserState>,
    public userDetailService: UserDetailsService
    ) {}
    public async initSocket() {
        this.socket = await io(environment.socketUrl, {
            query: {
                authorization:
          'Bearer ' +
          `${this.localStorageService.getLocalStore('authorization')}`,
            },
            transports: ['websocket', 'polling'],
        });
        await this.socket.connect();
        this.listenGlobalEvents();
    }

    public async reconnect() {
        await this.socket.disconnect();
        await this.socket.connect();
    }

    public emit(event: string, data: any) {
        return this.socket.emit(event, data);
    }

    public on(event: string, cb: any) {
        return this.socket.on(event, cb);
    }

    public discConnect(eventName: string) {
        this.socket.removeAllListeners(eventName);
    }

    public disconnect() {
        return this.socket.disconnect();
    }

    private listenGlobalEvents() {
        this.getActiveUser();
        this.getDeactiveUser();
        this.getUserState();
        this.getUpdatedUserState();
    }

    getUserState() {
        this.socket.on(SocketEvents.ON_USER_ACTIVE_STATUS, (res: any) => {
            if (res) {
                this.store.dispatch(updateUserStatus({ data: res }));
            }
        });
    }

    getUpdatedUserState() {
        this.socket.on(
            `${SocketEvents.ON_USER_ACTIVE_UPDATE}${this.userDetailService.decrypt}`,
            (res: any) => {
                if (res) {
                    this.store.dispatch(changeUserStatus({ data: [res] }));
                }
            }
        );
    }

    getActiveUser() {
        this.socket.on(
            SocketEvents.ON_GET_USER_CONNECTED,
            async (response: any) => {
            }
        );
    }

    getDeactiveUser() {
        this.socket.on(
            SocketEvents.ON_GET_USER_DISCONNECTED,
            async (response: any) => {
            }
        );
    }
}
