import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ChangeDetectorRef,
    ViewChild,
} from '@angular/core';
import { TasksService } from '../../services/task-service/tasks.service';
import { UserDetailsService } from '../../services/user-details.service';
import { UtilsService } from '../../services/utils-service/utils.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { taskState as TaskState } from 'src/app/shared/ngrx/reducer/task-state.reducer';
import { Store } from '@ngrx/store';
@Component({
    selector: 'app-check-box-common',
    templateUrl: './check-box-common.component.html',
    styleUrls: ['./check-box-common.component.scss'],
})
export class CheckBoxCommonComponent implements OnInit {
    allCheck = false;
    isChecked = false;
  @Input() memberListData: any;
  @Input() listName: string;
  @Input() isDisable: boolean;
  @Input() isShowSelectAll = true;
  @Input() isSingleSelect = false;
  @Output('onSelectCheckBox') onSelectCheckBox = new EventEmitter();
  @Output('assigneeSelected') assigneeSelected = new EventEmitter();
  @Output('assigneeDeSelected') assigneeDeSelected = new EventEmitter();
  @Input() isGroup: any;
  @Input() isTask: any;
  @Input() getAssignee: any;
  @Input() followerData: any;
  originalMemberList: any;
  subscription: Subscription;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  usersList: any[] = [];
  groupUserList: any[] = [];
  selectedElement: any[] = [];
  disabled = false;
  isToggled = false;
  selecteditems: any[] = [];
  searchTerm = '';
  unfilteredarray: any[] = [];
  groupName: string;
  constructor(
    public userDetailService: UserDetailsService,
    private taskService: TasksService,
    public utils: UtilsService,
    public _cd: ChangeDetectorRef,
    private store1: Store<{ taskState: TaskState }>
  ) {}
  ngOnChanges() {
      this.originalMemberList = Object.assign([], this.memberListData);
  }
  ngOnInit() {
      this.store1.select('taskState').subscribe((ele: any) => {
          ele?.taskState?.reportConfig?.company_all_members.forEach(
              (element: any) => {
                  if (
                      !this.usersList.some(
                          (item: any) => item.id === element.id && !item.is_deleted
                      )
                  ) {
                      this.usersList.push(element);
                  }
              }
          );
      });
      this.originalMemberList = Object.assign([], this.memberListData);
      this.subscription = this.taskService.searchText.subscribe(async (_res) => {
          await this.updateAllCheck();
      });
      this.allCheck = false;
  }
  async updateAllCheck() {
      this.allCheck =
      this.memberListData != null &&
      this.memberListData.every((t: any) => t.isCheck);
      if (this.originalMemberList.length > 0) {
          this.memberListData = _.orderBy(
              this.originalMemberList,
              'isCheck',
              'desc'
          );
          this._cd.detectChanges();
      }
  }

  async selectCheckBox(element?: any) {
      if (this.originalMemberList.length == 0) {
          this.originalMemberList = Object.assign([], this.memberListData);
      }
      const selectedId: any = [];
      if (this.isSingleSelect) {
          this.memberListData.find((data: any) =>
              data.id === element.id && element.isCheck
                  ? selectedId.push(data.id)
                  : ''
          );
      } else {
          await this.memberListData.forEach(async (ele: any) => {
              if (ele.isCheck) {
                  await selectedId.push(ele.id);
              }
          });
      }
      this.updateAllCheck();
      this.selecteditems.push(selectedId);
      this.onSelectCheckBox.emit(selectedId);
      this.selectedElement = [...new Set(selectedId)];
      this.menuTrigger.closeMenu();
  }

  async selectAllMembers(event: any) {
      await this.memberListData.forEach((ele: any) => {
          ele.isCheck = event.checked;
      });
      this.selectCheckBox();
  }
  ngOnDestroy() {
      this.subscription?.unsubscribe();
  }
  openGroupMembers(group: any) {
      this.searchTerm = '';
      this.groupName = group.name;
      const groupmembers: any = group.members_details;
      const result = this.usersList.filter(
          (obj) =>
              groupmembers.includes(obj.id) &&
        !this.followerData.includes(obj.id) &&
        !obj.is_deleted
      );

      const userList: any[] = [];
      let newArray: any[] = [];
      if (this.isToggled) {
          this.isToggled = false;
          this.menuTrigger.closeMenu();
      } else {
          this.isToggled = true;
          this.menuTrigger.openMenu();
      }

      if (this.isGroup && this.selectedElement.length > 0) {
          if (this.selectedElement.includes(group.id)) {
              this.disabled = true;
              newArray = result.map((obj) => ({ ...obj, isCheck: true }));
              this.groupUserList = newArray;
          } else {
              this.disabled = true;
              newArray = result.map((item) => ({ ...item, isCheck: false }));
              newArray.forEach((item) => {
                  if (this.getAssignee.includes(item.id)) {
                      item.isCheck = true;
                  } else {
                      item.isCheck = false;
                  }
              });
              this.groupUserList = newArray;
          }
      } else {
          this.disabled = false;
          newArray = result.map((item) => ({ ...item, isCheck: false }));
          newArray.forEach((item) => {
              if (this.getAssignee.includes(item.id)) {
                  item.isCheck = true;
              } else {
                  item.isCheck = false;
              }
          });
          this.groupUserList = newArray;
      }
      this.unfilteredarray = this.groupUserList;
      if (!this.isToggled) {
          this.menuTrigger.closeMenu();
      }
  }
  menuItemClicked(): void {
      // Add your menu item logic here
  }
  async assigneeSelect(event: any) {
      const selected: any[] = [];

      if (event.isCheck) {
          // Your logic when checkbox is checked
          selected.push(event.id);
          this.assigneeSelected.emit(selected);
      } else {
          this.assigneeDeSelected.emit(event.id);
      }
  }
  updateFilter() {
      const lowercasedInput = this.searchTerm.toLowerCase();
      if (this.searchTerm === '') {
          this.groupUserList = this.unfilteredarray;
          return;
      }

      this.groupUserList = this.groupUserList.filter(
          (item) =>
              item.first_name.toLowerCase().includes(lowercasedInput) ||
        item.last_name.toLowerCase().includes(lowercasedInput)
      );
  }
}
