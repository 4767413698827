<div class="modal-header p-0">
  <img src="assets/images/guideModal/headerimg.png" alt="" class="img-fluid" />
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 my-3">
      <h4 class="modal-block-title">Welcome {{ user }}!</h4>
    </div>
    <div class="col-12 mb-3">
      <div class="card w-a-c-card">
        <div
          class="card-body d-flex align-items-center"
          (click)="discardClicked('Started')"
        >
          <img src="assets/images/guideModal/user1.svg" alt="" class="me-3" />
          <div class="content-body">
            <h5>Get Started!</h5>
            <p class="mb-0">
              Create a new company, add members, assign projects and tasks
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3">
      <div class="card w-a-c-card">
        <a
          href="https://www.youtube.com/channel/UC0WW7iaInjLYObk6mubbCQA"
          class="text-decoration-none"
          target="_blank"
          (click)="discardClicked('Tutorial')"
        >
          <div class="card-body d-flex align-items-center">
            <img
              src="assets/images/guideModal/video-lesson1.svg"
              alt=""
              class="me-3"
            />
            <div class="content-body">
              <h5>Watch our Tutorial Video</h5>
              <p class="mb-0">
                Explore various features that can make your Organization more
                Productive.
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-12 mb-3">
      <div class="card w-a-c-card">
        <a
          href="https://guide.taskopad.com"
          target="_blank"
          class="text-decoration-none"
          (click)="discardClicked('Guide')"
        >
          <div class="card-body d-flex align-items-center">
            <img src="assets/images/guideModal/book1.svg" alt="" class="me-3" />
            <div class="content-body">
              <h5>Quick Start Guide</h5>
              <p class="mb-0">
                Search our Guide for information on everything from creating
                Companies to managing Tasks and Projects.
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-12 mb-3">
      <div class="card w-a-c-card">
        <a
          href="https://calendly.com/taskopad"
          target="_blank"
          class="text-decoration-none"
          (click)="discardClicked('Schedule')"
        >
          <div class="card-body d-flex align-items-center">
            <img
              src="assets/images/guideModal/calendar1.svg"
              alt=""
              class="me-3"
            />
            <div class="content-body">
              <h5>Schedule a Demo</h5>
              <p class="mb-0">
                See a live demonstration, get answers to your questions, and
                learn why TaskOPad is the right solution for your company.
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-12 mb-3">
      <div class="card w-a-c-card">
        <div
          class="card-body d-flex align-items-center"
          (click)="discardClicked('Contact')"
        >
          <img
            src="assets/images/guideModal/complain1.svg"
            alt=""
            class="me-3"
          />
          <div class="content-body">
            <h5>Contact us</h5>
            <p class="mb-0">
              For Sales + 91 9898 593 359 | For Support - + 91 7041 793 359
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center" (click)="discardClicked('Skip')">
      <a class="anchor-link">Skip for now</a>
    </div>
  </div>
</div>
