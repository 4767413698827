import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ThemeService } from './theme/theme.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    currentURL: any;
    @ViewChild('reload') reload: any;
    constructor(private themeService: ThemeService, private router: Router) { }
    @Output('regenerateGraph') regenerateGraph = new EventEmitter();
    toggle() {
        const active = this.themeService.getActiveTheme();
        if (active.name == 'light') {
            this.themeService.setTheme('dark');
        } else {
            this.themeService.setTheme('light');
        }
    }
    isToggled = false;
    buildVersion: any = environment.buildVersion;

    ngOnInit() {
        this.router.events.subscribe((_val) => {
            this.currentURL = this.router.url;
        });
        this.currentURL = this.router.url;
        const active = this.themeService.getActiveTheme();
        this.themeService.setTheme(active.name);
    }
    regenerateTableData(event: any) {
        this.regenerateGraph.emit(event);
        this.isToggled = event;
    }
}
