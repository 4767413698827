<div class="card attachment--card log-management-activity-ctm">
  <div class="card-header d-flex align-items-center bg-white">
    <ul class="list-unstyled mb-0 d-flex align-items-center w-100">
      <li class="flex-grow-1 me-3">
        <input
          class="form-control c-s-input custom-input border-0 w-100 m-w-100"
          placeholder="Type here to Search"
          type="text"
          [mentionConfig]="{ items: alluserList, labelKey: 'name' }"
          [mentionListTemplate]="mentionListTemplate"
          (input)="onInputChange($event)"
        />
        <ng-template #mentionListTemplate let-item="item">
          <div class="d-flex">
            <img
              alt="avtar"
              *ngIf="item?.profile && item?.profile?.charAt(0) != '#'"
              src="{{ item?.profile }}"
              class="img-avatar mx-2"
            />
            <div
              *ngIf="item?.profile?.charAt(0) == '#' || !item?.profile"
              class="mx-2 user-short-name"
              [ngStyle]="{ 'background-color': item?.profile }"
            >
              {{ utils.forShortName(item?.name) }}
            </div>
            &nbsp;
            <span>{{ item.name }}</span>
          </div>
        </ng-template>
      </li>
      <li
        *ngIf="readUnreadData?.length == 0 && memberTotalCount > 1"
        class="me-3"
      >
        <a class="d-flex align-items-center block-title block-title-12">
          {{ "READ_BY" | translate }} ({{ readUnreadData?.length }}/{{
            memberTotalCount
          }})
        </a>
      </li>
      <li *ngIf="readUnreadData?.length > 0" class="me-3">
        <a
          [matMenuTriggerFor]="menu"
          class="d-flex align-items-center block-title block-title-12"
        >
          {{ "READ_BY" | translate }} ({{ readUnreadData?.length }}/{{
            memberTotalCount
          }})
        </a>
        <mat-menu
          #menu="matMenu"
          class="custom-menu-list custom-user-list"
          xPosition="before"
        >
          <ul
            class="list-unstyled mb-0 py-0"
            (click)="$event.stopPropagation()"
          >
            <li *ngFor="let element of readUnreadData">
              <div class="d-flex align-items-center">
                <div
                  *ngIf="element?.image?.charAt(0) == '#' || !element?.image"
                  class="user-short-name"
                  [ngStyle]="{ 'background-color': element?.profile_image }"
                >
                  {{ utils.forShortName(element?.name) }}
                </div>
                <div
                  class="me-3"
                  *ngIf="element?.image && element?.image?.charAt(0) != '#'"
                >
                  <img
                    width="30"
                    height="30"
                    src="{{ element?.image }}"
                    alt=""
                  />
                </div>
                <div class="c-details">
                  <h4>{{ element?.name }}</h4>
                  <p>{{ element?.created_at | date : "MMM d, y h:mm a" }}</p>
                </div>
              </div>
            </li>
          </ul>
        </mat-menu>
      </li>
      <li>
        <button mat-button [matMenuTriggerFor]="menu">
          <img alt="calender-icon" src="assets/icons/calendericon.svg" />
        </button>
        <mat-menu #menu="matMenu" class="log-cal-mega-menu">
          <div class="search-cal-ctm-wrp" (click)="$event.stopPropagation()">
            <div class="search-cal-ctm">
              <mat-calendar
                [maxDate]="todayDate"
                [(selected)]="dateRange"
                (selectedChange)="rangeTaskDueDate($event)"
              ></mat-calendar>
              <div
                class="search-cal-start-end-date-stm"
                [ngStyle]="{ 'font-size': '12px' }"
                *ngIf="startDate && endDate"
              >
                <span class="mx-3">{{ startDate | date : "MMM d, y" }}</span> -
                <span class="mx-3">{{ endDate | date : "MMM d, y" }}</span>
              </div>
            </div>
          </div>
        </mat-menu>
      </li>
    </ul>
  </div>
</div>

<div class="box-body pt-1 px-4 h-100">
  <div class="log-activity-box">
    <ng-scrollbar #scrollable style="height: 67vh">
      <div class="row mb-3" *ngFor="let data of logActivityArr">
        <div class="col-12 mb-3 position-relative">
          <h4 class="sub-block-title text-dark title-w-divider">
            {{ data?.date | date : "EEEE d MMMM y" }}
          </h4>
        </div>
        <div class="col-12">
          <ul class="list-unstyled log-activity-list">
            <li *ngFor="let ele of data?.data">
              <time class="me-2">{{
                ele?.createdAt | date : "shortTime"
              }}</time>
              <span class="outer-hover main-span user--img">
                <span
                  class="user--img"
                  *ngIf="
                    ele?.profile_image && ele?.profile_image?.charAt(0) != '#'
                  "
                >
                  <img alt="avtar" src="{{ ele?.profile_image }}" class="img-avatar mx-2" />
                  <span
                    class="online--status"
                    [ngClass]="{
                      'default-active-status':
                        (ele.user_id | userStateByUserid : onlineArr) ==
                        'online'
                    }"
                  ></span>
                </span>
                <span
                  class="user--img"
                  *ngIf="
                    ele?.profile_image?.charAt(0) == '#' || !ele?.profile_image
                  "
                >
                  <div
                    class="mx-2 user-short-name"
                    [ngStyle]="{ 'background-color': ele?.profile_image }"
                  >
                    {{ utils.forShortName(ele?.user_name) }}
                  </div>
                  <span
                    class="online--status"
                    [ngClass]="{
                      'default-active-status':
                        (ele.user_id | userStateByUserid : onlineArr) ==
                        'online'
                    }"
                  ></span>
                </span>
                <app-member-hover
                  class="hoverimg math_ui-onHover-sub-block main-app-member"
                  [ngClass]="{
                    'assignee_div-only':
                      ele?.profile_image?.charAt(0) == '#' ||
                      !ele?.profile_image ||
                      ele?.profile_image?.charAt(0) != 'h'
                  }"
                  [ngStyle]="{
                    position: 'absolute',
                    background: '#fff',
                    'z-index': '3'
                  }"
                  [itemList]="ele"
                  [isOnlineByUserId]="true"
                ></app-member-hover>
              </span>
              <p [innerHTML]="ele?.log | safeHtml" style="color: #222"></p>
            </li>
          </ul>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
