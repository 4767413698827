import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { ApiService } from '../api-service/api.service';
@Injectable({
    providedIn: 'root',
})
export class ClientServiceService {
    constructor(private apiService: ApiService) {}
    res: any;
    apiEndPoint = NAVIGATE_ROUTES;
    clientId: any;

    async getClientList(
        companyId: any,
        page?: any,
        limit?: any,
        search?: any
    ): Promise<Observable<any>> {
        let url = `${this.apiEndPoint.CLIENT}?id=${companyId}`;
        if (page) {
            url += `&page=${page}`;
        }
        if (limit) {
            url += `&limit=${limit}`;
        }
        if (search) {
            url += `&search=${search}`;
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getClientListWithoutCustomField(
        companyId: any,
        page?: number,
        limit?: number,
        search?:string
    ): Promise<Observable<any>> {
        let url = `${this.apiEndPoint.CLIENT}clientList?id=${companyId}`;
        if (page) {
            url += `&page=${page}`;
        }
        if (limit) {
            url += `&limit=${limit}`;
        }
        if (search) {
            url += `&search=${search}`;
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async addNewClient(data: any): Promise<Observable<any>> {
        this.res = [];
        await this.apiService
            .post(this.apiEndPoint.CLIENT, data)
            .then((resdata) => {
                this.res.push(resdata);
            });
        return of(this.res);
    }

    async deleteClient(clientId: any, companyId: any) {
        this.res = [];
        const url = `${this.apiEndPoint.CLIENT}?id=${clientId}&companyId=${companyId}`;
        await this.apiService.delete(url).then((data) => {
            this.res = data;
        });
        return of(this.res);
    }

    async editClientDetails(data: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.CLIENT}?id=${this.clientId}`;
        await this.apiService.put(url, data).then((resdata) => {
            this.res = [];
            this.res = resdata;
        });
        return of(this.res);
    }

    async getClientDetails() {
        const url =
      `${this.apiEndPoint.CLIENT}` + `clientDetail?id=${this.clientId}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getClientDetailsById(data:any) {
        const url =
      `${this.apiEndPoint.CLIENT}` + `clientDetail?id=${data}`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async getMemberListOfService(companyId: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.MEMBER_LIST}members?companyId=${companyId}&userType=all`;
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }

    async bulkClientDelete(clientIds: any): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.BULK_CLIENT_DELETE}`;
        await this.apiService
            .deleteWithForm(url, clientIds)
            .then((resData: any) => {
                this.res = [];
                this.res = resData;
            });
        return of(this.res);
    }

    async bulkExportClient(data: any): Promise<Observable<any>> {
        this.res = [];
        await this.apiService
            .post(this.apiEndPoint.BULK_EXPORT_CLIENT, data)
            .then((resdata) => {
                this.res.push(resdata);
            });
        return of(this.res);
    }
    async bulkExportMember(data: any): Promise<Observable<any>> {
        this.res = [];
        await this.apiService
            .post(this.apiEndPoint.BULK_EXPORT_MEMBER, data)
            .then((resdata) => {
                this.res.push(resdata);
            });
        return of(this.res);
    }

    async getClientListForOverView(
        companyId: any,
        page?: any,
        limit?: any,
        search?: any
    ): Promise<Observable<any>> {
        let url = `${this.apiEndPoint.CLIENT}overview?id=${companyId}`;
        if (page) {
            url += `&page=${page}`;
        }
        if (limit) {
            url += `&limit=${limit}`;
        }
        if (search) {
            url += `&search=${search}`;
        }
        await this.apiService.get(url).then((resData: any) => {
            this.res = [];
            this.res = resData;
        });
        return of(this.res);
    }
}