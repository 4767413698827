import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../services/storage-service/local-storage.service';
import { AlertService } from '../services/alert-service/alert.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root',
})
export class HttpErrorHandlerService {
    isUserVerifiedOrNot: any;
    lastCode: any;
    currentUrl: string | undefined;
    errroEvent: any = new Subject();
    setFileSize: any;
    error: string | undefined;
    isAlertOpen: boolean = false;
    constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private toastrService: ToastrService,
    public translation: TranslateService,
    private spinner: NgxSpinnerService,
    public notificationService: AlertService
    ) {}

    async handleError(err: any) {
        this.currentUrl = this.router.url.split('/')[2];
        this.error =
      err.error.error ||
      err.error.err ||
      err.error.msg ||
      err.error.message ||
      'Internal server error!';
        if (err.status === 400) {
            this.lastCode = err.status;
            this.toastrService.error(this.error, 'Error');
            this.spinner.hide();
            if (this.currentUrl === 'tasks') {
                this.errroEvent.next(this.error);
                return false;
            }
            return true;
        } else if (err.status === 404) {
            this.checkLastErrorStatus(err);
            this.spinner.hide();
            return true;
        } else if (err.status === 401) {
            if (err.error.isEmailVerified === 0) {
                this.spinner.hide();
                this.logout();
            } else if(err.statusText==='Unauthorized'){
                if (!this.isAlertOpen) {
                    alert("Your session has expired. Please log in again.");
                    this.isAlertOpen= true;
                    this.router.navigate(["/"]);
                    this.logout();
                    location.reload();
                }
                return true;
            }
            else {
                this.checkLastErrorStatus(err);
                this.spinner.hide();
                this.logout();
                return true;
            }
        } else if (err.status === 402) {
            this.checkLastErrorStatus(err);
            this.router.navigate(['/' + '/subscription/plans']);
            this.spinner.hide();
            return true;
        } else if (err.status === 403 || err.status === 500 || err.status === 504) {
            this.lastCode = err.status;
            this.toastrService.error(this.error, 'Error');
            this.spinner.hide();
            return true;
        } else if (err.status === 0) {
            this.lastCode = err.status;
            this.toastrService.error(
                'There is no network connection right now. Please try again later.',
                'Error'
            );
            this.spinner.hide();
            return true;
        } else if (err.status === 406) {
            this.lastCode = err.status;
            this.toastrService.error('Invalid data Error');
            this.spinner.hide();
            return true;
        } else {
            return false;
        }
    }

    async logout() {
        this.localStorageService.clearStorage();
        await this.localStorageService.clearRequiredDataFromIndexedDB();
    }

    checkLastErrorStatus(err: any) {
        if (this.lastCode !== err.status) {
            this.lastCode = err.status;
            this.toastrService.error(this.error, 'Error');
        }
    }
}
