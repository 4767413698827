<div class="row">
  <div *ngIf="!data.projectId">
    <h2 class="page-title mb-0">{{ "CREATE_PROJECT" | translate }}</h2>
  </div>
  <div *ngIf="data.projectId" class="col-12">
    <h2 class="page-title mb-0">{{ "UPDATE_PROJECT_DETAIL" | translate }}</h2>
  </div>
</div>
<div>
  <form [formGroup]="projectDetailForm" class="rt-create-project">
    <div class="row">
      <div class="col-md-12 col-sm-8 col-12 mt-2 order-md-0 order-sm-0 order-1">
        <div class="row">
          <div class="col-12 mt-2">
            <mat-form-field
              appearance="standard"
              class="custom-input-field w-100"
            >
              <input
                matInput
                formControlName="projectName"
                class="project-field-m"
                placeholder="Project Name"
                trim="blur"
                type="text"
              />
              <mat-error
                *ngIf="
                  projectDetailForm.controls['projectName'].hasError('required')
                "
              >
                {{ "PROJECT_NAME_REQUIRED" | translate }}</mat-error
              >
              <div class="project-icons">
                <img src="/assets/images/projectname.svg" alt="project Name"/>
              </div>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-12">
        <mat-form-field appearance="standard" class="custom-input-field w-100">
          <textarea
            rows="1"
            class="project-field-m textarea-scrollable description"
            [ngStyle]="{ 'font-size': '16px', width: '95%' }"
            matInput
            formControlName="projectDesc"
            placeholder="Description"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
          ></textarea>
          <mat-error
            *ngIf="
              projectDetailForm.controls['projectDesc'].hasError('required')
            "
          >
            {{ "PROJECT_DSCRIPTION_REQUIRED" | translate }}</mat-error
          >
          <div class="project-icons">
            <img src="/assets/images/dicussionicon.svg" alt="discussion icon"/>
          </div>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-2 ctm-date-picker-cls">
      <div class="col-6">
        <mat-form-field appearance="standard" class="custom-input-field w-100">
          <mat-datepicker-toggle
            matPrefix
            [for]="startpicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startpicker></mat-datepicker>
          <input
            placeholder="Start Date"
            matInput
            [matDatepicker]="startpicker"
            formControlName="projectStartDate"
            [readonly]="true"
            (click)="startpicker.open()"
            [required]="false"
            [min]="todayDate"
            [max]="
              projectDetailForm.controls['projectEndDate'].value
                ? projectDetailForm.controls['projectEndDate'].value
                : null
            "
            (dateChange)="projectStartDate($event.value)"
          />
          <mat-error
            *ngIf="
              projectDetailForm.controls['projectStartDate'].hasError(
                'required'
              )
            "
          >
            {{ "PROJECT_START_DATE_REQUIRED" | translate }}</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field
          appearance="standard"
          class="custom-input-field w-100"
          [ngClass]="{
            'disable-class':
              isSetNoDueDate == true ||
              !projectDetailForm.controls['projectStartDate'].value
          }"
        >
          <mat-datepicker-toggle
            matPrefix
            [for]="endpicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endpicker></mat-datepicker>

          <input
            placeholder="End Date"
            matInput
            [matDatepicker]="endpicker"
            formControlName="projectEndDate"
            [readonly]="true"
            (click)="endpicker.open()"
            [required]="false"
            [min]="
              projectDetailForm.controls['projectStartDate'].value
                ? projectDetailForm.controls['projectStartDate'].value
                : todayDate
            "
            (dateChange)="projectEndDate($event.value)"
          />
          <mat-error
            *ngIf="
              projectDetailForm.controls['projectEndDate'].hasError('required')
            "
          >
            {{ "PROJECT_END_DATE_REQUIRED" | translate }}</mat-error
          >
        </mat-form-field>
        <mat-checkbox
          [checked]="isSetNoDueDate"
          (change)="setNoDueDate($event)"
          >{{ "NO_END_DATE" | translate }}</mat-checkbox
        >
      </div>
    </div>

    <div
      class="row my-2"
      [ngClass]="{ 'disable-class': data.projectId || !isShowClientList }"
    >
      <div class="col-12 mat-form-field-wrapper client-name">
        <a
          [matMenuTriggerFor]="client"
          class="d-flex align-items-center anchor-link font-weight-medium form-feild-select-custom project-field-m"
        >
          <span *ngIf="!selectedClient.length">{{
            "CLIENT_NAME" | translate
          }}</span>
          <app-mat-chip-preview
            [isStrong]="!data.projectId ? false : true"
            [isInfoShow]="true"
            [selectedMembers]="clientMatPreview"
            listArrName="client"
          >
          </app-mat-chip-preview>
          <div class="project-icons mt-1">
            <img src="/assets/images/usercircle.svg" alt="user circle"/>
          </div>
        </a>
        <mat-menu
          #client="matMenu"
          class="multi-select-chip-menu custom-menu-list"
        >
          <input
            *ngIf="originalClientListData.length !== 0"
            (click)="$event.stopPropagation()"
            type="text"
            autocomplete="off"
            placeholder="Write here"
            class="form-control c-s-input custom-input border-0 w-100 m-w-100"
            (input)="
              search($event, originalClientListData, clientListData, 'client')
            "
          />
          <div class="scroll-fix">
            <app-check-box-common
              [memberListData]="clientListData"
              [listName]="'client'"
              [isShowSelectAll]="false"
              [isSingleSelect]="true"
              [isDisable]="false"
              (onSelectCheckBox)="onSelectClientCheckBox($event)"
            >
            </app-check-box-common>
            <div
              class="text-center"
              *ngIf="
                originalClientListData.length !== 0 &&
                clientListData.length === 0
              "
            >
              {{ "NO_DATA_FOUND" | translate }}
            </div>
            <div
              class="text-center"
              *ngIf="originalClientListData.length === 0"
            >
              {{ "NO_DATA_AVAILABLE" | translate }}
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-12">
        <mat-form-field appearance="standard" class="custom-input-field w-100">
          <input
            matInput
            placeholder="Collaborator"
            type="text"
            class="project-field-m"
            formControlName="outsiderEmail"
          />
          <div class="project-icons">
            <img src="/assets/images/userwithplusicon.svg" alt="add user"/>
          </div>
          <button
            type="button"
            class="circle-plus-icon"
            [disabled]="
              projectDetailForm.controls['outsiderEmail'].hasError('pattern') ||
              !projectDetailForm.controls['outsiderEmail'].value
            "
            (click)="setOutsiderEmails()"
          >
            <img src="assets/images/circleplus.svg" alt="" />
          </button>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-chip-list>
          <mat-chip
            *ngFor="let user of outsiderEmailArr; let i = index"
            [removable]="true"
            (removed)="onToppingEmailRemoved(i)"
            class="u-m-chip m-s-u-chip"
          >
            {{ user.email }}
            <mat-icon matChipRemove>{{ "CANCEL" | translate }}</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

    <div class="row my-2">
      <div
        class="d-flex align-items-center"
        [class.edit-task-disable]="!disableAllFunctionality"
      >
      <!-- {{selectedAssignees|json}} {{selectedGroup|json}} -->
        <a
          [matMenuTriggerFor]="assignee"
          class="d-flex align-items-center anchor-link font-weight-medium form-feild-select-custom"
          #elementRefDiv
          (click)="removeEmit()"
        >
        <div class="project-icons mt-1">
          <img src="/assets/images/member-icon.svg" alt="member icon"/>
        </div>
        <mat-chip
              *ngFor="let user of deletedGroup"
              class="u-m-chip m-s-u-chip"style="margin-left: 30px;"
            >
              <div
                *ngIf="
                  user?.color_id?.charAt(0) == '#' ||
                  !user?.color_id ||
                  user?.color_id?.charAt(0) != 'h'
                "
                class="user-short-name mr-0"
                [ngStyle]="{ 'background-color': user?.color_id }"
              >
                {{ utils.forShortName(user?.name) }}
              </div>
              <span class="trim-info"> {{ user?.name }}</span>
              <mat-icon matChipRemove>{{ "CANCEL" | translate }}</mat-icon>
            </mat-chip>
          <span
            *ngIf="!selectedGroup?.length && !selectedAssignees?.length && !deletedGroup?.length"
            style="padding-left: 30px;">{{ "ASSIGNEE_TEAM" | translate
            }}</span
          >
          <app-mat-chip-preview style="padding-left: 30px;"
            [isInfoShow]="true"
            [isSubTask]="false"
            [selectedMembers]="selectedMatPreview"
            listArrName="{{ listArrName }}"
          ></app-mat-chip-preview>
          <span
            class="anchor-link"
            *ngIf="selectedMatPreview.length > 3"
            >{{ selectedMatPreview.length - 3 }} + more</span
          >
        </a>
        <mat-menu
                  #assignee="matMenu"
                  class="multi-select-chip-menu custom-menu-list"
                >
                  <app-group-assignee-common-checkbox
                  (click)="$event.stopPropagation()"
                    [isSubTask]="false"
                    [selectedAssigneesFromBE]="selectedAssigneesFromBE"
                    [coMembersList]="coMembersList"
                    (onSelectCheckBox)="onSelectCheckBox($event)"
                    [hide_user]="data.projectId ? data.owner : userRole?.memberId"
                  ></app-group-assignee-common-checkbox>
                </mat-menu>
    </div>
</div>
    <div class="row my-2">
      <div class="col-12 mat-form-field-wrapper">
        <a
        [class.edit-task-disable]="!selectedGroup?.length && !selectedAssignees?.length"
          [matMenuTriggerFor]="projectManagers"
          class="d-flex align-items-center anchor-link font-weight-medium form-feild-select-custom project-field-m"
          (click)="onMenuOpened()"
        >
          <span *ngIf="!projectManager.length">{{
            "PROJECT_ADMIN" | translate
          }}</span>
          <mat-chip-list>
            <mat-chip
              *ngFor="let user of projectManager"
              [removable]="true"
              (removed)="onToppingRemoved('manager', user)"
              class="u-m-chip m-s-u-chip"
            >
              <img
                *ngIf="
                  user?.profile_image?.charAt(0) !== '#' &&
                  user?.profile_image?.charAt(0) == 'h'
                "
                src="{{ user?.profile_image }}"
                alt=""
                class="me-1"
              />
              <div
                *ngIf="
                  user?.profile_image?.charAt(0) == '#' ||
                  !user?.profile_image ||
                  user?.profile_image?.charAt(0) != 'h'
                "
                class="user-short-name mr-0"
                [ngStyle]="{ 'background-color': user?.profile_image }"
              >
                {{ utils.forShortName(user?.name) }}
              </div>
              <span class="trim-info"> {{ user?.name }}</span>
              <mat-icon matChipRemove>{{ "CANCEL" | translate }}</mat-icon>
            </mat-chip>
          </mat-chip-list>
          <div class="project-icons mt-1">
            <img src="/assets/images/project-manager-icon.svg" alt="project-manager-icon"/>
          </div>
        </a>
        <mat-menu
          #projectManagers="matMenu"
          class="custom-sub-menu-dropdown-menu multi-select-chip-menu p-0"
          (click)="$event.stopPropagation();"
          yPosition="below"
        >
          <div (click)="$event.stopPropagation()">
            <div
              class="bg-dark-grey d-flex align-items-center d-flex pe-0 ps-3"
            >
            
              <mat-checkbox
                *ngIf="projectManagerListData.length !== 0"
                class="me-2 mx-4 custom-checkbox"
                (change)="selectAllManager($event)"
                [checked]="allCheckManager"
              >
              </mat-checkbox>
              <input
              #inputField
                *ngIf="originalprojectManagerList.length !== 0"
                type="text"
                autocomplete="off"
                placeholder="Type here to Search"
                (input)="
                  search(
                    $event,
                    originalprojectManagerList,
                    projectManagerListData,
                    'manager'
                  )
                "
                class="form-control c-s-input custom-input border-0 w-100 m-w-100 mx-2"
              />
            </div>
            <div class="scroll-fix">
              <ul
                class="list-unstyled mb-0 pl-4"
                (click)="$event.stopPropagation()"
              >
              <li *ngFor="let projectManager of projectManagerListData" class="custom-checkbox-p">
                <!-- Manager-->
                <div *ngIf="data.projectId ? projectManager.id != data.owner : projectManager.id != userRole.memberId">
                  <mat-checkbox
                    [checked]="projectManager.ischeck"
                    (change)="selectedProjectManager($event, projectManager)"
                    class="me-2 custom-checkbox"
                  >
                    <img
                      [src]="projectManager.profile_image"
                      *ngIf="
                        projectManager.profile_image?.charAt(0) !== '#' &&
                        projectManager.profile_image?.charAt(0) == 'h'
                      "
                      class="img-avatar"
                      alt="assignee profile"
                    />
                    <div
                      *ngIf="
                        projectManager.profile_image?.charAt(0) == '#' ||
                        !projectManager.profile_image ||
                        projectManager.profile_image?.charAt(0) != 'h'
                      "
                      class="user-short-name mr-0"
                      [ngStyle]="{
                        'background-color': projectManager.profile_image
                      }"
                    >
                      {{ utils.forShortName(projectManager.first_name + ' ' + projectManager.last_name) }}
                    </div>
                    <span class="trim-info"> {{ projectManager.name }}</span>
                  </mat-checkbox>
                </div>
              </li>
              
              </ul>
              <div
                class="text-center"
                *ngIf="
                  originalprojectManagerList.length !== 0 &&
                  projectManagerListData.length === 0
                "
              >
                {{ "NO_DATA_FOUND" | translate }}
              </div>
              <div
                class="text-center"
                *ngIf="originalprojectManagerList.length === 0"
              >
                {{ "NO_DATA_AVAILABLE" | translate }}
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 d-flex justify-content-end">
        <button
          (click)="closeDialog(false)"
          class="btn btn--bordered me-2"
          mat-button
          mat-flat-button
          type="button"
        >
          {{ "CANCEl_BUTTON" | translate }}
        </button>
        <button
          class="btn btn--primary"
          (click)="createProject()"
          [disabled]="!projectDetailForm.valid"
          type="submit"
          mat-button
          mat-flat-button
        >
          <span *ngIf="!data.projectId">{{ "CREATE" | translate }}</span>
          <span *ngIf="data.projectId">{{ "UPDATE_BUTTON" | translate }}</span>
        </button>
      </div>
    </div>
  </form>
</div>
