import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { discussionSocketEvents } from '../../global/constants';
import { updateUserStatus } from '../../ngrx/action/user-state.actions';
import { UserState } from '../../ngrx/reducer/user-state.reducer';
import { LocalStorageService } from '../storage-service/local-storage.service';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
    providedIn: 'root',
})
export class DiscussionSocketService {
    public newMessage = new BehaviorSubject({});
    public newMessageObservable = this.newMessage.asObservable();

    public readMessage = new BehaviorSubject({});
    public readMessageObservable = this.readMessage.asObservable();

    public socket: Socket;
    onlineUsers: any = [];
    constructor(
    private localStorageService: LocalStorageService,
    private store: Store<UserState>,
    private injector: Injector
    ) {}

    private authService = this.injector.get(AuthService);

    //Create connection with socket server.
    public createSocketConnection() {
        this.socket = io(environment.discussionURL, {
            query: {
                authorization: `Bearer ${this.localStorageService.getLocalStore(
                    'authorization'
                )}`,
            },
            extraHeaders: {
                authorization: `Bearer ${this.localStorageService.getLocalStore(
                    'authorization'
                )}`,
            },
            transports: ['websocket', 'polling'],
        });

        this.createConnection();
    }

    // closed socket connections with server.
    public closedSocketConnection() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    public createConnection() {
        this.emit(discussionSocketEvents.JOIN_SOCKET, {}, (res: any) => {
            this.onlineUsers = res.data;
            this.store.dispatch(updateUserStatus({ data: res.data }));
        });

        this.subscribe(
            discussionSocketEvents.COMPANY_ONLINE_OFFLINE,
            (res: any) => {
                const onlineUsers = [...this.onlineUsers].map((item: any) => {
                    return item.user_id === res.user_id
                        ? { ...item, user_state: res.user_state }
                        : { ...item };
                });
                this.store.dispatch(updateUserStatus({ data: onlineUsers }));
            }
        );
        this.subscribe('logout', () => {
            if (!this.authService.logout) {
                this.authService = this.injector.get(AuthService);
            }
            this.authService.logout();
        });
    }

    // Common event emition
    public emit(event: string, data: any, callback?: Function) {
        if (this.socket) {
            return this.socket.emit(event, data, callback);
        }
    }

    // Subscribe events
    public subscribe(event: string, callback: any) {
        if (this.socket) {
            return this.socket.on(event, callback);
        }
    }

    // Reconnection
    public async reconnect() {
        this.closedSocketConnection();
        this.createSocketConnection();
    }
}
