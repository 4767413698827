import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimesheetSettingService } from 'src/app/shared/services/organization-settings/timesheet-setting.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import * as moment from 'moment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as CryptoJS from 'crypto-js';
import { ApiService } from 'src/app/shared/services/api-service/api.service';
import { TIME_LOG_TYPE, status_name } from 'src/app/shared/global/constants';
import { MatDialog } from '@angular/material/dialog';
import { AddTimeLogComponent } from 'src/app/shared/components/add-time-log/add-time-log.component';
import { TimeSheetService } from 'src/app/shared/services/time-sheet-service/time-sheet.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-time-log-tab',
    templateUrl: './time-log-tab.component.html',
    styleUrls: ['./time-log-tab.component.scss'],
})
export class TimeLogTabComponent implements OnInit {
    company_id: any;
    timeLogData: any = [];
    timerShow = false;
    timerToggle = false;
    selectedDate: any;
    totalHours: any;
    freezeDays: any;
  @Input() taskId: any;
  @Input() status: any;
  timeLogDetail: any = [];
  @Output('onTimerEvent') onTimerEvent = new EventEmitter();
  minDate: any;
  maxDate: any;
  maxMinutes = 59;
  minEndTime: any = moment().endOf('day').toDate();
  timerObj: any;
  currentTab: any;
  decryptedCompanyId: any;
  decryptedUserId: any;
  timeLogForm!: FormGroup;
  startEpochTime: any;
  endEpochTime: any;
  @ViewChild('timepicker') timepicker: any;
  @ViewChild('timepickerEnd') timepickerEnd: any;
  @ViewChild('menuEle') menu: any;
  selectedTimeLogType: string;
  loggedUsers: any = [];
  searchForUser = '';
  selectedUserIds: any = [];
  allTasks: any = [];
  selectedTaskIds: any = [];
  isFilterApply = false;
  memberID: any;
  isCloseTask = false;
  user_company_relations: any;
  statusNameFormConst = status_name;
  public timeSheetSettingService: TimesheetSettingService;
  public userDetail: UserDetailsService;
  public timeSheetService: TimeSheetService;
  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private matDialog: MatDialog,
    public utils: UtilsService,
    public translate: TranslateService
  ) {
      this.timeSheetSettingService = injector.get<TimesheetSettingService>(
          TimesheetSettingService
      );
      this.userDetail = injector.get<UserDetailsService>(UserDetailsService);
      this.timeSheetService = injector.get<TimeSheetService>(TimeSheetService);
  }

    async ngOnInit() {
        this.decryptedUserId = await this.userDetail.userId;
        this.company_id = await this.userDetail.getSelectedCompanyId();
        this.decryptedCompanyId = CryptoJS.AES.decrypt(
            this.company_id?.trim(),
            ''
        ).toString(CryptoJS.enc.Utf8);
        this.user_company_relations = await this.userDetail.getUserRolePermission(
            this.decryptedUserId,
            this.decryptedCompanyId
        );
        this.memberID = this.user_company_relations.memberId;
        this.getTimeSheetDetail(this.decryptedCompanyId);
        this.maxDate = moment().toDate();
        await this.getTimeLogData();
        await this.getAllTasks();
        this.isCloseTask = this.status.status_name === 'closed';
    }

    formData() {
        this.timeLogForm = this.formBuilder.group({
            hoursControl: [
                null,
                [Validators.max(23), Validators.min(0), Validators.required],
            ],
            minutesControl: [
                null,
                [Validators.max(59), Validators.min(0), Validators.required],
            ],
            startFormControl: [null, [Validators.required]],
            endFormControl: [null, [Validators.required]],
            noteFormcontrol: [null],
        });
        this.timeLogForm.get('hoursControl').disable();
        this.timeLogForm.get('minutesControl').disable();
    }

    async getTimeSheetDetail(company_id: any) {
        (
            await this.timeSheetSettingService.getTimesheetSetting(company_id)
        ).subscribe((res: any) => {
            if (res) {
                this.timeLogData = res.result;
                this.selectedTimeLogType = this.timeLogData?.process_type;
                this.freezeDays = this.timeLogData?.freeze_days;
                this.minDate = moment()
                    .subtract(this.timeLogData?.freeze_days, 'days')
                    .toDate();
                this.checkTimeLog();
            }
        });
    }

    checkTimeLog() {
        if (this.timeLogData?.is_enabled == 1) {
            this.timerToggle = true;
        }
        if (
            this.timeLogData?.process_type === TIME_LOG_TYPE.BOTH ||
            this.timeLogData?.process_type === TIME_LOG_TYPE.MANUAL
        ) {
            this.timerShow = true;
        }
    }

    selectDate(date: any) {
        this.selectedDate = date;
    }

    onClear(_$event: Event) {
        this.timeLogForm.get('startFormControl').setValue(null);
    }
    onEndClear(_$event: Event) {
        this.timeLogForm.get('endFormControl').setValue(null);
    }

    setTime() {
        this.minEndTime = moment(
            this.timeLogForm.get('startFormControl').value,
            'HH:mm'
        ).format('HH:mm');
        this.setEndTime();
        this.updateTotalTime();
    }

    setEndTime() {
        const startMin = this.timeLogForm.get('startFormControl').value?.split(':');
        const endMin = this.timeLogForm.get('endFormControl').value?.split(':');
        const total: any = (
            +(
                +endMin?.[0] * 60 +
                +endMin?.[1] -
                (+startMin?.[0] * 60 + +startMin?.[1])
            ) / 60
        ).toFixed(2);
        this.totalHours = total;
        this.totalHours = isNaN(total)
            ? 0
            : moment(total, 'HH:mm:ss').format('HH:mm:ss');
        this.updateTotalTime();
    }

    onHourChange() {
        const hr = this.timeLogForm.get('hoursControl').value;
        if (hr == 24) {
            this.timeLogForm.get('minutesControl').setValue(0);
            this.maxMinutes = 0;
        } else if (this.timeLogForm.get('hoursControl').invalid) {
            this.timeLogForm.get('hoursControl').setValue(0);
        }
        this.updateTotalTime();
    }

    onMinuteChange() {
        if (this.timeLogForm.get('minutesControl').invalid) {
            this.timeLogForm.get('minutesControl').setValue(0);
        }
        this.updateTotalTime();
    }

    updateTotalTime() {
        if (this.currentTab === TIME_LOG_TYPE.NO_OF_HRS) {
            const hour = +this.timeLogForm.value.hoursControl;
            const minute = +this.timeLogForm.value.minutesControl;
            const seconds = hour * 60 * 60 + minute * 60;
            this.totalHours = new Date(seconds * 1000).toISOString().slice(11, 19);
        }
    }
    async submit() {
        if (this.currentTab === TIME_LOG_TYPE.NO_OF_HRS) {
            this.timerObj = {
                task_id: this.taskId,
                comment: this.timeLogForm.value.noteFormcontrol
                    ? this.timeLogForm.value.noteFormcontrol
                    : '',
                event: 'manual',
                hours: this.totalHours,
                type: 'task',
            };
        } else {
            const startTime =
                moment(this.selectedDate).format('MM/DD/YYYY') +
                ' ' +
                (this.timeLogForm.get('startFormControl').value
                    ? this.timeLogForm.get('startFormControl').value + ':00'
                    : '00:00:00');
            this.startEpochTime = new Date(startTime).getTime();
            const endTime =
                moment(this.selectedDate).format('MM/DD/YYYY') +
                ' ' +
                (this.timeLogForm.get('endFormControl').value
                    ? this.timeLogForm.get('endFormControl').value + ':00'
                    : '00:00:00');
            this.endEpochTime = new Date(endTime).getTime();
            this.timerObj = {
                task_id: this.taskId,
                comment: this.timeLogForm.value.noteFormcontrol
                    ? this.timeLogForm.value.noteFormcontrol
                    : '',
                event: 'start_end',
                start_time: `${this.startEpochTime}`,
                end_time: `${this.endEpochTime}`,
                hours: this.totalHours,
                type: 'task',
            };
        }
        (await this.timeSheetService.addTimeLogEntry(this.timerObj)).subscribe(
            (res: any) => {
                this.apiService.showSuccess(res.msg);
                this.getTimeLogData();
                this.menu.nativeElement.click();
                this.timeLogForm.reset();
                this.selectedDate = '';
                this.selectedUserIds = [];
                this.selectedTaskIds = [];
            }
        );
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.currentTab = tabChangeEvent.tab.textLabel;
        if (this.currentTab === TIME_LOG_TYPE.NO_OF_HRS) {
            this.timeLogForm.get('startFormControl').disable();
            this.timeLogForm.get('endFormControl').disable();
            this.timeLogForm.get('startFormControl').patchValue('');
            this.timeLogForm.get('endFormControl').patchValue('');
            this.timeLogForm.get('hoursControl').enable();
            this.timeLogForm.get('minutesControl').enable();
            this.updateTotalTime();
        } else {
            this.timeLogForm.get('startFormControl').enable();
            this.timeLogForm.get('endFormControl').enable();
            this.timeLogForm.get('hoursControl').disable();
            this.timeLogForm.get('minutesControl').disable();
            this.timeLogForm.get('hoursControl').patchValue('');
            this.timeLogForm.get('minutesControl').patchValue('');
            this.setEndTime();
        }
    }

    openTimeLogDialog() {
        const myCompDialog = this.matDialog.open(AddTimeLogComponent, {
            panelClass: [
                'custom-modal',
                'custom-modal-md',
                'add-client-modal',
                'custom-model-width',
            ],
            data: { type: 'task', taskId: this.taskId },
        });
        myCompDialog.afterClosed().subscribe((_res: any) => {
            if (_res) {
                this.getTimeLogData();
                this.selectedUserIds = [];
                this.selectedTaskIds = [];
            }
        });
    }

    closeMatMenu() {
        this.timeLogForm.reset();
    }

    async getTimeLogData() {
        if (this.selectedUserIds.length > 0 || this.selectedTaskIds.length > 0) {
            this.isFilterApply = true;
        }

        const data: any = {
            taskId: this.taskId,
            selectUserId:
                this.selectedUserIds.length > 0 ? this.selectedUserIds : null,
            selectedTaskId:
                this.selectedTaskIds.length > 0 ? this.selectedTaskIds : null,
            isPrimary: this.selectedTaskIds.length === 0,
            offset: this.utils.getOffsetTime(),
        };
        (await this.timeSheetService.getTimeLog(data)).subscribe((res: any) => {
            this.timeLogDetail = res.result;
            this.timeLogDetail?.timeSheetData.forEach((element: any) => {
                element.data.forEach((userEle: any) => {
                    const obj = this.loggedUsers.find((item: any) => {
                        return item.id === userEle?.user_details?.user_id;
                    });
                    if (!obj) {
                        this.loggedUsers.push({
                            id: userEle?.user_details?.user_id,
                            name:
                                userEle?.user_details?.first_name +
                                ' ' +
                                userEle?.user_details?.last_name,
                            profile_image: userEle?.user_details?.profile_image,
                            isCheck: false,
                        });
                    }
                });
            });
        });
    }

    checkedAllStatus(event: any) {
        this.loggedUsers = this.loggedUsers.map((status: any) => {
            status.isCheck = event.checked;
            return status;
        });
        const result = this.loggedUsers
            .filter((status: any) => status.isCheck)
            .map((status: any) => status.id);
        this.selectedUserIds = result;
        this.getTimeLogData();
    }

    isAllChecked(allCheckData: any) {
        return (
            allCheckData.length ===
            allCheckData.filter((allCheck: any) => allCheck.isCheck).length
        );
    }

    statusChange(data: any, event: any) {
        this.loggedUsers.forEach((element: any) => {
            if (element.id === data.id) {
                element.isCheck = event.checked;
            }
        });
        const result = this.loggedUsers
            .filter((status: any) => status.isCheck)
            .map((status: any) => status.id);
        this.selectedUserIds = result;
        this.getTimeLogData();
    }

    applyFilterSearch(e: any) {
        this.searchForUser = (e.target as HTMLInputElement).value;
    }

    async getAllTasks() {
        if (this.taskId) {
            (await this.timeSheetService.getAllTasks(this.taskId)).subscribe(
                (res: any) => {
                    this.allTasks = res.result;
                    this.allTasks.forEach((element: any) => {
                        element.isCheck = false;
                    });
                }
            );
        }
    }

    checkedAllTaskStatus(event: any) {
        this.allTasks = this.allTasks.map((status: any) => {
            status.isCheck = event.checked;
            return status;
        });
        const result = this.allTasks
            .filter((status: any) => status.isCheck)
            .map((status: any) => status._id);
        this.selectedTaskIds = result;
        this.getTimeLogData();
    }

    isAllCheckedTask(allCheckData: any) {
        return allCheckData.every((allCheck: any) => allCheck.isCheck);
    }    

    taskStatusChange(data: any, event: any) {
        this.allTasks.forEach((element: any) => {
            if (element._id === data._id) {
                element.isCheck = event.checked;
            }
        });
        const result = this.allTasks
            .filter((status: any) => status.isCheck)
            .map((status: any) => status._id);
        this.selectedTaskIds = result;
        this.getTimeLogData();
    }

    deleteConfirm(time_sheet_id: any) {
        const myCompDialog = this.matDialog.open(ConfirmDialogComponent, {
            panelClass: ['custom-modal', 'custom-modal-md'],
            data: {
                message: this.translate.instant('DELETE_TIMER_MSG'),
                buttonText: {
                    ok: 'Yes',
                    cancel: 'No',
                },
            },
        });
        myCompDialog.afterClosed().subscribe(async (confirmed: boolean) => {
            if (confirmed) {
                (await this.timeSheetService.deleteTimeLog(time_sheet_id)).subscribe(
                    (res: any) => {
                        this.apiService.showSuccess(res.msg);
                        this.getTimeLogData();
                    }
                );
            }
        });
    }

    getTypeClass(status: string): string {
        switch (status) {
        case 'Auto':
            return 'auto-type';
        case 'Manual':
            return 'manual-type';
        default:
            return '';
        }
    }
}
