<div
  class="common-quill-editor custom-quil-editor-cls math_ui_block-common-quill-editor checklist-common-quill task-list-common-quill discussion-common-quill small-quill"
  [style]="{
    'background-color':
      allDocFiles.length || selectedMessage ? '#f5f5f5' : '#ffffff'
  }"
>
  <!-- Uploadation part start -->
  <div
    class="textarea-uploadation-part"
    style="width: 100%; overflow: auto; display: flex"
    *ngIf="allDocFiles.length || selectedMessage || clientMatPreview.length > 0"
  >
    <div class="userMsgContent" *ngIf="selectedMessage">
      <div class="userMsgreply">
        <h5 class="d-flex justify-content-between align-items-center">
          {{ selectedMessage?.sender?.first_name }}
          {{ selectedMessage?.sender?.last_name }}
          <img
            draggable="false"
            src="assets/images/smallcrossicon.svg"
            (click)="isCancelMsgReply()"
            alt=""
          />
        </h5>
        <div *ngIf="selectedMessage?.message_type === MESSAGE_TYPES['CONTACT']">
          <div class="d-flex justify-content">
            <div class="user-short-name mr-0">
              {{ utilsService.forShortName(selectedMessage.contact_name) }}
            </div>
            <strong>{{ selectedMessage.contact_name }}</strong
            ><br />
          </div>
          <span>{{ selectedMessage.contact_number }}</span>
        </div>

        <div
          class="images-containner"
          *ngIf="
            selectedMessage?.message_type === MESSAGE_TYPES['DOCUMENT'] &&
            !selectedMessage.uploadFileInBase64
          "
        >
          <div
            class="image-containner"
            *ngFor="let image of selectedMessage?.attachement_details"
          >
            <img
              [src]="image.attachement_url"
              alt=""
              *ngIf="image?.is_image"
              draggable="false"
            />
            <img
              draggable="false"
              [src]="image.attachement_thumbnail"
              alt=""
              *ngIf="!image?.is_image"
            />
          </div>
        </div>

        <div
          class="images-containner"
          *ngIf="
            selectedMessage?.message_type === MESSAGE_TYPES['DOCUMENT'] &&
            selectedMessage.uploadFileInBase64
          "
        >
          <div
            class="image-containner"
            *ngFor="
              let image of selectedMessage?.uploadFileInBase64;
              let i = index
            "
          >
            <img
              draggable="false"
              [src]="image"
              alt=""
              *ngIf="selectedMessage?.uploadFile[i]?.is_image"
            />
            <img
              draggable="false"
              [src]="selectedMessage?.uploadFile[i]?.image"
              alt=""
              *ngIf="!selectedMessage?.uploadFile[i]?.is_image"
            />
          </div>
        </div>

        <div
          class="images-containner"
          *ngIf="
            selectedMessage?.message_type === MESSAGE_TYPES['AUDIO'] &&
            !selectedMessage?.audioFiles
          "
        >
          <div
            class="image-containner"
            *ngFor="let audio of selectedMessage?.attachement_details"
          >
            <audio id="player" controls [src]="audio.attachement_url"></audio>
            <p class="mb-0"></p>
          </div>
        </div>

        <div
          class="images-containner"
          *ngIf="
            selectedMessage?.message_type === MESSAGE_TYPES['AUDIO'] &&
            selectedMessage?.audioFiles
          "
        >
          <div
            class="image-containner"
            *ngFor="let audio of selectedMessage?.audioFiles"
          >
            <audio controls="controls" autobuffer="autobuffer">
              <source [src]="audio?.result" />
            </audio>
          </div>
        </div>

        <div
          (click)="
            redirectMap(selectedMessage.latitude, selectedMessage.longitude)
          "
          *ngIf="selectedMessage?.message_type === MESSAGE_TYPES['LOCATION']"
          class="position-relative"
          style="width: fit-content"
        >
          <span class="set-to-position-set">
            <img
              class="location-pin"
              draggable="false"
              src="../../../../assets/images/loctaion_pin.png"
              alt=""
            />
          </span>
          <img
            class="map-image"
            draggable="false"
            src="../../../../assets/images/map_image.png"
            alt=""
          />
        </div>

        <div
          class="info-box-click custom-editor-check"
          *ngIf="selectedMessage?.message_type == MESSAGE_TYPES['INFO']"
        >
          <div class="d-flex align-items-baseline justify-content-between">
            <div class="limited-pvt">
              <h6>{{ selectedMessage?.clientInfo?.company_name }}</h6>
              <label
                >{{ selectedMessage?.clientInfo?.client_name }}
                <span
                  *ngIf="selectedMessage?.clientInfo?.gender"
                  class="mail-btn-add"
                  >{{ selectedMessage?.clientInfo?.gender }}</span
                ></label
              >
            </div>
            <div
              class="info-cls"
              (click)="openContactDetails(selectedMessage?.clientInfo?.id)"
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.5 10.5498C0.5 16.3488 5.20101 21.0498 11 21.0498C16.799 21.0498 21.5 16.3488 21.5 10.5498C21.5 4.75081 16.799 0.0498047 11 0.0498047C5.20101 0.0498047 0.5 4.75081 0.5 10.5498ZM2 10.5498C2 5.57924 6.02944 1.5498 11 1.5498C15.9706 1.5498 20 5.57924 20 10.5498C20 15.5204 15.9706 19.5498 11 19.5498C6.02944 19.5498 2 15.5204 2 10.5498ZM11.75 14.7998V8.0498H8.75V9.5498H10.25V14.7998H8V16.2998H14V14.7998H11.75ZM9.875 4.6748C9.875 4.05348 10.3787 3.5498 11 3.5498C11.6213 3.5498 12.125 4.05348 12.125 4.6748C12.125 5.29612 11.6213 5.7998 11 5.7998C10.3787 5.7998 9.875 5.29612 9.875 4.6748Z"
                  fill="#276EF1"
                />
              </svg>
            </div>
          </div>

          <div class="intor-contacts mt-4">
            <div class="row align-items-center">
              <div class="col-lg-2 my-1">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.75 0.799805H1.25C0.55993 0.800494 0.000689037 1.35973 0 2.0498V15.7998C0.000689037 16.4899 0.55993 17.0491 1.25 17.0498H8.75C9.43993 17.0488 9.99897 16.4897 10 15.7998V2.0498C9.99931 1.35973 9.44007 0.800494 8.75 0.799805ZM8.75 2.0498V3.2998H1.25V2.0498H8.75ZM1.25 15.7998V4.5498H8.75V15.7998H1.25Z"
                    fill="#276EF1"
                  />
                </svg>
              </div>
              <div class="col-lg-10 my-1">
                <p class="mb-0">
                  {{ selectedMessage?.clientInfo?.mobile_number }}
                </p>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-2 my-1">
                <svg
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.5 0.0498047H1.5C0.809644 0.0498047 0.25 0.609449 0.25 1.2998V11.2998C0.25 11.9902 0.809644 12.5498 1.5 12.5498H16.5C17.1904 12.5498 17.75 11.9902 17.75 11.2998V1.2998C17.75 0.609449 17.1904 0.0498047 16.5 0.0498047ZM15.125 1.2998L9 5.5373L2.875 1.2998H15.125ZM1.5 11.2998V1.86855L8.64375 6.8123C8.858 6.96093 9.142 6.96093 9.35625 6.8123L16.5 1.86855V11.2998H1.5Z"
                    fill="#276EF1"
                  />
                </svg>
              </div>
              <div class="col-lg-10 my-1">
                <p class="mb-0">{{ selectedMessage?.clientInfo?.email }}</p>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-2 my-1">
                <svg
                  width="14"
                  height="20"
                  viewBox="0 0 14 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.125 7.24414C0.176434 3.49783 3.25365 0.501736 7 0.550389C10.7463 0.501736 13.8236 3.49783 13.875 7.24414C13.8734 8.69887 13.3899 10.1121 12.5 11.2629L7 19.3004L1.5 11.2629C0.610106 10.1121 0.12658 8.69887 0.125 7.24414ZM12.625 7.24414C12.5668 4.19111 10.0533 1.75857 7 1.80039C3.94671 1.75857 1.43317 4.19111 1.375 7.24414C1.37409 8.44549 1.78184 9.61143 2.53125 10.5504L7 17.0879L11.5063 10.5004C12.2316 9.56996 12.6253 8.42389 12.625 7.24414ZM7 5.5498C8.03553 5.5498 8.875 6.38927 8.875 7.4248C8.875 8.46034 8.03553 9.2998 7 9.2998C5.96447 9.2998 5.125 8.46034 5.125 7.4248C5.125 6.38927 5.96447 5.5498 7 5.5498ZM3.875 7.4248C3.875 5.69892 5.27411 4.2998 7 4.2998C8.72589 4.2998 10.125 5.69892 10.125 7.4248C10.125 9.15069 8.72589 10.5498 7 10.5498C5.27411 10.5498 3.875 9.15069 3.875 7.4248Z"
                    fill="#276EF1"
                  />
                </svg>
              </div>
              <div class="col-lg-10 my-1">
                <p class="mb-0">
                  {{ selectedMessage?.clientInfo?.company_address }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <p [innerHTML]="selectedMessage?.message"></p>
      </div>
    </div>

    <div
      class="d-flex align-items-center chat-box-attach"
      *ngIf="allDocFiles.length"
    >
      <div
        class="uploading position-relative me-3 text-center"
        *ngFor="let fileData of allDocFiles; let i = index"
      >
        <img src="{{ fileData?.image }}" draggable="false" alt="upload_first" />
        <p class="mb-0 text-truncate">{{ fileData?.files?.name }}</p>
        <span class="close-ics-text" (click)="removeImage(i)">
          <svg
            width="23"
            height="23"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="6"
              cy="6"
              r="5.75"
              fill="black"
              fill-opacity="0.5"
              stroke="white"
              stroke-width="0.5"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.28732 4.11236L7.88712 3.71216L6.00047 5.59881L4.11382 3.71216L3.71362 4.11236L5.60027 5.99901L3.71362 7.88566L4.11382 8.28586L6.00047 6.39921L7.88712 8.28586L8.28732 7.88566L6.40067 5.99901L8.28732 4.11236Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
    </div>
    <div
      class="uploading-ics-add"
      *ngIf="allDocFiles.length > 0"
      (click)="uploadFile.click()"
    >
      <input
        #uploadFile
        type="file"
        multiple
        style="display: none"
        (change)="
          uploadFiles($any($event).target.files); uploadDocument($event)
        "
      />
      <svg
        width="17"
        height="17"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.625 5.375V0.375H5.375V5.375H0.375V6.625H5.375V11.625H6.625V6.625H11.625V5.375H6.625Z"
          fill="#212121"
        />
      </svg>
    </div>
    <app-mat-chip-preview
      [isInfoShow]="true"
      [selectedMembers]="clientMatPreview"
      listArrName="client"
    ></app-mat-chip-preview>
  </div>
  <!-- Uploadation part over -->

  <div
    class="chat-content"
    [style]="{
      'border-top':
        allDocFiles.length || selectedMessage ? '4px solid #eeeeee' : 'unset'
    }"
  >
    <div
      class="quil-editor-box"
      [ngClass]="{ 'hide-quil-display': isrecording }"
    >
      <quill-editor
        [(ngModel)]="content"
        (keyup)="addMentionComment($event, 'keyup'); quickReplyCheck($event)"
        [modules]="modules"
        placeholder="Type a message"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onContentChanged)="onContentChanged($event)"
        (keyup.enter)="open($event)"
      >
      </quill-editor>
    </div>
    <div
      *ngIf="
        isEnableQuickReply &&
        quickReplyList?.length > 0 &&
        quickReplyFilter?.length > 0
      "
      class="form-group-inner custom-placeholder-form-group bottom-side quil-editor-quick-reply-dropdown custon-data-list mt-0"
    >
      <ng-container *ngFor="let reply of quickReplyFilter">
        <div (click)="handleStaticResultSelected(reply)" class="items">
          <label class="mb-0">{{ reply.title }}</label>
          <span>{{ reply.message }}</span>
        </div>
      </ng-container>
    </div>
    <div *ngIf="isrecording">
      <a class="closerecording" (click)="cancelRecording()">
        <img src="assets/images/blackcloseicon.svg" alt="" draggable="false" />
      </a>
    </div>
    <div *ngIf="isrecording" class="form-progress-bar-wrapper my-4">
      <div
        class="form-progress-bar"
        [class]="progress === 100 ? 'bg-success' : 'bg-primary'"
        [style.width.%]="progress"
      ></div>
    </div>
    <div class="quill-editor-controls">
      <a
        [matMenuTriggerFor]="client"
        *ngIf="
          !isrecording &&
          isClientInfoShare &&
          !selectedMessage &&
          !allDocFiles.length
        "(click)="resetOnClick()"
      >
        <img
          draggable="false"
          src="assets/images/contactcardicon.svg"
          alt="contact card"
          class="controll"
        />
        <mat-menu
          #client="matMenu"
          class="multi-select-chip-menu custom-menu-list"
        >
          <app-client-menu
            (getSelectedClientEvent)="getSelectedClient($event)"
            [resetData]="resetClient"
          ></app-client-menu>
        </mat-menu>
      </a>
      <a
        *ngIf="
          !isrecording &&
          !selectedMessage &&
          !clientMatPreview.length &&
          !allDocFiles.length
        "
      >
        <img
          src="assets/images/microphoneicon.svg"
          alt="micro phone"
          draggable="false"
          class="controll"
          (click)="!isStart ? startRecording() : stopRecording()"
        />
      </a>
      <a
        *ngIf="
          !isrecording &&
          !allDocFiles.length &&
          !selectedMessage &&
          !clientMatPreview.length
        "
      >
        <img
          src="assets/images/clipicon.svg"
          class="controll"
          draggable="false"
          alt=""
          (click)="uploadFile.click()"
        />
        <input
          #uploadFile
          type="file"
          multiple
          style="display: none"
          (change)="
            uploadFiles($any($event).target.files); uploadDocument($event)
          "
        />
      </a>
      <div *ngIf="isrecording">
        <span class="minute-txt m-2" *ngIf="isStart">{{ recordingValue }}</span>
      </div>
      <div class="send-btn">
        <img
          src="./../assets/images/sendbuttonimg.svg"
          alt=""
          draggable="false"
          (click)="open($event)"
          disabled
        />
      </div>
    </div>
  </div>
</div>
