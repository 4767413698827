<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="min-vh-100 vh-100" app-theme>
  <app-header (dataEvent)="regenerateTableData($event)"></app-header>
  <mat-drawer-container class="main-container" style="height: calc(100% - 4em)"
    [ngClass]="isToggled ? 'profile-sidebar-active' : ''" #reload>
    <mat-drawer mode="side" opened class="main-sidebar" [ngClass]="isToggled ? 'profile-sidebar' : ''">
      <app-side-bar></app-side-bar>
      <div class="buildVersion">{{ buildVersion }}</div>
    </mat-drawer>
    <mat-drawer-content class="custom-mat-drawer-content-wrp mat-drawer-content-wrap-cls"
      [ngClass]="isToggled ? 'active-drawer' : 'closed-drawer'">
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>