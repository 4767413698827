import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    DateRange,
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { UserDetailsService } from '../../services/user-details.service';
import { TasksService } from '../../services/task-service/tasks.service';
import { ApiService } from '../../services/api-service/api.service';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'app-task-reminder-component',
    templateUrl: './task-reminder-component.component.html',
    styleUrls: ['./task-reminder-component.component.scss'],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
        DefaultMatCalendarRangeStrategy,
        MatRangeDateSelectionModel,
    ],
})
export class TaskReminderComponentComponent implements OnInit {
    minDueDate: any;
    selectedDueDate: any;
  @Input() isSubTask: boolean;
  @Input() reminderDetail: any;
  @ViewChild('select') select: MatSelect;
  @Output('selectedDate') selectedDate = new EventEmitter();
  @Output('closeMenu') closeMenu = new EventEmitter();
  dateRange: DateRange<Date>;
  startDate: any;
  endDate: any;
  date: any;
  reminderDateEpoch: any;
  isTaskRecurring = true;
  selectedOnDay: any;
  selectedOnDayArr: any[] = [];
  customOnDay: any;
  recurringDate: any;
  recurringDateEpoch: any;
  startTime: any;
  endTime: any;
  allSelected = false;
  isRepeat = false;
  repeatValue: any = 1;
  remindType: any;
  userDetail: any;
  reminderDataObj: any;
  selectedTime: any;
  hour: any;
  min: any;
  reminderObj: any;
  @Input() task_id: any;
  remindTo: any = [
      { label: 'Owner', checked: false },
      { label: 'Assignees', checked: false },
      { label: 'Followers', checked: false },
  ];
  reminderOption: any[] = [
      { name: ' Notification', value: 'pushup_notification', checked: true },
      { name: 'Mail', value: 'email', checked: false },
      { name: 'Whatsapp', value: 'whatsapp', checked: false },
  ];
  reminderAudience: any[] = [];
  isOn: any;
  minDate: any;
  maxDate: any;
  frequency: any = [
      {
          name: 'Once',
          value: 'once',
      },
      {
          name: 'Daily',
          value: 'daily',
      },
      {
          name: 'Hourly',
          value: 'hourly',
      },
      {
          name: 'Weekly',
          value: 'weekly',
      },
  ];

  weekDays = [
      {
          name: 'Sunday',
          sortName: 'S',
          isCheck: false,
      },
      {
          name: 'Monday',
          sortName: 'M',
          isCheck: false,
      },
      {
          name: 'Tuesday',
          sortName: 'T',
          isCheck: false,
      },
      {
          name: 'Wednesday',
          sortName: 'W',
          isCheck: false,
      },
      {
          name: 'Thursday',
          sortName: 'T',
          isCheck: false,
      },
      {
          name: 'Friday',
          sortName: 'F',
          isCheck: false,
      },
      {
          name: 'Saturday',
          sortName: 'S',
          isCheck: false,
      },
  ];
  numbers = Array(28)
      .fill(1)
      .map((_x, index) => {
          return { id: index + 1, isCheck: false };
      });

  selectedFrequency = '';
  selectedWeekDays: any = [];
  newSelectedWeekDays: any = [];
  selectedHour = '00';
  selectedMinute = '00';
  hours: string[] = Array.from({ length: 24 }, (_, i) =>
      i.toString().padStart(2, '0')
  );
  minutes: string[] = ['00','30'];
  isChange = false;
  constructor(
    private injector: Injector,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private taskService: TasksService,
    private apiService: ApiService,
    private datePipe: DatePipe
  ) {
      this.userDetail = injector.get<UserDetailsService>(UserDetailsService);
      this.minDate = new Date();
  }

  ngOnInit() {
      this.selectedTime = false;
      this.minDueDate = new Date();
      this.selectedHour = '00';
      this.selectedMinute = '00';
      this.selectedDueDate = new Date();
  }

  ngOnChanges() {
      this.reminderDataObj = this.reminderDetail;
      this.getReminderData();
  }

  getReminderData() {
      this.isOn = this.reminderDataObj?.is_on;
      this.selectedDueDate = this.reminderDataObj?.reminder_date
          ? new Date(this.reminderDataObj?.reminder_date)
          : null;
      this.selectedFrequency = this.reminderDataObj?.repeat_frequency;

      this.selectedTime = this.datePipe.transform(this.selectedDueDate, 'HH:mm');

      this.selectedHour = this.datePipe.transform(this.selectedDueDate, 'HH');
      this.selectedMinute = this.datePipe.transform(this.selectedDueDate, 'mm');
      if (this.minutes.includes(this.selectedMinute)) {
          this.selectedMinute = this.datePipe.transform(this.selectedDueDate, 'mm');
      } else {
          this.selectedMinute = (
              Math.round(parseInt(this.selectedMinute) / 10) * 10
          ).toString();
      }
            
      if(0 < parseInt(this.selectedMinute) && parseInt(this.selectedMinute) <= 30){
          this.selectedMinute = this.minutes[1];
      }else if (30 < parseInt(this.selectedMinute) || parseInt(this.selectedMinute) == 0){
          this.selectedMinute = this.minutes[0];
      }
      if (this.reminderDataObj?.reminder_audience?.length) {
          this.remindTo.forEach((item: any) => {
              item.checked = this.reminderDataObj.reminder_audience.includes(
                  item.label
              );
          });
      }
      if (this.reminderDataObj?.remind_via.length) {
          this.reminderOption.forEach((item: any) => {
              item.checked = this.reminderDataObj?.remind_via.includes(item.value);
          });
      }
      this.repeatValue = this.reminderDataObj?.repeat_value;
      this.selectedFrequency = this.reminderDataObj?.repeat_frequency;
      this.emitDataObj();
  }

  onFocus(type: any) {
      if (type == 'hh') {
          this.hours = Array.from({ length: 24 }, (_, i) =>
              i.toString().padStart(2, '0')
          );
      } else if (type == 'mm') {
          this.minutes = Array.from({ length: 6 }, (_, i) =>
              (i * 10).toString().padStart(2, '0')
          );
      }
  }

  onChangetoggle(event: any) {
      this.isTaskRecurring = event.checked;
      if (!this.isTaskRecurring) {
          this.resetAll();
      }
  }

  resetOnFrequencyChange() {
      this.weekDays.forEach((data: any) => {
          data.isCheck = false;
      });
      this.selectedWeekDays = [];
      this.selectedOnDay = '';
      this.customOnDay = '';
      this.recurringDate = '';
      setTimeout(() => {
          if(this.selectedFrequency === 'once'){
            this.repeatValue = 1;
          }
      }, 200);
  }

  resetAll() {
      this.weekDays.forEach((data: any) => {
          data.isCheck = false;
      });
      this.selectedFrequency = '';
      this.selectedWeekDays = [];
      this.selectedOnDay = '';
      this.customOnDay = '';
      this.recurringDate = '';
  }

  selectFrequency(_d: any, data: any) {
      if (data.source._selected) {
          this.isRepeat = true;
      } else {
          this.isRepeat = false;
      }
      this.setMaxDate();
      this.resetOnFrequencyChange();
  }

  selectDays() {
      this.selectedOnDayArr = this.numbers
          .filter((ele: any) => ele.isCheck)
          .map((ele) => ele.id);
  }

  emitDataObj(done?: any) {
      this.reminderOption[0].checked = true;
      const reminder_audience = this.remindTo
          .filter((item: any) => item.checked)
          .map((item: any) => item.label);
      const remind_via = this.reminderOption
          .filter((item: any) => item.checked)
          .map((item: any) => item.value);
      const epoch = new Date(this.selectedDueDate).getTime();
      let date = new Date(epoch);
      date.setSeconds(0);
      date.setMilliseconds(0);

      const data: any = {
          reminder_date: epoch,
          is_repeat: this.isRepeat,
          reminder_audience,
          remind_via,
          is_on: this.isOn,
          repeat_value: this.repeatValue,
          repeat_frequency: this.selectedFrequency || 'once',
          next_reminder_date_time: date?.getTime(),
          task_id: '',
      };
      this.selectedDate.emit(data);
      if (
          epoch &&
      this.isRepeat &&
      reminder_audience &&
      remind_via &&
      this.isOn &&
      this.repeatValue &&
      this.selectedFrequency &&
      done
      ) {
          this.closeMenu.emit(true);
          this.reminder();
      }
      if (!this.isOn) {
          this.closeMenu.emit(true);
          this.isOn = false;
          this.selectedDueDate = '';
          this.remindTo.forEach((item: any) => {
              item.checked = false;
          });
          this.selectedFrequency = '';
          this.selectedTime = '';
          this.repeatValue = '';
      }

      if (this.reminderDataObj && done) {
          this.updateReminder();
      }

      if (this.reminderDataObj?._id && !this.isOn) {
          this.deleteReminder();
          this.reminderDataObj = null;
      }
  }

  optionClick(list: any, eve: any) {
      list['checked'] = eve.checked;
  }

  allSelect(data: any, eve: any) {
      data.forEach((data: any) => {
          data['checked'] = eve.checked;
      });
  }

  isAllChecked(data: any) {
      return data.every((item: any) => item.checked);
  }

  setMaxDate() {
      this.maxDate = this.selectedDueDate;
  }

  clearData() {
      this.selectedDueDate = '';
      this.remindTo.forEach((item: any) => {
          item.checked = false;
      });
      this.selectedFrequency = '';
      this.selectedTime = '';
      this.repeatValue = '';
      this.selectedHour = '00';
      this.selectedMinute = '00';
      this.reminderOption[0].checked = true;
      this.reminderOption[1].checked = false;
      this.reminderOption[2].checked = false;
      this.emitDataObj();
  }

  close() {
      this.closeMenu.emit(true);
  }

  timeSelection() {
      this.selectedDueDate.setHours(this.selectedHour);
      this.selectedDueDate.setMinutes(this.selectedMinute);
      this.selectedTime = this.selectedHour + ':' + this.selectedMinute;
  }

  isValidForm() {
      const isReminderVia = this.reminderOption.some((item: any) => item.checked);
      const isReminderAudiance = this.remindTo.some((item: any) => item.checked);
      let selectTime = false;
      if (this.selectedTime) {
          selectTime = true;
      }
      return (
          !this.selectedDueDate ||
      !this.selectedFrequency ||
      !this.repeatValue ||
      !isReminderVia ||
      !isReminderAudiance ||
      !selectTime
      );
  }

  async updateReminder() {
      const reminder_audience = this.remindTo
          .filter((item: any) => item.checked)
          .map((item: any) => item.label);
      const remind_via = this.reminderOption
          .filter((item: any) => item.checked)
          .map((item: any) => item.value);
      const epoch = new Date(this.selectedDueDate).getTime();
      let date = new Date(epoch);
      date.setSeconds(0);
      date.setMilliseconds(0);

      this.reminderObj = {
          reminder_date: epoch,
          is_repeat: this.isRepeat,
          reminder_audience: reminder_audience,
          remind_via: remind_via,
          is_on: this.isOn,
          repeat_value: this.repeatValue,
          repeat_frequency: this.selectedFrequency,
          next_reminder_date_time: date?.getTime(),
          task_id: this.reminderDataObj?.task_id,
      };

      delete this.reminderObj.task_id;
      if (this.reminderObj.is_on && this.reminderDataObj?._id) {
          (
              await this.taskService.editReminder(
                  this.reminderDataObj?._id,
                  this.reminderObj
              )
          ).subscribe((res: any) => {
              this.apiService.showSuccess(res.message);
              this.updateFlag();
          });
      }
  }

  async deleteReminder() {
      (
          await this.taskService.deleteReminder(
              this.reminderDataObj?.task_id,
              this.reminderDataObj?._id
          )
      ).subscribe((res: any) => {
      // do something after the reminder response
          this.apiService.showSuccess(res.message);
          this.reminderOption[0].checked = true;
          this.reminderOption[1].checked = false;
          this.reminderOption[2].checked = false;
          this.updateFlag();
      });
  }

  async updateFlag() {
      const updateObj: any = {
          reminder_on: this.isOn,
          task_id: this.task_id,
      };
      (await this.taskService.editReminderFlag(updateObj));
  }

  async reminder() {
      const reminder_audience = this.remindTo
          .filter((item: any) => item.checked)
          .map((item: any) => item.label);
      const remind_via = this.reminderOption
          .filter((item: any) => item.checked)
          .map((item: any) => item.value);
      const epoch = new Date(this.selectedDueDate).getTime();
      let date = new Date(epoch);
      date.setSeconds(0);
      date.setMilliseconds(0);

      this.reminderObj = {
          reminder_date: epoch,
          is_repeat: this.isRepeat,
          reminder_audience: reminder_audience,
          remind_via: remind_via,
          is_on: this.isOn,
          repeat_value: this.repeatValue,
          repeat_frequency: this.selectedFrequency,
          next_reminder_date_time: date?.getTime(),
          task_id: this.task_id,
      };
      if (this.isOn && this.task_id && !this.reminderDataObj?._id) {
          (await this.taskService.addReminder(this.reminderObj)).subscribe(
              (res: any) => {
                  this.apiService.showSuccess(res.message);
                  this.getReminderDetail();
                  // do something after the reminder response
                  this.updateFlag();
              }
          );
      }
  }

  async getReminderDetail() {
      (await this.taskService.getReminder(this.task_id)).subscribe((res: any) => {
          this.reminderDataObj = res.result;
      });
  }
}
