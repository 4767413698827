import { Injectable } from '@angular/core';

import { BehaviorSubject, debounceTime, Observable, of, Subject } from 'rxjs';

import { ApiService } from '../api-service/api.service';
import { NAVIGATE_ROUTES } from '../../global/constants';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root',
})
export class DmsService {
    queryURL = '';
    apiEndPoint = NAVIGATE_ROUTES;

    // Observables
    $debouncingSubject = new Subject();
    $searchObserver = this.$debouncingSubject
        .asObservable()
        .pipe(debounceTime(1000));
    $viewSubject = new Subject();
    $myDMSListCount = new Subject();
    $myDMSAccess = new Subject();
    dms_details: any;
    $viewDMSDetails = new Subject();
    resetDmsSearchText = new BehaviorSubject<any>({});

    constructor(private _apiService: ApiService,private http: HttpClient) {}

    //DMS Route
    async getList(data: any) {
        this.queryURL = `page=${data.page}&limit=${data.limit}&`;
        if (data.type)
            this.queryURL += !data.search
                ? `type=${data.type}`
                : `type=${data.type}&`;
        if (data.search) this.queryURL += `search=${data.search}`;
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_DMS_LIST}/${data.current_folder}?${this.queryURL}`
        );
        return of(response?.data);
    }

    async addFolder(data: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.ADD_FOLDER,
            data
        );
        return of(response);
    }

    async getPath(id: any,rootPath?: string) {
        let url = `${this.apiEndPoint.GET_PATH}/${id}`;
        if(rootPath==='Team Folder'){
            url += '?myTeam=true';
        }    
        const response: any = await this._apiService.get(url);
        return of(response?.data);
    }

    uploadFile(data: any) {
        return this._apiService.postFormData(this.apiEndPoint.ADD_FILE, data);
    }

    async formData(data: any) {
        return this._apiService.formData(this.apiEndPoint.ADD_FILE, data);
    }

    async uploadFiles(data: any) {
        return this._apiService.formData(this.apiEndPoint.UPLOAD_FILE, data);
    }

    async rename(data: any, id: string) {
        const response: any = await this._apiService.put(
            `${this.apiEndPoint.GET_DMS_LIST}/${id}`,
            data
        );
        return of(response?.data);
    }

    async color(data: any, id: string) {
        const response: any = await this._apiService.post(
            `${this.apiEndPoint.CHANGE_FOLDER_COLOR}/${id}`,
            data
        );
        return of(response);
    }

    async getCompanyAccesses() {
        const response: any = await this._apiService.get(
            this.apiEndPoint.GET_COMPANY_ACCESSES
        );
        return of(response.data);
    }

    // Recent open files Route
    async addRecent(data: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.RECENT,
            data
        );
        return of(response?.data);
    }

    async recentList(data: any) {
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.RECENT}?page=${data.page}&limit=${data.limit}&search=${data.search}`
        );
        return of(response?.data);
    }

    //Bin Routes
    async deleteFolder(id: any) {
        const response: any = await this._apiService.delete(
            `${this.apiEndPoint.DELETE_FOLDER_BIN}/${id}`
        );
        return of(response);
    }

    async deleteFile(id: any) {
        const response: any = await this._apiService.delete(
            `${this.apiEndPoint.DELETE_FILE_BIN}/${id}`
        );
        return of(response);
    }

    async binList(data: any) {
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_BIN}?page=${data.page}&limit=${data.limit}&search=${data.search}`
        );
        return of(response?.data);
    }

    async empty() {
        const response: any = await this._apiService.delete(
            this.apiEndPoint.EMPTY_BIN
        );
        return of(response);
    }

    async restore(ids: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.RESTORE_BIN,
            { ids: ids }
        );
        return of(response);
    }

    async setFavourite(id: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.FAVOURTE_DMS,
            { id: id }
        );
        return of(response);
    }
    async unFavourite(id: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.UNFAVOURTE_DMS,
            { id: id }
        );
        return of(response);
    }

    async getFavouriteDMSList(data: any) {
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.FAVOURTE_DMS_LIST}?page=${data.page}&limit=${data.limit}&search=${data.search}&type=${data.type}`
        );
        return of(response?.data);
    }

    async getSharedDMSList(data: any) {
        this.queryURL = `page=${data.page}&limit=${data.limit}&`;
        if (data.type)
            this.queryURL += !data.search
                ? `type=${data.type}`
                : `type=${data.type}&`;
        if (data.search) this.queryURL += `search=${data.search}`;
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_SHARED_DMS_LIST}/${data.current_folder}?${this.queryURL}`
        );
        return of(response?.data);
    }

    async shareDMS(data: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.SHARE_DMS,
            data
        );
        return of(response);
    }

    //Team Folders
    async getRoot() {
        const response: any = await this._apiService.get(
            this.apiEndPoint.GET_ROOT_TEAM_FOLDERS
        );
        return of(response?.data);
    }

    async getTeamFolder(data: any) {
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_TEAM_FOLDER}/${data.current_folder}?page=${data.page}&limit=${data.limit}&search=${data.search}&type=${data.type}`
        );
        return of(response?.data);
    }

    //Progress bar
    async getUploadProgress() {
        const response: any = await this._apiService.get(
            this.apiEndPoint.GET_PROGRESS
        );
        return of(response?.data);
    }

    async copyFile(id: any) {
        const response: any = await this._apiService.post(
            this.apiEndPoint.COPY_FILE,
            { id: id }
        );
        return of(response);
    }

    async getDMSDetailsById(id: any) {
        this.dms_details = {};
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.DMS_DETAILS}/${id}`
        );
        return of(response);
    }

    async orgStorageDetails() {
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.ORGANIZATION_DMS_DETAILS}`
        );
        return of(response);
    }

    async getOrgMemberList(data: any) {
        this.queryURL = `page=${data.page}&limit=${data.limit}`;
        if (data.search) this.queryURL += `&search=${data.search}`;
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_ORG_MEMBER_LIST}?${this.queryURL}`
        );
        return of(response?.data);
    }

    async getMembersDocumentList(data: any) {
        this.queryURL = `page=${data.page}&limit=${data.limit}&type=${data.type}`;
        if (data.userId) this.queryURL += `&user_id=${data.userId}`;
        if (data.search) this.queryURL += `&search=${data.search}`;
        const response: any = await this._apiService.get(
            `${this.apiEndPoint.GET_MEMBER_DOCUMENT_LIST}?${this.queryURL}`
        );
        return of(response?.data);
    }

    async deleteStorageFile(
        fileIds: any
    ): Promise<Observable<any>> {
        const url = `${this.apiEndPoint.STORAGE_BULK_DELETE}`;
        const response: any = await this._apiService.deleteWithForm(url,{file_ids:fileIds});
        return of(response);
    }

    fetchFile(filePath: string): Observable<Blob> {
        return this.http.get(filePath, { responseType: 'blob' });
    }
}
